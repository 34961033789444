import React, { useEffect, useState } from "react";
import { useLocation, Redirect, useHistory } from "react-router-dom";
import { CustomButton } from "../../../components/atoms/CustomButton";
import CustomInputBox from "../../../components/atoms/CustomInputBox";
import axios from "../../../axios";
import { toast } from "react-toastify";
import { PencilIcon } from "@heroicons/react/outline";
import CustomDropDownSearchSelect from "../../../components/atoms/CustomDropDownSearchSelect";
import { errorToast } from "../../../util/toast";
import { validateSeasonalPlanningEdit } from "../../Master Data/common/validations";

const SeasonalPlanningEdit = () => {
  const updatePropList = ["targetAcre", "fieldMotivatorId"];

  const location = useLocation();
  const history = useHistory();
  // const id = JSON.parse(location.state).data.id.value;
  const [data, setData] = useState(JSON.parse(location.state).data);
  const [id, setId] = useState(JSON.parse(location.state).id);

  console.log(data.id, "data");

  const [fieldMotivatorList, setFieldMotivatorList] = useState(null);
  const [error, setError] = useState(null);

  const valueSetter = (propertyName, newValue) => {
    const newData = { ...data };
    newData[propertyName] = newValue;
    setData(newData);
  };
  const getItemName = (obj, prop1, prop2) => {
    return obj[prop2];
  };

  useEffect(() => {
    const newObj = {};
    Object.keys(data).forEach((key) => {
      if (data[key]?.hasOwnProperty("show")) {
        newObj[key] = data[key].value;
      } else {
        newObj[key] = data[key];
      }
    });
    if (Object.keys(newObj).length > 0)
      setData({
        ...newObj,
        fieldMotivatorId: data.FieldMotivator.id,
        targetAcre: data.targetAcre.value,
      });

    axios
      .get(`users/index`, { params: { role: "Field Motivator", isActive: 1 } })
      .then((response) => {
        console.log(response.data.Result);
        setFieldMotivatorList(response.data.Result.users);
      })
      .catch((error) => {
        console.log(error);
        errorToast(error?.response?.data?.Comments);
      });
  }, []);

  if (!location.state) {
    return <Redirect to="/" />;
  }

  const createSendingData = async (obj) => {
    return new Promise((resolve, reject) => {
      let newData = {};
      Object.keys(obj).forEach((property, index) => {
        if (updatePropList.includes(property)) {
          newData[property] = obj[property];
        }
        if (index === Object.keys(obj).length - 1) resolve(newData);
      });
    });
  };

  const editSeasonalPlanning = () => {
    if (data?.targetAcre === NaN) {
      setError("please specify the name");
    } else {
      createSendingData(data)
        .then((newData) => {
          console.log(newData, " is the new data");
          if (validateSeasonalPlanningEdit(newData))
            return axios.put(`seasonalPlannings/${data.id}`, newData);
          else throw { message: "please rectify the data" };
        })
        .then((response) => {
          if (response?.data?.ResponseMessage === "SUCCESS") {
            toast.success("Update successful!", { position: "top-center" });
            history.goBack();
          }
        })
        .catch((error) => {
          errorToast(error?.response?.data?.Comments);
          console.log(error);
        });
    }
  };

  return data !== null ? (
    <div className=" m-3 p-3">
      <div className="flex">
        <span>
          <PencilIcon className="h-5 w-5 transform text-black mt-4 mr-1" />
        </span>
        <span className=" py-4 text-gray-700 text-base font-bold text-left">
          Edit Seasonal Planning
        </span>
      </div>
      <div className="flex flex-wrap justify-left">
        <div className="w-1/2">
          <div className="flex flex-wrap gap-x-3 gap-y-4 mb-4">
            <CustomInputBox
              title="Mandi"
              disabled={true}
              borderDisable={true}
              value={getItemName(data?.MandiMaster, "identifier", "name")}
            />
            <CustomInputBox
              title="Variety"
              borderDisable={true}
              value={getItemName(data?.VarietyMaster, "identifier", "name")}
              disabled={true}
            />
            <CustomInputBox
              title="Incharge"
              value={getItemName(data.Incharge, "identifier", "name")}
              borderDisable={true}
              disabled={true}
              className="w-1/2"
            />

            <div className="flex w-full gap-x-2">
              <div className="w-full">
                <CustomInputBox
                  title="Target Acre"
                  value={data.targetAcre}
                  className="w-1/2"
                  valueSetter={(value) => {
                    valueSetter("targetAcre", Number(value));
                  }}
                />
              </div>
              <div className=" w-full">
                {console.log("field motivator id is", data.fieldMotivatorId)}
                <CustomDropDownSearchSelect
                  items={
                    fieldMotivatorList !== null
                      ? fieldMotivatorList.map((element) => {
                          return getItemName(element, "identifier", "name");
                        })
                      : []
                  }
                  label={"Field Motivator"}
                  placeholder={"Field Motivator"}
                  selected={
                    fieldMotivatorList !== null &&
                    data.fieldMotivatorId !== null
                      ? getItemName(
                          fieldMotivatorList.filter((element) => {
                            return element.id === data.fieldMotivatorId;
                          })[0],
                          "identifier",
                          "name"
                        )
                      : null
                  }
                  handler={(value) => {
                    if (value !== null && fieldMotivatorList !== null) {
                      valueSetter(
                        "fieldMotivatorId",

                        fieldMotivatorList.filter((element) => {
                          console.log(element);
                          return (
                            getItemName(element, "identifier", "name") === value
                          );
                        })[0].id
                      );
                    } else {
                      valueSetter("fieldMotivatorId", null);
                    }
                  }}
                />
              </div>
            </div>
          </div>
          <div className="flex mt-8 justify-between">
            <CustomButton
              btnName="Cancel"
              className="p-2 bg-gray-500 hover:bg-gray-700 text-lg"
              onClick={() => {
                history.goBack();
              }}
            />
            <CustomButton
              btnName="Update"
              className="p-2 bg-green-500 hover:bg-green-700 text-lg"
              onClick={editSeasonalPlanning}
            />
          </div>
        </div>
      </div>
      <div className="text-xl text-red-500">{error}</div>
      <div className="flex w-max gap-x-10"></div>
    </div>
  ) : null;
};
export default SeasonalPlanningEdit;
