import React, { useState } from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { toast } from 'react-toastify';

export const appToast = (message, time = 5000) => {
	toast(message, {
		position: 'top-left',
		autoClose: time,
		hideProgressBar: false,
		closeOnClick: true,
		pauseOnHover: false,
		draggable: false,
		progress: undefined,
	});
};

export const uploadToast = (message) => {
	toast(message, {
		position: 'top-left',
		hideProgressBar: false,
		closeOnClick: false,
		pauseOnHover: false,
		draggable: false,
		progress: undefined,
	});
};

export const successToast = (message, time = 5000) => {
	console.log("hello")
	toast.success(message, {
		position: 'top-center',
		autoClose: time,
		hideProgressBar: true,
		closeOnClick: true,
		pauseOnHover: false,
		draggable: false,
		progress: undefined,
	});
};

export const infoToast = (message, time = 5000) => {
	toast.info(message, {
		position: 'top-left',
		autoClose: time,
		hideProgressBar: false,
		closeOnClick: true,
		pauseOnHover: false,
		draggable: false,
		progress: undefined,
	});
};

export const warningToast = (message, time = 5000) => {
	toast.warning(message, {
		position: 'top-left',
		autoClose: time,
		hideProgressBar: false,
		closeOnClick: true,
		pauseOnHover: false,
		draggable: false,
		progress: undefined,
	});
};

export const errorToast = (message, time = 5000) => {
	toast.error(message, {
		position: 'top-center',
		autoClose: time,
		hideProgressBar: true,
		closeOnClick: true,
		pauseOnHover: false,
		draggable: false,
		progress: undefined,
	});
};

const Toast = (props) => {
	const [isOpen, setOpen] = useState(true);

	const handleClose = (event, reason) => {
		if (reason === 'clickaway') {
			return;
		}
		setOpen(false);
		if (props.onClose) props.onClose();
	};

	function Alert(props) {
		return <MuiAlert elevation={6} variant="filled" {...props} />;
	}

	return (
		<Snackbar
			anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
			open={isOpen}
			autoHideDuration={3000}
			onClose={handleClose}
		>
			<Alert onClose={handleClose} severity={props.intent}>
				{props.message}
			</Alert>
		</Snackbar>
	);
};
export default Toast;
