import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import MasterDataDelete from "../common/MasterDataDelete";
import InsectDiseaseHome from "./InsectDiseaseHome.jsx";
import InsectDiseaseEdit from "./InsectDiseaseEdit";
import InsectDiseaseDisplay from "./InsectDiseaseDisplay";
import InsectDiseaseAdd from "./InsectDiseaseAdd";

const InsectDiseaseRoutes = () => {
    return (
        <BrowserRouter basename="/home/masterData/insectDisease/">
            <Switch>
                <Route path="/" component={InsectDiseaseHome} exact />
                <Route path="/add" component={InsectDiseaseAdd} />
                <Route path="/edit/:id" component={InsectDiseaseEdit} />
                <Route path="/delete/:id" component={MasterDataDelete} />
                <Route path="/display" component={InsectDiseaseDisplay} />
                <Route component={() => (<Redirect to="/" />)} />
            </Switch>
        </BrowserRouter>
    );
}

export default InsectDiseaseRoutes;