import React from 'react';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { CustomButton } from '../atoms/CustomButton';
import axios from '../../axios';
import  { errorToast, successToast } from '../../util/toast.js';
import { useHistory } from 'react-router-dom';


const initialValues = {
    email: '',
};

const validationSchema = Yup.object({
    email: Yup.string()
        .email('Invalid email address')
        .required('Email is required'),

});



const ForgotPasswordForm = () => {

    const history = useHistory();

    function forgot(values) {
        axios.post('users/forgotPassword', {
            email: values.email,
        })
            .then(function (response) {
                console.log(response.data.Comments);
                successToast(response.data.Comments ,8000);
                history.push('/forgotpassword');
                // history.push('/otp?email='+values);

            })
            .catch(function (error) {
                // console.log(error?.response?.data?.Comments);
                errorToast(error?.response?.data?.Comments);
            })
    }

  

    return (

        <Formik
            initialValues = {initialValues}

            validationSchema = {validationSchema}

            onSubmit={(values , {resetForm}) => {
                forgot(values);
                resetForm();
            }}
        >

            <div className="">
                <div className="flex flex-col sm:max-w-md  mx-auto bg-white  px-6 pt-5 pb-5 rounded-3xl border-t-8 border-green-400">
                    <span className=" font-robo  text-large font-bold text-center pt-6 pb-6 text-green-500"> Enter your phone number or recovery email </span>
                    <Form className="flex flex-col space-y-4">
                        <div className="flex flex-col">
                            <Field className="border border-gray-400 rounded-md pl-4 py-2 font-light focus:outline-none hover:border-green-400"
                                name="email" type="text"
                                placeholder="Email or phone number" />
                            <div className=" font-robo text-red-700 text-xs">
                                <ErrorMessage name="email" />
                            </div>

                        </div>


                        <div className="text-center pb-6 pt-4">
                            <CustomButton type="submit" btnName="Next" className="bg-green-500 hover:bg-green-600 p-2"/>
                        </div>
                        {/* <div className="text-center pb-6 pt-4">
                        <button type="submit"
                            className="bg-green-500 
                            rounded-md  py-4 w-1/2"
                        > Submit
                        </button>
                        </div> */}

                    </Form>
                </div>

            </div>



        </Formik>
        
    );
}

export default ForgotPasswordForm;



