import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import MasterDataDelete from "../common/MasterDataDelete";
import MandiMasterHome from "./MandiMasterHome";
import MandiMasterEdit from "./MandiMasterEdit";
import MandiMasterDisplay from "./MandiMasterDisplay";
import MandiMasterAdd from "./MandiMasterAdd";

const MandiMasterRoutes = () => {
  return (
    <BrowserRouter basename="/home/masterData/mandiMaster/">
      <Switch>
        <Route path="/" component={MandiMasterHome} exact />
        <Route path="/add" component={MandiMasterAdd} />
        <Route path="/edit/:id" component={MandiMasterEdit} />
        <Route path="/delete/:id" component={MasterDataDelete} />
        <Route path="/display" component={MandiMasterDisplay} />
        <Route component={() => <Redirect to="/" />} />
      </Switch>
    </BrowserRouter>
  );
};

export default MandiMasterRoutes;
