import React from "react";
import { useLocation, Redirect } from "react-router-dom";
import { EyeIcon } from "@heroicons/react/outline";
import CustomInputBox from "../../../components/atoms/CustomInputBox";

const MandiMasterDisplay = () => {
  const location = useLocation();
  if (!JSON.parse(location.state)) return <Redirect to="/" />;
  const { data } = JSON.parse(location.state);

  return (
    <div className="overflow-hidden m-3 p-3">
      <div className="flex">
        <span>
          <EyeIcon className="h-5 w-5 transform text-black mt-4 mr-1" />
        </span>
        <span className=" py-4 text-gray-700 text-base font-bold text-left">
          Display mandi master
        </span>
      </div>
      <div className="grid grid-cols-2 justify-left">
        <CustomInputBox
          title="Zone"
          value={data.zone.value}
          disabled={true}
          borderDisable={true}
        />
        <CustomInputBox
          title="Name"
          value={data.name.value}
          disabled={true}
          borderDisable={true}
        />
        <CustomInputBox
          title="Addreses"
          value={data.address}
          disabled={true}
          borderDisable={true}
        />
        <CustomInputBox
          title="District"
          value={data.district.value}
          disabled={true}
          borderDisable={true}
        />
        <CustomInputBox
          title="State"
          value={data.state.value}
          disabled={true}
          borderDisable={true}
        />
      </div>
      <div className="flex w-max gap-x-10"></div>
    </div>
  );
};
export default MandiMasterDisplay;
