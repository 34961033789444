import React from 'react';

const Footer = () => {
    return (
        <div className="flex justify-between text-sm text-black `dbg-black">
            <span className="pl-4"> Developed by vital wires </span>
            <span className="pr-4"> ©Copyright.2021</span>
        </div>
    );
}

export default Footer;