import React, { useEffect, useState } from "react";
import { useLocation, Redirect, useHistory } from "react-router-dom";
import { CustomButton } from "../../../components/atoms/CustomButton";
import CustomInputBox from "../../../components/atoms/CustomInputBox";
import axios from "../../../axios";
import { PencilIcon } from "@heroicons/react/outline";
import CustomDropDownSearchSelect from "../../../components/atoms/CustomDropDownSearchSelect";
import { errorToast, successToast } from "../../../util/toast";
import CustomDateSelector from "../../../components/atoms/CustomDateSelector";

import { validateAllVisitsEdit } from "../../Master Data/common/validations";
import CustomToggleSwitch from "../../../components/atoms/CustomToggleSwitch";
import { TrashIcon, PlusIcon } from "@heroicons/react/outline";

const AllVisitEdit = () => {
  const updatePropList = [
    "visitDate",
    "cropStageId",
    "cropHealthId",
    "insectDiseaseList",
    "fertilizerId",
    "fertilizerIdArray",
    "recommendedChemical",
    "otherRecommendation",
    "usedFertilizerId",
    "usedFertilizerIdArray",
    "usedFertilizerDate",
    "usedChemicalId",
    "usedChemicalIdArray",
    "usedChemicalDate",
    "waterUsage",
    "irrigationDates",
    "irrigationStages",
    "unresolvedFarmerQuery",
    "actionRequired",
    "generalRemarks",
    "farmerVarietyId",
    "contactPerson",
    "contactPersonMobile",
    "usedOtherIngredient",
    "otherIngredientUsedDate",
    "recommended",
    "used",
  ];

  const location = useLocation();
  const history = useHistory();
  const id = JSON.parse(location.state).data.id;
  const [data, setData] = useState([]);

  const [chemicalList, setChemicalList] = useState(null);
  const [fertilizerList, setFertilizerList] = useState(null);
  const [insectDiseaseList, setInsectDiseaseList] = useState(null);
  const [cropHealthList, setCropHealthList] = useState(null);
  const [cropStageList, setCropStageList] = useState(null);
  const [error, setError] = useState(null);
  const [numberIrrigation, setNumberIrrigation] = useState([]);
  const [dates, setDates] = useState([]);
  const [stages, setStages] = useState([]);
  const [recommended, setRecommended] = useState([]);
  const [stagesName, setStagesName] = useState([]);

  const [actualChemicalList, setActualChemicalList] = useState([]);
  const [actualInsectList, setActualInsectList] = useState([]);

  const valueSetter = (propertyName, newValue) => {
    const newData = { ...data[0] };
    newData[propertyName] = newValue;
    setData([newData]);
  };
  const getItemName = (obj, prop1, prop2) => {
    return obj[prop2];
  };

  const searchObject = (items, target, property) => {
    return items.filter((element) => {
      return element[property] === target;
    })[0];
  };

  const iFormValueSetter = (purchaseIndex, iFormIndex, property, value) => {
    if (value !== null) {
      let purchase = [...data];
      purchase[purchaseIndex].recommended[iFormIndex][property] = value;
      setData(purchase);
    }
  };

  const addRecommended = (index) => {
    let purchase = [...data];
    purchase[index].recommended = [
      ...purchase[index].recommended,
      { ingredient: null, brand: null, usesType: null },
    ];
    setData(purchase);
  };

  const removeRecommended = (index) => {
    let purchase = [...data];
    purchase[index].recommended = purchase[index].recommended.filter(
      (element, i) => {
        return i !== index;
      }
    );
    setData(purchase);
  };

  const iFormValueSetter2 = (purchaseIndex, iFormIndex, property, value) => {
    if (value !== null) {
      let purchase = [...data];
      purchase[purchaseIndex].used[iFormIndex][property] = value;
      setData(purchase);
    }
  };

  const addUsed = (index) => {
    let purchase = [...data];
    purchase[index].used = [
      ...purchase[index].used,
      { ingredient: null, brand: null, usesType: null, date: null },
    ];
    setData(purchase);
  };

  const removeUsed = (index) => {
    let purchase = [...data];
    purchase[index].used = purchase[index].used.filter((element, i) => {
      return i !== index;
    });
    setData(purchase);
  };

  useEffect(() => {
    axios
      .get(`allVisit/${id}`)
      .then((response) => {
        const temp = response.data.Result.AllVisit;
        temp.usedFertilizerDate =
          temp.usedFertilizerDate !== null
            ? new Date(temp.usedFertilizerDate)
            : null;
        temp.usedChemicalDate =
          temp.usedChemicalDate !== null
            ? new Date(temp.usedChemicalDate)
            : null;

        temp.recommendedChemical = temp.RecommendedChemicals.map((element) => {
          return element.ChemicalMaster.id;
        });

        temp.insectDiseaseList = temp.InsectDiseaseLists.map((element) => {
          return element.InsectsDiseasesMaster.id;
        });
        if (temp.recommended) {
          temp.recommended = JSON.parse(temp.recommended);
        }
        if (temp.fertilizerIdArray) {
          temp.fertilizerIdArray = JSON.parse(temp.fertilizerIdArray);
        }
        if (temp.usedFertilizerIdArray) {
          temp.usedFertilizerIdArray = JSON.parse(temp.usedFertilizerIdArray);
        }
        if (temp.usedChemicalIdArray) {
          temp.usedChemicalIdArray = JSON.parse(temp.usedChemicalIdArray);
        }
        if (temp.used) {
          temp.used = JSON.parse(temp.used);
        }
        console.log(temp, "data");
        setData([temp]);
        setStages(JSON.parse(response.data.Result.AllVisit.irrigationStages));
        setDates(JSON.parse(response.data.Result.AllVisit.irrigationDates));
        let arr = [];
        for (let i = 0; i < response.data.Result.AllVisit.waterUsage; i++) {
          arr.push(i);
        }
        setNumberIrrigation(arr);
        return axios.get(`cropStage`);
      })
      .then((response) => {
        setCropStageList(response.data.Result.CropStage);
        return axios.get(`cropHealth`);
      })
      .then((response) => {
        setCropHealthList(response.data.Result.CropHealth);
        return axios.get(`fertilizerMaster/load`);
      })
      .then((response) => {
        setFertilizerList(response.data.Result.FertilizerMaster);
        return axios.get(`insectsDiseasesMaster/load`);
      })
      .then((response) => {
        setInsectDiseaseList(response.data.Result.insectDiseaseMaster);
        return axios.get(`chemicalMasters/load`);
      })
      .then((response) => {
        setChemicalList(response.data.Result.ChemicalMaster);
      })
      .catch((error) => {
        console.log(error);
        errorToast(error?.response?.data?.Comments, "We Have An Error");
      });
  }, []);

  useEffect(() => {
    let list = chemicalList?.filter((chem) => {
      if (chem.insectDiseaseList) {
        let exists = false;
        let check = data[0]?.InsectDiseaseLists.map((item) => {
          return item.InsectsDiseasesMaster.id;
        });
        if (check.includes(parseInt(chem.insectDiseaseList))) {
          exists = true;
        }
        if (exists) {
          return chem;
        }
      }
    });
    console.log(list, "LIST");
    setActualChemicalList(list);
  }, [data[0]?.InsectDiseaseLists, chemicalList]);
  console.log(data, "DATAAAAAAA");
  if (!location.state) {
    return <Redirect to="/" />;
  }

  const createSendingData = async (obj) => {
    return new Promise((resolve, reject) => {
      let newData = {};
      Object.keys(obj).forEach((property, index) => {
        if (updatePropList.includes(property)) {
          newData[property] = obj[property];
        }
        if (index === Object.keys(obj).length - 1) resolve(newData);
      });
    });
  };
  const getDateString = (date) => {
    let year = date.getFullYear();
    let month = date.getMonth() + 1;
    month = month < 10 ? "0" + month : month;
    let day = date.getDate();
    day = day < 10 ? "0" + day : day;
    return day + "/" + month + "/" + year;
  };
  const editAllVisit = () => {
    createSendingData(data[0])
      .then((newData) => {
        newData.fertilizerIdArray = JSON.stringify(newData.fertilizerIdArray);
        newData.usedFertilizerIdArray = JSON.stringify(
          newData.usedFertilizerIdArray
        );
        newData.usedChemicalIdArray = JSON.stringify(
          newData.usedChemicalIdArray
        );
        if (validateAllVisitsEdit(newData))
          return axios.put(`allVisit/${data[0].id}`, newData);
        else throw { message: "Please rectify your data" };
      })
      .then((response) => {
        if (response?.data?.ResponseMessage === "SUCCESS") {
          successToast("Update successful!");
          history.goBack();
        }
      })
      .catch((error) => {
        errorToast(error?.response?.data?.Comments);
        console.log(error);
      });
  };

  return data.length ? (
    <div className=" m-3 p-3">
      <div className="flex">
        <span>
          <PencilIcon className="h-5 w-5 transform text-black mt-4 mr-1" />
        </span>
        <span className=" py-4 text-gray-700 text-base font-bold text-left">
          Edit All Visits
        </span>
      </div>
      <div className="w-2/3">
        <div className="">
          <div className="mb-10">
            <div className="w-full grid grid-cols-2  gap-x-3">
              <CustomInputBox
                title={"Farmer Name"}
                borderDisable={true}
                disabled={true}
                value={getItemName(
                  data[0].FarmerVariety.FarmerMaster,
                  "identifier",
                  "name"
                )}
              />
              <CustomInputBox
                title={"Variety"}
                borderDisable={true}
                disabled={true}
                value={getItemName(
                  data[0].FarmerVariety.VarietyMaster,
                  "identifier",
                  "name"
                )}
              />
              <CustomInputBox
                title={"Contact Person"}
                value={data[0].contactPerson}
                valueSetter={(value) => {
                  valueSetter("contactPerson", value);
                }}
              />
              <CustomInputBox
                title={"Contact Person Mobile"}
                value={data[0].contactPersonMobile}
                valueSetter={(value) => {
                  valueSetter("contactPersonMobile", value);
                }}
              />

              <div className="w-full">
                <CustomDateSelector
                  label={"Visit Date"}
                  date={new Date(data[0].visitDate)}
                  disabled={true}
                />
              </div>
              {/* //Crop Stage Id */}
              <div className="w-full">
                <CustomDropDownSearchSelect
                  label={"Crop Stage"}
                  items={
                    cropStageList !== null
                      ? cropStageList.map((element) => {
                          return getItemName(element, "identifier", "name");
                        })
                      : []
                  }
                  selected={
                    cropStageList !== null && data[0].cropStageId !== null
                      ? getItemName(
                          searchObject(
                            cropStageList,
                            data[0].cropStageId,
                            "id"
                          ),
                          "identifier",
                          "name"
                        )
                      : null
                  }
                  handler={(value) => {
                    if (value !== null) {
                      valueSetter(
                        "cropStageId",
                        cropStageList.filter((element) => {
                          return (
                            getItemName(element, "identifier", "name") === value
                          );
                        })[0].id
                      );
                    } else {
                      valueSetter("cropStageId", null);
                    }
                  }}
                />
              </div>
              <div className="w-full">
                <CustomDropDownSearchSelect
                  label={"Crop Health"}
                  items={
                    cropHealthList !== null
                      ? cropHealthList.map((element) => {
                          return getItemName(element, "identifier", "name");
                        })
                      : []
                  }
                  selected={
                    cropHealthList !== null && data[0].cropHealthId !== null
                      ? getItemName(
                          searchObject(
                            cropHealthList,
                            data[0].cropHealthId,
                            "id"
                          ),
                          "identifier",
                          "name"
                        )
                      : null
                  }
                  handler={(value) => {
                    if (value !== null) {
                      valueSetter(
                        "cropHealthId",
                        cropHealthList.filter((element) => {
                          return (
                            getItemName(element, "identifier", "name") === value
                          );
                        })[0].id
                      );
                    } else {
                      valueSetter("cropHealthId", null);
                    }
                  }}
                />
              </div>

              <div className="mb-5 w-full">
                <CustomDropDownSearchSelect
                  label={"Insect/Disease"}
                  multi={true}
                  items={
                    insectDiseaseList !== null
                      ? insectDiseaseList.map((element) => {
                          return getItemName(
                            element,
                            "identifier",
                            "insectDiseaseName"
                          );
                        })
                      : []
                  }
                  selected={
                    insectDiseaseList !== null &&
                    data[0].insectDiseaseList !== null
                      ? insectDiseaseList
                          .filter((item) => {
                            return (
                              data[0].insectDiseaseList.indexOf(item.id) !== -1
                            );
                          })
                          .map((item) =>
                            getItemName(item, "identifier", "insectDiseaseName")
                          )
                      : []
                  }
                  handler={(value) => {
                    valueSetter(
                      "insectDiseaseList",
                      insectDiseaseList
                        .filter((item) => {
                          return (
                            value.indexOf(
                              getItemName(
                                item,
                                "identifier",
                                "insectDiseaseName"
                              )
                            ) !== -1
                          );
                        })
                        .map((item) => {
                          return item.id;
                        })
                    );

                    setActualInsectList(
                      insectDiseaseList
                        .filter((item) => {
                          return (
                            value.indexOf(
                              getItemName(
                                item,
                                "identifier",
                                "insectDiseaseName"
                              )
                            ) !== -1
                          );
                        })
                        .map((item) => {
                          return item.id;
                        })
                    );
                  }}
                />
              </div>

              <div className="mb-5">
                {data[0]?.recommended?.map((element, varIndex) => {
                  console.log(actualChemicalList, "Actual Data");
                  console.log(element, "Element data");
                  return (
                    <div
                      className="flex flex-row justify-between"
                      style={{ width: "200%" }}
                      key={varIndex}
                    >
                      <CustomDropDownSearchSelect
                        label={"Recommended Ingredient"}
                        items={
                          actualChemicalList !== null &&
                          actualChemicalList?.length > 0
                            ? actualChemicalList
                                .filter((element) => element?.isRecommended)
                                .map((element) => {
                                  return getItemName(
                                    element,
                                    "identifier",
                                    "activeIngredient"
                                  );
                                })
                            : []
                        }
                        handler={(newValues) => {
                          iFormValueSetter(
                            0,
                            varIndex,
                            "ingredient",
                            newValues
                          );
                        }}
                        selected={element.ingredient}
                      />
                      <CustomDropDownSearchSelect
                        label={"Recommended Brand"}
                        items={
                          actualChemicalList !== null &&
                          actualChemicalList?.length > 0
                            ? actualChemicalList
                                .filter(
                                  (element) => (element?.isRecommended 
                                    && element?.activeIngredient === data[0].recommended[varIndex]['ingredient'])
                                )
                                .map((element) => {
                                  return getItemName(
                                    element,
                                    "identifier",
                                    "brandName"
                                  );
                                })
                            : []
                        }
                        handler={(newValues) => {
                          iFormValueSetter(0, varIndex, "brand", newValues);
                        }}
                        selected={element.brand}
                      />
                      <CustomDropDownSearchSelect
                        label={"Recommended UsesType"}
                        items={
                          actualChemicalList !== null &&
                          actualChemicalList?.length > 0
                            ? actualChemicalList
                                .filter(
                                  (element) => (element?.isRecommended 
                                    && element?.activeIngredient === data[0].recommended[varIndex]['ingredient'] 
                                    && element?.brandName === data[0].recommended[varIndex]['brand'])
                                )
                                .map((element) => {
                                  return getItemName(
                                    element,
                                    "identifier",
                                    "UsesType"
                                  ).usesType;
                                })
                            : []
                        }
                        handler={(newValues) => {
                          iFormValueSetter(0, varIndex, "usesType", newValues);
                        }}
                        selected={element.usesType}
                      />
                      {varIndex !== data[0].recommended.length - 1 ? (
                        <span
                          onClick={() => {
                            removeRecommended(0);
                          }}
                          className="h-6 w-6 mt-8 mr-3 text-gray-500 hover:text-red-500 hover:p-1 z-10"
                        >
                          <TrashIcon />
                        </span>
                      ) : (
                        <span
                          className="h-6 w-6 mt-8 text-white rounded-md bg-green-500 z-10"
                          onClick={() => {
                            console.log("click");
                            addRecommended(0);
                          }}
                        >
                          <PlusIcon />
                        </span>
                      )}
                    </div>
                  );
                })}
              </div>
              <br />

              <div className="mb-5">
                {data[0]?.used?.map((element, varIndex) => {
                  console.log("Element ", varIndex, " : ", element);
                  return (
                    <div
                      className="flex flex-row justify-between"
                      style={{ width: "240%" }}
                      key={varIndex}
                    >
                      <CustomDropDownSearchSelect
                        label={"Used Chemical Ingredient"}
                        items={
                          actualChemicalList !== null &&
                          actualChemicalList?.length > 0
                            ? actualChemicalList.map((element) => {
                                return getItemName(
                                  element,
                                  "identifier",
                                  "activeIngredient"
                                );
                              })
                            : []
                        }
                        handler={(newValues) => {
                          console.log(newValues, "NEW AVLUEs");

                          iFormValueSetter2(
                            0,
                            varIndex,
                            "ingredient",
                            newValues
                          );
                        }}
                        selected={element.ingredient}
                      />
                      <CustomDropDownSearchSelect
                        label={"Used Chemical Brand"}
                        items={
                          actualChemicalList !== null &&
                          actualChemicalList?.length > 0
                            ? actualChemicalList
                            .filter(
                              (element) => (element?.activeIngredient === data[0].used[varIndex]['ingredient'])
                            )
                            .map((element) => {
                                return getItemName(
                                  element,
                                  "identifier",
                                  "brandName"
                                );
                              })
                            : []
                        }
                        handler={(newValues) => {

                          iFormValueSetter2(0, varIndex, "brand", newValues);
                        }}
                        selected={element.brand}
                      />
                      <CustomDropDownSearchSelect
                        label={"Used Chemical UsesType"}
                        items={
                          actualChemicalList !== null &&
                          actualChemicalList?.length > 0
                            ? actualChemicalList.filter(
                              (element) => (element?.activeIngredient === data[0].used[varIndex]['ingredient'] 
                                && element?.brandName === data[0].used[varIndex]['brand'])
                            )
                            .map((element) => {
                                return getItemName(
                                  element,
                                  "identifier",
                                  "UsesType"
                                ).usesType;
                              })
                            : []
                        }
                        handler={(newValues) => {

                          iFormValueSetter2(0, varIndex, "usesType", newValues);
                        }}
                        selected={element.usesType}
                      />
                      <CustomDateSelector
                        label={"Used Chemical Date"}
                        date={
                          element.date !== null ? new Date(element.date) : ""
                        }
                        onChange={(value) => {
                          iFormValueSetter2(0, varIndex, "date", value);
                        }}
                      />
                      {varIndex !== data[0].used.length - 1 ? (
                        <span
                          onClick={() => {
                            removeUsed(0);
                          }}
                          className="h-6 w-6 mt-8 mr-3 text-gray-500 hover:text-red-500 hover:p-1 z-10"
                        >
                          <TrashIcon />
                        </span>
                      ) : (
                        <span
                          className="h-6 w-6 mt-8 text-white rounded-md bg-green-500 z-10"
                          onClick={() => {
                            addUsed(0);
                          }}
                        >
                          <PlusIcon />
                        </span>
                      )}
                    </div>
                  );
                })}
              </div>
              <br />

              <div className="w-full">
                <CustomDropDownSearchSelect
                  label={"Recommended Fertilizer"}
                  items={
                    fertilizerList !== null
                      ? fertilizerList.map((element) => {
                          return getItemName(element, "identifier", "name");
                        })
                      : []
                  }
                  multi={true}
                  handler={(newValues) => {
                    valueSetter(
                      "fertilizerIdArray",
                      fertilizerList
                        .filter((item) => {
                          return (
                            newValues.indexOf(
                              getItemName(item, "identifier", "name")
                            ) !== -1
                          );
                        })
                        .map((item) => {
                          return item.id;
                        })
                    );
                  }}
                  selected={
                    data[0].fertilizerIdArray !== null &&
                    fertilizerList !== null
                      ? fertilizerList
                          .filter((item) => {
                            return (
                              data[0].fertilizerIdArray.indexOf(item.id) !== -1
                            );
                          })
                          .map((item) =>
                            getItemName(item, "identifier", "name")
                          )
                      : []
                  }
                />
              </div>
              <div className="w-full">
                <CustomInputBox
                  title={"Other Recommendation"}
                  value={data[0].otherRecommendation}
                  valueSetter={(value) => {
                    valueSetter("otherRecommendation", value);
                  }}
                />
              </div>
              <div className="w-full">
                <CustomDropDownSearchSelect
                  label={"Used Fertilizer"}
                  items={
                    fertilizerList !== null
                      ? fertilizerList.map((element) => {
                          return getItemName(element, "identifier", "name");
                        })
                      : []
                  }
                  multi={true}
                  handler={(newValues) => {
                    valueSetter(
                      "usedFertilizerIdArray",
                      fertilizerList
                        .filter((item) => {
                          return (
                            newValues.indexOf(
                              getItemName(item, "identifier", "name")
                            ) !== -1
                          );
                        })
                        .map((item) => {
                          return item.id;
                        })
                    );
                  }}
                  selected={
                    data[0].usedFertilizerIdArray !== null &&
                    fertilizerList !== null
                      ? fertilizerList
                          .filter((item) => {
                            return (
                              data[0].usedFertilizerIdArray.indexOf(item.id) !==
                              -1
                            );
                          })
                          .map((item) =>
                            getItemName(item, "identifier", "name")
                          )
                      : []
                  }
                />
              </div>
              <div className="w-full">
                <CustomDateSelector
                  label={"Used Fertilizer Date"}
                  date={new Date(data[0].usedFertilizerDate)}
                  onChange={(value) => {
                    valueSetter("usedFertilizerDate", value);
                  }}
                />
              </div>
              <div className="w-full">
                <CustomInputBox
                  title={"Used Other Ingredient"}
                  value={data[0].usedOtherIngredient}
                  valueSetter={(value) => {
                    valueSetter("usedOtherIngredient", value);
                  }}
                />
              </div>
              <div className="w-full">
                <CustomDateSelector
                  label={"Other Ingredient Used Date"}
                  date={
                    data[0].otherIngredientUsedDate !== null
                      ? new Date(data[0].otherIngredientUsedDate)
                      : ""
                  }
                  onChange={(value) => {
                    valueSetter("otherIngredientUsedDate", value);
                  }}
                />
              </div>
              <div className="w-full">
                <CustomInputBox
                  title={"Unresolved Farmer Query"}
                  value={data[0].unresolvedFarmerQuery}
                  valueSetter={(value) => {
                    valueSetter("unresolvedFarmerQuery", value);
                  }}
                />
              </div>
              <div className="w-full">
                <CustomInputBox
                  title={"General Remarks"}
                  value={data[0].generalRemarks}
                  valueSetter={(value) => {
                    valueSetter("generalRemarks", value);
                  }}
                />
              </div>
              <div className="w-full">
                <CustomToggleSwitch
                  checked={data[0].actionRequired == 1}
                  label2={"Action Required?"}
                  onChange={(value) => {
                    valueSetter("actionRequired", value.value ? 1 : 0);
                  }}
                />
              </div>

              <div className="w-full">
                <CustomInputBox
                  title={"Number of irrigations from last visit"}
                  value={data[0].waterUsage}
                  valueSetter={(value) => {
                    valueSetter("waterUsage", value);
                    let arr = [];
                    for (let i = 0; i < value; i++) {
                      arr.push(i);
                    }
                    setNumberIrrigation(arr);
                  }}
                />
                <br />
                {numberIrrigation.length > 0 &&
                  numberIrrigation.map((item, indexx) => {
                    return (
                      <>
                        <div className="flex flex-row">
                          <CustomDropDownSearchSelect
                            key={indexx + 12345}
                            label={"Crop Stage"}
                            items={
                              cropStageList !== null
                                ? cropStageList.map((element) => {
                                    return getItemName(
                                      element,
                                      "identifier",
                                      "name"
                                    );
                                  })
                                : []
                            }
                            selected={
                              stages.filter((iter) => {
                                if (iter.index == indexx) {
                                  return iter.value;
                                }
                              })[0]?.value
                            }
                            handler={(value) => {
                              if (value !== null) {
                                let id = cropStageList.filter((element) => {
                                  return (
                                    getItemName(
                                      element,
                                      "identifier",
                                      "name"
                                    ) === value
                                  );
                                })[0].id;

                                let obj = {
                                  index: indexx,
                                  id: id,
                                  value: value,
                                };
                                let array = stages;
                                if (!array.length > 0) {
                                  array.push(obj);
                                } else {
                                  array.map((stage, count) => {
                                    if (stage.index == indexx) {
                                      stage.id = id;
                                      stage.value = value;
                                    } else {
                                      array.push(obj);
                                    }
                                  });
                                }
                                const unique = [
                                  ...new Map(
                                    array.map((item, key) => [
                                      item["index"],
                                      item,
                                    ])
                                  ).values(),
                                ];
                                setStages(unique);
                                setStagesName([...stagesName, value]);
                                valueSetter(
                                  "irrigationStages",
                                  JSON.stringify(stages)
                                );
                              }
                            }}
                          />
                          <div className="w-16"></div>
                          <CustomDateSelector
                            label={"Irrigation Date"}
                            key={indexx + 1234}
                            className=""
                            date={
                              new Date(
                                dates.filter((iter) => {
                                  if (iter.index == indexx) {
                                    return iter.date;
                                  }
                                })[0]?.date
                              )
                            }
                            onChange={(value) => {
                              if (value !== null) {
                                let obj = {
                                  index: indexx,
                                  date: value,
                                };
                                let array = dates;
                                if (!array.length > 0) {
                                  array.push(obj);
                                } else {
                                  array.map((stage, count) => {
                                    if (stage.index == indexx) {
                                      stage.date = value;
                                    } else {
                                      array.push(obj);
                                    }
                                  });
                                }
                                const unique = [
                                  ...new Map(
                                    array.map((item, key) => [
                                      item["index"],
                                      item,
                                    ])
                                  ).values(),
                                ];
                                setDates(unique);
                                valueSetter(
                                  "irrigationDates",
                                  JSON.stringify(dates)
                                );
                              }
                            }}
                          />
                        </div>
                        <br />
                      </>
                    );
                  })}
              </div>
            </div>
          </div>
          <div className="flex mt-8 justify-between">
            <CustomButton
              btnName="Cancel"
              className="p-2 bg-gray-500 hover:bg-gray-700 text-lg"
              onClick={() => {
                history.goBack();
              }}
            />
            <CustomButton
              btnName="Update"
              className="p-2 bg-green-500 hover:bg-green-700 text-lg"
              onClick={editAllVisit}
            />
          </div>
        </div>
      </div>
      <div className="text-xl text-red-500">{error}</div>
      <div className="flex w-max gap-x-10"></div>
    </div>
  ) : null;
};
export default AllVisitEdit;
