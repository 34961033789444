import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import ZoneMasterEdit from "./ZoneMasterEdit";
import ZoneMasterHome from "./ZoneMasterHome";
import MasterDataDelete from "./../common/MasterDataDelete";
import ZoneMasterAdd from "./ZoneMasterAdd";
import ZoneMasterDisplay from "./ZoneMasterDisplay";

const ZoneMasterRoutes = () => {
    return (
        <BrowserRouter basename="/home/masterData/zoneMaster">
            <Switch>
                <Route path="/" component={ZoneMasterHome} exact/>
                <Route path="/add" component={ZoneMasterAdd} />
                <Route path="/edit/:id" component={ZoneMasterEdit}/>
                <Route path="/delete/:id" component={MasterDataDelete}/>
                <Route path="/display"  component={ZoneMasterDisplay}/>
                <Route component={()=>(<Redirect to="/" />)}/>
            </Switch>
        </BrowserRouter>
    );
}

export default ZoneMasterRoutes;