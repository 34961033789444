import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
// import styled from 'styled-components';
import axios from "../../../axios";

export const Menu = ({ items }) => {
  const [allowed, setAllowed] = useState([]);
  const [final, setfinal] = useState([]);
  const type = localStorage.getItem("cfUserRole");

  useEffect(() => {
    let arr = [];
    allowed.length !== 0 &&
      allowed?.map((items) => {
        if (
          items.moduleCreate === 1 ||
          items.moduleRead === 1 ||
          items.moduleDelete === 1 ||
          items.moduleUpdate === 1
        ) {
          arr.push(items.moduleName);
        }
      });
    setfinal(arr);
  }, [allowed]);
  useEffect(() => {
    // checkAuthority("R" , "seasonalPlannings");
    // checkAuthority("R" , "chemicalMasters");
    // checkAuthority("R" , "aarthiMasters");
    // checkAuthority("R" , "allVisits");
    // checkAuthority("R" , "bonusCalculation");
    // checkAuthority("R" , "chemicalMasters");
    // checkAuthority("R" , "Crop Life Cycle");
    // checkAuthority("R" , "farmerMasters");
    // checkAuthority("R" , "fertilizerMasters");
    // checkAuthority("R" , "insectDiseaseMasters");
    // checkAuthority("R" , "varietyMasters");
    // checkAuthority("R" , "villageMasters");
    // checkAuthority("R" , "yieldMasters");
    // checkAuthority("R" , "zoneMasters");
    // checkAuthority("R" , "cropLifeCycles");
    // checkAuthority("R" , "initialValidations");
    // checkAuthority("R" , "productionEstimations");
    // checkAuthority("R" , "cropCuttingSamplings");
    // checkAuthority("R" , "purchaseDetails");
    // checkAuthority("R" , "mandiMasters");
    // checkAuthority("R" , "user");
    // checkAuthority("R" , "role");

    setAllowed(JSON.parse(localStorage.getItem("cfUserAuth")));
  }, []);

  return (
    <div>
      <div className="text-sm font-bold text-green-500 flex py-3 border border-gray-300 border-t-1 border-b-0 border-l-0 border-r-0 ">
        <span className="pl-4"> {items.header} </span>
      </div>
      <div>
        {items.menu.map((items, index) => {
          if (
            items.name === "Seasonal Planning" &&
            final.includes("seasonalPlannings")
          ) {
            return <MenuChild items={items} key={index} />;
          }

          if (
            items.name === "Aarthi Master" &&
            final.includes("aarthiMasters")
          ) {
            return <MenuChild items={items} key={index} />;
          }
          if (items.name === "All visits" && final.includes("allVisits")) {
            return <MenuChild items={items} key={index} />;
          }
          if (
            items.name === "Bonus Calculation" &&
            final.includes("bonusCalculation")
          ) {
            return <MenuChild items={items} key={index} />;
          }
          if (
            items.name === "Chemical Master" &&
            final.includes("chemicalMasters")
          ) {
            return <MenuChild items={items} key={index} />;
          }
          if (
            items.name === "Crop Life Cycle" &&
            final.includes("cropLifeCycles")
          ) {
            return <MenuChild items={items} key={index} />;
          }
          if (
            items.name === "Farmers Registration" &&
            final.includes("farmerMasters")
          ) {
            return <MenuChild items={items} key={index} />;
          }
          if (
            items.name === "Fertilizer Master" &&
            final.includes("fertilizerMasters")
          ) {
            return <MenuChild items={items} key={index} />;
          }
          if (
            items.name === "Insect & Pest Master" &&
            final.includes("insectDiseaseMasters")
          ) {
            return <MenuChild items={items} key={index} />;
          }
          if (items.name === "Mandi Master" && final.includes("mandiMasters")) {
            return <MenuChild items={items} key={index} />;
          }
          if (
            items.name === "Validation" &&
            final.includes("initialValidations")
          ) {
            return <MenuChild items={items} key={index} />;
          }
          if (
            items.name === "Variety Master" &&
            final.includes("varietyMasters")
          ) {
            return <MenuChild items={items} key={index} />;
          }
          if (items.name === "Yield Master" && final.includes("yieldMasters")) {
            return <MenuChild items={items} key={index} />;
          }
          if (items.name === "Zone Master" && final.includes("zoneMasters")) {
            return <MenuChild items={items} key={index} />;
          }
          if (
            items.name === "Crop Life Cycle" &&
            final.includes("cropLifeCycles")
          ) {
            return <MenuChild items={items} key={index} />;
          }
          if (
            items.name === "Estimation" &&
            final.includes("productionEstimations")
          ) {
            return <MenuChild items={items} key={index} />;
          }
          if (
            items.name === "Sampling Request" &&
            final.includes("cropCuttingSamplings")
          ) {
            return <MenuChild items={items} key={index} />;
          }
          if (
            items.name === "Sampling Confirmed" &&
            final.includes("cropCuttingSamplingsConfirmation")
          ) {
            return <MenuChild items={items} key={index} />;
          }
          if (
            items.name === "Sampling Result" &&
            final.includes("cropCuttingSamplingsResult")
          ) {
            return <MenuChild items={items} key={index} />;
          }
          if (
            items.name === "Estimation" &&
            final.includes("productionEstimations")
          ) {
            return <MenuChild items={items} key={index} />;
          }
          if (items.name === "Purchase" && final.includes("purchaseDetails")) {
            return <MenuChild items={items} key={index} />;
          }
          if (
            items.name === "Resampling Approval" &&
            final.includes("resamplingApproval")
          ) {
            return <MenuChild items={items} key={index} />;
          }
          if (items.name === "Mandi Master" && final.includes("mandiMasters")) {
            return <MenuChild items={items} key={index} />;
          }
          if (
            items.name === "Village Master" &&
            final.includes("villageMasters")
          ) {
            return <MenuChild items={items} key={index} />;
          }
          if (items.name === "Users" && type == '"Super Admin"') {
            return <MenuChild items={items} key={index} />;
          }
          if (items.name === "Roles" && type == '"Super Admin"') {
            return <MenuChild items={items} key={index} />;
          }
        })}
      </div>
    </div>
  );
};

export const MenuChild = ({ items }) => {
  const [show, setShow] = useState(false);

  const open = () => {
    setShow(!show);
  };
  return (
    <>
      <div
        onClick={open}
        className="flex pl-3 justify-between cursor-pointer 
             py-2
            "
      >
        <div className="flex space-x-4">
          <div className="-mt-1 h-7 w-7 text-green-400">
            {show ? items.iconSelected : items.iconUnselected}
          </div>
          <div className="tracking-wider">
            {items.subMenu === undefined ? (
              <a href={`${items.path}`}>{items.name}</a>
            ) : (
              <span>{items.name}</span>
            )}
          </div>
        </div>

        <div className="h-5 w-5 mr-3">
          {items.subMenu ? (show ? items.iconOpened : items.iconClosed) : null}
        </div>
      </div>
      {/* subMenu aka child */}
      <div>
        {show &&
          items?.subMenu?.map((value, index) => {
            return (
              <div
                className="cursor-pointer tracking-wider
                         text-center py-2"
              >
                <span>
                  {" "}
                  <a href={`${value.path}`}>{value.name}</a>{" "}
                </span>
              </div>
            );
          })}
      </div>
    </>
  );
};
