import React from "react";
import { useLocation, Redirect } from "react-router-dom";
import CustomInputBox from "../../../components/atoms/CustomInputBox";
import { EyeIcon } from "@heroicons/react/outline";

const ZoneMasterDisplay = () => {
  const location = useLocation();
  if (!JSON.parse(location.state)) return <Redirect to="/" />;
  const { data } = JSON.parse(location.state);

  return (
    <div className="overflow-hidden m-3 p-3">
      <div className="flex">
        <span>
          <EyeIcon className="h-5 w-5 transform text-black mt-4 mr-1" />
        </span>
        <span className=" py-4 text-gray-700 text-base font-bold text-left">
          Display zone master
        </span>
      </div>
      <div className="grid grid-cols-1 w-1/2 justify-left">
        {/* <CustomDisplayBox title="ID" value={data.id} className=" pl-2"/> */}
        <CustomInputBox
          title="Name"
          value={data.name.value}
          disabled={true}
          borderDisable={true}
        />
      </div>
      <div className="flex w-max gap-x-10"></div>
    </div>
  );
};
export default ZoneMasterDisplay;
