import { ArrowDownIcon, ArrowUpIcon } from "@heroicons/react/outline";
import React, { useState } from "react";

//The table's job is to only display the data given to it. Nothing else.

const getOrderedKeys = (obj) => {
  const keysToShow = Object.keys(obj).filter((key) => {
    if (obj[key]) {
      return obj[key].hasOwnProperty("show");
    } else return false;
  });
  if (keysToShow.length > 0 && obj[keysToShow[0]].hasOwnProperty("order")) {
    const orderedShowKeys = keysToShow.sort((a, b) => {
      return obj[a].order > obj[b].order ? 1 : -1;
    });
    return orderedShowKeys;
  } else {
    return keysToShow;
  }
};

const CustomTable = ({ rows, headers, sortByHandler }) => {
  return rows.length === 0 ? (
    <h1>No such rows</h1>
  ) : (
    <div className="overflow-auto">
      <div className="overflow-auto">
        <table className="text-lg w-full border-collapse">
          <thead>
            <tr>
              {/*Generating the table headers. Add sort button to sortable headers.*/}
              {headers.map((value, index) => {
                if (value.show)
                  return (
                    <th
                      key={index}
                      className="text-sm bg-white capitalize text-gray-700 px-6 bg-blueGray-50 text-blueGray-500 border border-solid border-blueGray-100 py-3 text-xs border-l-0 border-r-0 font-semibold text-left "
                    >
                      <div className="flex">
                        {value.displayName}
                        {value.sortable ? (
                          <SortButton
                            headerName={value.property}
                            callback={(headerName, order) => {
                              sortByHandler({
                                headerName: headerName,
                                order: order,
                              });
                            }}
                          />
                        ) : null}
                      </div>
                    </th>
                  );
              })}
            </tr>
          </thead>
          <tbody>
            {
              /* Generating the rows of the current page. */

              rows
                ? rows.map((row, index) => {
                    return (
                      <tr
                        key={index}
                        className=" border-t-0 even:bg-gray-100 odd:bg-gray-200"
                      >
                        {getOrderedKeys(row).map((property, index) => {
                          //check if headers has this property set to show or not.
                          if (
                            row[property] !== null &&
                            row[property].hasOwnProperty("show")
                          ) {
                            return (
                              <td
                                className={`border-t-0 font-light text-sm px-6 align-left text-left border-l-0 border-r-0  p-1 ${
                                  row[property].value === null
                                    ? "text-gray-400"
                                    : ""
                                }`}
                                key={index}
                              >
                                {row[property].value === null
                                  ? "N/A"
                                  : row[property].value}
                              </td>
                            );
                          }
                          // for(let i = 0;i < headers.length;i++)
                          // {
                          //     if(headers[i].property.toLowerCase() === property)
                          //     {
                          //         if(headers[i].show === true)
                          //         {
                          //         console.log("Header ", headers[i].displayName, " is set to show ?", headers[i].show)
                          //         return <td className="border-t-0 font-light text-sm px-6 align-left text-left border-l-0 border-r-0  p-1" key={index}>
                          //     {row[property]}
                          // </td>
                          //         }
                          //     }
                          // }
                        })}
                      </tr>
                    );
                  })
                : null
            }
          </tbody>
        </table>
      </div>
    </div>
  );
};

const SortButton = ({ headerName, callback }) => {
  const [currentOrder, setOrder] = useState(false);

  return (
    <button
      className="pl-3"
      onClick={() => {
        
        setOrder(!currentOrder);
        callback(headerName, !currentOrder);
      }}
    >
      {currentOrder ? (
        <ArrowDownIcon className="text-black w-3 h-3" />
      ) : (
        <ArrowUpIcon className="text-black w-3 h-3" />
      )}
    </button>
  );
};

export default CustomTable;
