import React, { useEffect, useState } from "react";
import { useLocation, Redirect, useHistory } from "react-router-dom";
import { CustomButton } from "../../../components/atoms/CustomButton";
import CustomInputBox from "../../../components/atoms/CustomInputBox";
import axios from "../../../axios";
import { toast } from "react-toastify";
import { PencilIcon } from "@heroicons/react/outline";
import { errorToast } from "../../../util/toast";
import CustomDateSelector from "../../../components/atoms/CustomDateSelector";
import { validateCropLifeCycleEdit } from "../../Master Data/common/validations";

const CropLifeCycleEdit = () => {
  const updatePropList = ["actualLandUsed", "farmerVarietyId", "sowingList"];
  const addDaysToDate = (date, days) => {
    let newDate = date;
    newDate.setDate(newDate.getDate() + days);
    return newDate;
  };
  const location = useLocation();
  const history = useHistory();
  const id = JSON.parse(location.state).data.id;
  const [data, setData] = useState(null);
  const [prevData, setPrevData] = useState(null);

  const [farmerList, setFarmerList] = useState(null);
  const [varietyList, setVarietyList] = useState(null);
  const [error, setError] = useState(null);

  const valueSetter = (index, property, newValue) => {
    let newData = { ...data };
    newData.sowingList[index][property] = newValue;
    setData(newData);
  };

  const addVariety = (index) => {
    let updatedCropLifeCycle = { ...data };
    updatedCropLifeCycle.sowingList = [
      ...updatedCropLifeCycle.sowingList,
      { sowingDate: null, landUsed: 0 },
    ];
    setData(updatedCropLifeCycle);
  };
  const removeVariety = (index, varietyIndex) => {
    let updatedCropLifeCycle = { ...data };
    updatedCropLifeCycle.sowingList = updatedCropLifeCycle.sowingList.filter(
      (element, i) => {
        return i !== varietyIndex;
      }
    );
    setData(updatedCropLifeCycle);
  };

  useEffect(() => {
    // const newObj = {};
    // Object.keys(data).forEach((key) => {
    //   if (data[key]?.hasOwnProperty("show")) {
    //     newObj[key] = data[key].value;
    //   } else {
    //     newObj[key] = data[key];
    //   }
    // });
    // if (Object.keys(newObj).length > 0) setData(newObj);
    axios
      .get(`cropLifeCycle/${id}`)
      .then((response) => {
        const temp = response.data.Result.CropLifeCycle[0];
        const newObj = {};
        newObj.id = temp.id;
        newObj.name = temp.FarmerMaster.name;
        newObj.varietyname = temp.VarietyMaster.name;
        newObj.sowingDate = temp.sowingDate;
        newObj.seedToSeed = temp.VarietyMaster.seedToSeed;
        newObj.farmerVarietyId = temp.id;
        newObj.actualLandUsed = temp.actualLandUsed;
        newObj.sowingList = [
          { sowingDate: temp.sowingDate, landUsed: temp.landUsed },
        ];
        setPrevData(newObj);
        return axios.get(`farmerMaster/load`);
      })
      .then((response) => {
        setFarmerList(response.data.Result.FarmerMaster);
        return axios.get(`varietyMaster/load`);
      })
      .then((response) => {
        setVarietyList(response.data.Result.VarietyMaster);
      })
      .catch((error) => {
        console.log(error);
        errorToast(error?.response?.data?.Comments);
      });
  }, []);

  useEffect(() => {
    if (prevData != null) {
      axios.get(`cropLifeCycleSowingDates/${prevData.id}`).then((response) => {
        prevData.sowingList.splice(1, 0, ...response.data.Result.sowingList);
        setData(prevData);
      });
    }
  }, [prevData]);

  if (!location.state) {
    return <Redirect to="/" />;
  }

  const createSendingData = async (obj) => {
    return new Promise((resolve, reject) => {
      let newData = {};
      Object.keys(obj).forEach((property, index) => {
        if (updatePropList.includes(property)) {
          newData[property] = obj[property];
        }
        if (index === Object.keys(obj).length - 1) resolve(newData);
      });
    });
  };
  const getDateString = (date) => {
    let year = date.getFullYear();
    let month = date.getMonth() + 1;
    month = month < 10 ? "0" + month : month;
    let day = date.getDate();
    day = day < 10 ? "0" + day : day;
    return year + "/" + month + "/" + day;
  };
  const editCropLifeCycle = () => {
    createSendingData(data)
      .then((newData) => {
        console.log(newData, " is the new data", data);
        if (validateCropLifeCycleEdit(newData))
          return axios.put(`cropLifeCycle/${data.id}`, newData);
        else throw { message: "please rectify the data" };
      })
      .then((response) => {
        if (response?.data?.ResponseMessage === "SUCCESS") {
          toast.success("Update successful!", { position: "top-center" });
          history.goBack();
        }
      })
      .catch((error) => {
        errorToast(error?.response?.data?.Comments);
        console.log(error);
      });
  };

  return data !== null ? (
    <div className=" m-3 p-3">
      <div className="flex">
        <span>
          <PencilIcon className="h-5 w-5 transform text-black mt-4 mr-1" />
        </span>
        <span className=" py-4 text-gray-700 text-base font-bold text-left">
          Edit Crop Life Cycle
        </span>
      </div>
      <div className="flex flex-wrap justify-left">
        <div className="w-3/4">
          <div className=" gap-x-3 space-y-2 mb-4">
            <div className="mb-4 flex gap-x-3 w-full">
              <div className="w-full">
                <CustomInputBox
                  title={"Farmer"}
                  disabled={true}
                  borderDisable={true}
                  value={data.name}
                />
              </div>
              <CustomInputBox
                title={"Variety"}
                disabled={true}
                borderDisable={true}
                value={data.varietyname}
              />
            </div>

            <div className="flex gap-x-3 w-full">
              <div className="w-full">
                <CustomDateSelector
                  label={"Seed To Seed Date"}
                  disabled={true}
                  date={
                    data.sowingDate === null
                      ? null
                      : addDaysToDate(
                          new Date(data.sowingDate),
                          data.seedToSeed
                        )
                  }
                />
              </div>
              {data?.actualLandUsed && (
                <CustomInputBox
                  title="Actual Land Used"
                  value={data?.actualLandUsed}
                  disabled={true}
                  className="w-1/2"
                  valueSetter={(value) => {
                    valueSetter("actualLandUsed", value);
                  }}
                />
              )}
            </div>
          </div>

          {data.sowingList.map((element, varIndex) => {
            return (
              <div className="flex gap-x-2">
                <div className="w-1/2">
                  <CustomDateSelector
                    label={"Sowing Date"}
                    date={
                      element.sowingDate === null
                        ? null
                        : new Date(element.sowingDate)
                    }
                    onChange={(val) => {
                      valueSetter(varIndex, "sowingDate", val);
                    }}
                  />
                </div>

                <div className="w-1/2">
                  <div className="w-full gap-x-3">
                    <CustomInputBox
                      title="Land Used"
                      value={element.landUsed}
                      valueSetter={(value) => {
                        valueSetter(varIndex, "landUsed", value);
                      }}
                    />
                  </div>
                </div>

                {varIndex === 0 ? (
                  <div className="ml-5 mt-7">
                    <CustomButton
                      btnName="Add"
                      onClick={() => {
                        addVariety(0);
                      }}
                    />
                  </div>
                ) : (
                  <CustomButton
                    btnName="🗑️"
                    bgColor="bg-white"
                    onClick={() => {
                      removeVariety(0, varIndex);
                    }}
                  />
                )}
              </div>
            );
          })}

          <div className="flex mt-8 justify-between">
            <CustomButton
              btnName="Cancel"
              className="p-2 bg-gray-500 hover:bg-gray-700 text-lg"
              onClick={() => {
                history.goBack();
              }}
            />
            <CustomButton
              btnName="Update"
              className="p-2 bg-green-500 hover:bg-green-700 text-lg"
              onClick={editCropLifeCycle}
            />
          </div>
        </div>
      </div>
      <div className="text-xl text-red-500">{error}</div>
      <div className="flex w-max gap-x-10"></div>
    </div>
  ) : null;
};
export default CropLifeCycleEdit;
