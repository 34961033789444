import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { CustomButton } from "../../../components/atoms/CustomButton";
import axios from "../../../axios";
import { DocumentAddIcon, PlusIcon, TrashIcon } from "@heroicons/react/outline";
import CustomInputBox from "../../../components/atoms/CustomInputBox";
import { successToast, errorToast } from "../../../util/toast";
import CustomDropDownSearchSelect from "../../../components/atoms/CustomDropDownSearchSelect";
import { validateFertilizerAdd } from "../common/validations";

const getItemName = (obj, prop1) => {
  return obj[prop1];
};
//returns the first object in the list whose property matches the target
const searchObject = (items, target, property) => {
  return items.filter((element) => {
    return element[property] === target;
  })[0];
};

const FertilizerAdd = () => {
  const history = useHistory();
  const [newFertilizer, setNewFertilizer] = useState([
    { name: "", brandName: "", uomId: null },
  ]);
  const [uomObjectsList, setUomObjectsList] = useState([]);
  const [uomId, setUomId] = useState(null);
  const [isSubmit, setIsSubmit] = useState(false);

  useEffect(() => {
    axios
      .get(`uom`)
      .then((response) => {
        setUomObjectsList(response.data.Result.Uom);
        console.log(uomObjectsList);
      })
      .catch((error) => {
        console.log(error?.message);
        errorToast(error?.response?.data?.Comments);
      });
  }, []);

  const valueSetter = (index, property, newValue) => {
    if (newValue !== null) {
      let Fertilizer = [...newFertilizer];
      Fertilizer[index][property] = newValue;
      setNewFertilizer(Fertilizer);
    }
  };

  const addFertilizer = () => {
    if (validateFertilizerAdd(newFertilizer)) {
      newFertilizer[uomId] = uomId;
      setIsSubmit(true);
      successToast("Your request is being processed");
      axios
        .post("fertilizerMaster/", newFertilizer)
        .then((response) => {
          if (response?.data?.ResponseMessage === "SUCCESS") {
            successToast("New Fertilizer added!");
            history.goBack();
          }
        })
        .catch((error) => {
          errorToast(error?.response?.data?.Comments);
          console.log(error);
        });
    } else {
      setIsSubmit(false);
    }
  };

  return (
    <div className="p-3 m-3">
      <div className="flex mb-7">
        <DocumentAddIcon className="h-5 w-5text-black mt-3 mr-1" />
        <div className="text-base pt-3 font-bold text-gray-800">
          Add Fertilizer{" "}
        </div>
      </div>
      <div>
        {newFertilizer.map((value, index) => {
          return (
            <div className="mb-4 flex">
              <div className="flex w-11/12">
                <div className="flex-wrap flex m-3 gap-x-2">
                  <div className="w-full">
                    <CustomInputBox
                      title="Fertilizer Brand Name"
                      value={newFertilizer[index].brandName}
                      valueSetter={(value) => {
                        valueSetter(index, "brandName", value);
                      }}
                    />
                    <CustomInputBox
                      title="Fertilizer Name"
                      value={newFertilizer[index].name}
                      valueSetter={(value) => {
                        valueSetter(index, "name", value);
                      }}
                    />
                  </div>
                  <div className="w-full flex">
                    <div className="w-full">
                      <CustomDropDownSearchSelect
                        label={"UoM"}
                        items={uomObjectsList.map((value) => {
                          return getItemName(value, "uom");
                        })}
                        selected={
                          value.uomId !== null && uomObjectsList.length > 0
                            ? getItemName(
                                searchObject(uomObjectsList, value.uomId, "id"),
                                "uom"
                              )
                            : null
                        }
                        handler={(value) => {
                          if (value !== null)
                            valueSetter(
                              index,
                              "uomId",
                              uomObjectsList.filter((element) => {
                                return getItemName(element, "uom") == value;
                              })[0].id
                            );
                          else {
                            valueSetter(index, "uomId", null);
                          }
                        }}
                      />
                    </div>

                    <div className="h-5 w-5">
                      {
                        newFertilizer.length - 1 !== index ? (
                          <div
                            onClick={() => {
                              setNewFertilizer(
                                newFertilizer.filter((v, i) => {
                                  return i !== index;
                                })
                              );
                            }}
                            className="h-5 w-5 ml-2 mt-8 text-gray-500 hover:text-red-500 hover:p-1"
                          >
                            <TrashIcon />
                          </div>
                        ) : (
                          <div
                            className="h-4 w-4 ml-2 mt-8 text-white rounded-md bg-green-500"
                            onClick={() => {
                              setNewFertilizer([
                                ...newFertilizer,
                                {
                                  brandName: "",
                                  name: "",
                                  uomId: null,
                                },
                              ]);
                            }}
                          >
                            <PlusIcon />
                          </div>
                        )
                        // :<CustomButton className=" mt-8 ml-3 text-sm p-1 bg-green-500" btnName={<span className="h-3 w-3"><PlusIcon /></span>} onClick={() => { setNewFertilizer([...newFertilizer, { name: "" }]) }} />
                      }
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
      <div className="flex w-1/2  ml-3 justify-between">
        <CustomButton
          btnName="Cancel"
          bgColor="bg-gray-400"
          onClick={() => {
            history.goBack();
          }}
        />
        {!isSubmit ? (
          <CustomButton btnName="Submit" onClick={addFertilizer} />
        ) : (
          <CustomButton btnName="Submit" onClick={() => {}} />
        )}
      </div>
    </div>
  );
};
export default FertilizerAdd;
