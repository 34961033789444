import React, { useState } from "react";
import { useHistory } from "react-router";
import { CustomButton } from "../../../components/atoms/CustomButton";
import axios from "../../../axios";
import { DocumentAddIcon, PlusIcon, TrashIcon } from "@heroicons/react/outline";
import CustomInputBox from "../../../components/atoms/CustomInputBox";
import { successToast, errorToast } from "../../../util/toast";
import CustomDropDownSearchSelect from "../../../components/atoms/CustomDropDownSearchSelect";
import { validateInsectDiseaseAdd } from "../common/validations";

const InsectDiseaseAdd = () => {
  const history = useHistory();
  const [newInsectDisease, setNewInsectDisease] = useState([
    { insectDiseaseName: "", type: "", severity: "" },
  ]);
  const [isSubmit, setIsSubmit] = useState(false);

  const valueSetter = (index, property, newValue) => {
    console.log("hi");
    if (newValue !== null) {
      let InsectDisease = [...newInsectDisease];
      InsectDisease[index][property] = newValue;
      setNewInsectDisease(InsectDisease);
    }
  };

  const addInsectDisease = () => {
    console.log(newInsectDisease);
    if (validateInsectDiseaseAdd(newInsectDisease)) {
      setIsSubmit(true);
      successToast("Your request is being processed");
      axios
        .post("insectsDiseasesMaster/", newInsectDisease)
        .then((response) => {
          if (response?.data?.ResponseMessage === "SUCCESS") {
            successToast("New Insect/Disease added!");
            history.goBack();
          }
        })
        .catch((error) => {
          errorToast(error?.response?.data?.Comments);
          console.log(error);
        });
    } else {
      setIsSubmit(false);
    }
  };

  return (
    <div className="p-3 m-3">
      <div className="flex mb-7">
        <DocumentAddIcon className="h-5 w-5text-black mt-3 mr-1" />
        <div className="text-base pt-3 font-bold text-gray-800">
          Add Insect/Disease{" "}
        </div>
      </div>
      <div className="w-1/2">
        {newInsectDisease.map((value, index) => {
          return (
            <div className="mb-4 flex">
              <div className="flex w-full">
                <div className="w-full flex-wrap flex gap-y-4 m-3 gap-x-2">
                  <div className="w-full">
                    <CustomInputBox
                      title="Insect/Disease name"
                      value={value.insectDiseaseName}
                      valueSetter={(value) => {
                        valueSetter(index, "insectDiseaseName", value);
                      }}
                    />
                  </div>
                  <div className="w-full">
                    <CustomDropDownSearchSelect
                      label="Type"
                      items={["Insect", "Disease"]}
                      handler={(value) => {
                        valueSetter(index, "type", value);
                      }}
                      selected={value.type}
                    />
                  </div>
                  <div className="w-full flex">
                    <div className="w-full">
                      <CustomDropDownSearchSelect
                        label="Severity"
                        items={["High", "Medium", "Low"]}
                        handler={(value) => {
                          valueSetter(index, "severity", value);
                        }}
                        selected={value.severity}
                      />
                    </div>

                    <div className="h-5 w-5">
                      {
                        newInsectDisease.length - 1 !== index ? (
                          <div
                            onClick={() => {
                              setNewInsectDisease(
                                newInsectDisease.filter((v, i) => {
                                  return i !== index;
                                })
                              );
                            }}
                            className="h-5 w-5 ml-2 mt-8 text-gray-500 hover:text-red-500 hover:p-1"
                          >
                            <TrashIcon />
                          </div>
                        ) : (
                          <div
                            className="h-4 w-4 ml-2 mt-8 text-white rounded-md bg-green-500"
                            onClick={() => {
                              setNewInsectDisease([
                                ...newInsectDisease,
                                {
                                  insectDiseaseName: "",
                                  type: "",
                                  severity: "",
                                },
                              ]);
                            }}
                          >
                            <PlusIcon />
                          </div>
                        )
                        // :<CustomButton className=" mt-8 ml-3 text-sm p-1 bg-green-500" btnName={<span className="h-3 w-3"><PlusIcon /></span>} onClick={() => { setNewInsectDisease([...newInsectDisease, { name: "" }]) }} />
                      }
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
      <div className="flex w-1/2 justify-between">
        <CustomButton
          btnName="Cancel"
          bgColor="bg-gray-400"
          onClick={() => {
            history.goBack();
          }}
        />
        {!isSubmit ? (
          <CustomButton btnName="Submit" onClick={addInsectDisease} />
        ) : (
          <CustomButton btnName="Submit" onClick={() => {}} />
        )}
      </div>
    </div>
  );
};
export default InsectDiseaseAdd;
