import { BrowserRouter, Route, Switch } from "react-router-dom";
import ZoneMasterRoutes from "./ZoneMaster/ZoneMasterRoutes";
import MandiMasterRoutes from "./MandiMaster/MandiMasterRoutes";
import InsectDiseaseRoutes from "./InsectDiseaseMaster/InsectDiseaseRoutes";
import ProcessTypesMasterRoutes from "./ProcessTypesMaster/ProcessTypesRoutes";
import VarietyMasterRoutes from "./VarietyMaster/VarietyMasterRoutes";
import AarthiMasterRoutes from "./AarthiMaster/AarthiMasterRoutes";
import YieldMasterRoutes from "./YieldMaster/YieldMasterRoutes";
import FertilizerMasterRoutes from "./FertilizerMaster/FertilizerMasterRoutes";
import ChemicalMasterRoutes from "./ChemicalMaster/ChemicalMasterRoutes";
import UomRoutes from "./UomMaster/UomRoutes";
import UsersRoutes from "./Users/UsersRoutes";
import RolesRoute from "./Roles/RolesRoute";
import VillageMasterRoutes from './VillageMaster/VillageMasterRoute'

const MasterDataRoutes = () => {


    return (
        <BrowserRouter basename="/home/masterData/">
            <Switch>
            <Route path="/users" >
                    <UsersRoutes />
                </Route>
                <Route path="/roles" >
                    <RolesRoute />
                </Route>
                <Route path="/mandiMaster" >
                    <MandiMasterRoutes />
                </Route>
                <Route path="/villageMaster" >
                    <VillageMasterRoutes />
                </Route>
                <Route path="/zoneMaster" >
                    <ZoneMasterRoutes />
                </Route>
                <Route path="/insectDisease" >
                    <InsectDiseaseRoutes />
                </Route>
                <Route path="/processTypesMaster" >
                    <ProcessTypesMasterRoutes />
                </Route>
                <Route path="/uomMaster" >
                    <UomRoutes/>
                </Route>
                <Route path="/varietyMaster" >
                    <VarietyMasterRoutes />
                </Route>
                <Route path="/aarthiMaster" >
                    <AarthiMasterRoutes />
                </Route>
                <Route path="/yieldMaster" >
                    <YieldMasterRoutes />
                </Route>
                <Route path="/fertilizerMaster" >
                    <FertilizerMasterRoutes />
                </Route>
                <Route path="/chemicalMaster" >
                    <ChemicalMasterRoutes />
                </Route>
            
            </Switch>
        </BrowserRouter>
    );
}

export default MasterDataRoutes;