import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { CustomButton } from "../../../components/atoms/CustomButton";
import TransactionDataDelete from "../common/TransactionDataDelete";
import axios from "../../../axios";
import { DocumentAddIcon, ChartBarIcon } from "@heroicons/react/outline";
import {
  EyeIcon,
  PencilIcon,
  TrashIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  CheckIcon,
  BanIcon,
} from "@heroicons/react/solid";
import { toast } from "react-toastify";
import CustomTable from "../../../components/atoms/CustomTable";
import CustomDropDownSearchSelect from "../../../components/atoms/CustomDropDownSearchSelect";
import { errorToast, successToast } from "../../../util/toast";

const getItemName = (obj, prop1, prop2) => {
  return obj[prop2];
};
//returns the first object in the list whose property matches the target
const searchObject = (items, target, property) => {
  return items.filter((element) => {
    return element[property] === target;
  })[0];
};

const ResamplingApprovalHome = () => {
  const role = JSON.parse(localStorage.getItem("cfUserAuth"));
  const result = role.find((obj) => {
    return obj.moduleName === "resamplingApproval";
  });

  const history = useHistory();
  const tablePageNumbersShown = 3;
  //table related.
  const [currentTableData, setTableData] = useState([]);
  const rowsPerPage = 10;
  const [totalRecords, setTotalRecords] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [sortBy, setSortBy] = useState({
    headerName: "id",
    order: false,
  });

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showUploadFile, setShowUploadFile] = useState(false);
  const [deleteObject, setDeleteObject] = useState(null);
  const [villageList, setVillageList] = useState(null);
  const [villageId, setvillageId] = useState(null);

  const tableHeaders = [
    { displayName: "id", property: "id", sortable: true, show: false },
    { displayName: "Farmer ID", property: "id", sortable: false, show: true },
    {
      displayName: "Farmer",
      property: "FarmerMaster.name",
      sortable: true,
      show: true,
    },
    {
      displayName: "Variety Name",
      property: "VarietyMaster.name",
      sortable: true,
      show: true,
    },
    {
      displayName: "Sampling Request Date",
      property: "samplingRequestDate",
      sortable: true,
      show: true,
    },
    {
      displayName: "Sampling Collect Date",
      property: "samplingCollectDate",
      sortable: true,
      show: true,
    },
    {
      displayName: "Sampling Combination Code",
      property: "samplingCombinationCode",
      sortable: true,
      show: true,
    },
    {
      displayName: "Area in acre",
      property: "acre",
      sortable: true,
      show: true,
    },
    {
      displayName: "Resampling Status",
      property: "resamplingStatus",
      show: true,
    },
    {
      displayName: "actions",
      property: "actions",
      sortable: false,
      show: true,
    },
  ];

  const [varietyId, setVarietyId] = useState(null);
  const [resamplingStatus, setStatusId] = useState(0);
  const [farmerId, setfarmerId] = useState(null);
  const [zoneId, setZoneId] = useState(null);
  const [varietyList, setVarietyList] = useState(null);
  const [farmerList, setFarmerList] = useState(null);
  const [zoneList, setZoneList] = useState(null);
  const [mandiList, setMandiList] = useState(null);
  const [aarthiList, setAarthiList] = useState(null);
  const [pakkaAarthiList, setPakkaAarthiList] = useState(null);

  const [pakkaAarthiId, setPakkaAarthiId] = useState(null);
  const [update, setUpdate] = useState(false);
  const [mandiId, setMandiId] = useState(null);

  const statusList = [
    { id: 0, status: "Pending" },
    { id: 1, status: "Declined" },
    { id: 2, status: "Approved" },
    { id: 3, status: "All" },
  ];

  useEffect(() => {
    setMandiId(null);
  }, [zoneId]);
  useEffect(() => {
    setvillageId(null);
  }, [mandiId]);

  useEffect(() => {
    setfarmerId(null);
  }, [villageId]);

  useEffect(() => {
    axios
      .get(`zoneMasters/specificload/${localStorage.getItem("cfUserId")}`)
      .then((response) => {
        setZoneList(response.data.Result.ZoneMaster);
        return axios.get(
          `mandiMaster/load/${localStorage.getItem("cfUserId")}`
        );
      })
      .then((response) => {
        setMandiList(response.data.Result.MandiMaster);
        return axios.get(`farmerMaster/load`);
      })
      .then((response) => {
        setFarmerList(response.data.Result.FarmerMaster);

        return axios.get(`varietyMaster/load`);
      })
      .then((response) => {
        setVarietyList(response.data.Result.VarietyMaster);
        return axios.get(`villageMasters/load`);
      })
      .then((response) => {
        setVillageList(response.data.Result.villageMaster);
        return axios.get("farmerMaster/load");
      })
      .then((response) => {
        setFarmerList(response.data.Result.FarmerMaster);
      })
      .catch((err) => {
        errorToast(err.response.data.Comments);
        console.log(err);
      });
  }, []);

  const getDateString = (date) => {
    let year = date.getFullYear();
    let month = date.getMonth() + 1;
    month = month < 10 ? "0" + month : month;
    let day = date.getDate();
    day = day < 10 ? "0" + day : day;
    return day + "/" + month + "/" + year;
  };
  const addActions = (objects) => {
    //adds the actions buttons to the table rows.
    const newObjects = [];

    objects.map((obj) => {
      console.log("OBJ", obj);
      obj.identifier = {
        value: obj.popId === 1 ? `FRTC-${obj.id}` : `FRIP-${obj.id}`,
        show: true,
        order: 0,
      };
      obj.farmerName = {
        value: getItemName(
          obj.FarmerVariety.FarmerMaster,
          "identifier",
          "name"
        ),
        show: true,
        order: 1,
      };
      obj.varietyName = {
        value: getItemName(
          obj.FarmerVariety.VarietyMaster,
          "identifier",
          "name"
        ),
        show: true,
        order: 2,
      };
      obj.samplingRequestDate = {
        value: getDateString(new Date(obj.samplingRequestDate)),
        show: true,
        order: 3,
      };
      obj.samplingCollectDate = {
        value: getDateString(new Date(obj.samplingCollectDate)),
        show: true,
        order: 4,
      };
      obj.samplingCombinationCode = {
        value: obj.samplingCombinationCode,
        show: true,
        order: 5,
      };
      obj.acre = { value: obj.acre, show: true, order: 6 };
      obj.resamplingStatus = {
        value:
          obj.resamplingStatus == 0
            ? "Pending"
            : obj.resamplingStatus == 1
            ? "Declined"
            : obj.resamplingStatus == 2
            ? "Approved"
            : null,
        show: true,
        order: 7,
      };

      obj.actions = {
        order: 8,
        value: (
          <div className="flex gap-x-3 -ml-4 p-1">
            <span
              onClick={() => {
                axios
                  .post(
                    `resamplingApprove/${obj.samplingCombinationCode.value}`
                  )
                  .then((response) => {
                    successToast("Approved Successfully");
                    setUpdate(!update);
                  });
              }}
            >
              <CheckIcon className="h-5 w-5 text-gray-700 hover:text-black" />
            </span>
            <span
              onClick={() => {
                axios
                  .post(
                    `resamplingDecline/${obj.samplingCombinationCode.value}`
                  )
                  .then((response) => {
                    successToast("Declined Successfully");
                    setUpdate(!update);
                  });
              }}
            >
              <BanIcon className="h-5 w-5 text-gray-700 hover:text-black" />
            </span>
          </div>
        ),
        show: true,
      };
      newObjects.push(obj);
    });

    return newObjects;
  };

  const deleteCropCuttingSampling = () => {
    axios
      .delete(`cropCuttingSampling/${deleteObject.id}`)
      .then((response) => {
        if (response?.data?.ResponseMessage === "SUCCESS") {
          // window.location.reload();
          setShowDeleteModal(false);
          successToast("Delete Successful!");
          window.location.reload();
          // remove this and do it better, we need to remove the row of the delete object thats it
        }
      })
      .catch((error) => {
        errorToast(error?.response?.data?.Comments);
        console.log(error);
      });
  };

  useEffect(() => {
    //updating the table data based on the search terms.
    // setTotalRecords([]);
    let params = {
      page: pageNumber,
      limit: 1000,
      orderBy: sortBy.headerName,
      order: sortBy.order ? "ASC" : "DESC",
    };
    if (varietyId !== null) {
      params.varietyId = varietyId;
    }
    if (resamplingStatus !== null) {
      params.resamplingStatus = resamplingStatus;
    }
    if (farmerId !== null) {
      params.farmerId = farmerId;
    }
    if (villageId !== null) {
      params.villageId = villageId;
    }

    if (zoneId !== null) {
      params.zoneId = zoneId;
    }

    if (mandiId !== null) {
      params.mandiId = mandiId;
    }

    // params.isConfirmed = 1;
    params.samplingCombinationCode = "NOTNULL";
    if (varietyList !== null && farmerList !== null) {
      axios
        .get(`cropCuttingSampling`, { params })
        .then((response) => {
          console.log(
            "RETURNED ROWS",
            response.data.Result.CropCuttingSampling
          );
          const returnedRows = response.data.Result.CropCuttingSampling?.filter(
            (item) => {
              if (resamplingStatus !== 3) {
                if (
                  item.isRequestedResampling &&
                  item.resamplingStatus == resamplingStatus
                ) {
                  return item;
                }
              } else {
                if (item.isRequestedResampling) {
                  return item;
                }
              }
            }
          );
          setTotalRecords(returnedRows.length);
          setTotalPages(Math.ceil(response.data.Result.total / rowsPerPage));
          let actualRows = addActions(returnedRows);

          console.log("ROWS", actualRows);
          setTableData(actualRows);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [
    varietyId,
    farmerId,
    varietyId,
    villageId,
    varietyList,
    farmerList,
    zoneList,
    mandiId,
    zoneId,
    pakkaAarthiId,
    pakkaAarthiList,
    pageNumber,
    sortBy,
    resamplingStatus,
    showUploadFile,
    update,
  ]);

  console.log(resamplingStatus, "resamplingStatus");

  const nextPage = () => {
    if (pageNumber !== totalPages) setPageNumber(pageNumber + 1);
  };
  const prevPage = () => {
    if (pageNumber !== 1) setPageNumber(pageNumber - 1);
  };

  const getPageButtons = (noOfButtons) => {
    let buttonList = [];
    if (true) {
      let printed = 0;
      for (
        let i = Math.floor((pageNumber - 1) / noOfButtons) * noOfButtons + 1;
        printed < noOfButtons && i <= totalPages;
        i++
      ) {
        buttonList.push(
          <button
            className={`px-3 ${
              i === pageNumber
                ? " bg-green-500 text-white text-sm rounded-xl"
                : "text-gray-600 text-sm"
            }`}
            onClick={() => {
              setPageNumber(i);
            }}
          >
            {i}
          </button>
        );
        printed++;
      }
    }
    return buttonList;
  };

  return (
    <div className="m-3 h-screen">
      <TransactionDataDelete
        show={showDeleteModal}
        object={deleteObject}
        onClose={(e) => {
          setShowDeleteModal(false);
        }}
        onYes={deleteCropCuttingSampling}
      />
      {/* <TransactionDataAddCSV
        sampleFileDownloadPath={"sampleSeasonal.csv"}
        fileUploadPath={"fertilizerMaster/file"}
        show={showUploadFile}
        onClose={(e) => {
          setShowUploadFile(false);
        }}
      /> */}

      <div className="flex justify-between overflow-wrap align-middle">
        <div name="IconAndPageTitle" className="bg-white flex ml-3">
          <span>
            <ChartBarIcon className="my-3 mx-1 h-6 w-6 mr-1" />
          </span>
          <span className="text-lg mt-3 text-center align-center">
            Resampling Approval
          </span>
          <span className="mt-5 text-xs ml-2">({totalRecords} records)</span>
        </div>
      </div>
      <div
        name="FilterSection"
        className="flex gap-x-3  border-t-0 border-l-0 border-r-0 pb-5 ml-5 w-11/12"
      >
        <div className="mt-6 flex gap-x-2 w-full">
          <div className="w-full">
            <CustomDropDownSearchSelect
              label={"Zone"}
              labelDisable={true}
              placeholder={"Select Zone"}
              items={
                zoneList !== null
                  ? zoneList.map((element) => {
                      return getItemName(element, "identifier", "name");
                    })
                  : []
              }
              selected={
                zoneId !== null && zoneList !== null
                  ? getItemName(
                      searchObject(zoneList, zoneId, "id"),
                      "identifier",
                      "name"
                    )
                  : null
              }
              handler={(value) => {
                if (value !== null) {
                  setZoneId(
                    zoneList.filter((element) => {
                      return (
                        getItemName(element, "identifier", "name") === value
                      );
                    })[0].id
                  );
                } else {
                  setZoneId(null);
                }
              }}
            />
          </div>

          <div className="w-full">
            <CustomDropDownSearchSelect
              label={"Mandi"}
              labelDisable={true}
              placeholder={"Select Mandi"}
              items={
                mandiList !== null
                  ? mandiList
                      .filter((element) => {
                        return element.zoneId === zoneId;
                      })
                      .map((element) => {
                        return getItemName(element, "identifier", "name");
                      })
                  : []
              }
              selected={
                mandiId !== null && mandiList !== null
                  ? getItemName(
                      searchObject(mandiList, mandiId, "id"),
                      "identifier",
                      "name"
                    )
                  : null
              }
              handler={(value) => {
                if (value !== null) {
                  setMandiId(
                    mandiList.filter((element) => {
                      return (
                        getItemName(element, "identifier", "name") === value
                      );
                    })[0].id
                  );
                } else {
                  setMandiId(null);
                }
              }}
            />
          </div>

          <div className="w-full">
            <CustomDropDownSearchSelect
              items={
                villageList !== null
                  ? villageList
                      .filter((element) => {
                        return element.MandiMaster.id === mandiId;
                      })
                      .map((element) => {
                        return getItemName(element, "identifier", "name");
                      })
                  : []
              }
              label={"Village"}
              labelDisable={true}
              placeholder={"Village"}
              selected={
                villageId !== null
                  ? getItemName(
                      searchObject(villageList, villageId, "id"),
                      "identifier",
                      "name"
                    )
                  : null
              }
              handler={(value) => {
                if (value !== null && villageList !== null) {
                  setvillageId(
                    villageList.filter((element) => {
                      return (
                        getItemName(element, "identifier", "name") === value
                      );
                    })[0].id
                  );
                } else {
                  setvillageId(null);
                }
              }}
            />
          </div>

          <div className="w-full">
            <CustomDropDownSearchSelect
              items={
                farmerList !== null
                  ? farmerList
                      .filter((element) => {
                        return element.VillageMaster.id === villageId;
                      })
                      .map((element) => {
                        return getItemName(element, "identifier", "name");
                      })
                  : []
              }
              label={"Farmer"}
              labelDisable={true}
              placeholder={"Farmer"}
              selected={
                farmerId !== null
                  ? getItemName(
                      searchObject(farmerList, farmerId, "id"),
                      "identifier",
                      "name"
                    )
                  : null
              }
              handler={(value) => {
                if (value !== null && farmerList !== null) {
                  setfarmerId(
                    farmerList.filter((element) => {
                      return (
                        getItemName(element, "identifier", "name") === value
                      );
                    })[0].id
                  );
                } else {
                  setfarmerId(null);
                }
              }}
            />
          </div>

          <div className="w-full">
            <CustomDropDownSearchSelect
              items={
                varietyList !== null
                  ? varietyList.map((element) => {
                      return getItemName(element, "identifier", "name");
                    })
                  : []
              }
              label={"Variety"}
              labelDisable={true}
              placeholder={"Variety"}
              selected={
                varietyId !== null
                  ? getItemName(
                      searchObject(varietyList, varietyId, "id"),
                      "identifier",
                      "name"
                    )
                  : null
              }
              handler={(value) => {
                if (value !== null && varietyList !== null) {
                  setVarietyId(
                    varietyList.filter((element) => {
                      return (
                        getItemName(element, "identifier", "name") === value
                      );
                    })[0].id
                  );
                } else {
                  setVarietyId(null);
                }
              }}
            />
          </div>

          <div className="w-full">
            <CustomDropDownSearchSelect
              items={
                statusList !== null
                  ? statusList.map((element) => {
                      return getItemName(element, "identifier", "status");
                    })
                  : []
              }
              label={"Status"}
              labelDisable={true}
              placeholder={"Status"}
              selected={
                resamplingStatus !== null
                  ? getItemName(
                      searchObject(statusList, resamplingStatus, "id"),
                      "identifier",
                      "status"
                    )
                  : null
              }
              handler={(value) => {
                if (value !== null && statusList !== null) {
                  setStatusId(
                    statusList.filter((element) => {
                      return (
                        getItemName(element, "identifier", "status") === value
                      );
                    })[0].id
                  );
                } else {
                  setStatusId(null);
                }
              }}
            />
          </div>

          {/* <CustomButton btnName="Filter" className="p-2 mt-12 bg-green-500 hover:bg-green-700 text-sm " onClick={applyFilter} /> */}
        </div>
      </div>

      {/* <CustomButton btnName="Filter" className="p-2 mt-12 bg-green-500 hover:bg-green-700 text-sm " onClick={applyFilter} /> */}

      <div name="Table" className="bg-white ml-3 mt-4 overflow-hidden mb-8">
        <CustomTable
          rows={currentTableData}
          headers={tableHeaders}
          sortByHandler={setSortBy}
        />
      </div>
      {
        //show the table navigation buttons only if the table has more than 0 records.
        currentTableData.length > 0 ? (
          <div className="text-sm w-max mx-auto text-center flex mt-1">
            <button
              onClick={prevPage}
              className="border-2 text-gray-600 rounded-xl border-black p-1 w-6 h-6 mx-3"
            >
              <ChevronLeftIcon />
            </button>
            <div className="mx-2 mt-1 w-28">
              {getPageButtons(tablePageNumbersShown)}
            </div>
            <button
              onClick={nextPage}
              className="border-2  text-gray-600 rounded-xl border-black p-1 w-6 h-6 mx-3"
            >
              <ChevronRightIcon />
            </button>
          </div>
        ) : null
      }
    </div>
  );
};
export default ResamplingApprovalHome;
