import { React, useEffect, useState } from "react";
import { useLocation, Redirect } from "react-router-dom";
import { EyeIcon } from "@heroicons/react/outline";
import axios from "../../../axios";
import CustomInputBox from "../../../components/atoms/CustomInputBox";

const FarmerMasterDisplay = () => {
  const [pop, setPop] = useState(null);
  const [mandi, setMandi] = useState(null);
  const [varietyList, setVarietyList] = useState(null);
  const [varieties, setVarities] = useState(null);
  const [Jform, setJform] = useState(null);

  const getItemName = (obj, prop1, prop2) => {
    return obj[prop2];
  };

  const searchObject = (items, target, property) => {
    return items.filter((element) => {
      return element[property] === target;
    })[0];
  };

  useEffect(() => {
    axios
      .get(`pop`)
      .then((response) => {
        setPop(
          response.data.Result.Pop.filter((element) => {
            return element.id === data.popId;
          })[0].pop
        );
        return axios.get(`mandiMaster/load`);
      })
      .then((response) => {
        setMandi(
          response.data.Result.MandiMaster.filter((element) => {
            return element.id === data.mandiId;
          })[0].name
        );

        return axios.get(`specificVariety/${data.id}`);
      })
      .then((response) => {
        setVarietyList(response.data.Result.FarmerVariety);
        return axios.get("varietyMaster/load");
      })
      .then((response) => {
        setVarities(response.data.Result.VarietyMaster);
        return axios.get(
          `/farmerJform/${!data.id.value ? data.id : data.id.value}`
        );
      })
      .then((response) => {
        setJform(response.data.Result.Jform);
      });
  }, []);

  const location = useLocation();
  if (!JSON.parse(location.state)) return <Redirect to="/" />;
  const { data } = JSON.parse(location.state);
  console.log(data);

  return (
    <div className="overflow-hidden m-3 p-3">
      <div className="flex">
        <span>
          <EyeIcon className="h-5 w-5 transform text-black mt-4 mr-1" />
        </span>
        <span className=" py-4 text-gray-700 text-base font-bold text-left">
          Display Farmer master
        </span>
      </div>
      <div className="w-11/12 flex flex-wrap">
        {/* <CustomDisplayBox title="ID" value={data.id} className=" pl-2"/> */}
        <div className="flex w-full">
          <CustomInputBox
            title="Farmer"
            value={`${data.name.value}`}
            disabled={true}
            borderDisable={true}
          />
          <CustomInputBox
            title="Registration Date"
            value={data.registrationDate.substr(0, 10).replaceAll("-", "/")}
            disabled={true}
            borderDisable={true}
          />
          <CustomInputBox
            title="Package of Practice"
            value={pop !== null ? pop : null}
            disabled={true}
            borderDisable={true}
          />
        </div>

        <div className="flex w-full">
          <CustomInputBox
            title="Father's Name"
            value={`${data.fatherName}`}
            disabled={true}
            borderDisable={true}
          />
          <CustomInputBox
            title="Mandi Name"
            value={mandi !== null ? mandi : null}
            disabled={true}
            borderDisable={true}
          />
          <CustomInputBox
            title="Farmer's Address"
            value={data.address}
            disabled={true}
            borderDisable={true}
          />
        </div>
        <div className="flex w-full">
          <CustomInputBox
            title="Pakka Aarthi Name"
            value={data.PakkaAarthi.name}
            disabled={true}
            borderDisable={true}
          />
          <CustomInputBox
            title="Own Land"
            value={data.ownLand.value}
            disabled={true}
            borderDisable={true}
          />
          <CustomInputBox
            title="Leased Land (in acres)"
            value={data.leasedLand}
            disabled={true}
            borderDisable={true}
          />
        </div>

        <div className="flex w-full">
          <CustomInputBox
            title="Total Land"
            value={data.totalLand}
            disabled={true}
            borderDisable={true}
          />
          <CustomInputBox
            title="Cycle"
            value={data.cycle.value}
            disabled={true}
            borderDisable={true}
          />
          <CustomInputBox
            title="Previous Year"
            value={data.year}
            disabled={true}
            borderDisable={true}
          />
        </div>
        {/* <CustomInputBox
          title="Land Used (in acres)"
          value={data.landUsed}
          disabled={true}
          borderDisable={true}
        /> */}
        {/* <CustomInputBox
          title="Crop / Variety"
          value={"[unknown]"}
          disabled={true}
          borderDisable={true}
        /> */}

        <div className="flex w-full">
          <CustomInputBox
            title="Seed Source"
            value={data.seedSource}
            disabled={true}
            borderDisable={true}
          />
          <CustomInputBox
            title="Special Practice"
            value={data.specialPractices ? data.specialPractices : "None"}
            disabled={true}
            borderDisable={true}
          />
          <CustomInputBox
            title="Seed Treatment"
            value={data.seedTreatment}
            disabled={true}
            borderDisable={true}
          />
        </div>

        <div className="flex w-full">
          <CustomInputBox
            title="Kaccha Aarthi Name"
            value={data.KacchaAarthi.name}
            disabled={true}
            borderDisable={true}
          />
          <CustomInputBox
            title="Kaccha Aarthi Contact Persons"
            value={data.KacchaAarthi.contactPerson}
            disabled={true}
            borderDisable={true}
          />
          <div className="w-full">
            <CustomInputBox
              title="Leased Landowner Name"
              value={data.leasedLandowner}
              disabled={true}
              borderDisable={true}
            />
          </div>
        </div>
        <br></br>
        {varieties != null &&
          varietyList?.map((element, varIndex) => {
            return (
              <div className="w-2/3 flex">
                <CustomInputBox
                  title="Variety"
                  value={getItemName(
                    searchObject(varieties, element.varietyId, "id"),
                    "identifier",
                    "name"
                  )}
                  disabled={true}
                  borderDisable={true}
                />
                <CustomInputBox
                  title="Land Used"
                  value={element?.landUsed}
                  disabled={true}
                  borderDisable={true}
                />
              </div>
            );
          })}
        <div className="flex w-full">
          <CustomInputBox
            title="Laser Leveling Area (in acres)"
            value={`${data.levelingArea}`}
            disabled={true}
            borderDisable={true}
          />
          <CustomInputBox
            title="Date"
            value={
              data.date === null
                ? ""
                : data.date.substr(0, 10).replaceAll("-", "/")
            }
            disabled={true}
            borderDisable={true}
          />
          <CustomInputBox
            title="Remarks"
            value={`${data.remarks}`}
            disabled={true}
            borderDisable={true}
          />
        </div>
        <div className="w-full">
          <CustomInputBox
            title="Seed Treatment Chemical Name"
            value={data.seedTreatmentChemical}
            disabled={true}
            borderDisable={true}
          />
        </div>
        {Jform &&
          Jform.length &&
          Jform?.map((element, varIndex) => {
            return (
              <div className="w-2/3 flex">
                <CustomInputBox
                  title="Jform Acre"
                  value={element?.acre}
                  disabled={true}
                  borderDisable={true}
                />
                <CustomInputBox
                  title="Jform Name"
                  value={element?.name}
                  disabled={true}
                  borderDisable={true}
                />
              </div>
            );
          })}

        {/* <CustomInputBox
          title="Shop Number"
          value={"[unknown]"}
          disabled={true}
          borderDisable={true}
        /> */}
      </div>
    </div>
  );
};
export default FarmerMasterDisplay;
