import React, { useEffect, useState } from "react";
import { useLocation, Redirect, useHistory } from "react-router-dom";
import { CustomButton } from "../../../components/atoms/CustomButton";
import CustomInputBox from "../../../components/atoms/CustomInputBox";
import axios from "../../../axios";
import { toast } from "react-toastify";
import { PencilIcon } from "@heroicons/react/outline";
import CustomDropDownSearchSelect from "../../../components/atoms/CustomDropDownSearchSelect";
import { errorToast } from "../../../util/toast";
import { validateAarthiEdit } from "../common/validations";

const AarthiMasterEdit = () => {
  const location = useLocation();
  const history = useHistory();
  const { data } = JSON.parse(location.state);
  const [name, setName] = useState(data?.aarthiName.value);
  const [identifier, setIdentifier] = useState(data.identifier.value);
  const [mandiId, setMandiId] = useState(null);
  const [contactPerson, setContactPerson] = useState(data.contactPerson.value);
  const [shopNumber, setShopNumber] = useState(data.shopNumber.value);
  const [email, setEmail] = useState(data.email);
  const [mobile, setMobile] = useState(data.mobile);
  const [error, setError] = useState(null);
  const [mandiList, setMandiList] = useState([]);

  const getItemName = (obj, prop1) => {
    return obj[prop1];
  };

  useEffect(() => {
    axios
      .get(`mandiMaster/load`)
      .then((response) => {
        setMandiList(response.data.Result.MandiMaster);
        setMandiId(
          response.data.Result.MandiMaster.filter((element) => {
            return element.identifier === data.MandiMaster.identifier;
          })[0].id
        );
      })
      .catch((error) => {
        console.log(error.message);
        errorToast(error?.response?.data?.Comments);
      });
  }, []);

  if (!location.state) {
    return <Redirect to="/" />;
  }

  const editAarthi = () => {
    if (
      validateAarthiEdit({
        identifier,
        name,
        mandiId,
        contactPerson,
        shopNumber,
        email,
        mobile,
      })
    ) {
      axios
        .put(`aarthiMaster/${data.id}`, {
          identifier,
          name,
          mandiId,
          contactPerson,
          shopNumber,
          email,
          mobile,
        })
        .then((response) => {
          if (response?.data?.ResponseMessage === "SUCCESS") {
            toast.success("Update successful!", { position: "top-center" });
            history.goBack();
          }
        })
        .catch((error) => {
          errorToast(error?.response?.data?.Comments);
          console.log(error);
        });
    }
  };

  return (
    <div className=" m-3 p-3">
      <div className="flex">
        <span>
          <PencilIcon className="h-5 w-5 transform text-black mt-4 mr-1" />
        </span>
        <span className=" py-4 text-gray-700 text-base font-bold text-left">
          Edit Aarthi Master
        </span>
      </div>
      <div className="flex flex-wrap justify-left">
        <div className="w-1/2">
          <div className="flex flex-wrap gap-x-3 mb-4">
            {/* <CustomDisplayBox title="ID" value={data.id} /> */}
            <CustomInputBox
              title="Name"
              value={name}
              className="w-1/2"
              valueSetter={setName}
            />
            <CustomInputBox
              title="Contact Person"
              value={contactPerson}
              className="w-1/2"
              valueSetter={setContactPerson}
            />
            <CustomInputBox
              title="Mobile"
              value={mobile}
              className="w-1/2"
              valueSetter={setMobile}
            />
            <CustomInputBox
              title="Shop Number"
              value={shopNumber}
              className="w-1/2"
              valueSetter={setShopNumber}
            />
            <div className="w-1/2">
              <CustomDropDownSearchSelect
                label={"Mandi"}
                items={mandiList.map((value) => {
                  return getItemName(value, "name");
                })}
                selected={
                  mandiId !== null && mandiList.length > 0
                    ? getItemName(
                        mandiList.filter((element) => {
                          return element.id === mandiId;
                        })[0],
                        "name"
                      )
                    : null
                }
                handler={(value) => {
                  if (value !== null)
                    setMandiId(
                      mandiList.filter((element) => {
                        return getItemName(element, "name") === value;
                      })[0].id
                    );
                  else {
                    setMandiId(null);
                  }
                }}
              />
            </div>
          </div>
          <div className="flex mt-8 justify-between">
            <CustomButton
              btnName="Cancel"
              className="p-2 bg-gray-500 hover:bg-gray-700 text-lg"
              onClick={() => {
                history.goBack();
              }}
            />
            <CustomButton
              btnName="Update"
              className="p-2 bg-green-500 hover:bg-green-700 text-lg"
              onClick={editAarthi}
            />
          </div>
        </div>
      </div>
      <div className="text-xl text-red-500">{error}</div>
      <div className="flex w-max gap-x-10"></div>
    </div>
  );
};
export default AarthiMasterEdit;
