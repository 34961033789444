import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { CustomButton } from "../../../components/atoms/CustomButton";
import TransactionDataDelete from "../common/TransactionDataDelete";
import TransactionDataSampleCode from "../common/TransactionDataSampleCode";
import axios from "../../../axios";
import {
  DocumentAddIcon,
  ClockIcon,
  ChartBarIcon,
} from "@heroicons/react/outline";
import {
  EyeIcon,
  PencilIcon,
  TrashIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
} from "@heroicons/react/solid";
import { ArrowDownIcon, ArrowUpIcon } from "@heroicons/react/outline";
import CustomCheckBox from "../../../components/atoms/CustomCheckBox";
import { toast } from "react-toastify";
import CustomTable from "../../../components/atoms/CustomTable";
import CustomDropDownSearchSelect from "../../../components/atoms/CustomDropDownSearchSelect";
import { errorToast, successToast } from "../../../util/toast";

const getItemName = (obj, prop1, prop2) => {
  return obj[prop2];
};
//returns the first object in the list whose property matches the target
const searchObject = (items, target, property) => {
  return items.filter((element) => {
    return element[property] === target;
  })[0];
};

const CropCuttingSamplingHome = () => {
  const role = JSON.parse(localStorage.getItem("cfUserAuth"));
  const result = role.find((obj) => {
    return obj.moduleName === "cropCuttingSamplings";
  });

  const [, updateState] = React.useState();
  const forceUpdate = React.useCallback(() => updateState({}), []);
  const [checked, setChecked] = useState({});
  const history = useHistory();
  const tablePageNumbersShown = 3;
  //table related.
  const [currentTableData, setTableData] = useState([]);
  const rowsPerPage = 10;
  const [totalRecords, setTotalRecords] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [sortBy, setSortBy] = useState({
    headerName: "id",
    order: false,
  });

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showGenerateModal, setShowGenerateModal] = useState(false);
  const [showUploadFile, setShowUploadFile] = useState(false);
  const [deleteObject, setDeleteObject] = useState(null);
  const [tempObj, setTempObj] = useState(null);

  const [mixed, setMixed] = useState(false);

  const tableHeaders = [
    // { displayName: "id", property: "id", sortable: true, show: false },
    { displayName: "Farmer ID", property: "id", sortable: false, show: true },

    {
      displayName: "Farmer",
      property: "FarmerMaster.name",
      sortable: true,
      show: true,
    },
    {
      displayName: "Variety Name",
      property: "VarietyMaster.name",
      sortable: true,
      show: true,
    },
    {
      displayName: "Sampling Request Date",
      property: "samplingRequestDate",
      sortable: true,
      show: true,
    },
    {
      displayName: "Sampling Number",
      property: "samplingNumber",
      sortable: true,
      show: true,
    },
    {
      displayName: "Sampling Combination Code",
      property: "samplingCombinationCode",
      sortable: true,
      show: true,
    },

    {
      displayName: "actions",
      property: "actions",
      sortable: false,
      show: true,
    },
  ];

  const [varietyId, setVarietyId] = useState(null);
  const [farmerId, setfarmerId] = useState(null);
  const [zoneId, setZoneId] = useState(null);
  const [varietyList, setVarietyList] = useState(null);
  const [farmerList, setFarmerList] = useState(null);
  const [zoneList, setZoneList] = useState(null);
  const [mandiList, setMandiList] = useState(null);
  const [aarthiList, setAarthiList] = useState(null);
  const [pakkaAarthiList, setPakkaAarthiList] = useState(null);
  const [villageList, setVillageList] = useState(null);
  const [villageId, setvillageId] = useState(null);
  const [pakkaAarthiId, setPakkaAarthiId] = useState(null);

  const [mandiId, setMandiId] = useState(null);

  const getOrderedKeys = (obj) => {
    const keysToShow = Object.keys(obj).filter((key) => {
      if (obj[key]) {
        return obj[key].hasOwnProperty("show");
      } else return false;
    });
    if (keysToShow.length > 0 && obj[keysToShow[0]].hasOwnProperty("order")) {
      const orderedShowKeys = keysToShow.sort((a, b) => {
        return obj[a].order > obj[b].order ? 1 : -1;
      });
      return orderedShowKeys;
    } else {
      return keysToShow;
    }
  };

  const fun = (obj) => {
    // obj.ischecked = true;

    let prev = currentTableData;
    let check = checked;

    // prev = prev.filter((val)=>val.id != obj.id);

    for (let i = 0; i < prev.length; i++) {
      if (prev[i].id === obj.id) {
        if (prev[i].ischecked == false) {
          check[obj.id] = 1;
        } else {
          delete check[obj.id];
        }
        prev[i].ischecked = !prev[i].ischecked;
      }
    }
    setChecked(check);
    setTableData(prev);
    forceUpdate();
  };

  const generateSampleCode = () => {
    if (!mixed) {
      axios
        .post("createSamplingCode", { checked })
        .then((response) => {
          successToast("Sample Code Generated!");
          window.location.reload();
        })
        .catch((e) => console.log(e));
    } else {
      errorToast(
        "Only farmers of a single type (FRTC/FRIP) can be selected for code generation"
      );
    }
  };
  useEffect(() => {
    let arr = [];
    currentTableData.map((item) => {
      if (item.ischecked) {
        console.log(item, "Item");
        arr.push(item.FarmerVariety.FarmerMaster.popId);
      }
    });
    let isMixed = false;
    if (arr.length > 1) {
      for (let i = 0; i < arr?.length - 1; i++) {
        if (arr[i] != arr[i + 1]) {
          isMixed = true;
        }
      }
    }
    if (isMixed) {
      setMixed(true);
    } else {
      setMixed(false);
    }
  });

  useEffect(() => {
    axios
      .get(`zoneMasters/specificload/${localStorage.getItem("cfUserId")}`)
      .then((response) => {
        setZoneList(response.data.Result.ZoneMaster);
        return axios.get(
          `mandiMaster/load/${localStorage.getItem("cfUserId")}`
        );
      })
      .then((response) => {
        setMandiList(response.data.Result.MandiMaster);
        return axios.get(`farmerMaster/load`);
      })
      .then((response) => {
        setFarmerList(response.data.Result.FarmerMaster);

        return axios.get(`varietyMaster/load`);
      })
      .then((response) => {
        setVarietyList(response.data.Result.VarietyMaster);
        return axios.get(`villageMasters/load`);
      })
      .then((response) => {
        setVillageList(response.data.Result.villageMaster);
        return axios.get("farmerMaster/load");
      })
      .then((response) => {
        setFarmerList(response.data.Result.FarmerMaster);
      })
      .catch((err) => {
        errorToast(err.response.data.Comments);
        console.log(err);
      });
  }, []);

  const getDateString = (date) => {
    let year = date.getFullYear();
    let month = date.getMonth() + 1;
    month = month < 10 ? "0" + month : month;
    let day = date.getDate();
    day = day < 10 ? "0" + day : day;

    return day + "/" + month + "/" + year;
  };

  const deleteCropCuttingSampling = () => {
    axios
      .delete(`cropCuttingSampling/${deleteObject.id}`)
      .then((response) => {
        if (response?.data?.ResponseMessage === "SUCCESS") {
          // window.location.reload();
          setShowDeleteModal(false);
          successToast("Delete Successful!");
          window.location.reload();
          // remove this and do it better, we need to remove the row of the delete object thats it
        }
      })
      .catch((error) => {
        errorToast(error?.response?.data?.Comments);
        console.log(error);
      });
  };

  useEffect(() => {
    console.log("REINITIATED");
    let params = {
      page: pageNumber,
      limit: 10,
      orderBy: sortBy.headerName,
      order: sortBy.order ? "ASC" : "DESC",
    };
    if (varietyId !== null) {
      params.varietyId = varietyId;
    }
    if (farmerId !== null) {
      params.farmerId = farmerId;
    }
    if (villageId !== null) {
      params.villageId = villageId;
    }

    if (zoneId !== null) {
      params.zoneId = zoneId;
    }

    if (mandiId !== null) {
      params.mandiId = mandiId;
    }
    params.isConfirmed = 0;
    if (varietyList !== null && farmerList !== null) {
      axios
        .get(`cropCuttingSampling`, { params })
        .then((response) => {
          setTotalRecords(response.data.Result.total);
          const returnedRows = response.data.Result.CropCuttingSampling;
          setTempObj(returnedRows);
          setTotalPages(Math.ceil(response.data.Result.total / rowsPerPage));

          returnedRows.map((obj) => {
            obj.ischecked = checked[obj.id] == undefined ? false : true;
          });

          let actualRows = addActions(returnedRows);

          setTableData(actualRows);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [
    varietyId,
    checked,
    farmerId,
    varietyList,
    villageList,
    farmerList,
    zoneList,
    zoneId,
    mandiId,
    villageId,
    pakkaAarthiList,
    pageNumber,
    sortBy,
    showUploadFile,
  ]);

  const addActions = (objects) => {
    //adds the actions buttons to the table rows.
    const newObjects = [];

    objects.map((obj) => {
      obj.identifier = {
        value:
          obj.FarmerVariety.FarmerMaster.popId === 1
            ? `FRTC-${obj.id}`
            : `FRIP-${obj.id}`,
        show: true,
        order: 0,
      };
      obj.farmerName = {
        value: getItemName(
          obj.FarmerVariety.FarmerMaster,
          "identifier",
          "name"
        ),
        show: true,
        order: 1,
      };
      obj.varietyName = {
        value: getItemName(
          obj.FarmerVariety.VarietyMaster,
          "identifier",
          "name"
        ),
        show: true,
        order: 2,
      };
      obj.samplingRequestDate = {
        value: getDateString(new Date(obj.samplingRequestDate)),
        show: true,
        order: 3,
      };
      obj.samplingNumber = { value: obj.samplingNumber, show: true, order: 4 };
      obj.samplingCombinationCode = {
        value: obj.samplingCombinationCode,
        show: true,
        order: 5,
      };

      obj.actions = {
        order: 6,
        value: (
          <div className="flex gap-x-3 -ml-4 p-1">
            {result.moduleUpdate == 1 && (
              <Link
                to={{
                  pathname: `/edit/${obj.id}`,
                  state: JSON.stringify({ data: obj }),
                }}
              >
                <span>
                  <PencilIcon className="h-5 w-5 text-gray-700 hover:text-black" />
                </span>
              </Link>
            )}
            <Link
              to={{
                pathname: `/display`,
                state: JSON.stringify({ data: obj }),
              }}
            >
              <span>
                <EyeIcon className="h-5 w-5 text-gray-700 hover:text-black" />
              </span>
            </Link>
            {result.moduleDelete == 1 && (
              <span>
                <TrashIcon
                  className="h-5 w-5 text-gray-700 hover:text-black"
                  onClick={() => {
                    setShowDeleteModal(true);
                    // obj.name = "Sampling Detail"
                    obj.name = "";
                    setDeleteObject(obj);
                  }}
                />
              </span>
            )}
          </div>
        ),
        show: true,
      };
      // obj.checkbox = {value : (<input key = {obj.id} type="checkbox" obj = {obj} onChange={fun(obj)}/>) , show:true ,order:8}
      newObjects.push(obj);
    });

    return newObjects;
  };

  useEffect(() => {
    setMandiId(null);
  }, [zoneId]);
  useEffect(() => {
    setvillageId(null);
  }, [mandiId]);

  useEffect(() => {
    setfarmerId(null);
  }, [villageId]);

  const nextPage = () => {
    if (pageNumber !== totalPages) setPageNumber(pageNumber + 1);
  };
  const prevPage = () => {
    if (pageNumber !== 1) setPageNumber(pageNumber - 1);
  };

  const getPageButtons = (noOfButtons) => {
    let buttonList = [];
    if (true) {
      let printed = 0;
      for (
        let i = Math.floor((pageNumber - 1) / noOfButtons) * noOfButtons + 1;
        printed < noOfButtons && i <= totalPages;
        i++
      ) {
        buttonList.push(
          <button
            className={`px-3 ${
              i === pageNumber
                ? " bg-green-500 text-white text-sm rounded-xl"
                : "text-gray-600 text-sm"
            }`}
            onClick={() => {
              setPageNumber(i);
            }}
          >
            {i}
          </button>
        );
        printed++;
      }
    }
    return buttonList;
  };

  return (
    <div className="m-3 h-screen">
      <TransactionDataDelete
        show={showDeleteModal}
        object={deleteObject}
        onClose={(e) => {
          setShowDeleteModal(false);
        }}
        onYes={deleteCropCuttingSampling}
      />
      <TransactionDataSampleCode
        show={showGenerateModal}
        onClose={(e) => {
          setShowGenerateModal(false);
        }}
        onYes={generateSampleCode}
      />

      {/* <TransactionDataAddCSV
        sampleFileDownloadPath={"sampleSeasonal.csv"}
        fileUploadPath={"fertilizerMaster/file"}
        show={showUploadFile}
        onClose={(e) => {
          setShowUploadFile(false);
        }}
      /> */}

      <div className="flex justify-between overflow-wrap align-middle">
        <div name="IconAndPageTitle" className="bg-white flex ml-3">
          <span>
            <ChartBarIcon className="my-3 mx-1 h-6 w-6 mr-1" />
          </span>
          <span className="text-lg mt-3 text-center align-center">
            Sampling Request
          </span>
          <span className="mt-5 text-xs ml-2">({totalRecords} records)</span>
        </div>
        {result.moduleCreate == 1 && (
          <div name="Add buttons" className="flex flex-wrap gap-x-10">
            {/* <CustomButton
            onClick={() => {
              history.push("/renew");
            }}
            btnName="Renew"
            icon={<RefreshIcon className="h-5 w-5 mr-1" />}
          /> */}
            <CustomButton
              onClick={() => {
                history.push("/addSelect");
              }}
              btnName="Add"
              icon={<DocumentAddIcon className="h-5 w-5 mr-1" />}
            />
          </div>
        )}
      </div>
      <div
        name="FilterSection"
        className="flex gap-x-3  border-t-0 border-l-0 border-r-0 pb-5 w-11/12"
      >
        <div className="mt-6 flex gap-x-2 w-full">
          <div className="w-full">
            <CustomDropDownSearchSelect
              label={"Zone"}
              labelDisable={true}
              placeholder={"Select Zone"}
              items={
                zoneList !== null
                  ? zoneList.map((element) => {
                      return getItemName(element, "identifier", "name");
                    })
                  : []
              }
              selected={
                zoneId !== null && zoneList !== null
                  ? getItemName(
                      searchObject(zoneList, zoneId, "id"),
                      "identifier",
                      "name"
                    )
                  : null
              }
              handler={(value) => {
                if (value !== null) {
                  setZoneId(
                    zoneList.filter((element) => {
                      return (
                        getItemName(element, "identifier", "name") === value
                      );
                    })[0].id
                  );
                } else {
                  setZoneId(null);
                }
              }}
            />
          </div>

          <div className="w-full">
            <CustomDropDownSearchSelect
              label={"Mandi"}
              labelDisable={true}
              placeholder={"Select Mandi"}
              items={
                mandiList !== null
                  ? mandiList
                      .filter((element) => {
                        return element.zoneId === zoneId;
                      })
                      .map((element) => {
                        return getItemName(element, "identifier", "name");
                      })
                  : []
              }
              selected={
                mandiId !== null && mandiList !== null
                  ? getItemName(
                      searchObject(mandiList, mandiId, "id"),
                      "identifier",
                      "name"
                    )
                  : null
              }
              handler={(value) => {
                if (value !== null) {
                  setMandiId(
                    mandiList.filter((element) => {
                      return (
                        getItemName(element, "identifier", "name") === value
                      );
                    })[0].id
                  );
                } else {
                  setMandiId(null);
                }
              }}
            />
          </div>

          <div className="w-full">
            <CustomDropDownSearchSelect
              items={
                villageList !== null
                  ? villageList
                      .filter((element) => {
                        return element.MandiMaster.id === mandiId;
                      })
                      .map((element) => {
                        return getItemName(element, "identifier", "name");
                      })
                  : []
              }
              label={"Village"}
              labelDisable={true}
              placeholder={"Village"}
              selected={
                villageId !== null
                  ? getItemName(
                      searchObject(villageList, villageId, "id"),
                      "identifier",
                      "name"
                    )
                  : null
              }
              handler={(value) => {
                if (value !== null && villageList !== null) {
                  setvillageId(
                    villageList.filter((element) => {
                      return (
                        getItemName(element, "identifier", "name") === value
                      );
                    })[0].id
                  );
                } else {
                  setvillageId(null);
                }
              }}
            />
          </div>

          <div className="w-full">
            <CustomDropDownSearchSelect
              items={
                farmerList !== null
                  ? farmerList
                      .filter((element) => {
                        return element.VillageMaster.id === villageId;
                      })
                      .map((element) => {
                        return getItemName(element, "identifier", "name");
                      })
                  : []
              }
              label={"Farmer"}
              labelDisable={true}
              placeholder={"Farmer"}
              selected={
                farmerId !== null
                  ? getItemName(
                      searchObject(farmerList, farmerId, "id"),
                      "identifier",
                      "name"
                    )
                  : null
              }
              handler={(value) => {
                if (value !== null && farmerList !== null) {
                  setfarmerId(
                    farmerList.filter((element) => {
                      return (
                        getItemName(element, "identifier", "name") === value
                      );
                    })[0].id
                  );
                } else {
                  setfarmerId(null);
                }
              }}
            />
          </div>

          <div className="w-full">
            <CustomDropDownSearchSelect
              items={
                varietyList !== null
                  ? varietyList.map((element) => {
                      return getItemName(element, "identifier", "name");
                    })
                  : []
              }
              label={"Variety"}
              labelDisable={true}
              placeholder={"Variety"}
              selected={
                varietyId !== null
                  ? getItemName(
                      searchObject(varietyList, varietyId, "id"),
                      "identifier",
                      "name"
                    )
                  : null
              }
              handler={(value) => {
                if (value !== null && varietyList !== null) {
                  setVarietyId(
                    varietyList.filter((element) => {
                      return (
                        getItemName(element, "identifier", "name") === value
                      );
                    })[0].id
                  );
                } else {
                  setVarietyId(null);
                }
              }}
            />
          </div>

          <div className="-mt-6"></div>
          {/* <CustomButton btnName="Filter" className="p-2 mt-12 bg-green-500 hover:bg-green-700 text-sm " onClick={applyFilter} /> */}
        </div>
      </div>

      {/* <CustomButton btnName="Filter" className="p-2 mt-12 bg-green-500 hover:bg-green-700 text-sm " onClick={applyFilter} /> */}

      {checked && // 👈 null and undefined check
        Object.keys(checked).length != 0 && (
          <CustomButton
            width="14rem"
            onClick={() => {
              setShowGenerateModal(true);
            }}
            btnName="Generate Combination Code"
            icon={<DocumentAddIcon className="h-5 w-5 mr-1" />}
          />
        )}
      <br></br>

      {currentTableData.length == 0 && <h1>No such rows</h1>}
      {currentTableData.length > 0 && (
        <div className="overflow-auto">
          <div className="overflow-auto">
            <table className="text-lg w-full border-collapse">
              <thead>
                <tr>
                  {/*Generating the table headers. Add sort button to sortable headers.*/}
                  {tableHeaders.map((value, index) => {
                    if (value.show)
                      return (
                        <th
                          key={index}
                          className="text-sm bg-white capitalize text-gray-700 px-6 bg-blueGray-50 text-blueGray-500 border border-solid border-blueGray-100 py-3 text-xs border-l-0 border-r-0 font-semibold text-left "
                        >
                          <div className="flex">
                            {value.displayName}
                            {value.sortable ? (
                              <SortButton
                                headerName={value.property}
                                callback={(headerName, order) => {
                                  setSortBy({
                                    headerName: headerName,
                                    order: order,
                                  });
                                }}
                              />
                            ) : null}
                          </div>
                        </th>
                      );
                  })}
                  <th
                    key={7}
                    className="text-sm bg-white capitalize text-gray-700 px-6 bg-blueGray-50 text-blueGray-500 border border-solid border-blueGray-100 py-3 text-xs border-l-0 border-r-0 font-semibold text-left "
                  >
                    Checkbox
                  </th>
                </tr>
              </thead>
              <tbody>
                {
                  /* Generating the rows of the current page. */
                  <>
                    {currentTableData
                      ? currentTableData.map((row, index) => {
                          return (
                            <>
                              <tr
                                key={index}
                                className=" border-t-0 even:bg-gray-100 odd:bg-gray-200"
                              >
                                {getOrderedKeys(row).map((property, index) => {
                                  //check if headers has this property set to show or not.
                                  if (
                                    row[property] !== null &&
                                    row[property].hasOwnProperty("show")
                                  ) {
                                    return (
                                      <td
                                        className={`border-t-0 font-light text-sm px-6 align-left text-left border-l-0 border-r-0  p-1 ${
                                          row[property].value === null
                                            ? "text-gray-400"
                                            : ""
                                        }`}
                                        key={index}
                                      >
                                        {row["samplingCombinationCode"].value &&
                                        index == 0 ? (
                                          <>&#9733; </>
                                        ) : null}
                                        {row[property].value === null
                                          ? "N/A"
                                          : row[property].value}
                                      </td>
                                    );
                                  }
                                  // for(let i = 0;i < headers.length;i++)
                                  // {
                                  //     if(headers[i].property.toLowerCase() === property)
                                  //     {
                                  //         if(headers[i].show === true)
                                  //         {
                                  //         console.log("Header ", headers[i].displayName, " is set to show ?", headers[i].show)
                                  //         return <td className="border-t-0 font-light text-sm px-6 align-left text-left border-l-0 border-r-0  p-1" key={index}>
                                  //     {row[property]}
                                  // </td>
                                  //         }
                                  //     }
                                  // }
                                })}
                                <td
                                  className={`border-t-0 font-light text-sm px-6 align-left text-left border-l-0 border-r-0  p-1`}
                                  key={index}
                                >
                                  {row["samplingCombinationCode"].value ==
                                  null ? (
                                    <input
                                      key={row.id}
                                      type="checkbox"
                                      defaultChecked={
                                        checked[row.id] == 1 ? true : false
                                      }
                                      onClick={() => fun(row)}
                                    />
                                  ) : null}
                                </td>
                              </tr>
                            </>
                          );
                        })
                      : null}
                  </>
                }
              </tbody>
            </table>
          </div>
        </div>
      )}

      {
        //show the table navigation buttons only if the table has more than 0 records.
        currentTableData.length > 0 ? (
          <div className="text-sm w-max mx-auto text-center flex mt-1">
            <button
              onClick={prevPage}
              className="border-2 text-gray-600 rounded-xl border-black p-1 w-6 h-6 mx-3"
            >
              <ChevronLeftIcon />
            </button>
            <div className="mx-2 mt-1 w-28">
              {getPageButtons(tablePageNumbersShown)}
            </div>
            <button
              onClick={nextPage}
              className="border-2  text-gray-600 rounded-xl border-black p-1 w-6 h-6 mx-3"
            >
              <ChevronRightIcon />
            </button>
          </div>
        ) : null
      }
    </div>
  );
};
export default CropCuttingSamplingHome;

const SortButton = ({ headerName, callback }) => {
  const [currentOrder, setOrder] = useState(false);

  return (
    <button
      className="pl-3"
      onClick={() => {
        callback(headerName, !currentOrder);

        setOrder(!currentOrder);
      }}
    >
      {currentOrder ? (
        <ArrowDownIcon className="text-black w-3 h-3" />
      ) : (
        <ArrowUpIcon className="text-black w-3 h-3" />
      )}
    </button>
  );
};
