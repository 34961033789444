import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import Footer from '../components/molecules/Footer';
import Sidebar from '../components/molecules/Sidebar/Sidebar'
import Header from '../components/molecules/header';
import MasterDataRoutes from './Master Data/MasterDataRoutes'
import TransactionDataRoutes from './Transaction Data/TransactionDataRoutes';

const HomePage = () => {
    return (
        <div className="w-full  min-h-screen">
            {console.log("HOMEPAGE")}
            <div className="flex flex-row overflow-auto">
                <Sidebar />
                <div className="w-full font-light">
                    <Header />
                    <BrowserRouter basename="/home">
                        <Switch>
                            <Route exact path="/"><div></div></Route>
                            <Route path="/masterData" ><MasterDataRoutes /></Route>
                            <Route path="/transactionData"><TransactionDataRoutes/></Route>
                        </Switch>
                    </BrowserRouter>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default HomePage;
