import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import MasterDataDelete from "../common/MasterDataDelete"
import AarthiMasterHome from "./AarthiMasterHome";
import AarthiMasterEdit from "./AarthiMasterEdit";
import AarthiMasterDisplay from "./AarthiMasterDisplay";
import AarthiMasterAdd from "./AarthiMasterAdd";

const AarthiMasterRoutes = () => {
    return (
        <BrowserRouter basename="/home/masterData/AarthiMaster/">
            <Switch>
                <Route path="/" component={AarthiMasterHome} exact />
                <Route path="/add" component={AarthiMasterAdd} />
                <Route path="/edit/:id" component={AarthiMasterEdit} />
                <Route path="/delete/:id" component={MasterDataDelete} />
                <Route path="/display" component={AarthiMasterDisplay} />
                <Route component={() => (<Redirect to="/" />)} />
            </Switch>
        </BrowserRouter>
    );
}

export default AarthiMasterRoutes;