import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router";
import { CustomButton } from "../../../components/atoms/CustomButton";
import axios from "../../../axios";
import { DocumentAddIcon, PlusIcon, TrashIcon } from "@heroicons/react/outline";
import CustomInputBox from "../../../components/atoms/CustomInputBox";
import { successToast, errorToast } from "../../../util/toast";
import CustomDropDownSearchSelect from "../../../components/atoms/CustomDropDownSearchSelect";
import CustomDateSelector from "../../../components/atoms/CustomDateSelector";
import CustomToggleSwitch from "../../../components/atoms/CustomToggleSwitch";
import { validatePurchaseAdd } from "../../Master Data/common/validations";

const PurchaseDetailAdd = ({ match }) => {
  const history = useHistory();
  const location = useLocation();
  const [isSubmit, setIsSubmit] = useState(false);
  const [farmerVariety, setFarmerVariety] = useState(
    JSON.parse(location.state).data
  );
  const [aarthiList, setAarthiList] = useState(null);
  const [purchasedStatus, setPurchasedStatus] = useState(false);

  const [purchase, setPurchase] = useState(null);

  console.log(farmerVariety, "Farmer Variety");

  const makeDataObject = () => {
    return {
      farmerVarietyId: farmerVariety.id,
      purchaserName: null,
      slipNumber: null,
      slipDate: null,
      slipQuantity: null,
      isPurchased: null,
      kacchaAarthiId: farmerVariety.FarmerMaster.kacchaAarthiId,
      pakkaAarthiId: farmerVariety.FarmerMaster.pakkaAarthiId,
      iForm: [{ iFormNumber: null, iFormDate: null, iFormQuantity: null }],
      bill: [{ billNumber: null, billDate: null, billQuantity: null }],
      po: [{ poNumber: null, poDate: null }],
      reason: null,
    };
  };
  const [newPurchaseDetail, setNewPurchaseDetails] = useState([
    makeDataObject(),
  ]);
  const searchObject = (items, target, property) => {
    return items.filter((element) => {
      return element[property] === target;
    })[0];
  };
  const getDateString = (date) => {
    let year = date.getFullYear();
    let month = date.getMonth() + 1;
    month = month < 10 ? "0" + month : month;
    let day = date.getDate();
    day = day < 10 ? "0" + day : day;
    return day + "/" + month + "/" + year;
  };

  const getItemName = (obj, prop1, prop2) => {
    return obj[prop2];
  };
  const valueSetter = (index, property, newValue) => {
    let purchaseDetail = [...newPurchaseDetail];
    purchaseDetail[index][property] = newValue;
    setNewPurchaseDetails(purchaseDetail);
  };

  useEffect(() => {
    axios
      .get(`aarthiMaster/load`)
      .then((response) => {
        setAarthiList(response.data.Result.AarthiMaster);
      })
      .catch((error) => {
        console.log(error);
        errorToast(error?.response?.data?.Comments);
      });
  }, []);

  //validation.
  const checkAll = () => {
    let flag = true;
    for (let i = 0; i < newPurchaseDetail.length; i++) {
      // const temp = newPurchaseDetail[i];
      // if (temp.isAcreageVerified === 0) {
      //   if (
      //     temp.actualLandUsed.trim() === "" ||
      //     Number(temp.actualLandUsed === NaN)
      //   ) {
      //     flag = false;
      //     errorToast("Please enter actual land used!");
      //   }
      // }
    }
    return flag;
  };

  const addPurchaseDetails = () => {
    if (purchasedStatus) {
      let flag = true;
      if (validatePurchaseAdd(newPurchaseDetail)) {
        newPurchaseDetail.map((item, index) => {
          let billSum = 0;
          let iFormSum = 0;
          let slipQty = parseInt(item.slipQuantity);
          item.bill.map((bill) => {
            billSum += parseInt(bill.billQuantity);
          });
          item.iForm.map((iFormItem) => {
            iFormSum += parseInt(iFormItem.iFormQuantity);
          });
          if (slipQty == billSum && slipQty == iFormSum) {
            console.log("fine");
          } else {
            flag = false;
            errorToast(
              "Sum of Bill Quantities and Sum of iForm Quantities should be equal to Slip Quantity"
            );
          }
        });
        if (flag) {
          setIsSubmit(true);
          successToast("Your request is being processed");
          axios
            .post("purchaseDetail/", newPurchaseDetail[0])
            .then((response) => {
              if (response?.data?.ResponseMessage === "SUCCESS") {
                successToast("New purchase details added!");
                history.push("/home/transactionData/purchase");
              }
            })
            .catch((error) => {
              errorToast(error?.response?.data?.Comments);
              console.log(error);
            });
        }
      }
    } else {
      setIsSubmit(false);
      axios
        .post("purchaseDetail/", newPurchaseDetail[0])
        .then((response) => {
          if (response?.data?.ResponseMessage === "SUCCESS") {
            successToast("New purchase details added!");
            history.push("/home/transactionData/purchase");
          }
        })
        .catch((error) => {
          errorToast(error?.response?.data?.Comments);
          console.log(error);
        });
    }
  };

  const iFormValueSetter = (purchaseIndex, iFormIndex, property, value) => {
    if (value !== null) {
      let purchase = [...newPurchaseDetail];
      purchase[purchaseIndex].iForm[iFormIndex][property] = value;
      setNewPurchaseDetails(purchase);
    }
  };

  const addiform = (index) => {
    let purchase = [...newPurchaseDetail];
    purchase[index].iForm = [
      ...purchase[index].iForm,
      { iFormNumber: null, iFormQuantity: null, iFormDate: null },
    ];
    setNewPurchaseDetails(purchase);
  };

  const removeiform = (index) => {
    let purchase = [...newPurchaseDetail];
    purchase[index].iForm = purchase[index].iForm.filter((element, i) => {
      return i !== index;
    });
    setNewPurchaseDetails(purchase);
  };

  const billValueSetter = (purchaseIndex, billIndex, property, value) => {
    if (value !== null) {
      let purchase = [...newPurchaseDetail];
      purchase[purchaseIndex].bill[billIndex][property] = value;
      setNewPurchaseDetails(purchase);
    }
  };

  const addBill = (index) => {
    let purchase = [...newPurchaseDetail];
    purchase[index].bill = [
      ...purchase[index].bill,
      { billNumber: null, billQuantity: null, billDate: null },
    ];
    setNewPurchaseDetails(purchase);
  };

  const removeBill = (index) => {
    let purchase = [...newPurchaseDetail];
    purchase[index].bill = purchase[index].bill.filter((element, i) => {
      return i !== index;
    });
    setNewPurchaseDetails(purchase);
  };

  const poValueSetter = (purchaseIndex, poIndex, property, value) => {
    if (value !== null) {
      let purchase = [...newPurchaseDetail];
      purchase[purchaseIndex].po[poIndex][property] = value;
      setNewPurchaseDetails(purchase);
    }
  };

  const addPo = (index) => {
    let purchase = [...newPurchaseDetail];
    purchase[index].po = [
      ...purchase[index].po,
      { poNumber: null, poQuantity: null },
    ];
    setNewPurchaseDetails(purchase);
  };

  const removePo = (index) => {
    let purchase = [...newPurchaseDetail];
    purchase[index].po = purchase[index].po.filter((element, i) => {
      return i !== index;
    });
    setNewPurchaseDetails(purchase);
  };
  return (
    <div className="p-3 m-3">
      <div className="flex mb-7">
        <DocumentAddIcon className="h-5 w-5text-black mt-3 mr-1" />
        <div className="text-base pt-3 font-bold text-gray-800">
          Add Purchase Details
        </div>
      </div>
      <div>
        {newPurchaseDetail !== null
          ? newPurchaseDetail.map((value, index) => {
              return (
                <div>
                  <div className="grid gap-x-3 grid-cols-2 w-2/3">
                    {/* <div className="flex gap-x-3">
            <CustomDateSelector 
              label={"Sowing Date"}
              date = {value.sowingDate === null ? (new Date()) : (new Date(value.sowingDate))}
              onChange={(value)=>{valueSetter(index,"sowingDate", getDateString(new Date(value)))}}
            />
            <CustomDateSelector 
              label={"Seed To Seed Date"}
              date = {value.seedToSeedDate === null ? (new Date()) : (new Date(value.seedToSeedDate))}
              onChange={(value)=>{valueSetter(index,"seedToSeedDate", getDateString(new Date(value)))}}
            />
          </div> */}
                    {/* <CustomInputBox
                      title={"Farmer Code"}
                      disabled={true}
                      borderDisable={true}
                      value={value.FarmerVariety.FarmerMaster.name}
                    />
                    <CustomInputBox
                      title={"Variety"}
                      disabled={true}
                      borderDisable={true}
                      value={value.FarmerVariety.VarietyMaster.name}
                    /> */}
                     <div className="w-full">
                      <CustomInputBox
                        title={"Purchaser Name"}
                        value={
                          value.purchaserName !== null ? value.purchaserName : ""
                        }
                        valueSetter={(value) => {
                          valueSetter(index, "purchaserName", value);
                        }}
                      />
                    </div>
                    <div className="w-full">
                      <CustomInputBox
                        title={"Slip Number"}
                        value={
                          value.slipNumber !== null ? value.slipNumber : ""
                        }
                        valueSetter={(value) => {
                          valueSetter(index, "slipNumber", value);
                        }}
                      />
                    </div>
                    <div className="w-full">
                      <CustomInputBox
                        title={"Slip Quantity"}
                        value={
                          value.slipQuantity !== null ? value.slipQuantity : ""
                        }
                        valueSetter={(value) => {
                          valueSetter(index, "slipQuantity", value);
                        }}
                      />
                    </div>
                    <div className="w-full">
                      <CustomDateSelector
                        label={"Slip Date"}
                        date={
                          value.slipDate === null
                            ? null
                            : new Date(value.slipDate)
                        }
                        onChange={(value) => {
                          valueSetter(index, "slipDate", value);
                        }}
                      />
                    </div>
                    {/* <div className="mt-1">
                      <CustomToggleSwitch
                        label2={"Purchased"}
                        checked={value.isPurchased === 1}
                        onChange={(value) => {
                          valueSetter(index, "isPurchased", value ? 1 : 0);
                        }}
                      />
                    </div> */}
                    <div className="w-full">
                      <CustomDropDownSearchSelect
                        label={"Purchased"}
                        items={["Yes", "No"]}
                        selected={
                          value.isPurchased === 1
                            ? "Yes"
                            : value.isPurchased === 0
                            ? "No"
                            : null
                        }
                        handler={(value) => {
                          setPurchasedStatus(value == "Yes");
                          setPurchase(value);
                          valueSetter(
                            index,
                            "isPurchased",
                            value == "Yes" ? 1 : value == "No" ? 0 : null
                          );
                        }}
                      />
                    </div>
                    <div className="w-full">
                      <CustomDropDownSearchSelect
                        label={"Kaccha Aarthi Name"}
                        items={
                          aarthiList !== null
                            ? aarthiList
                                .filter(
                                  (val) =>
                                    farmerVariety.FarmerMaster.MandiMaster
                                      .id === val.MandiMaster.id
                                )
                                .map((value) => {
                                  return getItemName(
                                    value,
                                    "identifier",
                                    "name"
                                  );
                                })
                            : []
                        }
                        selected={
                          aarthiList !== null && value.kacchaAarthiId !== null
                            ? getItemName(
                                searchObject(
                                  aarthiList,
                                  value.kacchaAarthiId,
                                  "id"
                                ),
                                "identifier",
                                "name"
                              )
                            : null
                        }
                        handler={(value) => {
                          if (value !== null) {
                            valueSetter(
                              index,
                              "kacchaAarthiId",
                              aarthiList.filter((element) => {
                                return (
                                  getItemName(element, "identifier", "name") ===
                                  value
                                );
                              })[0].id
                            );
                          } else {
                            valueSetter(index, "kacchaAarthiId", null);
                          }
                        }}
                      />
                    </div>
                    <div className="w-full">
                      <CustomDropDownSearchSelect
                        label={"Pakka Aarthi Name"}
                        items={
                          aarthiList !== null
                            ? aarthiList
                                .filter(
                                  (val) =>
                                    farmerVariety.FarmerMaster.MandiMaster
                                      .id === val.MandiMaster.id
                                )
                                .map((value) => {
                                  return getItemName(
                                    value,
                                    "identifier",
                                    "name"
                                  );
                                })
                            : []
                        }
                        selected={
                          aarthiList !== null && value.pakkaAarthiId !== null
                            ? getItemName(
                                searchObject(
                                  aarthiList,
                                  value.pakkaAarthiId,
                                  "id"
                                ),
                                "identifier",
                                "name"
                              )
                            : null
                        }
                        handler={(value) => {
                          if (value !== null) {
                            valueSetter(
                              index,
                              "pakkaAarthiId",
                              aarthiList.filter((element) => {
                                return (
                                  getItemName(element, "identifier", "name") ===
                                  value
                                );
                              })[0].id
                            );
                          } else {
                            valueSetter(index, "pakkaAarthiId", null);
                          }
                        }}
                      />
                    </div>
                    {/* <div className="w-full">
                      <CustomDateSelector
                        label={"J-Form Date"}
                        date={
                          value.jFormDate === null
                            ? null
                            : new Date(value.jFormDate)
                        }
                        onChange={(value) => {
                          valueSetter(index,
                            "jFormDate",
                            value
                          );
                        }}
                      />
                    </div>
                    <div className="w-full">
                      <CustomInputBox
                        title={"J-Form Number"}
                        value={value.jFormNumber}
                        valueSetter={(value) => {
                          valueSetter(index,"jFormNumber", value);
                        }}
                      />
                    </div>
                    <div className="w-full">
                      <CustomInputBox
                        title={"Name on J-Form"}
                        value={value.jFormName}
                        valueSetter={(value) => {
                          valueSetter(index,"jFormName", value);
                        }}
                      />
                    </div>
                    <div className="w-full">
                      <CustomInputBox
                        title={"J-Form Quantity"}
                        value={value.jFormQuantity}
                        valueSetter={(value) => {
                          valueSetter(index,"jFormQuantity", value);
                        }}
                      />
                    </div> */}
                  </div>
                  {purchase == "Yes" || purchase === null ? (
                    <>
                      {value.iForm.map((element, varIndex) => {
                        return (
                          <div className="flex gap-x-3 w-2/3" key={varIndex}>
                            <div className="w-full">
                              <CustomInputBox
                                title={"I-Form Number"}
                                value={element.iFormNumber}
                                valueSetter={(value) => {
                                  iFormValueSetter(
                                    index,
                                    varIndex,
                                    "iFormNumber",
                                    value
                                  );
                                }}
                              />
                            </div>
                            <div className="w-full">
                              <CustomDateSelector
                                label={"I-Form Date"}
                                date={
                                  element.iFormDate === null
                                    ? null
                                    : new Date(element.iFormDate)
                                }
                                onChange={(value) => {
                                  iFormValueSetter(
                                    index,
                                    varIndex,
                                    "iFormDate",
                                    value
                                  );
                                }}
                              />
                            </div>
                            <div className="w-full">
                              <CustomInputBox
                                title={"I-Form Quantity"}
                                value={element.iFormQuantity}
                                valueSetter={(value) => {
                                  iFormValueSetter(
                                    index,
                                    varIndex,
                                    "iFormQuantity",
                                    value
                                  );
                                }}
                              />
                            </div>
                            {varIndex !== value.iForm.length - 1 ? (
                              <span
                                onClick={() => {
                                  removeiform(index);
                                }}
                                className="h-5 w-16 mt-8 mr-3 text-gray-500 hover:text-red-500 hover:p-1"
                              >
                                <TrashIcon />
                              </span>
                            ) : (
                              <span
                                className="h-5 w-16 mt-8 text-white rounded-md bg-green-500"
                                onClick={() => {
                                  addiform(index);
                                }}
                              >
                                <PlusIcon />
                              </span>
                            )}
                          </div>
                        );
                      })}
                      <br />
                      {value.po.map((element, varIndex) => {
                        return (
                          <div className="flex gap-x-3 w-2/3" key={varIndex}>
                            <div className="w-full">
                              <CustomInputBox
                                title={"Po Number"}
                                value={element.poNumber}
                                valueSetter={(value) => {
                                  poValueSetter(
                                    index,
                                    varIndex,
                                    "poNumber",
                                    value
                                  );
                                }}
                              />
                            </div>
                            <div className="w-full">
                              <CustomDateSelector
                                label={"Po Date"}
                                date={
                                  element.poDate === null
                                    ? null
                                    : new Date(element.poDate)
                                }
                                onChange={(value) => {
                                  poValueSetter(
                                    index,
                                    varIndex,
                                    "poDate",
                                    value
                                  );
                                }}
                              />
                            </div>
                            {varIndex !== value.po.length - 1 ? (
                              <span
                                onClick={() => {
                                  removePo(index);
                                }}
                                className="h-5 w-10 mt-8 mr-3 text-gray-500 hover:text-red-500 hover:p-1"
                              >
                                <TrashIcon />
                              </span>
                            ) : (
                              <span
                                className="h-5 w-10 mt-8 text-white rounded-md bg-green-500"
                                onClick={() => {
                                  addPo(index);
                                }}
                              >
                                <PlusIcon />
                              </span>
                            )}
                          </div>
                        );
                      })}
                      <br />
                      {value.bill.map((element, varIndex) => {
                        return (
                          <div className="flex gap-x-3 w-2/3" key={varIndex}>
                            <div className="w-full">
                              <CustomInputBox
                                title={"Bill Number"}
                                value={element.billNumber}
                                valueSetter={(value) => {
                                  billValueSetter(
                                    index,
                                    varIndex,
                                    "billNumber",
                                    value
                                  );
                                }}
                              />
                            </div>
                            <div className="w-full">
                              <CustomDateSelector
                                label={"Bill Date"}
                                date={
                                  element.billDate === null
                                    ? null
                                    : new Date(element.billDate)
                                }
                                onChange={(value) => {
                                  billValueSetter(
                                    index,
                                    varIndex,
                                    "billDate",
                                    value
                                  );
                                }}
                              />
                            </div>
                            <div className="w-full">
                              <CustomInputBox
                                title={"Bill Quantity"}
                                value={element.billQuantity}
                                valueSetter={(value) => {
                                  billValueSetter(
                                    index,
                                    varIndex,
                                    "billQuantity",
                                    value
                                  );
                                }}
                              />
                            </div>
                            {varIndex !== value.bill.length - 1 ? (
                              <span
                                onClick={() => {
                                  removeBill(index);
                                }}
                                className="h-5 w-16 mt-8 mr-3 text-gray-500 hover:text-red-500 hover:p-1"
                              >
                                <TrashIcon />
                              </span>
                            ) : (
                              <span
                                className="h-5 w-16 mt-8 text-white rounded-md bg-green-500"
                                onClick={() => {
                                  addBill(index);
                                }}
                              >
                                <PlusIcon />
                              </span>
                            )}
                          </div>
                        );
                      })}
                    </>
                  ) : (
                    <>
                      <div className="w-2/3">
                        <CustomInputBox
                          title={"Reason"}
                          value={value.reason !== null ? value.reason : ""}
                          valueSetter={(value) => {
                            valueSetter(index, "reason", value);
                          }}
                        />
                      </div>
                    </>
                  )}
                </div>
              );
            })
          : null}
      </div>
      <div className="flex mt-4 w-1/2 justify-between">
        <CustomButton
          btnName="Cancel"
          bgColor="bg-gray-400"
          onClick={() => {
            history.goBack();
          }}
        />
        {!isSubmit ? (
          <CustomButton btnName="Submit" onClick={addPurchaseDetails} />
        ) : (
          <CustomButton btnName="Submit" onClick={() => {}} />
        )}
      </div>
    </div>
  );
};
export default PurchaseDetailAdd;
