import {
  BanIcon,
  BeakerIcon,
  CalendarIcon,
  ChartBarIcon,
  ChartPieIcon,
  ChevronDownIcon,
  ChevronUpIcon,
  ClipboardCheckIcon,
  ClockIcon,
  CogIcon,
  CurrencyRupeeIcon,
  LightningBoltIcon,
  LocationMarkerIcon,
  ShieldCheckIcon,
  ShoppingCartIcon,
  SunIcon,
  TemplateIcon,
  TrendingUpIcon,
  UserCircleIcon,
  UserGroupIcon,
  ViewGridIcon,
  UsersIcon,
  BadgeCheckIcon,
} from "@heroicons/react/outline";

export const Data = [
  {
    header: "Transaction",
    menu: [
      {
        name: "Seasonal Planning",
        iconSelected: <CalendarIcon className="text-green" />,
        iconUnselected: <CalendarIcon className="text-black" />,
        path: `${process.env.REACT_APP_ENDPOINT}home/transactionData/seasonalPlanning`,
        iconOpened: <ChevronUpIcon className="text-gray-400" />,
        iconClosed: <ChevronDownIcon className="text-gray-400" />,
        subMenu: undefined,
      },
      {
        name: "Farmers Registration",
        iconSelected: <UserCircleIcon className="text-green-400" />,
        iconUnselected: <UserCircleIcon className="text-black" />,
        path: `${process.env.REACT_APP_ENDPOINT}home/transactionData/farmerMaster`,
        iconOpened: <ChevronUpIcon className="text-gray-400" />,
        iconClosed: <ChevronDownIcon className="text-gray-400" />,
        subMenu: undefined,
      },

      {
        name: "Crop Life Cycle",
        iconSelected: <ClockIcon className="text-green-400" />,
        iconUnselected: <ClockIcon className="text-black" />,
        path: `${process.env.REACT_APP_ENDPOINT}home/transactionData/cropLifeCycle`,
        iconOpened: <ChevronUpIcon className="text-gray-400" />,
        iconClosed: <ChevronDownIcon className="text-gray-400" />,
        subMenu: undefined,
      },

      {
        name: "Validation",
        iconSelected: <ShieldCheckIcon className="text-green-400" />,
        iconUnselected: <ShieldCheckIcon className="text-black" />,
        path: `${process.env.REACT_APP_ENDPOINT}home/transactionData/initialValidation`,
        iconOpened: <ChevronUpIcon className="text-gray-400" />,
        iconClosed: <ChevronDownIcon className="text-gray-400" />,
        subMenu: undefined,
      },
      {
        name: "All visits",
        iconSelected: <LocationMarkerIcon className="text-green-400" />,
        iconUnselected: <LocationMarkerIcon className="text-black" />,
        path: `${process.env.REACT_APP_ENDPOINT}home/transactionData/allVisits`,
        iconOpened: <ChevronUpIcon className="text-gray-400" />,
        iconClosed: <ChevronDownIcon className="text-gray-400" />,
        subMenu: undefined,
      },
      {
        name: "Estimation",
        iconSelected: <TrendingUpIcon className="text-green-400" />,
        iconUnselected: <TrendingUpIcon className="text-black" />,
        path: `${process.env.REACT_APP_ENDPOINT}home/transactionData/estimation`,
        iconOpened: <ChevronUpIcon className="text-gray-400" />,
        iconClosed: <ChevronDownIcon className="text-gray-400" />,
        subMenu: undefined,
      },
      {
        name: "Sampling Request",
        iconSelected: <BeakerIcon className="text-green-400" />,
        iconUnselected: <BeakerIcon className="text-black" />,
        path: `${process.env.REACT_APP_ENDPOINT}home/transactionData/sampling`,
        iconOpened: <ChevronUpIcon className="text-gray-400" />,
        iconClosed: <ChevronDownIcon className="text-gray-400" />,
        subMenu: undefined,
      },
      {
        name: "Sampling Confirmed",
        iconSelected: <CogIcon className="text-green-400" />,
        iconUnselected: <CogIcon className="text-black" />,
        path: `${process.env.REACT_APP_ENDPOINT}home/transactionData/samplingconfirm`,
        iconOpened: <ChevronUpIcon className="text-gray-400" />,
        iconClosed: <ChevronDownIcon className="text-gray-400" />,
        subMenu: undefined,
      },
      {
        name: "Sampling Result",
        iconSelected: <ChartPieIcon className="text-green-400" />,
        iconUnselected: <ChartPieIcon className="text-black" />,
        path: `${process.env.REACT_APP_ENDPOINT}home/transactionData/samplingresult`,
        iconOpened: <ChevronUpIcon className="text-gray-400" />,
        iconClosed: <ChevronDownIcon className="text-gray-400" />,
        subMenu: undefined,
      },
      {
        name: "Purchase",
        iconSelected: <CurrencyRupeeIcon className="text-green-400" />,
        iconUnselected: <CurrencyRupeeIcon className="text-black" />,
        path: `${process.env.REACT_APP_ENDPOINT}home/transactionData/purchase`,
        iconOpened: <ChevronUpIcon className="text-gray-400" />,
        iconClosed: <ChevronDownIcon className="text-gray-400" />,
        subMenu: undefined,
      },
      {
        name: "Resampling Approval",
        iconSelected: <BadgeCheckIcon className="text-green-400" />,
        iconUnselected: <BadgeCheckIcon className="text-black" />,
        path: `${process.env.REACT_APP_ENDPOINT}home/transactionData/resamplingapproval`,
        iconOpened: <ChevronUpIcon className="text-gray-400" />,
        iconClosed: <ChevronDownIcon className="text-gray-400" />,
        subMenu: undefined,
      },
      {
        name: "Bonus Calculation",
        iconSelected: <LightningBoltIcon className="text-green-400" />,
        iconUnselected: <LightningBoltIcon className="text-black" />,
        path: "",
        iconOpened: <ChevronUpIcon className="text-gray-400" />,
        iconClosed: <ChevronDownIcon className="text-gray-400" />,
        subMenu: [
          {
            name: "page-1",
            path: "",
          },
          {
            name: "page-2",
            path: "",
          },
          {
            name: "page-3",
            path: "",
          },
        ],
      },
    ],
  },
  {
    header: "Master Data",
    menu: [
      {
        name: "Users",
        iconSelected: <UsersIcon className="text-green-400" />,
        iconUnselected: <UsersIcon className="text-black" />,
        path: `${process.env.REACT_APP_ENDPOINT}home/masterData/users`,
        iconOpened: <ChevronUpIcon className="text-gray-400" />,
        iconClosed: <ChevronDownIcon className="text-gray-400" />,
        subMenu: undefined,
        show: true,
      },
      {
        name: "Roles",
        iconSelected: <UsersIcon className="text-green-400" />,
        iconUnselected: <UsersIcon className="text-black" />,
        path: `${process.env.REACT_APP_ENDPOINT}home/masterData/roles`,
        iconOpened: <ChevronUpIcon className="text-gray-400" />,
        iconClosed: <ChevronDownIcon className="text-gray-400" />,
        subMenu: undefined,
      },
      {
        name: "Variety Master",
        iconSelected: <ViewGridIcon className="text-green-400" />,
        iconUnselected: <ViewGridIcon className="text-black" />,
        path: `${process.env.REACT_APP_ENDPOINT}home/masterData/varietyMaster`,
        iconOpened: <ChevronUpIcon className="text-gray-400" />,
        iconClosed: <ChevronDownIcon className="text-gray-400" />,
        subMenu: undefined,
      },
      {
        name: "Chemical Master",
        iconSelected: <BeakerIcon className="text-green-400" />,
        iconUnselected: <BeakerIcon className="text-black" />,
        path: `${process.env.REACT_APP_ENDPOINT}home/masterData/chemicalMaster`,
        iconOpened: <ChevronUpIcon className="text-gray-400" />,
        iconClosed: <ChevronDownIcon className="text-gray-400" />,
        subMenu: undefined,
      },
      {
        name: "Process Master",
        iconSelected: <CogIcon className="text-green-400" />,
        iconUnselected: <CogIcon className="text-black" />,
        path: `${process.env.REACT_APP_ENDPOINT}home/masterData/processTypesMaster`,
        iconOpened: <ChevronUpIcon className="text-gray-400" />,
        iconClosed: <ChevronDownIcon className="text-gray-400" />,
        subMenu: undefined,
      },
      {
        name: "UoM Master",
        iconSelected: <ChartPieIcon className="text-green-400" />,
        iconUnselected: <ChartPieIcon className="text-black" />,
        path: `${process.env.REACT_APP_ENDPOINT}home/masterData/uomMaster`,
        iconOpened: <ChevronUpIcon className="text-gray-400" />,
        iconClosed: <ChevronDownIcon className="text-gray-400" />,
        subMenu: undefined,
      },
      {
        name: "Fertilizer Master",
        iconSelected: <SunIcon className="text-green-400" />,
        iconUnselected: <SunIcon className="text-black" />,
        path: `${process.env.REACT_APP_ENDPOINT}home/masterData/fertilizerMaster`,
        iconOpened: <ChevronUpIcon className="text-gray-400" />,
        iconClosed: <ChevronDownIcon className="text-gray-400" />,
        subMenu: undefined,
      },
      {
        name: "Insect & Pest Master",
        iconSelected: <BanIcon className="text-green-400" />,
        iconUnselected: <BanIcon className="text-black" />,
        path: `${process.env.REACT_APP_ENDPOINT}home/masterData/insectDisease`,
        iconOpened: <ChevronUpIcon className="text-gray-400" />,
        iconClosed: <ChevronDownIcon className="text-gray-400" />,
        subMenu: undefined,
      },
      {
        name: "Zone Master",
        iconSelected: <TemplateIcon className="text-green-400" />,
        iconUnselected: <TemplateIcon className="text-black" />,
        path: `${process.env.REACT_APP_ENDPOINT}home/masterData/zoneMaster`,
        iconOpened: <ChevronUpIcon className="text-gray-400" />,
        iconClosed: <ChevronDownIcon className="text-gray-400" />,
        subMenu: undefined,
      },
      {
        name: "Yield Master",
        iconSelected: <ClipboardCheckIcon className="text-green-400" />,
        iconUnselected: <ClipboardCheckIcon className="text-black" />,
        path: `${process.env.REACT_APP_ENDPOINT}home/masterData/yieldMaster`,
        iconOpened: <ChevronUpIcon className="text-gray-400" />,
        iconClosed: <ChevronDownIcon className="text-gray-400" />,
        subMenu: undefined,
      },
      {
        name: "Aarthi Master",
        iconSelected: <UserGroupIcon className="text-green-400" />,
        iconUnselected: <UserGroupIcon className="text-black" />,
        path: `${process.env.REACT_APP_ENDPOINT}home/masterData/aarthiMaster`,
        iconOpened: <ChevronUpIcon className="text-gray-400" />,
        iconClosed: <ChevronDownIcon className="text-gray-400" />,
        subMenu: undefined,
      },
      {
        name: "Mandi Master",
        iconSelected: <ShoppingCartIcon className="text-green-400" />,
        iconUnselected: <ShoppingCartIcon className="text-black" />,
        path: `${process.env.REACT_APP_ENDPOINT}home/masterData/mandiMaster`,
        iconOpened: <ChevronUpIcon className="text-gray-400" />,
        iconClosed: <ChevronDownIcon className="text-gray-400" />,
        subMenu: undefined,
      },
      {
        name: "Village Master",
        iconSelected: <ShoppingCartIcon className="text-green-400" />,
        iconUnselected: <ShoppingCartIcon className="text-black" />,
        path: `${process.env.REACT_APP_ENDPOINT}home/masterData/villageMaster`,
        iconOpened: <ChevronUpIcon className="text-gray-400" />,
        iconClosed: <ChevronDownIcon className="text-gray-400" />,
        subMenu: undefined,
      },
    ],
  },
];
