import React, { useEffect, useState } from "react";
import { useLocation, Redirect, useHistory } from "react-router-dom";
import { CustomButton } from "../../../components/atoms/CustomButton";
import CustomInputBox from "../../../components/atoms/CustomInputBox";
import axios from "../../../axios";
import { toast } from "react-toastify";
import { PencilIcon } from "@heroicons/react/outline";
import CustomDropDownSearchSelect from "../../../components/atoms/CustomDropDownSearchSelect";
import { errorToast } from "../../../util/toast";
import CustomDateSelector from "../../../components/atoms/CustomDateSelector";
import CustomToggleSwitch from "../../../components/atoms/CustomToggleSwitch";

const CropLifeCycleEdit = () => {
  const updatePropList = [
    "isPassed",
    "passedQuality",
    "failedReason",
    "passedAcre",
    "passedTag",
  ];

  const location = useLocation();
  const history = useHistory();
  const initial = JSON.parse(location.state);
  // console.log("initial" , initial)
  const id = JSON.parse(location.state).data.id;
  const [data, setData] = useState(null);

  const [farmerList, setFarmerList] = useState(null);
  const [varietyList, setVarietyList] = useState(null);
  const [error, setError] = useState(null);

  const [confirm, setConfirm] = useState(initial.data.isConfirmed);
  const [isRequest, setisRequest] = useState(
    initial.data.isRequestedResampling
  );
  const [isPassed, setisPassed] = useState(initial.data.isPassed);

  useEffect(() => {
    if (initial.data.isPassed) {
      setResult("Passed");
    } else if (initial.data.isRequestedResampling) {
      setResult("Failed");
    }
  }, [initial.data]);

  const [pop, setPop] = useState([]);
  const [result, setResult] = useState(null);

  const valueSetter = (propertyName, newValue) => {
    console.log("here");
    const newData = { ...data };
    newData[propertyName] = newValue;

    console.log(newData, "New Data");
    setData(newData);
  };

  useEffect(() => {
    // const newObj = {};
    // Object.keys(data).forEach((key) => {
    //   if (data[key]?.hasOwnProperty("show")) {
    //     newObj[key] = data[key].value;
    //   } else {
    //     newObj[key] = data[key];
    //   }
    // });
    // if (Object.keys(newObj).length > 0) setData(newObj);
    axios
      .get(`cropCuttingSamplingResult/${id}`)
      .then((response) => {
        const temp = response.data.Result.CropCuttingSampling;
        console.log("Sampling", response, temp);

        setData(temp);
        setConfirm(temp.isConfirmed);
        setisRequest(temp.isRequestedResampling);
        setisPassed(temp.isPassed);
        return axios.get(`farmerMaster/load`);
      })
      .then((response) => {
        setFarmerList(response.data.Result.FarmerMaster);
        return axios.get(`varietyMaster/load`);
      })
      .then((response) => {
        setVarietyList(response.data.Result.VarietyMaster);
      })
      .catch((error) => {
        console.log(error);
        errorToast(error?.response?.data?.Comments);
      });
  }, [location, id]);

  useEffect(() => {
    axios.get(`farmerMaster/load`).then((responsee) => {
      responsee.data.Result.FarmerMaster.map((item, index) => {
        if (item.name == data?.FarmerVariety?.FarmerMaster?.name) {
          axios.get(`pop`).then((response) => {
            response.data.Result.Pop.map((popItem, indexPop) => {
              console.log(popItem, "POP Item");
              console.log(item.popId, "POP ID AXIOS");
              if (popItem.id == item.popId) {
                setPop(
                  popItem.pop == "FRTC"
                    ? ["Red", "Yellow"]
                    : popItem.pop == "FRIP"
                    ? ["Pink", "Yellow"]
                    : []
                );
                console.log(
                  popItem.pop == "FRTC"
                    ? ["Red", "Yellow"]
                    : popItem.pop == "FRIP"
                    ? ["Pink", "Yellow"]
                    : [],
                  "Selected"
                );
              }
            });
          });
        }
      });
    });
  }, [data]);
  if (!location.state) {
    return <Redirect to="/" />;
  }

  const getItemName = (obj, prop1, prop2) => {
    return obj[prop2];
  };

  const createSendingData = async (obj) => {
    return new Promise((resolve, reject) => {
      let newData = {};
      Object.keys(obj).forEach((property, index) => {
        // console.log( "index" ,property , index)
        if (updatePropList.includes(property)) {
          newData[property] = obj[property];
        }
        if (index === Object.keys(obj).length - 1) resolve(newData);
      });
    });
  };
  const getDateString = (date) => {
    let year = date.getFullYear();
    let month = date.getMonth() + 1;
    month = month < 10 ? "0" + month : month;
    let day = date.getDate();
    day = day < 10 ? "0" + day : day;
    return day + "/" + month + "/" + year;
  };
  const editCropLifeCycle = () => {
    if (result != "Sample lost") {
      if (data?.actualLandUsed === NaN || data?.actualLandUsed == 0) {
        errorToast("please specify the actualLandUsed");
      } else if (data?.landUsed === NaN || data?.landUsed == 0) {
        errorToast("Please specify the land used");
      } else if (isPassed == isRequest) {
        errorToast("Either Pass the sample or Make a request!");
      } else {
        createSendingData(data)
          .then((newData) => {
            newData.isPassed = isPassed ? 1 : 0;
            newData.isRequest = isRequest ? 1 : 0;
            //   newData.isConfirmed = confirm?1:0;

            console.log(newData, " is the new data hhmmmm");
            return axios.put(`cropCuttingSamplingResult/${data.id}`, newData);
          })
          .then((response) => {
            if (response?.data?.ResponseMessage === "SUCCESS") {
              toast.success("Update successful!", { position: "top-center" });
              history.push("/home/transactionData/samplingresult");
            }
          })
          .catch((error) => {
            errorToast(error?.response?.data?.Comments);
            console.log(error);
          });
      }
    } else {
      axios
        .post(`resamplingApprove/${data?.samplingCombinationCode}`)
        .then((response) => {
          console.log("API FOR CF HEAD");
          toast.success("Update successful!", { position: "top-center" });
          history.push("/home/transactionData/samplingresult");
        });
    }
  };
  console.log(pop, "Console pop");
  return data !== null ? (
    <div className=" m-3 p-3">
      <div className="flex">
        <span>
          <PencilIcon className="h-5 w-5 transform text-black mt-4 mr-1" />
        </span>
        <span className=" py-4 text-gray-700 text-base font-bold text-left">
          Edit Sampling
        </span>
      </div>
      <div className="flex flex-wrap justify-left">
        <div className="w-2/3">
          <div className=" gap-x-3 space-y-2 mb-4">
            <div className="mb-4 flex gap-x-3">
              <CustomInputBox
                title={"Farmer"}
                disabled={true}
                borderDisable={true}
                value={getItemName(
                  data.FarmerVariety.FarmerMaster,
                  "identifier",
                  "name"
                )}
              />
              <CustomInputBox
                title={"Village"}
                disabled={true}
                borderDisable={true}
                value={getItemName(
                  data.FarmerVariety.FarmerMaster.VillageMaster,
                  "identifier",
                  "name"
                )}
              />
            </div>

            <div className="flex gap-x-3">
              <CustomInputBox
                title={"Variety"}
                disabled={true}
                borderDisable={true}
                value={getItemName(
                  data.FarmerVariety.VarietyMaster,
                  "identifier",
                  "name"
                )}
              />
              <CustomInputBox
                title="Sampling Number"
                disabled={true}
                borderDisable={true}
                value={data?.samplingNumber}
                className="w-1/2"
                valueSetter={(value) => {
                  valueSetter("samplingNumber", value);
                }}
              />
            </div>

            <div className="flex gap-x-3">
              <span className="w-1/2">
                <CustomInputBox
                  disabled={true}
                  borderDisable={true}
                  title={"Sampling Date"}
                  value={
                    data.samplingDate === null
                      ? new Date()
                      : getDateString(new Date(data.samplingDate))
                  }
                />
              </span>
              <span className="w-1/2">
                <CustomInputBox
                  disabled={true}
                  borderDisable={true}
                  title={"Sampling Request Date"}
                  value={
                    data.samplingRequestDate === null
                      ? new Date()
                      : getDateString(new Date(data.samplingRequestDate))
                  }
                />
              </span>
            </div>

            <div className="flex gap-x-3">
              <CustomInputBox
                title="Area in acre "
                value={data?.acre}
                disabled={true}
                borderDisable={true}
                className="w-1/2"
                valueSetter={(value) => {
                  valueSetter("acre", value);
                }}
              />
              <CustomInputBox
                title="Expected Harvesting date"
                disabled={true}
                borderDisable={true}
                value={getDateString(new Date(data?.expectedHarvestingDate))}
                className="w-1/2"
              />
            </div>

            <div className="flex gap-x-3">
              <div className="w-1/2">
                <CustomToggleSwitch
                  label2={"Is Confirmed"}
                  obj={"obj"}
                  disabled={true}
                  checked={confirm}
                  onChange={(val) =>
                    isRequest ? setConfirm(false) : setConfirm(true)
                  }
                />
              </div>
              <div className="w-1/2">
                <CustomInputBox
                  title="Sampling Combination Code"
                  disabled={true}
                  borderDisable={true}
                  value={data?.samplingCombinationCode}
                  valueSetter={(value) => {
                    valueSetter("samplingCombinationCode", value);
                  }}
                  className="w-1/2"
                />
              </div>
            </div>

            <div className="flex gap-x-3">
              <div className="w-1/2">
                <CustomDropDownSearchSelect
                  label={"Result"}
                  items={["Passed", "Failed", "Sample lost"]}
                  className="w-60"
                  selected={result}
                  handler={(value) => {
                    setResult(value);
                    if (value == "Passed") {
                      setisPassed(true);
                      setisRequest(false);
                    } else if (value == "Failed") {
                      setisRequest(true);
                      setisPassed(false);
                    } else if (value == "Sample lost") {
                      setisPassed(false);
                      setisRequest(false);
                    }
                  }}
                />
              </div>
            </div>

            <div className="flex gap-x-3">
              {isPassed == true && (
                <CustomInputBox
                  title="Passed Quality "
                  value={data?.passedQuality}
                  className="w-1/2"
                  valueSetter={(value) => {
                    valueSetter("passedQuality", value);
                  }}
                />
              )}
              {isPassed == true && (
                <CustomInputBox
                  title="Passed Acre"
                  value={data?.passedAcre}
                  className="w-1/2"
                  valueSetter={(value) => {
                    valueSetter("passedAcre", value);
                  }}
                />
              )}

              {isRequest == true && (
                <CustomInputBox
                  title="Failed Reason "
                  value={data?.failedReason}
                  className="w-1/2"
                  valueSetter={(value) => {
                    valueSetter("failedReason", value);
                  }}
                />
              )}
            </div>
            <div className="flex gap-x-3">
              {isPassed == true && (
                <CustomDropDownSearchSelect
                  label={"Pass/Pass UD-tag"}
                  items={pop}
                  className="w-60"
                  selected={data?.passedTag}
                  handler={(value) => {
                    valueSetter("passedTag", value);
                  }}
                />
              )}
            </div>

            <div className="flex gap-x-3">
              <span className="w-1/2">
                <CustomDateSelector
                  label={"Result Recording Date"}
                  disabled={true}
                  date={
                    data.resultRecordingDate === null
                      ? new Date()
                      : new Date(data.resultRecordingDate)
                  }
                  onChange={(value) => {
                    valueSetter(
                      "resultRecordingDate",
                      getDateString(new Date(value))
                    );
                  }}
                />
              </span>
            </div>
          </div>
          <div className="flex mt-8 justify-between">
            <CustomButton
              btnName="Cancel"
              className="p-2 bg-gray-500 hover:bg-gray-700 text-lg"
              onClick={() => {
                history.goBack();
              }}
            />
            <CustomButton
              btnName="Update"
              className="p-2 bg-green-500 hover:bg-green-700 text-lg"
              onClick={editCropLifeCycle}
            />
          </div>
        </div>
      </div>
      <div className="text-xl text-red-500">{error}</div>
      <div className="flex w-max gap-x-10"></div>
    </div>
  ) : null;
};
export default CropLifeCycleEdit;
