import { React, useState, useEffect } from "react";

/**
 *
 * @param {string} label Label for the date selector
 * @param {Date} date the initial value of the date selector
 * @param {function} onChange callback function which is called with the date value.
 */

const CustomDateSelector = ({ label, date, onChange, disabled }) => {
  const [currentDate, setCurrentDate] = useState(null);

  useEffect(() => {
    if (date !== null && typeof date === "object") {
      let year = date.getFullYear();
      let month = date.getMonth() + 1;
      let day = date.getDate();

      month = month > 9 ? month : "0" + month;
      day = day > 9 ? day : "0" + day;

      setCurrentDate(year + "-" + month + "-" + day);
    } else {
      setCurrentDate(date);
    }
  }, [date]);

  return (
    <div className="mb-2">
      <div className="text-sm mt-1">{label}</div>
      <div>
        <input
          type="date"
          className="border border-1 p-1 h-9 border-gray-300 w-full text-sm font-light text-gray-700"
          style={{ borderRadius: "4px", height: "38px" }}
          value={currentDate}
          disabled={disabled}
          onChange={(e) => {
            setCurrentDate(e.target.value);
            onChange(e.target.value);
          }}
        />
      </div>
    </div>
  );
};

export default CustomDateSelector;
