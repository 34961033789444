import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { CustomButton } from "../../../components/atoms/CustomButton";
import axios from "../../../axios";
import { DocumentAddIcon, ChartPieIcon } from "@heroicons/react/outline";
import { EyeIcon } from "@heroicons/react/solid";
import CustomTable from "../../../components/atoms/CustomTable";

const UomHome = () => {
  const history = useHistory();
  //const tablePageNumbersShown = 3;
  //table related.
  const [currentTableData, setTableData] = useState([]);
  //const rowsPerPage = 10
  //const [totalRecords, setTotalRecords] = useState(0);
  //const [pageNumber, setPageNumber] = useState(1);
  //const [totalPages, setTotalPages] = useState(0);
  //const [sortBy, setSortBy] = useState({ headerName: 'identifier', order: false });

  //const [showDeleteModal, setShowDeleteModal] = useState(false);
  //const [showUploadFile, setShowUploadFile] = useState(false);
  //const [deleteObject, setDeleteObject] = useState(null);

  const tableHeaders = [
    { displayName: "id", property: "id", sortable: true, show: false },
    { displayName: "UoM", property: "uom", sortable: false, show: true },
    {
      displayName: "identifier",
      property: "identifier",
      sortable: false,
      show: true,
    },
    {
      displayName: "actions",
      property: "actions",
      sortable: false,
      show: true,
    },
  ];

  // const [searchSeverity, setSearchSeverity] = useState(null);
  // const [searchType, setSearchType] = useState(null);
  // const [searchName, setSearchName] = useState(null);

  const addActions = (objects) => {
    //adds the actions buttons to the table rows.
    const newObjects = [];
    objects.map((obj) => {
      obj.identifier = { value: obj.identifier, show: true };
      obj.uom = { value: obj.uom, show: true };
      obj.actions = {
        value: (
          <div className="flex gap-x-3 p-1">
            {/* <Link to={{ pathname: `/edit/${obj.id}`, state: JSON.stringify({ data: obj }) }}>
                <span><PencilIcon className="h-5 w-5 text-gray-700 hover:text-black" /></span>
            </Link> */}
            <Link
              to={{
                pathname: `/display`,
                state: JSON.stringify({ data: obj }),
              }}
            >
              <span>
                <EyeIcon className="h-5 w-5 text-gray-700 hover:text-black" />
              </span>
            </Link>
            {/* <span><TrashIcon className="h-5 w-5 text-gray-700 hover:text-black" onClick={() => { setShowDeleteModal(true); setDeleteObject(obj) }} /></span> */}
          </div>
        ),
        show: true,
      };
      newObjects.push(obj);
    });
    return newObjects;
  };

  // const deleteUom = () => {
  //     axios.delete(`uom/${deleteObject.name}`)
  //         .then(response => {
  //             if (response?.data?.ResponseMessage === "SUCCESS") {
  //                 toast.success("Delete successful!", { position: "top-center" })
  //                 // remove this and do it better, we need to remove the row of the delete object thats it
  //                 window.location.reload(false);
  //                 setShowDeleteModal(false)
  //             }
  //         })
  //         .catch(error => {
  //             toast.error(error?.message);
  //             console.log(error);
  //         })
  //
  // }    ********* THERE IS NO DELETE FOR PROCESS TYPE. **********

  useEffect(() => {
    //loading the table data here for the first time.
    //load the first page of the table.
    // let params = {
    //     page: pageNumber,
    //     limit: 10,
    //     orderBy: sortBy.headerName,
    //     order: sortBy.order ? "ASC" : "DESC"
    // };
    axios
      .get(`uom`)
      .then((response) => {
        const returnedRows = response.data.Result.Uom;
        let actualRows = addActions(returnedRows);
        setTableData(actualRows);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  // useEffect(() => {
  //     //updating the table data based on the search terms.
  //     let params = {
  //         page: pageNumber,
  //         limit: 10,
  //         orderBy: sortBy.headerName,
  //         order: sortBy.order ? "ASC" : "DESC"
  //     };
  //      if(searchName !== null && searchName.trim() !== "") {
  //          params.uomName = searchName.trim();
  //      }
  //      if(searchSeverity !== null){
  //          params.severity = searchSeverity;
  //      }
  //      if(searchType !== null){
  //          params.type = searchType;
  //      }
  //     axios.get(`insectsDiseasesMaster`, { params })
  //     axios.get(`uom`)
  //         .then(response => {
  //             //setTotalRecords(response.data.Result.total);
  //             //setTotalRecords(response.data.Result.ZoneMaster.length);
  //             const returnedRows = response.data.Result.Uom
  //             //setTotalPages(Math.ceil(response.data.Result.total / rowsPerPage));
  //             let actualRows = addActions(returnedRows)
  //             setTableData(actualRows);
  //         })
  //         .catch(error => {
  //             console.log(error)
  //         })
  // }, [searchName, searchSeverity, searchType, pageNumber, sortBy, showUploadFile]);

  //const nextPage = () => {
  //    if (pageNumber !== totalPages) setPageNumber(pageNumber + 1);
  //}
  //const prevPage = () => {
  //    if (pageNumber !== 1) setPageNumber(pageNumber - 1);
  //}

  // const getPageButtons = (noOfButtons) => {
  //     let buttonList = []
  //     if (true) {
  //         let printed = 0
  //         for (let i = Math.floor((pageNumber - 1) / noOfButtons) * noOfButtons + 1; (printed < noOfButtons) && (i <= totalPages); i++) {
  //             buttonList.push(
  //                 <button className={`px-3 ${i === pageNumber ? " bg-green-500 text-white text-sm rounded-xl" : "text-gray-600 text-sm"}`} onClick={() => { setPageNumber(i) }}>
  //                     {i}
  //                 </button>
  //             )
  //             printed++;
  //         }
  //     }
  //     return buttonList
  // }

  // const applyFilter = ()=>{

  // }

  return (
    <div className="m-3 h-screen">
      {/* <MasterDataDelete show={showDeleteModal} object={deleteObject} onClose={(e) => { setShowDeleteModal(false) }} onYes={deleteUom} />
            <MasterDataAddCSV show={showUploadFile} onClose={(e) => { setShowUploadFile(false) }} /> */}
      <div className="flex justify-between overflow-wrap align-middle">
        <div name="IconAndPageTitle" className="bg-white flex ml-3">
          <span>
            <ChartPieIcon className="my-3 mx-1 h-6 w-6 mr-1" />
          </span>
          <span className="text-lg mt-3 text-center align-center">
            UoM Master data
          </span>
          {/* <span className="mt-5 text-xs ml-2">({totalRecords} records)</span> */}
        </div>
        <div name="Add buttons" className="flex flex-wrap gap-x-10">
          <CustomButton
            onClick={() => {
              history.push("/add");
            }}
            btnName="Add"
            icon={<DocumentAddIcon className="h-5 w-5 mr-1" />}
          />
          {/* <CustomButton onClick={() => { setShowUploadFile(true); }} btnName="CSV" icon={<CloudUploadIcon className="h-5 w-5 mr-1" />} /> */}
        </div>
      </div>
      {/* <div name="FilterSection" className="flex  gap-x-2 ml-3 bg-white w-1/2 mb-4">
                <CustomDropDownSearchSelect label="Severity" items={["High", "Medium", "Low"]} handler={setSearchSeverity} />
                <CustomDropDownSearchSelect label="Type" items={["Insect", "Disease"]} handler={setSearchType} bgColor="bg-white" />
                <div className='mt-6 flex gap-x-2'>
                    <CustomInputBox title="Search by name" labelClass="mt-2 hidden" valueSetter={setSearchName} />
                <CustomButton btnName="Filter" className="p-2 mt-12 bg-green-500 hover:bg-green-700 text-sm " onClick={applyFilter} />
                </div>
            </div> */}
      <div
        name="Table"
        className="bg-white ml-3 mt-4 overflow-auto"
        style={{ height: "420px" }}
      >
        {/* <CustomTable rows={currentTableData} headers={tableHeaders} sortByHandler={setSortBy} /> */}
        <CustomTable rows={currentTableData} headers={tableHeaders} />
      </div>
      {/* {//show the table navigation buttons only if the table has more than 0 records.
                currentTableData.length > 0 ? (
                    <div className="text-sm w-max mx-auto text-center flex mt-1">
                        <button onClick={prevPage} className="border-2 text-gray-600 rounded-xl border-black p-1 w-6 h-6 mx-3"><ChevronLeftIcon /></button>
                        <div className="mx-2 mt-1 w-28">
                            {
                                getPageButtons(tablePageNumbersShown)
                            }
                        </div>
                        <button onClick={nextPage} className="border-2  text-gray-600 rounded-xl border-black p-1 w-6 h-6 mx-3"><ChevronRightIcon /></button>
                    </div>
                ) : null
            } */}
    </div>
  );
};

export default UomHome;
