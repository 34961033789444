import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { CustomButton } from "../../../components/atoms/CustomButton";
import MasterDataAddCSV from "../../Master Data/common/MasterDataAddCSV";
import MasterDataDelete from "../../Master Data/common/MasterDataDelete";
import axios from "../../../axios";
import {
  EyeIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  RefreshIcon,
} from "@heroicons/react/solid";
import { UserCircleIcon } from "@heroicons/react/outline";
import { toast } from "react-toastify";
import CustomTable from "../../../components/atoms/CustomTable";
import CustomInputBox from "../../../components/atoms/CustomInputBox";
import CustomDropDownSearchSelect from "../../../components/atoms/CustomDropDownSearchSelect";
import { isObject } from "formik";
import { errorToast } from "../../../util/toast";
import { keys } from "@material-ui/core/styles/createBreakpoints";

const getItemName = (obj, prop1, prop2) => {
  return obj[prop2];
};

const searchObject = (items, target, property) => {
  return items.filter((element) => {
    return element[property] === target;
  })[0];
};

const FarmerMasterRenew = () => {
  const history = useHistory();
  const tablePageNumbersShown = 3;
  //table related.
  const [currentTableData, setTableData] = useState([]);
  const rowsPerPage = 10;
  const [totalRecords, setTotalRecords] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [sortBy, setSortBy] = useState({
    headerName: "id",
    order: false,
  });

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [zoneId, setZoneId] = useState(null);
  const [farmerId, setfarmerId] = useState(null);
  const [villageId, setvillageId] = useState(null);
  const [villageList, setVillageList] = useState(null);
  const [farmerList, setFarmerList] = useState(null);

  const [showUploadFile, setShowUploadFile] = useState(false);
  const [deleteObject, setDeleteObject] = useState(null);

  const tableHeaders = [
    { displayName: "id", property: "id", sortable: true, show: true },
    {
      displayName: "Own Land",
      property: "ownLand",
      sortable: true,
      show: true,
    },
    { displayName: "Cycle", property: "cycle", sortable: true, show: true },
    {
      displayName: "Farmer Name",
      property: "name",
      sortable: true,
      show: true,
    },
    {
      displayName: "Mandi Name",
      property: "mandiName",
      sortable: false,
      show: true,
    },
    {
      displayName: "actions",
      property: "actions",
      sortable: false,
      show: true,
    },
  ];

  const [mandiList, setMandiList] = useState([]);
  const [aarthiList, setAarthiList] = useState([]);
  const [zoneList, setZoneList] = useState([]);

  const [searchName, setSearchName] = useState(null);
  const [mandiId, setMandiId] = useState(null);
  useEffect(() => {
    axios
      .get(`zoneMasters/load`)
      .then((response) => {
        console.log("Ummeed", response.data.Result.ZoneMaster);
        setZoneList(response.data.Result.ZoneMaster);
        return axios.get(`mandiMaster/load`);
      })
      .then((response) => {
        setMandiList(response.data.Result.MandiMaster);
        return axios.get(`villageMasters/load`);
      })
      .then((response) => {
        console.log("Village", response.data.Result);
        setVillageList(response.data.Result.villageMaster);
        return axios.get("farmerMaster/load", {
          params: { year: new Date().getFullYear() - 1 },
        });
      })
      .then((response) => {
        setFarmerList(response.data.Result.FarmerMaster);
      })
      .catch((err) => {
        errorToast(err.message);
        console.log(err);
      });
  }, []);

  const addActions = (objects, jForm) => {
    //adds the actions buttons to the table rows.
    const newObjects = [];

    objects.map((obj) => {
      obj.name = { value: obj.name, show: true };
      obj.id = { value: obj.id, show: true };
      obj.jForm = jForm.filter((jf) => jf.farmerId === obj.id.value);
      obj.cycle = { value: obj.cycle, show: true };
      obj.ownLand = { value: obj.ownLand, show: true };
      obj.mandiName = {
        value: mandiList.filter((element) => {
          return element.id === obj.mandiId;
        })[0]?.name,
        show: true,
      };
      obj.actions = {
        value: (
          <div className="flex gap-x-3 p-1">
            <Link
              to={{
                pathname: `/renewEdit/${obj.id}`,
                state: JSON.stringify({ data: obj }),
              }}
            >
              <span>
                <RefreshIcon className="h-5 w-5 text-gray-700 hover:text-black" />
              </span>
            </Link>
            <Link
              to={{
                pathname: `/display`,
                state: JSON.stringify({ data: obj }),
              }}
            >
              <span>
                <EyeIcon className="h-5 w-5 text-gray-700 hover:text-black" />
              </span>
            </Link>
            {/* <span>
                        <TrashIcon className="h-5 w-5 text-gray-700 hover:text-black" onClick={() => { setShowDeleteModal(true); setDeleteObject(obj) }} />
                    </span> */}
          </div>
        ),
        show: true,
      };
      delete obj.jForm;
      newObjects.push(obj);
    });

    return newObjects;
  };

  const deleteFarmerRenew = () => {
    axios
      .delete(`farmerMaster/${deleteObject.id}`)
      .then((response) => {
        if (response?.data?.ResponseMessage === "SUCCESS") {
          toast.success("Delete successful!", { position: "top-center" });
          // remove this and do it better, we need to remove the row of the delete object thats it
          window.location.reload(false);
          setShowDeleteModal(false);
        }
      })
      .catch((error) => {
        toast.error(error?.message);
        console.log(error);
      });
  };

  useEffect(() => {
    async function farmer() {
      let params = {
        page: pageNumber,
        limit: 10,
        orderBy: sortBy.headerName,
        order: sortBy.order ? "ASC" : "DESC",
        year: new Date().getFullYear() - 1,
      };
      if (searchName !== null && searchName.trim() !== "") {
        params.name = searchName.trim();
      }
      if (zoneId !== null) {
        params.zoneId = zoneId;
      }
      if (mandiId !== null) {
        params.mandiId = mandiId;
      }

      if (farmerId !== null) {
        params.farmerId = farmerId;
      }

      if (villageId !== null) {
        params.villageId = villageId;
      }

      // params.year = new Date().getFullYear();

      console.log("params", params);
      if (1) {
        const response = await axios.get(`farmerMaster`, { params });
        setTotalRecords(response.data.Result.total);
        const returnedRows = response.data.Result.FarmerMaster;
        setTotalPages(Math.ceil(response.data.Result.total / rowsPerPage));

        const ids = [];
        returnedRows.map((object) => {
          ids.push(object.id);
        });
        const Jformres = await axios.post("getFarmerJform", { ids });

        let actualRows = addActions(returnedRows, Jformres.data.Result.Jform);
        setTableData(actualRows);
      }
    }
    farmer();
  }, [
    searchName,
    zoneId,
    mandiId,
    villageId,
    farmerId,
    mandiList,
    pageNumber,
    sortBy,
    showUploadFile,
  ]);

  useEffect(() => {
    setMandiId(null);
  }, [zoneId]);
  useEffect(() => {
    setvillageId(null);
  }, [mandiId]);

  useEffect(() => {
    setfarmerId(null);
  }, [villageId]);

  useEffect(() => {
    setPageNumber(1);
  }, [searchName, mandiId, villageId, farmerId, zoneId]);

  const nextPage = () => {
    if (pageNumber !== totalPages) setPageNumber(pageNumber + 1);
  };
  const prevPage = () => {
    if (pageNumber !== 1) setPageNumber(pageNumber - 1);
  };

  const getPageButtons = (noOfButtons) => {
    let buttonList = [];
    if (true) {
      let printed = 0;
      for (
        let i = Math.floor((pageNumber - 1) / noOfButtons) * noOfButtons + 1;
        printed < noOfButtons && i <= totalPages;
        i++
      ) {
        buttonList.push(
          <button
            className={`px-3 ${
              i === pageNumber
                ? " bg-green-500 text-white text-sm rounded-xl"
                : "text-gray-600 text-sm"
            }`}
            onClick={() => {
              setPageNumber(i);
            }}
          >
            {i}
          </button>
        );
        printed++;
      }
    }
    return buttonList;
  };

  return (
    <div className="m-3 h-screen">
      <MasterDataDelete
        show={showDeleteModal}
        object={deleteObject}
        onClose={(e) => {
          setShowDeleteModal(false);
        }}
        onYes={deleteFarmerRenew}
      />
      <MasterDataAddCSV
        sampleFileDownloadPath={"sampleFarmerRenewMaster.csv"}
        fileUploadPath={"farmerMaster/file"}
        show={showUploadFile}
        onClose={(e) => {
          setShowUploadFile(false);
        }}
      />

      <div className="flex justify-between overflow-wrap align-middle">
        <div name="IconAndPageTitle" className="bg-white flex ml-3">
          <span>
            <UserCircleIcon className="my-3 mx-1 h-6 w-6 mr-1" />
          </span>
          <span className="text-lg mt-3 text-center align-center">
            Renew Registered Farmers
          </span>
          <span className="mt-5 text-xs ml-2">({totalRecords} records)</span>
        </div>
        <div name="Add buttons" className="flex flex-wrap gap-x-10">
          {/* <CustomButton onClick={() => { history.push("/renew") }} btnName="Renew" icon={<RefreshIcon className="h-5 w-5 mr-1" />} />
                    <CustomButton onClick={() => { history.push("/freshRegistration") }} btnName="Add" icon={<DocumentAddIcon className="h-5 w-5 mr-1" />} /> */}
        </div>
      </div>
      <div
        name="FilterSection"
        className="flex  gap-x-2 ml-3 bg-white w-11/12 mb-4"
      >
        <div className="mt-3 flex gap-x-2 w-full">
          <div className="w-full">
            <CustomInputBox
              title="Search by name"
              labelClass="mt-2 hidden"
              valueSetter={setSearchName}
              value={searchName}
            />
          </div>
          <div className="w-full">
            <CustomDropDownSearchSelect
              label={"Zone"}
              labelDisable={true}
              placeholder={"Select Zone"}
              items={
                zoneList !== null
                  ? zoneList.map((element) => {
                      return getItemName(element, "identifier", "name");
                    })
                  : []
              }
              selected={
                zoneId !== null && zoneList !== null
                  ? getItemName(
                      searchObject(zoneList, zoneId, "id"),
                      "identifier",
                      "name"
                    )
                  : null
              }
              handler={(value) => {
                if (value !== null) {
                  setZoneId(
                    zoneList.filter((element) => {
                      return (
                        getItemName(element, "identifier", "name") === value
                      );
                    })[0].id
                  );
                } else {
                  setZoneId(null);
                }
              }}
            />
          </div>

          <div className="w-full">
            <CustomDropDownSearchSelect
              label={"Mandi"}
              labelDisable={true}
              placeholder={"Select Mandi"}
              items={
                mandiList !== null
                  ? mandiList
                      .filter((element) => {
                        return element.ZoneMaster.id === zoneId;
                      })
                      .map((element) => {
                        return getItemName(element, "identifier", "name");
                      })
                  : []
              }
              selected={
                mandiId !== null && mandiList !== null
                  ? getItemName(
                      searchObject(mandiList, mandiId, "id"),
                      "identifier",
                      "name"
                    )
                  : null
              }
              handler={(value) => {
                if (value !== null) {
                  setMandiId(
                    mandiList.filter((element) => {
                      return (
                        getItemName(element, "identifier", "name") === value
                      );
                    })[0].id
                  );
                } else {
                  setMandiId(null);
                }
              }}
            />
          </div>

          <div className="w-full">
            <CustomDropDownSearchSelect
              items={
                villageList !== null
                  ? villageList
                      .filter((element) => {
                        return element.MandiMaster.id === mandiId;
                      })
                      .map((element) => {
                        return getItemName(element, "identifier", "name");
                      })
                  : []
              }
              label={"Village"}
              labelDisable={true}
              placeholder={"Village"}
              selected={
                villageId !== null
                  ? getItemName(
                      searchObject(villageList, villageId, "id"),
                      "identifier",
                      "name"
                    )
                  : null
              }
              handler={(value) => {
                if (value !== null && villageList !== null) {
                  setvillageId(
                    villageList.filter((element) => {
                      return (
                        getItemName(element, "identifier", "name") === value
                      );
                    })[0].id
                  );
                } else {
                  setvillageId(null);
                }
              }}
            />
          </div>

          <div className="w-full">
            {console.log(farmerList)}
            <CustomDropDownSearchSelect
              items={
                farmerList !== null
                  ? farmerList
                      .filter((element) => {
                        return element.VillageMaster.id === villageId;
                      })
                      .map((element) => {
                        return getItemName(element, "identifier", "name");
                      })
                  : []
              }
              label={"Farmer"}
              labelDisable={true}
              placeholder={"Farmer"}
              selected={
                farmerId !== null
                  ? getItemName(
                      searchObject(farmerList, farmerId, "id"),
                      "identifier",
                      "name"
                    )
                  : null
              }
              handler={(value) => {
                if (value !== null && farmerList !== null) {
                  setfarmerId(
                    farmerList.filter((element) => {
                      return (
                        getItemName(element, "identifier", "name") === value
                      );
                    })[0].id
                  );
                } else {
                  setfarmerId(null);
                }
              }}
            />
          </div>
          {/* <CustomButton btnName="Filter" className="p-2 mt-12 bg-green-500 hover:bg-green-700 text-sm " onClick={applyFilter} /> */}
        </div>
      </div>
      <div
        name="Table"
        className="bg-white ml-3 mt-4 overflow-hidden"
        style={{ height: "420px" }}
      >
        <CustomTable
          rows={currentTableData}
          headers={tableHeaders}
          sortByHandler={setSortBy}
        />
      </div>
      {
        //show the table navigation buttons only if the table has more than 0 records.
        currentTableData.length > 0 ? (
          <div className="text-sm w-max mx-auto text-center flex mt-1">
            <button
              onClick={prevPage}
              className="border-2 text-gray-600 rounded-xl border-black p-1 w-6 h-6 mx-3"
            >
              <ChevronLeftIcon />
            </button>
            <div className="mx-2 mt-1 w-28">
              {getPageButtons(tablePageNumbersShown)}
            </div>
            <button
              onClick={nextPage}
              className="border-2  text-gray-600 rounded-xl border-black p-1 w-6 h-6 mx-3"
            >
              <ChevronRightIcon />
            </button>
          </div>
        ) : null
      }
    </div>
  );
};

export default FarmerMasterRenew;
