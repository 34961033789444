import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { CustomButton } from "../../../components/atoms/CustomButton";
import MasterDataAddCSV from "../common/MasterDataAddCSV";
import MasterDataDelete from "../common/MasterDataDelete";
import axios from "../../../axios";
import { DocumentAddIcon, UsersIcon } from "@heroicons/react/outline";
import {
  EyeIcon,
  PencilIcon,
  TrashIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
} from "@heroicons/react/solid";
import { toast } from "react-toastify";
import CustomTable from "../../../components/atoms/CustomTable";
import CustomInputBox from "../../../components/atoms/CustomInputBox";
import { errorToast } from "../../../util/toast";

const RolesHome = () => {
  const history = useHistory();
  const role = JSON.parse(localStorage.getItem('cfUserAuth'))
  const result = role.find(obj => {
    return obj.moduleName === "initialValidations"
  })
  
  const tablePageNumbersShown = 3;
  //table related.
  const [currentTableData, setTableData] = useState([]);
  const rowsPerPage = 10;
  const [totalRecords, setTotalRecords] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [sortBy, setSortBy] = useState({
    headerName: "identifier",
    order: false,
  });

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showUploadFile, setShowUploadFile] = useState(false);
  const [deleteObject, setDeleteObject] = useState(null);

  const tableHeaders = [
    { displayName: "id", property: "id", sortable: true, show: false },
    {
      displayName: "identifier",
      property: "identifier",
      sortable: false,
      show: true,
    },
    {
      displayName: "role",
      property: "role",
      sortable: false,
      show: true,
    },
    {
      displayName: "actions",
      property: "actions",
      sortable: false,
      show: true,
    },
  ];

  const [searchEmail, setSearchEmail] = useState(null);
  const [searchName, setSearchName] = useState(null);

  const addActions = (objects) => {
    //adds the actions buttons to the table rows. this function does other things too.
    const newObjects = [];
    objects.map((obj) => {
      obj.identifier = { value: obj.identifier, show: true , order:1};
      obj.role = { value: obj.roleName, show: true ,order:2 };
      obj.actions = {
        value: (
          <div className="flex gap-x-3 p-1">
            
            <Link
              to={{
                pathname: `/display`,
                state: JSON.stringify({ data: obj }),
              }}
            >
              <span>
                <EyeIcon className="h-5 w-5 text-gray-700 hover:text-black" />
              </span>
            </Link>
            {/* <span>
                        <TrashIcon className="h-5 w-5 text-gray-700 hover:text-black" onClick={() => { setShowDeleteModal(true); setDeleteObject(obj) }} />
                    </span> */}
          </div>
        ),
        show: true,
        order:3
      };
      newObjects.push(obj);
    });
    return newObjects;
  };

  const deleteUsers = () => {
    axios
      .delete(`Users/${deleteObject.identifier.value}`)
      .then((response) => {
        if (response?.data?.ResponseMessage === "SUCCESS") {
          toast.success("Delete successful!", { position: "top-center" });
          // remove this and do it better, we need to remove the row of the delete object thats it
          window.location.reload(false);
          setShowDeleteModal(false);
        }
      })
      .catch((error) => {
        errorToast(error?.response?.data?.Comments);
        console.log(error);
      });
  };

  useEffect(() => {
    //updating the table data based on the search terms.
    let params = {
      page: pageNumber,
      limit: 10,
      orderBy: sortBy.headerName,
      order: sortBy.order ? "ASC" : "DESC",
    };
    if (searchName !== null) {
      params.name = searchName;
    }
    if (searchEmail !== null) {
      params.email = searchEmail;
    }
    axios
      .get(`rolesall`, { params })
      .then((response) => {
        console.log(response.data.Result.permissions)
        setTotalRecords(response.data.Result.permissions.length);
        const returnedRows = response.data.Result.permissions;
        setTotalPages(Math.ceil(response.data.Result.length / rowsPerPage));
        let actualRows = addActions(returnedRows);
        setTableData(actualRows);
      })
      .catch((error) => {
        console.log(error);
        errorToast(error?.response?.data?.Comments);
      });
  }, [searchName, searchEmail, pageNumber, sortBy, showUploadFile]);

  useEffect(() => {
    setPageNumber(1);
  }, [searchName, searchEmail]);

  const nextPage = () => {
    if (pageNumber !== totalPages) setPageNumber(pageNumber + 1);
  };
  const prevPage = () => {
    if (pageNumber !== 1) setPageNumber(pageNumber - 1);
  };

  const getPageButtons = (noOfButtons) => {
    let buttonList = [];
    if (true) {
      let printed = 0;
      for (
        let i = Math.floor((pageNumber - 1) / noOfButtons) * noOfButtons + 1;
        printed < noOfButtons && i <= totalPages;
        i++
      ) {
        buttonList.push(
          <button
            className={`px-3 ${
              i === pageNumber
                ? " bg-green-500 text-white text-sm rounded-xl"
                : "text-gray-600 text-sm"
            }`}
            onClick={() => {
              setPageNumber(i);
            }}
          >
            {i}
          </button>
        );
        printed++;
      }
    }
    return buttonList;
  };

  const applyFilter = () => {};

  return (
    <div className="m-3 h-screen">
      <MasterDataDelete
        show={showDeleteModal}
        object={deleteObject}
        onClose={(e) => {
          setShowDeleteModal(false);
        }}
        onYes={deleteUsers}
      />
      <MasterDataAddCSV
        show={showUploadFile}
        onClose={(e) => {
          setShowUploadFile(false);
        }}
        sampleFileDownloadPath={"/sampleVariety.csv"}
        fileUploadPath={"Users/file"}
      />

      <div className="flex justify-between overflow-wrap align-middle">
        <div name="IconAndPageTitle" className="bg-white flex ml-3">
          <span>
            <UsersIcon className="my-3 mx-1 h-6 w-6 mr-1" />
          </span>
          <span className="text-lg mt-3 text-center align-center">Roles </span>
          <span className="mt-5 text-xs ml-2">({totalRecords} records)</span>
        </div>

      </div>
      
      <div
        name="Table"
        className="bg-white ml-3 mt-4 overflow-hidden"
        style={{ height: "420px" }}
      >
        <CustomTable
          rows={currentTableData}
          headers={tableHeaders}
          sortByHandler={setSortBy}
        />
      </div>
    </div>
  );
};

export default RolesHome;
