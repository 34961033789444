import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router";
import { CustomButton } from "../../../components/atoms/CustomButton";
import axios from "../../../axios";
import { DocumentAddIcon } from "@heroicons/react/outline";
import CustomInputBox from "../../../components/atoms/CustomInputBox";
import CustomDateSelector from "../../../components/atoms/CustomDateSelector";
import { successToast, errorToast } from "../../../util/toast";
import CustomDropDownSearchSelect from "../../../components/atoms/CustomDropDownSearchSelect";
import CustomToggleSwitch from "../../../components/atoms/CustomToggleSwitch";
import { validateAllVisitsAdd } from "../../Master Data/common/validations";
import { TrashIcon, PlusIcon } from "@heroicons/react/outline";

const AllVisitAdd = ({ match }) => {
  const history = useHistory();
  const location = useLocation();
  const [farmerVariety, setFarmerVariety] = useState(
    JSON.parse(location.state).data
  );
  const [cropHealthList, setCropHealthList] = useState(null);
  const [cropStageList, setCropStageList] = useState(null);
  const [fertilizerList, setFertilizerList] = useState(null);
  const [insectDiseaseList, setInsectDiseaseList] = useState(null);
  const [chemicalList, setChemicalList] = useState(null);
  const [numberIrrigation, setNumberIrrigation] = useState([]);
  const [dates, setDates] = useState([]);
  const [stages, setStages] = useState([]);
  const [stagesName, setStagesName] = useState([]);
  const [isSubmit, setIsSubmit] = useState(false);
  const [actualChemicalList, setActualChemicalList] = useState([]);
  const [actualInsectList, setActualInsectList] = useState([]);

  useEffect(() => {
    console.log(actualChemicalList, "Actual Chemical List");
    console.log(actualInsectList, "Actual Insect List");
    let list = chemicalList?.filter((chem) => {
      if (chem) {
        let exists = false;
        if (actualInsectList?.includes(parseInt(chem?.insectDiseaseList))) {
          exists = true;
        }
        if (exists) {
          return chem;
        }
      }
    });
    console.log(list, "LIST");
    setActualChemicalList(list);
  }, [actualInsectList]);
  useEffect(() => {
    axios
      .get(`cropStage`)
      .then((response) => {
        setCropStageList(response.data.Result.CropStage);
        return axios.get(`cropHealth`);
      })
      .then((response) => {
        setCropHealthList(response.data.Result.CropHealth);
        return axios.get(`fertilizerMaster/load`);
      })
      .then((response) => {
        setFertilizerList(response.data.Result.FertilizerMaster);
        return axios.get(`insectsDiseasesMaster/load`);
      })
      .then((response) => {
        setInsectDiseaseList(response.data.Result.insectDiseaseMaster);
        return axios.get(`chemicalMasters/load`);
      })
      .then((response) => {
        setChemicalList(response.data.Result.ChemicalMaster);
      })
      .catch((error) => {
        console.log(error);
        errorToast(error?.response?.data?.Comments);
      });
  }, []);

  const makeDataObject = () => {
    return {
      farmerVarietyId: farmerVariety.id,
      visitDate: new Date(),
      cropStageId: null,
      cropHealthId: null,
      fertilizerId: null,
      fertilizerIdArray: [],
      recommendedChemical: [],
      insectDiseasesList: [],
      otherRecommendation: null,
      usedFertilizerId: null,
      usedFertilizerIdArray: [],
      usedFertilizerDate: null,
      usedChemicalId: null,
      usedChemicalIdArray: [],
      usedChemicalDate: null,
      waterUsage: null,
      irrigationDates: null,
      irrigationStages: null,
      unresolvedFarmerQuery: null,
      actionRequired: 1,
      generalRemarks: null,
      contactPerson: null,
      contactPersonMobile: null,
      usedOtherIngredient: null,
      otherIngredientUsedDate: null,
      recommended: [{ ingredient: null, brand: null, usesType: null }],
      used: [{ ingredient: null, brand: null, usesType: null, date: null }],
    };
  };

  const [newAllVisit, setnewAllVisits] = useState([makeDataObject()]);

  const iFormValueSetter = (purchaseIndex, iFormIndex, property, value) => {
    if (value !== null) {
      let purchase = [...newAllVisit];
      purchase[purchaseIndex].recommended[iFormIndex][property] = value;
      setnewAllVisits(purchase);
    }
  };

  const addRecommended = (index) => {
    console.log("Recommend Being added", index);
    let purchase = [...newAllVisit];
    purchase[index].recommended = [
      ...purchase[index].recommended,
      { ingredient: null, brand: null, usesType: null },
    ];
    setnewAllVisits(purchase);
  };

  const removeRecommended = (index) => {
    let purchase = [...newAllVisit];
    purchase[index].recommended = purchase[index].recommended.filter(
      (element, i) => {
        return i !== index;
      }
    );
    setnewAllVisits(purchase);
  };

  const iFormValueSetter2 = (purchaseIndex, iFormIndex, property, value) => {
    if (value !== null) {
      let purchase = [...newAllVisit];
      purchase[purchaseIndex].used[iFormIndex][property] = value;
      setnewAllVisits(purchase);
    }
  };

  const addUsed = (index) => {
    console.log("used Being added", index);
    let purchase = [...newAllVisit];
    purchase[index].used = [
      ...purchase[index].used,
      { ingredient: null, brand: null, usesType: null, date: null },
    ];
    setnewAllVisits(purchase);
  };

  const removeUsed = (index) => {
    let purchase = [...newAllVisit];
    purchase[index].used = purchase[index].used.filter((element, i) => {
      return i !== index;
    });
    setnewAllVisits(purchase);
  };

  console.log(newAllVisit[0].used, "New All Visit");

  const valueSetter = (index, property, newValue) => {
    let allVisit = [...newAllVisit];
    allVisit[index][property] = newValue;
    setnewAllVisits(allVisit);
  };

  const checkAll = () => {
    let flag = true;
    for (let i = 0; i < newAllVisit.length; i++) {
      const temp = newAllVisit[i];
      if (temp.isAcreageVerified === 0) {
        if (
          temp.actualLandUsed.trim() === "" ||
          Number(temp.actualLandUsed === NaN)
        ) {
          flag = false;
          errorToast("Please enter actual land used!");
        }
      }
    }
    return flag;
  };
  const getItemName = (obj, prop1, prop2) => {
    return obj[prop2];
  };

  const searchObject = (items, target, property) => {
    return items.filter((element) => {
      return element[property] === target;
    })[0];
  };

  const addAllVisits = () => {
    console.log(newAllVisit[0]);
    newAllVisit[0].fertilizerIdArray = JSON.stringify(
      newAllVisit[0].fertilizerIdArray
    );
    newAllVisit[0].usedFertilizerIdArray = JSON.stringify(
      newAllVisit[0].usedFertilizerIdArray
    );
    newAllVisit[0].usedChemicalIdArray = JSON.stringify(
      newAllVisit[0].usedChemicalIdArray
    );
    if (validateAllVisitsAdd(newAllVisit)) {
      setIsSubmit(true);
      successToast("Your request is being processed");
      axios
        .post("allVisit/", newAllVisit[0])
        .then((response) => {
          if (response?.data?.ResponseMessage === "SUCCESS") {
            successToast("New All Visit added!");
            history.push("/home/transactionData/allVisits");
          }
        })
        .catch((error) => {
          errorToast(error?.response?.data?.Comments);
          console.log(error);
        });
    } else {
      setIsSubmit(false);
    }
  };
  console.log(dates, "Dates");
  console.log(stages, "Stages");

  console.log(
    dates.filter((iter) => {
      if (iter.index == 0) {
        return iter.date;
      }
    })[0]?.date,
    "Selected"
  );
  console.log(newAllVisit[0]?.insectDiseaseList, "INsect HEREEEEEEEE");
  return (
    <div className="p-3 m-3">
      <div className="flex mb-7">
        <DocumentAddIcon className="h-5 w-5text-black mt-3 mr-1" />
        <div className="text-base pt-3 font-bold text-gray-800">
          Add All Visit
        </div>
      </div>
      <div>
        {newAllVisit !== null
          ? newAllVisit.map((value, index) => {
              return (
                <div className="mb-10 flex-wrap w-2/3">
                  <div className="w-full grid grid-cols-2  gap-x-3">
                    <CustomInputBox
                      title={"Farmer Name"}
                      borderDisable={true}
                      disabled={true}
                      value={getItemName(
                        farmerVariety.FarmerMaster,
                        "identifier",
                        "name"
                      )}
                    />
                    <CustomInputBox
                      title={"Variety"}
                      borderDisable={true}
                      disabled={true}
                      value={getItemName(
                        farmerVariety.VarietyMaster,
                        "identifier",
                        "name"
                      )}
                    />
                    <CustomDateSelector
                      label={"Visit Date"}
                      disabled={true}
                      date={new Date()}
                      onChange={(value) => {
                        valueSetter(index, "visitDate", value);
                      }}
                    />
                    {/* //Crop Stage Id */}
                    <CustomInputBox
                      title={"Contact Person"}
                      value={value.contactPerson}
                      valueSetter={(value) => {
                        valueSetter(index, "contactPerson", value);
                      }}
                    />
                    <CustomInputBox
                      title={"Contact Person Mobile"}
                      value={value.contactPersonMobile}
                      valueSetter={(value) => {
                        valueSetter(index, "contactPersonMobile", value);
                      }}
                    />

                    <CustomDropDownSearchSelect
                      label={"Crop Stage"}
                      items={
                        cropStageList !== null
                          ? cropStageList.map((element) => {
                              return getItemName(element, "identifier", "name");
                            })
                          : []
                      }
                      selected={
                        cropStageList !== null && value.cropStageId !== null
                          ? getItemName(
                              searchObject(
                                cropStageList,
                                value.cropStageId,
                                "id"
                              ),
                              "identifier",
                              "name"
                            )
                          : null
                      }
                      handler={(value) => {
                        if (value !== null) {
                          valueSetter(
                            index,
                            "cropStageId",
                            cropStageList.filter((element) => {
                              return (
                                getItemName(element, "identifier", "name") ===
                                value
                              );
                            })[0].id
                          );
                        } else {
                          valueSetter(index, "cropStageId", null);
                        }
                      }}
                    />
                    <CustomDropDownSearchSelect
                      label={"Crop Health"}
                      items={
                        cropHealthList !== null
                          ? cropHealthList.map((element) => {
                              return getItemName(element, "identifier", "name");
                            })
                          : []
                      }
                      selected={
                        cropHealthList !== null && value.cropHealthId !== null
                          ? getItemName(
                              searchObject(
                                cropHealthList,
                                value.cropHealthId,
                                "id"
                              ),
                              "identifier",
                              "name"
                            )
                          : null
                      }
                      handler={(value) => {
                        if (value !== null) {
                          valueSetter(
                            index,
                            "cropHealthId",
                            cropHealthList.filter((element) => {
                              return (
                                getItemName(element, "identifier", "name") ===
                                value
                              );
                            })[0].id
                          );
                        } else {
                          valueSetter(index, "cropHealthId", null);
                        }
                      }}
                    />

                    <div className="mb-5">
                      <CustomDropDownSearchSelect
                        label={"Insect/Disease"}
                        multi={true}
                        items={
                          insectDiseaseList !== null
                            ? insectDiseaseList.map((element) => {
                                return getItemName(
                                  element,
                                  "identifier",
                                  "insectDiseaseName"
                                );
                              })
                            : []
                        }
                        selected={
                          insectDiseaseList !== null &&
                          value.insectDiseasesList !== null
                            ? insectDiseaseList
                                .filter((item) => {
                                  return (
                                    value.insectDiseasesList.indexOf(
                                      item.id
                                    ) !== -1
                                  );
                                })
                                .map((item) =>
                                  getItemName(
                                    item,
                                    "identifier",
                                    "insectDiseaseName"
                                  )
                                )
                            : []
                        }
                        handler={(value) => {
                          console.log("Value", value);
                          if (value !== null) {
                            valueSetter(
                              index,
                              "insectDiseasesList",
                              insectDiseaseList
                                .filter((element) => {
                                  console.log(
                                    getItemName(
                                      element,
                                      "identifier",
                                      "insectDiseaseName"
                                    )
                                  );
                                  return (
                                    value.indexOf(
                                      getItemName(
                                        element,
                                        "identifier",
                                        "insectDiseaseName"
                                      )
                                    ) !== -1
                                  );
                                })
                                .map((item) => {
                                  return item.id;
                                })
                            );
                            setActualInsectList(
                              insectDiseaseList
                                .filter((element) => {
                                  console.log(
                                    getItemName(
                                      element,
                                      "identifier",
                                      "insectDiseaseName"
                                    )
                                  );
                                  return (
                                    value.indexOf(
                                      getItemName(
                                        element,
                                        "identifier",
                                        "insectDiseaseName"
                                      )
                                    ) !== -1
                                  );
                                })
                                .map((item) => {
                                  return item.id;
                                })
                            );
                          } else {
                            valueSetter(index, "insectDiseasesList", null);
                          }
                        }}
                      />
                    </div>
                    <div className="mb-5">
                      {value.recommended.map((element, varIndex) => {
                        console.log("Element ", varIndex, " : ", element);
                        return (
                          <div
                            className="flex flex-row justify-between"
                            style={{ width: "200%" }}
                            key={varIndex}
                          >
                            <CustomDropDownSearchSelect
                              label={"Recommended Ingredient"}
                              items={
                                actualChemicalList !== null &&
                                actualChemicalList?.length > 0
                                  ? actualChemicalList
                                      .filter(
                                        (element) => element?.isRecommended
                                      )
                                      .map((element) => {
                                        return getItemName(
                                          element,
                                          "identifier",
                                          "activeIngredient"
                                        );
                                      })
                                  : []
                              }
                              handler={(newValues) => {
                                console.log(newValues, "NEW AVLUEs");

                                iFormValueSetter(
                                  index,
                                  varIndex,
                                  "ingredient",
                                  newValues
                                );
                              }}
                              selected={element.ingredient}
                            />
                            <CustomDropDownSearchSelect
                              label={"Recommended Brand"}
                              items={
                                actualChemicalList !== null &&
                                actualChemicalList?.length > 0
                                  ? actualChemicalList
                                      .filter(
                                        (element) => (element?.isRecommended 
                                          && element?.activeIngredient === newAllVisit[index].recommended[varIndex]['ingredient'])
                                      )
                                      .map((element) => {
                                        return getItemName(
                                          element,
                                          "identifier",
                                          "brandName"
                                        );
                                      })
                                  : []
                              }
                              handler={(newValues) => {
                                console.log(newValues, "NEW AVLUEs");

                                iFormValueSetter(
                                  index,
                                  varIndex,
                                  "brand",
                                  newValues
                                );
                              }}
                              selected={element.brand}
                            />
                            <CustomDropDownSearchSelect
                              label={"Recommended UsesType"}
                              items={
                                actualChemicalList !== null &&
                                actualChemicalList?.length > 0
                                  ? actualChemicalList
                                      .filter(
                                        (element) => (element?.isRecommended 
                                          && element?.activeIngredient === newAllVisit[index].recommended[varIndex]['ingredient'] 
                                          && element?.brandName === newAllVisit[index].recommended[varIndex]['brand'])
                                      )
                                      .map((element) => {
                                        return getItemName(
                                          element,
                                          "identifier",
                                          "UsesType"
                                        ).usesType;
                                      })
                                  : []
                              }
                              handler={(newValues) => {
                                console.log(newValues, "NEW AVLUEs");

                                iFormValueSetter(
                                  index,
                                  varIndex,
                                  "usesType",
                                  newValues
                                );
                              }}
                              selected={element.usesType}
                            />
                            {varIndex !== value.recommended.length - 1 ? (
                              <span
                                onClick={() => {
                                  removeRecommended(index);
                                }}
                                className="h-6 w-6 mt-8 mr-3 text-gray-500 hover:text-red-500 hover:p-1 z-10"
                              >
                                <TrashIcon />
                              </span>
                            ) : (
                              <span
                                className="h-6 w-6 mt-8 text-white rounded-md bg-green-500 z-10"
                                onClick={() => {
                                  console.log("click");
                                  addRecommended(index);
                                }}
                              >
                                <PlusIcon />
                              </span>
                            )}
                          </div>
                        );
                      })}
                    </div>
                    <br />
                    <div className="">
                      <CustomDropDownSearchSelect
                        label={"Recommended Fertilizer"}
                        items={
                          fertilizerList !== null
                            ? fertilizerList.map((element) => {
                                return getItemName(
                                  element,
                                  "identifier",
                                  "name"
                                );
                              })
                            : []
                        }
                        multi={true}
                        handler={(newValues) => {
                          valueSetter(
                            index,
                            "fertilizerIdArray",
                            fertilizerList
                              .filter((item) => {
                                return (
                                  newValues.indexOf(
                                    getItemName(item, "identifier", "name")
                                  ) !== -1
                                );
                              })
                              .map((item) => {
                                return item.id;
                              })
                          );
                        }}
                        selected={
                          value.fertilizerIdArray !== null &&
                          fertilizerList !== null
                            ? fertilizerList
                                .filter((item) => {
                                  return (
                                    value.fertilizerIdArray.indexOf(item.id) !==
                                    -1
                                  );
                                })
                                .map((item) =>
                                  getItemName(item, "identifier", "name")
                                )
                            : []
                        }
                      />
                    </div>
                    <CustomInputBox
                      title={"Other Recommendation"}
                      value={value.otherRecommendation}
                      valueSetter={(value) => {
                        valueSetter(index, "otherRecommendation", value);
                      }}
                    />
                    <div className="">
                      <CustomDropDownSearchSelect
                        label={"Used Fertilizer"}
                        items={
                          fertilizerList !== null
                            ? fertilizerList.map((element) => {
                                return getItemName(
                                  element,
                                  "identifier",
                                  "name"
                                );
                              })
                            : []
                        }
                        multi={true}
                        handler={(newValues) => {
                          valueSetter(
                            index,
                            "usedFertilizerIdArray",
                            fertilizerList
                              .filter((item) => {
                                return (
                                  newValues.indexOf(
                                    getItemName(item, "identifier", "name")
                                  ) !== -1
                                );
                              })
                              .map((item) => {
                                return item.id;
                              })
                          );
                        }}
                        selected={
                          value.usedFertilizerIdArray !== null &&
                          fertilizerList !== null
                            ? fertilizerList
                                .filter((item) => {
                                  return (
                                    value.usedFertilizerIdArray.indexOf(
                                      item.id
                                    ) !== -1
                                  );
                                })
                                .map((item) =>
                                  getItemName(item, "identifier", "name")
                                )
                            : []
                        }
                      />
                    </div>
                    <CustomDateSelector
                      label={"Used Fertilizer Date"}
                      date={
                        value.usedFertilizerDate !== null
                          ? new Date(value.usedFertilizerDate)
                          : ""
                      }
                      onChange={(value) => {
                        valueSetter(index, "usedFertilizerDate", value);
                      }}
                    />
                    <div className="mb-5">
                      {value.used.map((element, varIndex) => {
                        console.log("Element ", varIndex, " : ", element);
                        return (
                          <div
                            className="flex flex-row justify-between"
                            style={{ width: "240%" }}
                            key={varIndex}
                          >
                            <CustomDropDownSearchSelect
                              label={"Used Chemical Ingredient"}
                              items={
                                actualChemicalList !== null &&
                                actualChemicalList?.length > 0
                                  ? actualChemicalList.map((element) => {
                                      return getItemName(
                                        element,
                                        "identifier",
                                        "activeIngredient"
                                      );
                                    })
                                  : []
                              }
                              handler={(newValues) => {
                                console.log(newValues, "NEW AVLUEs");

                                iFormValueSetter2(
                                  index,
                                  varIndex,
                                  "ingredient",
                                  newValues
                                );
                              }}
                              selected={element.ingredient}
                            />
                            <CustomDropDownSearchSelect
                              label={"Used Chemical Brand"}
                              items={
                                actualChemicalList !== null &&
                                actualChemicalList?.length > 0
                                  ? actualChemicalList.filter(
                                        (element) => (element?.activeIngredient === newAllVisit[index].used[varIndex]['ingredient'])
                                      )
                                      .map((element) => {
                                      return getItemName(
                                        element,
                                        "identifier",
                                        "brandName"
                                      );
                                    })
                                  : []
                              }
                              handler={(newValues) => {
                                console.log(newValues, "NEW AVLUEs");

                                iFormValueSetter2(
                                  index,
                                  varIndex,
                                  "brand",
                                  newValues
                                );
                              }}
                              selected={element.brand}
                            />
                            <CustomDropDownSearchSelect
                              label={"Used Chemical UsesType"}
                              items={
                                actualChemicalList !== null &&
                                actualChemicalList?.length > 0
                                  ? actualChemicalList.filter(
                                        (element) => (element?.activeIngredient === newAllVisit[index].used[varIndex]['ingredient'] 
                                          && element?.brandName === newAllVisit[index].used[varIndex]['brand'])
                                      )
                                      .map((element) => {
                                      return getItemName(
                                        element,
                                        "identifier",
                                        "UsesType"
                                      ).usesType;
                                    })
                                  : []
                              }
                              handler={(newValues) => {
                                console.log(newValues, "NEW AVLUEs");

                                iFormValueSetter2(
                                  index,
                                  varIndex,
                                  "usesType",
                                  newValues
                                );
                              }}
                              selected={element.usesType}
                            />
                            <CustomDateSelector
                              label={"Used Chemical Date"}
                              date={
                                element.date !== null
                                  ? new Date(element.date)
                                  : ""
                              }
                              onChange={(value) => {
                                iFormValueSetter2(
                                  index,
                                  varIndex,
                                  "date",
                                  value
                                );
                              }}
                            />
                            {varIndex !== value.used.length - 1 ? (
                              <span
                                onClick={() => {
                                  removeUsed(index);
                                }}
                                className="h-6 w-6 mt-8 mr-3 text-gray-500 hover:text-red-500 hover:p-1 z-10"
                              >
                                <TrashIcon />
                              </span>
                            ) : (
                              <span
                                className="h-6 w-6 mt-8 text-white rounded-md bg-green-500 z-10"
                                onClick={() => {
                                  console.log("click");
                                  addUsed(index);
                                }}
                              >
                                <PlusIcon />
                              </span>
                            )}
                          </div>
                        );
                      })}
                    </div>
                    <br />
                    <CustomInputBox
                      title={"Used Other Ingredient"}
                      value={value.usedOtherIngredient}
                      valueSetter={(value) => {
                        valueSetter(index, "usedOtherIngredient", value);
                      }}
                    />
                    <CustomDateSelector
                      label={"Other Ingredient Used Date"}
                      date={
                        value.otherIngredientUsedDate !== null
                          ? new Date(value.otherIngredientUsedDate)
                          : ""
                      }
                      onChange={(value) => {
                        valueSetter(index, "otherIngredientUsedDate", value);
                      }}
                    />
                    <CustomInputBox
                      title={"Unresolved Farmer Query"}
                      value={value.unresolvedFarmerQuery}
                      valueSetter={(value) => {
                        valueSetter(index, "unresolvedFarmerQuery", value);
                      }}
                    />
                    <CustomInputBox
                      title={"General Remarks"}
                      value={value.generalRemarks}
                      valueSetter={(value) => {
                        valueSetter(index, "generalRemarks", value);
                      }}
                    />
                    <CustomToggleSwitch
                      label2={"Action Required?"}
                      checked={value.actionRequired === 1}
                      onChange={(value) => {
                        valueSetter(
                          index,
                          "actionRequired",
                          value.value ? 1 : 0
                        );
                      }}
                    />

                    <CustomInputBox
                      title={"Number of irrigations from last visit"}
                      value={value.waterUsage}
                      valueSetter={(value) => {
                        valueSetter(index, "waterUsage", value);
                        let arr = [];
                        for (let i = 0; i < value; i++) {
                          arr.push(i);
                        }
                        setNumberIrrigation(arr);
                      }}
                    />
                    <br />
                    {numberIrrigation.length > 0 &&
                      numberIrrigation.map((item, indexx) => {
                        return (
                          <>
                            <div className="flex flex-row">
                              <CustomDropDownSearchSelect
                                key={indexx + 12345}
                                label={"Crop Stage"}
                                items={
                                  cropStageList !== null
                                    ? cropStageList.map((element) => {
                                        return getItemName(
                                          element,
                                          "identifier",
                                          "name"
                                        );
                                      })
                                    : []
                                }
                                selected={
                                  stages.filter((iter) => {
                                    if (iter.index == indexx) {
                                      return iter.value;
                                    }
                                  })[0]?.value
                                }
                                handler={(value) => {
                                  if (value !== null) {
                                    let id = cropStageList.filter((element) => {
                                      return (
                                        getItemName(
                                          element,
                                          "identifier",
                                          "name"
                                        ) === value
                                      );
                                    })[0].id;

                                    let obj = {
                                      index: indexx,
                                      id: id,
                                      value: value,
                                    };
                                    let array = stages;
                                    if (!array.length > 0) {
                                      array.push(obj);
                                    } else {
                                      array.map((stage, count) => {
                                        if (stage.index == indexx) {
                                          stage.id = id;
                                          stage.value = value;
                                        } else {
                                          array.push(obj);
                                        }
                                      });
                                    }
                                    const unique = [
                                      ...new Map(
                                        array.map((item, key) => [
                                          item["index"],
                                          item,
                                        ])
                                      ).values(),
                                    ];
                                    setStages(unique);
                                    setStagesName([...stagesName, value]);
                                    valueSetter(
                                      index,
                                      "irrigationStages",
                                      JSON.stringify(stages)
                                    );
                                  }
                                }}
                              />
                              <div className="w-16"></div>
                              <CustomDateSelector
                                label={"Irrigation Date"}
                                key={index}
                                className=""
                                date={
                                  new Date(
                                    dates.filter((iter) => {
                                      if (iter.index == indexx) {
                                        return iter.date;
                                      }
                                    })[0]?.date
                                  )
                                }
                                onChange={(value) => {
                                  if (value !== null) {
                                    let obj = {
                                      index: indexx,
                                      date: value,
                                    };
                                    let array = dates;
                                    if (!array.length > 0) {
                                      array.push(obj);
                                    } else {
                                      array.map((stage, count) => {
                                        if (stage.index == indexx) {
                                          stage.date = value;
                                        } else {
                                          array.push(obj);
                                        }
                                      });
                                    }
                                    const unique = [
                                      ...new Map(
                                        array.map((item, key) => [
                                          item["index"],
                                          item,
                                        ])
                                      ).values(),
                                    ];
                                    setDates(unique);
                                    valueSetter(
                                      index,
                                      "irrigationDates",
                                      JSON.stringify(dates)
                                    );
                                  }
                                }}
                              />
                            </div>
                            <br />
                          </>
                        );
                      })}
                  </div>
                </div>
              );
            })
          : null}
      </div>
      <div className="flex w-1/2 justify-between">
        <CustomButton
          btnName="Cancel"
          bgColor="bg-gray-400"
          onClick={() => {
            history.goBack();
          }}
        />
        {/* {!isSubmit ? (
          <CustomButton btnName="Submit" onClick={addAllVisits} />
        ) : (
          <CustomButton btnName="Submit" onClick={() => {}} />
        )} */}
        <CustomButton btnName="Submit" onClick={addAllVisits} />
      </div>
    </div>
  );
};
export default AllVisitAdd;
