import React, { useEffect, useState } from "react";
import { useLocation, Redirect, useHistory } from "react-router-dom";
import { CustomButton } from "../../../components/atoms/CustomButton";
import CustomInputBox from "../../../components/atoms/CustomInputBox";
import axios from "../../../axios";
import { toast } from "react-toastify";
import { PencilIcon } from "@heroicons/react/outline";
import { errorToast } from "../../../util/toast";
import CustomDropDownSearchSelect from "../../../components/atoms/CustomDropDownSearchSelect";
import { validateChemicalEdit } from "../common/validations";

const getItemName = (obj, prop1) => {
  return obj[prop1];
};
const getItemName2 = (obj, prop1, prop2) => {
  return obj[prop2];
};
//returns the first object in the list whose property matches the target
const searchObject = (items, target, property) => {
  return items.filter((element) => {
    return element[property] === target;
  })[0];
};

const ChemicalEdit = () => {
  const location = useLocation();
  const history = useHistory();
  const { data } = JSON.parse(location.state);
  console.log(data, "Dataaaaaaaaa");
  const [dosePerAcre, setDosePerAcre] = useState(data.dosePerAcre.value);
  const [activeIngredient, setActiveIngredient] = useState(
    data.activeIngredient.value
  );
  const [uomId, setUomId] = useState(null);
  const [brandName, setBrandName] = useState(data.brandName.value);
  const [phi, setPHI] = useState(data.phi.value);
  const [insectDiseaseList, setInsectDiseaseList] = useState(
    data.insectDiseaseList.value
  );
  const [isRecommended, setIsRecommended] = useState(data.isRecommended.value);
  const [insectDiseaseListId, setInsectDiseaseListId] = useState(null);
  const [popId, setPopId] = useState(null);
  const [usesTypeId, setUsesTypeId] = useState(null);

  const [error, setError] = useState(null);

  const [usesTypesList, setUsesTypesList] = useState([]);
  const [popList, setPopList] = useState([]);
  const [uomObjectsList, setUomObjectsList] = useState([]);

  useEffect(() => {
    axios
      .get(`pop`)
      .then((response) => {
        setPopList(response.data.Result.Pop);
        setPopId(
          response.data.Result.Pop.filter((element) => {
            return element.identifier === data.Pop.identifier;
          })[0].id
        );
        return axios.get(`usesType`);
      })
      .then((response) => {
        setUsesTypesList(response.data.Result.UsesTypes);
        setUsesTypeId(
          response.data.Result.UsesTypes.filter((element) => {
            return element.identifier === data.UsesType.identifier;
          })[0].id
        );
        return axios.get(`uom`);
      })
      .then((response) => {
        setUomObjectsList(response.data.Result.Uom);
        setUomId(
          response.data.Result.Uom.filter((element) => {
            return element.identifier === data.Uom.identifier;
          })[0].id
        );
        return axios.get(`insectsDiseasesMaster/load`);
      })
      .then((response) => {
        setInsectDiseaseListId(response.data.Result.insectDiseaseMaster);
      })
      .catch((error) => {
        // console.log(error?.message);
        errorToast(error?.response?.data?.Comments);
      });
  }, []);

  if (!location.state) {
    return <Redirect to="/" />;
  }

  const editChemical = () => {
    if (
      validateChemicalEdit({
        dosePerAcre,
        activeIngredient,
        uomId,
        brandName,
        phi,
        popId,
        usesTypeId,
        insectDiseaseList
      })
    ) {
      axios
        .put(`chemicalMasters/${data.id}`, {
          dosePerAcre,
          activeIngredient,
          uomId,
          brandName,
          phi,
          popId,
          usesTypeId,
          insectDiseaseList,
          isRecommended,
        })
        .then((response) => {
          if (response?.data?.ResponseMessage === "SUCCESS") {
            toast.success("Update successful!", { position: "top-center" });
            history.goBack();
          }
        })
        .catch((error) => {
          errorToast(error?.response?.data?.Comments);
          console.log(error);
        });
    }
  };

  return (
    <div className=" m-3 p-3">
      <div className="flex">
        <span>
          <PencilIcon className="h-5 w-5 transform text-black mt-4 mr-1" />
        </span>
        <span className=" py-4 text-gray-700 text-base font-bold text-left">
          Edit chemical master
        </span>
      </div>
      <div className="flex flex-wrap justify-left">
        <div className="w-2/3">
          <div className="flex flex-wrap gap-x-3 mb-4">
            {/* <CustomDisplayBox title="ID" value={data.id} /> */}
            <CustomInputBox
              title="brandName"
              value={brandName}
              className="w-1/2"
              valueSetter={setBrandName}
            />
            <CustomInputBox
              title="Active Ingredient"
              value={activeIngredient}
              className="w-1/2"
              valueSetter={setActiveIngredient}
            />
            <CustomInputBox
              title="PHI (Days)"
              value={phi}
              className="w-1/2"
              valueSetter={setPHI}
            />
            <CustomInputBox
              title="Dose"
              value={dosePerAcre}
              className="w-1/2"
              valueSetter={setDosePerAcre}
            />
            <CustomDropDownSearchSelect

            label={"Insect/Disease"}
              items={
                insectDiseaseListId !== null ?
                  insectDiseaseListId.map((value) => {
                  return getItemName(value, "insectDiseaseName");
                })
                : []
              }
              selected={
                insectDiseaseList !== null && insectDiseaseListId !== null && insectDiseaseListId.length > 0
                  ? getItemName(
                      searchObject(insectDiseaseListId, parseInt(insectDiseaseList), "id"),
                      "insectDiseaseName"
                    )
                  : null
              }
              handler={(value) => {
                if (value !== null && insectDiseaseListId.length > 0)
                  setInsectDiseaseList(
                    insectDiseaseListId.filter((element) => {
                      return getItemName(element, "insectDiseaseName") == value;
                    })[0].id
                  );
                else {
                  setInsectDiseaseList(null);
                }
              }}
            />
            <CustomDropDownSearchSelect
              label={"IS Recommended?"}
              items={["Yes", "No"]}
              selected={isRecommended == 1 ? "Yes" : "No"}
              handler={(value) => {
                setIsRecommended(value == "Yes" ? true : false);
                console.log(value, "VAlUE");
              }}
            />
            {/*UoM Drop down */}
            <div className="flex gap-x-4 w-full">
              <div className="w-full">
                <CustomDropDownSearchSelect
                  label={"UoM"}
                  items={uomObjectsList.map((value) => {
                    return getItemName(value, "uom");
                  })}
                  selected={
                    uomId !== null && uomObjectsList.length > 0
                      ? getItemName(
                          searchObject(uomObjectsList, uomId, "id"),
                          "uom"
                        )
                      : null
                  }
                  handler={(value) => {
                    if (value !== null && uomObjectsList.length > 0)
                      setUomId(
                        uomObjectsList.filter((element) => {
                          return getItemName(element, "uom") == value;
                        })[0].id
                      );
                    else {
                      setUomId(null);
                    }
                  }}
                />
              </div>

              {/* PoP Dropdown */}
              <div className="w-full">
                <CustomDropDownSearchSelect
                  label={"Package of Practice"}
                  items={popList.map((value) => {
                    return value.pop;
                  })}
                  selected={
                    popId !== null && popList.length > 0
                      ? popList.filter((element) => {
                          return element.id === popId;
                        })[0].pop
                      : null
                  }
                  handler={(value) => {
                    if (value !== null && popList.length > 0)
                      setPopId(
                        popList.filter((element) => {
                          return element.pop === value;
                        })[0].id
                      );
                    else {
                      setPopId(null);
                    }
                  }}
                />
              </div>

              {/* Usage Types Dropdown */}
              <div className="w-full">
                <CustomDropDownSearchSelect
                  label={"Usage Type"}
                  items={usesTypesList.map((value) => {
                    return value.usesType;
                  })}
                  selected={
                    usesTypeId !== null && usesTypesList.length > 0
                      ? usesTypesList.filter((element) => {
                          return element.id === usesTypeId;
                        })[0].usesType
                      : null
                  }
                  handler={(value) => {
                    if (value !== null && popList.length > 0)
                      setUsesTypeId(
                        usesTypesList.filter((element) => {
                          return element.usesTypeId === value;
                        })[0].id
                      );
                    else {
                      setUsesTypeId(null);
                    }
                  }}
                />
              </div>
            </div>
          </div>
          <div className="flex mt-8 justify-between">
            <CustomButton
              btnName="Cancel"
              className="p-2 bg-gray-500 hover:bg-gray-700 text-lg"
              onClick={() => {
                history.goBack();
              }}
            />
            <CustomButton
              btnName="Update"
              className="p-2 bg-green-500 hover:bg-green-700 text-lg"
              onClick={editChemical}
            />
          </div>
        </div>
      </div>
      <div className="text-xl text-red-500">{error}</div>
      <div className="flex w-max gap-x-10"></div>
    </div>
  );
};
export default ChemicalEdit;
