import React from "react";

const CustomInputBox = ({
  title,
  value,
  bgColor,
  disabled,
  className,
  valueSetter,
  labelClass,
  borderDisable,
}) => {
  return (
    <div className="mb-2 w-full">
      <span className={`text-sm ${labelClass} font-base text-gray-700`}>
        {title}
      </span>
      <input
        name={title + value}
        placeholder={title}
        type="text"
        disabled={disabled}
        value={`${
          borderDisable === true && disabled === true && value === null
            ? "[unknown]"
            : value === null
            ? ""
            : value
        }`}
        className={`${bgColor ? bgColor : "bg-white"} text-md font-light ${
          borderDisable ? "" : " border border-gray-300 "
        } h-9 w-full focus:outline-none ${
          borderDisable ? "" : "pl-3"
        } ${className}`}
        onChange={(e) => {
          valueSetter(e.target.value);
        }}
        style={{ borderRadius: "3px" }}
        style={{ height: "38px", borderRadius: "3px" }}
      />
    </div>
  );
};

export default CustomInputBox;
