import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { CustomButton } from "../../../components/atoms/CustomButton";
import axios from "../../../axios";
import {
  DocumentAddIcon,
  PlusIcon,
  TrashIcon,
  XCircleIcon,
} from "@heroicons/react/outline";
import CustomInputBox from "../../../components/atoms/CustomInputBox";
import { successToast, errorToast } from "../../../util/toast";
import CustomDropDownSearchSelect from "../../../components/atoms/CustomDropDownSearchSelect";
import { validateSeasonalPlanningAdd } from "../../Master Data/common/validations";

const makeDataObject = () => {
  return {
    year: new Date().getFullYear(),
    varietyId: null,
    inchargeId: null,
    mandiId: null,
    fieldMotivatorId: null,
    targetAcre: null,
  };
};

const SeasonalPlanningAdd = () => {
  const history = useHistory();
  const [mandiList, setMandiList] = useState(null);
  const [varietyList, setVarietyList] = useState(null);
  const [inchargeList, setInchargeList] = useState(null);
  const [fieldMotivatorList, setFieldMotivatorList] = useState(null);
  const [newSeasonalPlanning, setNewSeasonalPlannings] = useState([
    makeDataObject(),
  ]);
  const [isSubmit, setIsSubmit] = useState(false);

  const getItemName = (obj, prop1, prop2) => {
    return obj[prop2];
  };

  console.log(inchargeList, "Incharge List");
  useEffect(() => {
    axios
      .get(`mandiMaster/load/${localStorage.getItem("cfUserId")}`)
      .then((response) => {
        setMandiList(response.data.Result.MandiMaster);
        return axios.get(`varietyMaster/load`);
      })
      .then((response) => {
        setVarietyList(response.data.Result.VarietyMaster);
        return axios.get(`users/index`, {
          params: { role: "CF Head", limit: 1000000,isActive: 1 },
        }); // this is returning empty arrays.
        // return axios.get(`users/index`, { params: { limit: 1000000 } });
        // params: { role: "CF Head", isActive: 1 },
      })
      .then((response) => {
        console.log(response.data.Result);
        setInchargeList(response.data.Result.users);
        return axios.get(`users/index`, {
          params: { role: "Field Motivator", limit: 1000000, isActive: 1 },
        });
      })
      .then((response) => {
        setFieldMotivatorList(response.data.Result.users);
      })
      .catch((error) => {
        errorToast(error?.response?.data?.Comments);
      });
  }, []);

  const valueSetter = (index, property, newValue) => {
    let seasonalPlannings = [...newSeasonalPlanning];
    seasonalPlannings[index][property] = newValue;
    setNewSeasonalPlannings(seasonalPlannings);
  };

  const addSeasonalPlannings = () => {
    console.log(newSeasonalPlanning);
    if (validateSeasonalPlanningAdd(newSeasonalPlanning)) {
      setIsSubmit(true);
      successToast("Your request is being processed");
      axios
        .post("seasonalPlannings/", newSeasonalPlanning)
        .then((response) => {
          console.log(response);
          if (response?.data?.ResponseMessage === "SUCCESS") {
            successToast("New seasonal plannings added!");
            history.push("/home/transactionData/seasonalPlanning");
          }
        })
        .catch((error) => {
          errorToast(error?.response?.data?.Comments);
        });
    } else {
      setIsSubmit(false);
    }
  };

  return (
    <div className="p-3 m-3">
      <div className="flex mb-7">
        <DocumentAddIcon className="h-5 w-5text-black mt-3 mr-1" />
        <div className="text-base pt-3 font-bold text-gray-800">
          Add Seasonal Planning
        </div>
      </div>
      <div>
        {newSeasonalPlanning.map((value, index) => {
          return (
            <div className="mb-10 flex-wrap w-1/2">
              <div className="flex gap-x-4">
                <div className="space-y-5 w-1/2 p-1">
                  <CustomDropDownSearchSelect
                    label={"Mandi"}
                    items={
                      mandiList !== null
                        ? mandiList.map((value) => {
                            // return value.name;
                            return getItemName(value, "identifier", "name");
                          })
                        : []
                    }
                    selected={
                      value.mandiId !== null && mandiList !== null
                        ? getItemName(
                            mandiList.filter((element) => {
                              return element.id === value.mandiId;
                            })[0],
                            "identifier",
                            "name"
                          )
                        : null
                    }
                    handler={(value) => {
                      //the value that the handler gets is not the id, but the name of the uom.
                      if (value === null) valueSetter(index, "mandiId", null);
                      else
                        valueSetter(
                          index,
                          "mandiId",
                          mandiList.filter((element) => {
                            return (
                              getItemName(element, "identifier", "name") ===
                              value
                            );
                          })[0].id
                        );
                    }}
                  />
                  <CustomDropDownSearchSelect
                    label={"Variety"}
                    items={
                      varietyList !== null
                        ? varietyList.map((value) => {
                            return getItemName(value, "identifier", "name");
                          })
                        : []
                    }
                    selected={
                      value.varietyId !== null && varietyList !== null
                        ? getItemName(
                            varietyList.filter((element) => {
                              return element.id === value.varietyId;
                            })[0],
                            "identifier",
                            "name"
                          )
                        : null
                    }
                    handler={(value) => {
                      //the value that the handler gets is not the id, but the name of the uom.
                      if (value === null) valueSetter(index, "varietyId", null);
                      else
                        valueSetter(
                          index,
                          "varietyId",
                          varietyList.filter((element) => {
                            return (
                              getItemName(element, "identifier", "name") ===
                              value
                            );
                          })[0].id
                        );
                    }}
                  />
                </div>
                <div className="space-y-5 w-1/2">
                  <CustomDropDownSearchSelect
                    label={"Incharge"}
                    items={

                      inchargeList !== null && value.mandiId != null
                        ? inchargeList
                            .filter((datas) => {
                              let temp = datas.UserMandis.some(el => el.mandiId === value.mandiId)
                              return temp;
                            })
                            .map((value) => {
                              return getItemName(value, "identifier", "name");
                            })
                        : []
                    }
                    selected={
                      value.inchargeId !== null && inchargeList !== null
                        ? getItemName(
                            inchargeList.filter((element) => {
                              return element.id === value.inchargeId;
                            })[0],
                            "identifier",
                            "name"
                          )
                        : null
                    }
                    handler={(value) => {
                      //the value that the handler gets is not the id, but the name of the uom.
                      if (value === null)
                        valueSetter(index, "inchargeId", null);
                      else
                        valueSetter(
                          index,
                          "inchargeId",
                          inchargeList.filter((element) => {
                            // return value === element.name;
                            return (
                              getItemName(element, "identifier", "name") ===
                              value
                            );
                          })[0].id
                        );
                    }}
                  />
                  <CustomDropDownSearchSelect
                    label={"Field Motivator"}
                    items={
                      fieldMotivatorList !== null && value.mandiId != null
                        ? fieldMotivatorList
                            .filter((datas) => {
                              let temp = datas.UserMandis.some(el => el.mandiId === value.mandiId)
                              return temp;
                            })
                            .map((value) => {
                              return getItemName(value, "identifier", "name");
                            })
                        : []
                    }
                    selected={
                      value.fieldMotivatorId !== null &&
                      fieldMotivatorList !== null
                        ? getItemName(
                            fieldMotivatorList.filter((element) => {
                              return element.id === value.fieldMotivatorId;
                            })[0],
                            "identifier",
                            "name"
                          )
                        : null
                    }
                    handler={(value) => {
                      //the value that the handler gets is not the id, but the name of the uom.
                      if (value === null)
                        valueSetter(index, "fieldMotivatorId", null);
                      else
                        valueSetter(
                          index,
                          "fieldMotivatorId",
                          fieldMotivatorList.filter((element) => {
                            // return value === element.name;
                            return (
                              getItemName(element, "identifier", "name") ===
                              value
                            );
                          })[0].id
                        );
                    }}
                  />
                </div>
              </div>

              <div className="flex w-full">
                <div className="w-full gap-x-3">
                  <CustomInputBox
                    title="Target Acre"
                    value={value.targetAcre}
                    valueSetter={(value) => {
                      valueSetter(index, "targetAcre", Number(value));
                    }}
                  />
                </div>

                {
                  newSeasonalPlanning.length - 1 !== index ? (
                    <span
                      onClick={() => {
                        setNewSeasonalPlannings(
                          newSeasonalPlanning.filter((v, i) => {
                            return i !== index;
                          })
                        );
                      }}
                      className="h-5 w-5 ml-2 mt-8 text-gray-500 hover:text-red-500 hover:p-1"
                    >
                      <TrashIcon />
                    </span>
                  ) : (
                    <span
                      className="h-4 w-4 ml-2 mt-8 text-white rounded-md bg-green-500"
                      onClick={() => {
                        setNewSeasonalPlannings([
                          ...newSeasonalPlanning,
                          makeDataObject(),
                        ]);
                      }}
                    >
                      <PlusIcon />
                    </span>
                  )
                  // :<CustomButton className=" mt-8 ml-3 text-sm p-1 bg-green-500" btnName={<span className="h-3 w-3"><PlusIcon /></span>} onClick={() => { setNewVarieties([...newVarieties, { name: "" }]) }} />
                }
              </div>
            </div>
          );
        })}
      </div>

      <div className="flex w-1/2 justify-between">
        <CustomButton
          btnName="Cancel"
          bgColor="bg-gray-400"
          onClick={() => {
            history.goBack();
          }}
        />

        {!isSubmit ? (
          <CustomButton btnName="Submit" onClick={addSeasonalPlannings} />
        ) : (
          <CustomButton btnName="Submit" onClick={() => {}} />
        )}
      </div>
    </div>
  );
};
export default SeasonalPlanningAdd;
