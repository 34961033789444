import React, { useEffect, useState } from "react";
import { useLocation, Redirect, useHistory } from "react-router-dom";
import { CustomButton } from "../../../components/atoms/CustomButton";
import CustomInputBox from "../../../components/atoms/CustomInputBox";
import axios from "../../../axios";
import { toast } from "react-toastify";
import { PencilIcon } from "@heroicons/react/outline";
import CustomDropDownSearchSelect from "../../../components/atoms/CustomDropDownSearchSelect";
import { errorToast } from "../../../util/toast";
import { validateFertilizerEdit } from "../common/validations";

const getItemName = (obj, prop1) => {
  return obj[prop1];
};
//returns the first object in the list whose property matches the target
const searchObject = (items, target, property) => {
  return items.filter((element) => {
    return element[property] === target;
  })[0];
};

const FertilizerMasterEdit = () => {
  const location = useLocation();
  const history = useHistory();
  const { data } = JSON.parse(location.state);
  const [name, setName] = useState(data?.name.value);
  const [uomObjectsList, setUomObjectsList] = useState([]);
  const [brandName, setBrandName] = useState(data?.brandName.value);
  const [uomId, setUomId] = useState(data.Uom.id);
  const [error, setError] = useState(null);

  useEffect(() => {
    axios
      .get(`uom`)
      .then((response) => {
        setUomObjectsList(response.data.Result.Uom);
        console.log(uomObjectsList);
      })
      .catch((error) => {
        console.log(error?.message);
        errorToast(error?.response?.data?.Comments);
      });
  }, []);

  if (!location.state) {
    return <Redirect to="/" />;
  }

  const editFertilizer = () => {
    if (
      validateFertilizerEdit({ brandName: brandName, name: name, uomId: uomId })
    ) {
      axios
        .put(`fertilizerMaster/${data.id}`, {
          brandName: brandName,
          name: name,
          uomId: uomId,
        })
        .then((response) => {
          if (response?.data?.ResponseMessage === "SUCCESS") {
            toast.success("Update successful!", { position: "top-center" });
            history.goBack();
          }
        })
        .catch((error) => {
          errorToast(error?.response?.data?.Comments);
          console.log(error);
        });
    }
  };

  return (
    <div className=" m-3 p-3">
      <div className="flex">
        <span>
          <PencilIcon className="h-5 w-5 transform text-black mt-4 mr-1" />
        </span>
        <span className=" py-4 text-gray-700 text-base font-bold text-left">
          Edit Fertilizer Master
        </span>
      </div>
      <div className="flex flex-wrap justify-left">
        <div className="w-1/2">
          <div className="flex flex-wrap gap-x-3 mb-4">
            {/* <CustomDisplayBox title="ID" value={data.id} /> */}
            <CustomInputBox
              title="Name"
              value={name}
              className="w-1/2"
              valueSetter={setName}
            />
            <CustomInputBox
              title="Brand Name"
              value={brandName}
              className="w-1/2"
              valueSetter={setBrandName}
            />
            <div className="w-1/2">
              <CustomDropDownSearchSelect
                label={"UoM"}
                items={uomObjectsList.map((value) => {
                  return getItemName(value, "uom");
                })}
                selected={
                  uomId !== null && uomObjectsList.length > 0
                    ? getItemName(
                        searchObject(uomObjectsList, uomId, "id"),
                        "uom"
                      )
                    : null
                }
                handler={(value) => {
                  if (value !== null && uomObjectsList.length > 0)
                    setUomId(
                      uomObjectsList.filter((element) => {
                        return getItemName(element, "uom") == value;
                      })[0].id
                    );
                  else {
                    setUomId(null);
                  }
                }}
              />
            </div>
          </div>
          <div className="flex mt-8 justify-between">
            <CustomButton
              btnName="Cancel"
              className="p-2 bg-gray-500 hover:bg-gray-700 text-lg"
              onClick={() => {
                history.goBack();
              }}
            />
            <CustomButton
              btnName="Update"
              className="p-2 bg-green-500 hover:bg-green-700 text-lg"
              onClick={editFertilizer}
            />
          </div>
        </div>
      </div>
      <div className="text-xl text-red-500">{error}</div>
      <div className="flex w-max gap-x-10"></div>
    </div>
  );
};
export default FertilizerMasterEdit;
