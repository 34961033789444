import React, { useState } from "react";
import { useLocation, Redirect, useHistory } from "react-router-dom";
import { CustomButton } from "../../../components/atoms/CustomButton";
import CustomInputBox from "../../../components/atoms/CustomInputBox";
import axios from "../../../axios";
import { toast } from "react-toastify";
import { PencilIcon } from "@heroicons/react/outline";
import CustomDropDownSearchSelect from "../../../components/atoms/CustomDropDownSearchSelect";
import { validateInsectDiseaseEdit } from "../common/validations";
import { successToast, errorToast } from "../../../util/toast";

const InsectDiseaseEdit = () => {
  const location = useLocation();
  const history = useHistory();
  const { data } = JSON.parse(location.state);
  const [name, setName] = useState(data?.name.value);
  const [severity, setSeverity] = useState(data?.severity.value);
  const [type, setType] = useState(data.type.value);
  const [error, setError] = useState(null);

  if (!location.state) {
    return <Redirect to="/" />;
  }

  const editZone = () => {
    if (
      validateInsectDiseaseEdit({
        insectDiseaseName: name,
        severity: severity,
        type: type,
      })
    ) {
      axios
        .put(`insectsDiseasesMaster/${data.id}`, {
          insectDiseaseName: name,
          severity: severity,
          type: type,
        })
        .then((response) => {
          if (response?.data?.ResponseMessage === "SUCCESS") {
            toast.success("Update successful!", { position: "top-center" });
            history.goBack();
          }
        })
        .catch((error) => {
          errorToast(error?.response?.data?.Comments);
          console.log(error);
        });
    }
  };

  return (
    <div className=" m-3 p-3">
      <div className="flex">
        <span>
          <PencilIcon className="h-5 w-5 transform text-black mt-4 mr-1" />
        </span>
        <span className=" py-4 text-gray-700 text-base font-bold text-left">
          Edit Insect/Disease{" "}
        </span>
      </div>
      <div className="flex flex-wrap justify-left">
        <div className="w-1/2">
          <div className="flex flex-wrap gap-x-3 mb-4">
            {/* <CustomDisplayBox title="ID" value={data.id} /> */}
            <CustomInputBox
              title="Type"
              value={data.type.value}
              disabled={true}
              borderDisable={true}
            />

            <div className="flex gap-x-2 w-full">
              <div className="w-full">
                <CustomInputBox
                  title="Name"
                  value={name}
                  className="w-1/2"
                  valueSetter={setName}
                />
              </div>

              <div className="w-full">
                <CustomDropDownSearchSelect
                  items={["High", "Medium", "Low"]}
                  selected={severity}
                  label="Severity"
                  handler={(value) => {
                    setSeverity(value);
                  }}
                />
              </div>
            </div>
          </div>
          <div className="flex mt-8 justify-between">
            <CustomButton
              btnName="Cancel"
              className="p-2 bg-gray-500 hover:bg-gray-700 text-lg"
              onClick={() => {
                history.goBack();
              }}
            />
            <CustomButton
              btnName="Update"
              className="p-2 bg-green-500 hover:bg-green-700 text-lg"
              onClick={editZone}
            />
          </div>
        </div>
      </div>
      <div className="text-xl text-red-500">{error}</div>
      <div className="flex w-max gap-x-10"></div>
    </div>
  );
};
export default InsectDiseaseEdit;
