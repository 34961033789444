import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router";
import { CustomButton } from "../../../components/atoms/CustomButton";
import axios from "../../../axios";
import { DocumentAddIcon, PlusIcon, TrashIcon } from "@heroicons/react/outline";
import CustomInputBox from "../../../components/atoms/CustomInputBox";
import { successToast, errorToast } from "../../../util/toast";
import CustomDropDownSearchSelect from "../../../components/atoms/CustomDropDownSearchSelect";
// import { validateUsersAdd } from "../common/validations";

const UsersEdit = () => {
  const history = useHistory();
  const location = useLocation();

  console.log(JSON.parse(location.state));
  const data = JSON.parse(location.state).data;

  const [roleId, setRoleId] = useState(data.Role.identifier);

  const [RoleList, setRoleList] = useState(null);

  const [newUsers, setnewUsers] = useState([
    { name: "", email: "", password: "", role: "" },
  ]);

  const [MandiList, setMandiList] = useState(null);
  const valueSetter = (index, property, newValue) => {
    let users = [...newUsers];
    users[index][property] = newValue;
    setnewUsers(users);
  };

  useEffect(() => {
    axios
      .get("rolesall")
      .then((res) => {
         console.log( "ROLEES", res.data.Result.permissions)
        let filterRole = res.data.Result.permissions;
        filterRole = filterRole.filter((elem) => {
          if (elem.roleName != "Super Admin") return elem;
          if (elem.roleName == "Super Admin") return elem;
        });
        console.log("Role :", filterRole);
        setRoleList(filterRole);
      })
      .catch((e) => {
        console.log(e);
      });

    axios
      .get(`mandiMaster/load`)
      .then((res) => {
        //  console.log(res.data.Result.MandiMaster)
        setMandiList(res.data.Result.MandiMaster);
      })
      .catch((e) => console.log(e));

    axios.get(`/userMandi/mandi/${data.id}`).then((res) => {
      let userRole = [];
      //  console.log("Mandis", res.data.Result.userMandi)
      let temp = res.data.Result.userMandi;
      temp.map((val, ind) => {
        userRole.push(val.mandiId);
      });

      console.log("UserRole", userRole);
      valueSetter(0, "userRoles", userRole);
    });
  }, []);

  const addUser = () => {
    // console.log(newUsers[0]);
    // if (validateUsersAdd(newUsers)){
    if (true) {
      var Role;

      if (roleId === null) Role = "";
      else {
        Role = getItem(
          searchObject(RoleList, roleId, "identifier"),
          "identifier",
          "roleName"
        );
      }

      axios
        .put("changerole", { email: data.email.value, role: Role })
        .then(() => {
          if (
            RoleList != null &&
            roleId != null &&
            (getItem(
              searchObject(RoleList, roleId, "identifier"),
              "identifier",
              "roleName"
            ) == "Purchaser" ||
              getItem(
                searchObject(RoleList, roleId, "identifier"),
                "identifier",
                "roleName"
              ) == "Field Motivator" ||
              getItem(
                searchObject(RoleList, roleId, "identifier"),
                "identifier",
                "roleName"
              ) == "Field Assistant" ||
              getItem(
                searchObject(RoleList, roleId, "identifier"),
                "identifier",
                "roleName"
              ) == "CF Head")
          ) {
          } else {
            newUsers[0]["userRoles"] = [];
          }

          const bullkarray = [];
          newUsers[0].userRoles.map((elem) => {
            const temp_obj = {};

            temp_obj["mandiId"] = elem;
            temp_obj["userId"] = data?.id;

            bullkarray.push(temp_obj);
          });
          console.log(bullkarray);

          axios
            .put(`userMandi/${data.id}`, bullkarray)
            .then((res) => {
              if (res?.data?.ResponseMessage === "SUCCESS") {
                successToast("User Updated");
                history.goBack();
              }
            })
            .catch((error) => {
              errorToast(error?.response?.data?.Comments);
              console.log(JSON.stringify(error));
            });
        })
        .catch((error) => {
          errorToast(error?.response?.data?.Comments);
          console.log(JSON.stringify(error));
        });
    }
  };
  const getItemName = (obj, prop1) => {
    console.log(`Error:  ${obj}, ${prop1}`);
    return obj[prop1];
  };
  const getItem = (obj, prop1, prop2) => {
    return obj[prop2];
  };

  const searchObject = (items, target, property) => {
     console.log(items , target , property)

    return (
      items &&
      items.filter((element) => {
        return element[property] === target;
      })[0]
    );
  };

  return (
    <div className="p-3 m-3">
      <div className="flex mb-7">
        <DocumentAddIcon className="h-5 w-5text-black mt-3 mr-1" />
        <div className="text-base pt-3 font-bold text-gray-800">
          Edit User Role
        </div>
      </div>
      {newUsers.map((value, index) => (
        <div>
          <div className="mb-4 flex flex-wrap w-1/2">
            <div className="flex w-full">
              <div className="w-full mb-10">
                {/* <CustomInputBox
                    title="Identifier"
                    value={value.identifier}
                    valueSetter={(value) => {
                      valueSetter(index, "identifier", value);
                    }}
                  /> */}
                <CustomInputBox
                  title="Name"
                  disabled={true}
                  borderDisable={true}
                  value={data.name.value}
                />
                <CustomInputBox
                  title="User Email"
                  disabled={true}
                  borderDisable={true}
                  value={data.email.value}
                />

                <CustomInputBox
                  title="Is Active"
                  disabled={true}
                  borderDisable={true}
                  value={data.isActive ? "Yes" : "No"}
                />

                <CustomDropDownSearchSelect
                  label="User Role"
                  items={
                    RoleList !== null
                      ? RoleList.map((element) => {
                          return getItemName(element, "roleName");
                        })
                      : []
                  }
                  selected={
                    roleId !== null && RoleList !== null
                      ? getItemName(
                          searchObject(RoleList, roleId, "identifier"),
                          "roleName"
                        )
                      : null
                  }
                  handler={(value) => {
                    if (value !== null && RoleList !== null) {
                      setRoleId(
                        RoleList.filter((element) => {
                          return getItemName(element, "roleName") === value;
                        })[0].identifier
                      );
                    } else {
                      setRoleId(null);
                    }
                  }}
                />

                <br></br>

                {RoleList != null &&
                  roleId != null &&
                  (getItem(
                    searchObject(RoleList, roleId, "identifier"),
                    "identifier",
                    "roleName"
                  ) == "Purchaser" ||
                    getItem(
                      searchObject(RoleList, roleId, "identifier"),
                      "identifier",
                      "roleName"
                    ) == "Field Motivator" ||
                    getItem(
                      searchObject(RoleList, roleId, "identifier"),
                      "identifier",
                      "roleName"
                    ) == "Field Assistant" ||
                    getItem(
                      searchObject(RoleList, roleId, "identifier"),
                      "identifier",
                      "roleName"
                    ) == "CF Head") && (
                    <div>
                      <CustomDropDownSearchSelect
                        label="User mandis"
                        items={
                          MandiList !== null
                            ? MandiList.map((element) => {
                                return getItemName(
                                  element,

                                  "name"
                                );
                              })
                            : []
                        }
                        multi={true}
                        handler={(newValues) => {
                          valueSetter(
                            index,
                            "userRoles",
                            MandiList.filter((item) => {
                              return (
                                newValues.indexOf(getItemName(item, "name")) !==
                                -1
                              );
                            }).map((item) => {
                              return item.id;
                            })
                          );
                        }}
                        selected={
                          value.userRoles !== undefined && MandiList !== null
                            ? MandiList.filter((item) => {
                                return value.userRoles.indexOf(item.id) !== -1;
                              }).map((item) => getItemName(item, "name"))
                            : []
                        }
                      />
                    </div>
                  )}
              </div>
            </div>
          </div>
        </div>
      ))}
      <div className="flex w-1/2 justify-between">
        <CustomButton
          btnName="Cancel"
          bgColor="bg-gray-400"
          onClick={() => {
            history.goBack();
          }}
        />
        <CustomButton btnName="Submit" onClick={addUser} />
      </div>
    </div>
  );
};
export default UsersEdit;
