import { React, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
  PlusCircleIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  SearchIcon,
} from "@heroicons/react/outline";
import axios from "../../../axios";
import CustomDropDownSearchSelect from "../../../components/atoms/CustomDropDownSearchSelect";
import { errorToast } from "../../../util/toast";
import CustomTable from "../../../components/atoms/CustomTable";

const getItemName = (obj, prop1, prop2) => {
  return obj[prop2];
};
//returns the first object in the list whose property matches the target
const searchObject = (items, target, property) => {
  return items.filter((element) => {
    return element[property] === target;
  })[0];
};

const getDateString = (date) => {
  let year = date.getFullYear();
  let month = date.getMonth() + 1;
  month = month < 10 ? "0" + month : month;
  let day = date.getDate();
  day = day < 10 ? "0" + day : day;
  return day + "/" + month + "/" + year;
};

const addActions = (objects) => {
  //adds the actions buttons to the table rows.
  const newObjects = [];

  objects.map((obj) => {
    obj.name = { value: "this validation" }; // this is for delete
    obj.identifier = {
      value: obj.popId === 1 ? `FRTC-${obj.id}` : `FRIP-${obj.id}`,
      show: true,
      order: 0,
    };
    obj.farmerName = {
      value: getItemName(obj.FarmerMaster, "identifier", "name"),
      show: true,
      order: 1,
    };
    obj.landUsed = { value: obj.landUsed, show: true, order: 2 };
    obj.varietyName = {
      value: getItemName(obj.VarietyMaster, "identifier", "name"),
      show: true,
      order: 2,
    };
    obj.actualLandUsed = { value: obj.actualLandUsed, show: true, order: 3 };
    obj.sowingDate = {
      value:
        obj.sowingDate === null
          ? null
          : getDateString(new Date(obj.sowingDate)),
      show: true,
      order: 4,
    };

    obj.actions = {
      value: (
        <div className="flex gap-x-3 -ml-4 p-1">
          <Link
            to={{
              pathname: `/add`,
              state: JSON.stringify({ data: obj }),
            }}
          >
            <span>
              <PlusCircleIcon className="h-5 w-5 text-gray-700 hover:text-black" />
            </span>
          </Link>
        </div>
      ),
      show: true,
      order: 6,
    };
    newObjects.push(obj);
  });

  return newObjects;
};

const ValidationAddSelect = () => {
  const [zoneList, setZoneList] = useState(null);
  const [mandiList, setMandiList] = useState(null);
  const [aarthiList, setAarthiList] = useState(null);

  const [zoneId, setZoneId] = useState(null);
  const [mandiId, setMandiId] = useState(null);
  const [aarthiId, setAarthiId] = useState(null);

  const [message, setMessage] = useState(null);

  //table related.
  const tablePageNumbersShown = 3;
  const [currentTableData, setTableData] = useState([]);
  const rowsPerPage = 10;
  const [totalRecords, setTotalRecords] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [varietyId, setVarietyId] = useState(null);
  const [farmerId, setfarmerId] = useState(null);
  const [varietyList, setVarietyList] = useState(null);
  const [farmerList, setFarmerList] = useState(null);
  const [villageList, setVillageList] = useState(null);
  const [villageId, setvillageId] = useState(null);

  const [nameArray, setNameArray] = useState([]);

  const [sortBy, setSortBy] = useState({
    headerName: "id",
    order: false,
  });
  const tableHeaders = [
    { displayName: "id", property: "id", sortable: true, show: false },
    { displayName: "Farmer ID", property: "id", sortable: false, show: true },

    {
      displayName: "Farmer",
      property: "FarmerMaster.name",
      sortable: true,
      show: true,
    },
    {
      displayName: "Variety",
      property: "VarietyMaster.name",
      sortable: true,
      show: true,
    },
    {
      displayName: "Land Used",
      property: "landUsed",
      sortable: true,
      show: true,
    },
    {
      displayName: "Actual Land Used",
      property: "actualLandUsed",
      sortable: true,
      show: true,
    },
    {
      displayName: "Sowing Date",
      property: "sowingDate",
      sortable: true,
      show: true,
    },
    {
      displayName: "Tag Colour",
      property: "passedTag",
      sortable: false,
      show: true,
    },
    {
      displayName: "actions",
      property: "actions",
      sortable: false,
      show: true,
    },
  ];

  useEffect(() => {
    axios
      .get(`zoneMasters/specificload/${localStorage.getItem("cfUserId")}`)
      .then((response) => {
        setZoneList(response.data.Result.ZoneMaster);
        return axios.get(
          `mandiMaster/load/${localStorage.getItem("cfUserId")}`
        );
      })
      .then((response) => {
        setMandiList(response.data.Result.MandiMaster);
        return axios.get(`varietyMaster/load`);
      })
      .then((response) => {
        setVarietyList(response.data.Result.VarietyMaster);
        return axios.get(`villageMasters/load`);
      })
      .then((response) => {
        console.log("Village", response.data.Result);
        setVillageList(response.data.Result.villageMaster);
        return axios.get("farmerMaster/load");
      })
      .then((response) => {
        setFarmerList(response.data.Result.FarmerMaster);
      })
      .catch((error) => {
        console.log(error);
        errorToast(error);
      });
  }, []);
  //farmer variety filter call
  useEffect(() => {
    //when the filter values are all set, then make the call.

    if (1) {
      let params = {
        page: pageNumber,
        limit: 10,
        orderBy: sortBy.headerName,
        order: sortBy.order ? "ASC" : "DESC",
        varietyId: varietyId,
        villageId: villageId,
        zoneId: zoneId,
        mandiId: mandiId,
      };
      params.sowingDate = "NOTNULL";
      params.sampleAreaPassed = "NOTNULL";

      axios.get(`farmerVariety`, { params }).then((response) => {
        if (response.data.Result.FarmerVariety.length === 0) {
          setMessage("No such Farmer Varieties found!");
        } else {
          setMessage(null);
        }
        setTotalRecords(response.data.Result.total);
        const returnedRows = response.data.Result.FarmerVariety;
        setTotalPages(Math.ceil(response.data.Result.total / rowsPerPage));
        let actualRows = addActions(returnedRows);
        axios.get("cropCuttingSampling").then((res) => {
          let arr = [];
          res.data?.Result?.CropCuttingSampling?.map((cropItem) => {
            if (cropItem.isPassed) {
              arr.push({
                id: cropItem.FarmerVariety.FarmerMaster.id,
                tag: cropItem.passedTag,
              });
            }
          });
          setNameArray(arr);
          let finalArray = [];
          actualRows.map((actualItem) => {
            let flag = false;
            let tag = null;
            arr.map((itm) => {
              if (itm.id == actualItem.FarmerMaster.id) {
                flag = true;
                tag = itm.tag;
              }
            });
            if (flag) {
              actualItem.passedTag = { value: tag, show: true, order: 5 };
              finalArray.push(actualItem);
            }
          });
          setTableData(finalArray);
        });
      });
    } else {
      setMessage("Please select all the filter options");
    }
  }, [zoneId, mandiId, varietyId, villageId, pageNumber, sortBy]);

  useEffect(() => {
    setMandiId(null);
  }, [zoneId]);
  useEffect(() => {
    setvillageId(null);
  }, [mandiId]);

  useEffect(() => {
    setfarmerId(null);
  }, [villageId]);

  const nextPage = () => {
    if (pageNumber !== totalPages) setPageNumber(pageNumber + 1);
  };
  const prevPage = () => {
    if (pageNumber !== 1) setPageNumber(pageNumber - 1);
  };

  const getPageButtons = (noOfButtons) => {
    let buttonList = [];
    if (true) {
      let printed = 0;
      for (
        let i = Math.floor((pageNumber - 1) / noOfButtons) * noOfButtons + 1;
        printed < noOfButtons && i <= totalPages;
        i++
      ) {
        buttonList.push(
          <button
            className={`px-3 ${
              i === pageNumber
                ? " bg-green-500 text-white text-sm rounded-xl"
                : "text-gray-600 text-sm"
            }`}
            onClick={() => {
              setPageNumber(i);
            }}
          >
            {i}
          </button>
        );
        printed++;
      }
    }
    return buttonList;
  };

  return (
    <div>
      <div name="IconAndPageTitle" className="bg-white flex ml-3">
        <span>
          <SearchIcon className="my-3 mx-1 h-6 w-6 mr-1" />
        </span>
        <span className="text-lg mt-3 text-center align-center">
          Choose Farmer Variety
        </span>
        <span className="mt-5 text-xs ml-2">({totalRecords} records)</span>
      </div>
      <div
        name="filter section"
        className="flex gap-x-3 border border-t-0 border-l-0 border-r-0 pb-10 ml-5 w-11/12"
      >
        <div className="w-full">
          <CustomDropDownSearchSelect
            label={"Zone"}
            labelDisable={true}
            placeholder={"Select Zone"}
            items={
              zoneList !== null
                ? zoneList.map((element) => {
                    return getItemName(element, "identifier", "name");
                  })
                : []
            }
            selected={
              zoneId !== null && zoneList !== null
                ? getItemName(
                    searchObject(zoneList, zoneId, "id"),
                    "identifier",
                    "name"
                  )
                : null
            }
            handler={(value) => {
              if (value !== null) {
                setZoneId(
                  zoneList.filter((element) => {
                    return getItemName(element, "identifier", "name") === value;
                  })[0].id
                );
              } else {
                setZoneId(null);
              }
            }}
          />
        </div>

        <div className="w-full">
          <CustomDropDownSearchSelect
            label={"Mandi"}
            labelDisable={true}
            placeholder={"Select Mandi"}
            items={
              mandiList !== null
                ? mandiList
                    .filter((element) => {
                      return element.zoneId === zoneId;
                    })
                    .map((element) => {
                      return getItemName(element, "identifier", "name");
                    })
                : []
            }
            selected={
              mandiId !== null && mandiList !== null
                ? getItemName(
                    searchObject(mandiList, mandiId, "id"),
                    "identifier",
                    "name"
                  )
                : null
            }
            handler={(value) => {
              if (value !== null) {
                setMandiId(
                  mandiList.filter((element) => {
                    return getItemName(element, "identifier", "name") === value;
                  })[0].id
                );
              } else {
                setMandiId(null);
              }
            }}
          />
        </div>

        <div className="w-full">
          <CustomDropDownSearchSelect
            items={
              villageList !== null
                ? villageList
                    .filter((element) => {
                      return element.MandiMaster.id === mandiId;
                    })
                    .map((element) => {
                      return getItemName(element, "identifier", "name");
                    })
                : []
            }
            label={"Village"}
            labelDisable={true}
            placeholder={"Village"}
            selected={
              villageId !== null
                ? getItemName(
                    searchObject(villageList, villageId, "id"),
                    "identifier",
                    "name"
                  )
                : null
            }
            handler={(value) => {
              if (value !== null && villageList !== null) {
                setvillageId(
                  villageList.filter((element) => {
                    return getItemName(element, "identifier", "name") === value;
                  })[0].id
                );
              } else {
                setvillageId(null);
              }
            }}
          />
        </div>

        <div className="w-full">
          <CustomDropDownSearchSelect
            items={
              farmerList !== null
                ? farmerList
                    .filter((element) => {
                      return element.VillageMaster.id === villageId;
                    })
                    .map((element) => {
                      return getItemName(element, "identifier", "name");
                    })
                : []
            }
            label={"Farmer"}
            labelDisable={true}
            placeholder={"Farmer"}
            selected={
              farmerId !== null
                ? getItemName(
                    searchObject(farmerList, farmerId, "id"),
                    "identifier",
                    "name"
                  )
                : null
            }
            handler={(value) => {
              if (value !== null && farmerList !== null) {
                setfarmerId(
                  farmerList.filter((element) => {
                    return getItemName(element, "identifier", "name") === value;
                  })[0].id
                );
              } else {
                setfarmerId(null);
              }
            }}
          />
        </div>

        <div className="w-full">
          <CustomDropDownSearchSelect
            items={
              varietyList !== null
                ? varietyList.map((element) => {
                    return getItemName(element, "identifier", "name");
                  })
                : []
            }
            label={"Variety"}
            labelDisable={true}
            placeholder={"Variety"}
            selected={
              varietyId !== null
                ? getItemName(
                    searchObject(varietyList, varietyId, "id"),
                    "identifier",
                    "name"
                  )
                : null
            }
            handler={(value) => {
              if (value !== null && varietyList !== null) {
                setVarietyId(
                  varietyList.filter((element) => {
                    return getItemName(element, "identifier", "name") === value;
                  })[0].id
                );
              } else {
                setVarietyId(null);
              }
            }}
          />
        </div>
      </div>
      <div name="Message" className="font-bold">
        {message}
      </div>
      <div name="Table">
        {currentTableData.length === 0 ? null : (
          <CustomTable
            rows={currentTableData}
            headers={tableHeaders}
            sortByHandler={setSortBy}
          />
        )}
      </div>
      {
        //show the table navigation buttons only if the table has more than 0 records.
        currentTableData.length > 0 ? (
          <div className="text-sm w-max mx-auto text-center flex mt-1">
            <button
              onClick={prevPage}
              className="border-2 text-gray-600 rounded-xl border-black p-1 w-6 h-6 mx-3"
            >
              <ChevronLeftIcon />
            </button>
            <div className="mx-2 mt-1 w-28">
              {getPageButtons(tablePageNumbersShown)}
            </div>
            <button
              onClick={nextPage}
              className="border-2  text-gray-600 rounded-xl border-black p-1 w-6 h-6 mx-3"
            >
              <ChevronRightIcon />
            </button>
          </div>
        ) : null
      }
    </div>
  );
};

export default ValidationAddSelect;
