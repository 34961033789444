import React from 'react';
import Footer from '../components/molecules/Footer';
import OtpForm from '../components/molecules/OtpForm';
import Navbar from '../components/molecules/Navbar';


const Otp = () => {
    return (
        <div className="min-h-screen bg-hero-img4 bg-cover">
            <div className="flex flex-col space-y-40
            ">
                <div>
                    <Navbar />
                </div>
                <div className="">
                    <OtpForm />
                </div>
                <div className="">
                    <Footer />
                </div>
            </div>
        </div>
    );
}

export default Otp;