import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { CustomButton } from "../../../components/atoms/CustomButton";
import MasterDataAddCSV from "../common/MasterDataAddCSV";
import MasterDataDelete from "../common/MasterDataDelete";
import axios from "../../../axios";
import {
  DocumentAddIcon,
  UsersIcon,
  CheckIcon,
} from "@heroicons/react/outline";
import {
  EyeIcon,
  PencilIcon,
  TrashIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
} from "@heroicons/react/solid";
import { toast } from "react-toastify";
import CustomTable from "../../../components/atoms/CustomTable";
import CustomInputBox from "../../../components/atoms/CustomInputBox";
import { errorToast } from "../../../util/toast";
import CustomDropDownSearchSelect from "../../../components/atoms/CustomDropDownSearchSelect";

const UsersHome = () => {
  const history = useHistory();
  const tablePageNumbersShown = 3;
  //table related.
  const [currentTableData, setTableData] = useState([]);
  const rowsPerPage = 10;
  const [totalRecords, setTotalRecords] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [isActive, setIsActive] = useState(2);
  const [sortBy, setSortBy] = useState({
    headerName: "identifier",
    order: false,
  });

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showUploadFile, setShowUploadFile] = useState(false);
  const [deleteObject, setDeleteObject] = useState(null);

  const tableHeaders = [
    { displayName: "id", property: "id", sortable: true, show: false },
    { displayName: "name", property: "name", sortable: true, show: true },
    { displayName: "email", property: "email", sortable: true, show: true },
    {
      displayName: "role",
      property: "Role.roleName",
      sortable: false,
      show: true,
    },
    {
      displayName: "actions",
      property: "actions",
      sortable: false,
      show: true,
    },
  ];

  const [searchEmail, setSearchEmail] = useState(null);
  const [searchName, setSearchName] = useState(null);

  const getItemName = (obj, prop1) => {
    return obj[prop1];
  };

  const addActions = (objects) => {
    //adds the actions buttons to the table rows. this function does other things too.
    const newObjects = [];
    objects.map((obj) => {
      console.log(obj);
      obj.name = { value: obj.name, show: true, order: 1 };
      obj.email = { value: obj.email, show: true, order: 2 };
      obj.role = {
        value: getItemName(obj.Role, "roleName"),
        show: true,
        order: 3,
      };
      // obj.isActive=  {value:obj.isActive , show:false , order:4};
      obj.actions = {
        order: 4,
        value: (
          <div className="flex gap-x-3 p-1">
            <Link
              to={{
                pathname: `/edit/${obj.id}`,
                state: JSON.stringify({ data: obj }),
              }}
            >
              <span>
                <PencilIcon className="h-5 w-5 text-gray-700 hover:text-black" />
              </span>
            </Link>
            {obj.isActive === 1 && (
              <span>
                <TrashIcon
                  className="h-5 w-5 text-gray-700 hover:text-black"
                  onClick={() => {
                    setShowDeleteModal(true);
                    setDeleteObject(obj);
                  }}
                />
              </span>
            )}

            {obj.isActive === 0 && (
              <span>
                <CheckIcon
                  className="h-5 w-5 text-gray-700 hover:text-black"
                  onClick={() => {
                    setShowDeleteModal(true);
                    setDeleteObject(obj);
                  }}
                />
              </span>
            )}
          </div>
        ),
        show: true,
      };
      newObjects.push(obj);
    });
    return newObjects;
  };

  const deleteUsers = () => {
    axios
      .put(`deleteuser`, {
        email: deleteObject.email.value,
        isActive: !deleteObject.isActive,
      })
      .then((response) => {
        if (response?.data?.ResponseMessage === "SUCCESS") {
          toast.success("Update successfull!", { position: "top-center" });
          // remove this and do it better, we need to remove the row of the delete object thats it
          window.location.reload(false);
          setShowDeleteModal(false);
        }
      })
      .catch((error) => {
        errorToast(error?.response?.data?.Comments);
        console.log(error);
      });
  };

  useEffect(() => {
    //updating the table data based on the search terms.
    let params = {
      page: pageNumber,
      limit: 10,
      orderBy: sortBy.headerName,
      order: sortBy.order ? "ASC" : "DESC",
    };
    if (searchName !== null) {
      params.name = searchName;
    }
    if (searchEmail !== null) {
      params.email = searchEmail;
    }

    params.isActive = isActive;
    axios
      .get(`users/index2`, { params })
      .then((response) => {
        setTotalRecords(response.data.Result.total);
        const returnedRows = response.data.Result.users;
        console.log("Received", response.data.Result.users);
        setTotalPages(Math.ceil(response.data.Result.total / rowsPerPage));
        let actualRows = addActions(returnedRows);
        setTableData(actualRows);
      })
      .catch((error) => {
        console.log(error);
        errorToast(error?.response?.data?.Comments);
      });
  }, [searchName, searchEmail, pageNumber, sortBy, showUploadFile, isActive]);

  useEffect(() => {
    setPageNumber(1);
  }, [searchName, searchEmail]);

  const nextPage = () => {
    if (pageNumber !== totalPages) setPageNumber(pageNumber + 1);
  };
  const prevPage = () => {
    if (pageNumber !== 1) setPageNumber(pageNumber - 1);
  };

  const getPageButtons = (noOfButtons) => {
    let buttonList = [];
    if (true) {
      let printed = 0;
      for (
        let i = Math.floor((pageNumber - 1) / noOfButtons) * noOfButtons + 1;
        printed < noOfButtons && i <= totalPages;
        i++
      ) {
        buttonList.push(
          <button
            className={`px-3 ${
              i === pageNumber
                ? " bg-green-500 text-white text-sm rounded-xl"
                : "text-gray-600 text-sm"
            }`}
            onClick={() => {
              setPageNumber(i);
            }}
          >
            {i}
          </button>
        );
        printed++;
      }
    }
    return buttonList;
  };

  const applyFilter = () => {};

  return (
    <div className="m-3 h-screen">
      <MasterDataDelete
        show={showDeleteModal}
        object={deleteObject}
        onClose={(e) => {
          setShowDeleteModal(false);
        }}
        msg={deleteObject?.isActive == 1 ? "Delete" : "Reactivate"}
        onYes={deleteUsers}
      />
      <MasterDataAddCSV
        show={showUploadFile}
        onClose={(e) => {
          setShowUploadFile(false);
        }}
        sampleFileDownloadPath={"/sampleVariety.csv"}
        fileUploadPath={"Users/file"}
      />
      {/* {console.log(totalPages)} */}

      <div className="flex justify-between overflow-wrap align-middle">
        <div name="IconAndPageTitle" className="bg-white flex ml-3">
          <span>
            <UsersIcon className="my-3 mx-1 h-6 w-6 mr-1" />
          </span>
          <span className="text-lg mt-3 text-center align-center">Users </span>
          <span className="mt-5 text-xs ml-2">({totalRecords} records)</span>
        </div>
        <div name="Add buttons" className="flex flex-wrap gap-x-10">
          <CustomButton
            onClick={() => {
              history.push("/add");
            }}
            btnName="Add"
            icon={<DocumentAddIcon className="h-5 w-5 mr-1" />}
          />
          {/* <CustomButton
            onClick={() => {
              setShowUploadFile(true);
            }}
            btnName="CSV"
            icon={<CloudUploadIcon className="h-5 w-5 mr-1" />}
          /> */}
        </div>
      </div>
      <div
        name="FilterSection"
        className="flex  gap-x-2 ml-3 bg-white w-11/12 mb-4"
      >
        <div className="mt-6 flex gap-x-2 w-full">
          <div className="w-full">
            <CustomInputBox
              title="Search by name"
              labelClass="mt-2 hidden"
              valueSetter={setSearchName}
              value={searchName}
            />
          </div>

          <div className="w-full">
            <CustomInputBox
              title="Search by email"
              labelClass="mt-2 hidden"
              valueSetter={setSearchEmail}
              value={searchEmail}
            />
          </div>

          <div className="w-full">
            <CustomDropDownSearchSelect
              label={"User "}
              labelDisable={true}
              placeholder={"User Type"}
              items={["Active Users", "Not Active Users"]}
              selected={
                // if(isActive===1)
                isActive === 1
                  ? "Active Users"
                  : isActive === 0
                  ? "Not Active Users"
                  : null
              }
              handler={(value) => {
                console.log(value);
                if (value == "Active Users") setIsActive(1);
                else if (value == "Not Active Users") setIsActive(0);
                else setIsActive(2);
              }}
            />
          </div>

          {/* <CustomButton btnName="Filter" className="p-2 mt-12 bg-green-500 hover:bg-green-700 text-sm " onClick={applyFilter} /> */}
        </div>
      </div>
      <div
        name="Table"
        className="bg-white ml-3 mt-4 overflow-hidden"
        // style={{ height: "420px" }}
      >
        <CustomTable
          rows={currentTableData}
          headers={tableHeaders}
          sortByHandler={setSortBy}
        />
      </div>
      {
        //show the table navigation buttons only if the table has more than 0 records.
        currentTableData.length > 0 ? (
          <div className="text-sm w-max mx-auto text-center flex mt-1">
            <button
              onClick={prevPage}
              className="border-2 text-gray-600 rounded-xl border-black p-1 w-6 h-6 mx-3"
            >
              <ChevronLeftIcon />
            </button>
            <div className="mx-2 mt-1 w-28">
              {getPageButtons(tablePageNumbersShown)}
            </div>
            <button
              onClick={nextPage}
              className="border-2  text-gray-600 rounded-xl border-black p-1 w-6 h-6 mx-3"
            >
              <ChevronRightIcon />
            </button>
          </div>
        ) : null
      }
    </div>
  );
};

export default UsersHome;
