import { React, useEffect, useState } from "react";
import { useLocation, Redirect } from "react-router-dom";
import { EyeIcon } from "@heroicons/react/outline";
import axios from "../../../axios";
import { errorToast } from "../../../util/toast";
import CustomInputBox from "../../../components/atoms/CustomInputBox";

const PurchaseDetailDisplay = () => {
  const location = useLocation();
  const [initialData, setInitialData] = useState(
    JSON.parse(location.state).data
  );
  const [data, setData] = useState(null);

  useEffect(() => {
    if (initialData.id !== undefined)
      axios
        .get(`purchaseDetail/${initialData.id}`)
        .then((response) => {
          console.log("Result is", response.data.Result.PurchaseDetail);
          setData(response.data.Result.PurchaseDetail);
        })
        .catch((error) => {
          console.log(error);
          errorToast(error?.message);
        });
  }, [initialData]);

  const getDateString = (date) => {
    let year = date.getFullYear();
    let month = date.getMonth() + 1;
    month = month < 10 ? "0" + month : month;
    let day = date.getDate();
    day = day < 10 ? "0" + day : day;
    return day + "/" + month + "/" + year;
  };
  const getItemName = (obj, prop1, prop2) => {
    return obj[prop2];
  };

  if (!initialData) return <Redirect to="/" />;
  return data !== null ? (
    <div className="overflow-hidden m-3 p-3 ">
      <div className="flex">
        <span>
          <EyeIcon className="h-5 w-5 transform text-black mt-4 mr-1" />
        </span>
        <span className=" py-4 text-gray-700 text-base font-bold text-left">
          Display Purchase Detail
        </span>
      </div>
      <div className="flex flex-wrap">
        <div className="">
          <div className=" grid grid-cols-3 w-2/3 gap-x-5 gap-x-3 mb-4">
            {/* <CustomDisplayBox title="ID" value={data.id} className=" pl-2"/> */}
            <CustomInputBox
              title={"Farmer"}
              disabled={true}
              borderDisable={true}
              value={getItemName(
                data.FarmerVariety.FarmerMaster,
                "identifier",
                "name"
              )}
            />
            <CustomInputBox
              title={"Purchaser Name"}
              disabled={true}
              borderDisable={true}
              value={data?.purchaserName}
            />
            <CustomInputBox
              title={"Mandi"}
              disabled={true}
              borderDisable={true}
              value={getItemName(
                data.FarmerVariety.FarmerMaster.MandiMaster,
                "identifier",
                "name"
              )}
            />
            <CustomInputBox
              title={"Village"}
              disabled={true}
              borderDisable={true}
              value={getItemName(
                data.FarmerVariety.FarmerMaster.VillageMaster,
                "identifier",
                "name"
              )}
            />

            <CustomInputBox
              title={"Kaccha Aarthi Name"}
              disabled={true}
              borderDisable={true}
              value={getItemName(data.KacchaAarthi, "identifier", "name")}
            />
            <CustomInputBox
              title={"Pakka Aarthi Name"}
              disabled={true}
              borderDisable={true}
              value={getItemName(data.PakkaAarthi, "identifier", "name")}
            />

            <CustomInputBox
              title={"Slip Number"}
              disabled={true}
              borderDisable={true}
              value={data.slipNumber}
            />
            <CustomInputBox
              title={"Slip Date"}
              disabled={true}
              borderDisable={true}
              value={
                data.slipDate === null
                  ? "[unknown]"
                  : getDateString(new Date(data.slipDate))
              }
            />
            <CustomInputBox
              title={"Slip Qty"}
              disabled={true}
              borderDisable={true}
              value={data.slipQuantity}
            />
            <CustomInputBox
              title={"J-Form Date"}
              disabled={true}
              borderDisable={true}
              value={
                data.jFormDate === null
                  ? "[unknown]"
                  : getDateString(new Date(data.jFormDate))
              }
            />
            <CustomInputBox
              title={"Purchased"}
              disabled={true}
              borderDisable={true}
              value={data.isPurchased === 1 ? "Yes" : "No"}
            />
            <CustomInputBox
              title={"Name on J-Form"}
              disabled={true}
              borderDisable={true}
              value={data.jFormName}
            />
            <CustomInputBox
              title={"J-Form Number"}
              disabled={true}
              borderDisable={true}
              value={data.jFormNumber}
            />
            <CustomInputBox
              title={"J-Form Quantity"}
              disabled={true}
              borderDisable={true}
              value={
                data.jFormQuantity === null ? "[unknown]" : data.jFormQuantity
              }
            />
          </div>
          {data.isPurchased == 1 || data.isPurchased === null ? (
            <>
              {data.iForm != null &&
                JSON.parse(data.iForm).map((element, varIndex) => {
                  return (
                    <div className="w-2/3 flex">
                      <CustomInputBox
                        title="I-Form Number"
                        value={element.iFormNumber}
                        disabled={true}
                        borderDisable={true}
                      />
                      <CustomInputBox
                        title={"I-Form Date"}
                        disabled={true}
                        borderDisable={true}
                        value={
                          element.iFormDate === null
                            ? "[unknown]"
                            : getDateString(new Date(element.iFormDate))
                        }
                      />
                      <CustomInputBox
                        title="I-Form Quantity"
                        value={element.iFormQuantity}
                        disabled={true}
                        borderDisable={true}
                      />
                    </div>
                  );
                })}
              <br />
              {data.po != null &&
                JSON.parse(data.po).map((element, varIndex) => {
                  return (
                    <div className="w-2/3 flex">
                      <CustomInputBox
                        title="PO Number"
                        value={element.poNumber}
                        disabled={true}
                        borderDisable={true}
                      />
                      <CustomInputBox
                        title={"PO Date"}
                        disabled={true}
                        borderDisable={true}
                        value={
                          element.poDate === null
                            ? "[unknown]"
                            : getDateString(new Date(element.poDate))
                        }
                      />
                    </div>
                  );
                })}
              <br />
              {data.bill != null &&
                JSON.parse(data.bill).map((element, varIndex) => {
                  return (
                    <div className="w-2/3 flex">
                      <CustomInputBox
                        title="Bill Number"
                        value={element.billNumber}
                        disabled={true}
                        borderDisable={true}
                      />
                      <CustomInputBox
                        title={"Bill Date"}
                        disabled={true}
                        borderDisable={true}
                        value={
                          element.billDate === null
                            ? "[unknown]"
                            : getDateString(new Date(element.billDate))
                        }
                      />
                      <CustomInputBox
                        title="Bill Quantity"
                        value={element.billQuantity}
                        disabled={true}
                        borderDisable={true}
                      />
                    </div>
                  );
                })}
            </>
          ) : (
            <>
              <CustomInputBox
                title="Reason"
                value={data.reason}
                disabled={true}
                borderDisable={true}
              />
            </>
          )}
        </div>
      </div>
      <div className="flex w-max gap-x-10"></div>
    </div>
  ) : null;
};
export default PurchaseDetailDisplay;
