import React, { useState } from "react";
import { useLocation, Redirect, useHistory } from "react-router-dom";
import { CustomButton } from "../../../components/atoms/CustomButton";
import CustomDisplayBox from "../../../components/atoms/CustomDisplayBox";
import CustomInputBox from "../../../components/atoms/CustomInputBox";
import axios from "../../../axios";
import { toast } from "react-toastify";
import { PencilIcon } from "@heroicons/react/outline";
import { errorToast } from "../../../util/toast";

const ZoneMasterEdit = () => {
  const location = useLocation();
  const history = useHistory();
  const { data } = JSON.parse(location.state);
  const [name, setName] = useState(data?.name.value);
  const [error, setError] = useState(null);

  if (!location.state) {
    return <Redirect to="/" />;
  }

  const editZone = () => {
    if (name.trim().length === 0) {
      errorToast("please specify the name");
    } else {
      axios
        .put(`zoneMasters/${data.id}`, { name: name })
        .then((response) => {
          if (response?.data?.ResponseMessage === "SUCCESS") {
            toast.success("Update successful!", { position: "top-center" });
            history.goBack();
          }
        })
        .catch((error) => {
          errorToast(error?.response?.data?.Comments);
          console.log(error);
        });
    }
  };

  return (
    <div className="overflow-hidden m-3 p-3">
      <div className="flex">
        <span>
          <PencilIcon className="h-5 w-5 transform text-black mt-4 mr-1" />
        </span>
        <span className=" py-4 text-gray-700 text-base font-bold text-left">
          Edit zone master
        </span>
      </div>
      <div className="flex flex-wrap justify-left">
        <div className="w-1/2">
          {/* <p className="mt-4 py-4 text-gray-700 text-left text-base">You can edit the name of the zone.</p> */}
          <CustomInputBox
            title="Name"
            value={name}
            className="w-1/2"
            valueSetter={setName}
          />
          <div className="flex mt-8 justify-between">
            <CustomButton
              btnName="Cancel"
              className="p-2 bg-gray-500 hover:bg-gray-700 text-lg"
              onClick={() => {
                history.goBack();
              }}
            />
            <CustomButton
              btnName="Update"
              className="p-2 bg-green-500 hover:bg-green-700 text-lg"
              onClick={editZone}
            />
          </div>
        </div>
      </div>
      <div className="text-xl text-red-500">{error}</div>
      <div className="flex w-max gap-x-10"></div>
    </div>
  );
};
export default ZoneMasterEdit;
