import { React, useEffect, useState } from "react";
import { useLocation, Redirect, Link } from "react-router-dom";
import axios from "../../../axios";
import Switch from "react-switch";

import {
  EyeIcon,
  PencilIcon,
  TrashIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
} from "@heroicons/react/solid";
import { toast } from "react-toastify";
import CustomTable from "../../../components/atoms/CustomTable";
import CustomToggleSwitch from "../../../components/atoms/CustomToggleSwitch";
import { useReducer } from "react";
import CustomInputBox from "../../../components/atoms/CustomInputBox";

const RolesDisplay = () => {
  const [pop, setPop] = useState(null);
  const [mandi, setMandi] = useState(null);
  const [currentTableData, setTableData] = useState([]);
  const rowsPerPage = 10000;
  const [totalRecords, setTotalRecords] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [sortBy, setSortBy] = useState({
    headerName: "id",
    order: false,
  });
  const location = useLocation();

  const { data } = JSON.parse(location.state);
  const tablePageNumbersShown = 1;
  console.log(data.roleName);

  const tableHeaders = [
    { displayName: "id", property: "id", sortable: true, show: false },
    {
      displayName: "Module",
      property: "moduleName",
      sortable: true,
      show: true,
    },
    {
      displayName: "Module Key",
      property: "module",
      sortable: true,
      show: true,
    },
    {
      displayName: "Module Read",
      property: "moduleRead",
      sortable: false,
      show: true,
    },
    {
      displayName: "Module Create",
      property: "moduleCreate",
      sortable: false,
      show: true,
    },
    {
      displayName: "Module Update",
      property: "moduleUpdate",
      sortable: false,
      show: true,
    },
    {
      displayName: "Module Delete",
      property: "moduleDelete",
      sortable: false,
      show: true,
    },
  ];

  const nextPage = () => {
    if (pageNumber !== totalPages) setPageNumber(pageNumber + 1);
  };
  const prevPage = () => {
    if (pageNumber !== 1) setPageNumber(pageNumber - 1);
  };

  const getPageButtons = (noOfButtons) => {
    //   console.log("Here" , noOfButtons , pageNumber ,totalPages)
    let buttonList = [];
    if (true) {
      let printed = 0;
      for (
        let i = Math.floor((pageNumber - 1) / noOfButtons) * noOfButtons + 1;
        printed < noOfButtons && i <= totalPages;
        i++
      ) {
        buttonList.push(
          <button
            className={`px-3 ${i === pageNumber
              ? " bg-green-500 text-white text-sm rounded-xl"
              : "text-gray-600 text-sm"
              }`}
            onClick={() => {
              setPageNumber(i);
            }}
          >
            {i}
          </button>
        );
        printed++;
      }
    }
    // console.log(buttonList)
    return buttonList;
  };

  const fun = (e) => {
    let moduleName = "";

    if (e.label === "C") moduleName = "moduleCreate";
    else if (e.label === "R") moduleName = "moduleRead";
    else if (e.label === "U") moduleName = "moduleUpdate";
    else moduleName = "moduleDelete";

    axios
      .post("/authorization/authorize", {
        role: e.obj.Role.roleName,
        tableName: e.obj.moduleName.value,
        authority: moduleName,
        access: e.value ? 1 : 0,
      })
      .then((res) => { })
      .catch((e) => console.log(e));
  };

  // const CustomToggleSwitch = ({label , obj, checked, onChange , keys}) => {

  //   const [check ,setcheck] = useState(checked)
  //   console.log(keys)
  //     return (
  //     <div className="flex">
  //     <div className="text-sm font-light">{keys}</div>
  //       <Switch
  //         className="-mt-1 ml-3"
  //         checked={check}
  //         onChange={(value) => {
  //             onChange({value:value , obj:obj , label:label});
  //             setcheck(value)
  //         }}
  //       />
  //   </div>)
  // }

  const addActions = (objects) => {
    //adds the actions buttons to the table rows. this function does other things too.
    const newObjects = [];
    objects.map((obj, ind) => {
      let R = obj.moduleRead;
      let C = obj.moduleCreate;
      let U = obj.moduleUpdate;
      let D = obj.moduleDelete;

      let b = obj.identifier;
      obj.module = { value: obj.module, show: true, order: 1 };
      obj.moduleName = { value: obj.moduleName, show: false, order: 2 };
      obj.moduleRead = {
        value: (
          <CustomToggleSwitch
            key={b + 10000}
            label={"R"}
            obj={obj}
            checked={R}
            onChange={fun}
          />
        ),
        show: true,
        order: 3,
      };
      obj.moduleCreate = {
        value: (
          <CustomToggleSwitch
            key={b + 10001}
            label={"C"}
            obj={obj}
            checked={C}
            onChange={fun}
          />
        ),
        show: true,
        order: 4,
      };
      obj.moduleUpdate = {
        value: (
          <CustomToggleSwitch
            key={b + 10002}
            label={"U"}
            obj={obj}
            checked={U}
            onChange={fun}
          />
        ),
        show: true,
        order: 5,
      };
      obj.moduleDelete = {
        value: (
          <CustomToggleSwitch
            key={b + 10003}
            label={"D"}
            obj={obj}
            checked={D}
            onChange={fun}
          />
        ),
        show: true,
        order: 6,
      };

      newObjects.push(obj);
    });

    return newObjects;
  };

  useEffect(() => {
    // setTableData([])

    let params = {
      page: pageNumber,
      limit: 10000,
      orderBy: sortBy.headerName,
      order: sortBy.order ? "ASC" : "DESC",
    };

    axios
      .get(`/authorization/${data.roleName}`, { params })
      .then((response) => {
        console.log(response.data.Result.permissions);
        setTotalRecords(response.data.Result.length);
        const returnedRows = response.data.Result.permissions;
        setTotalPages(Math.ceil(response.data.Result.total / rowsPerPage));
        let actualRows = addActions(returnedRows);
        setTableData(actualRows);
      });
  }, [pageNumber, sortBy]);

  if (!JSON.parse(location.state)) return <Redirect to="/" />;

  return (
    <div className="overflow-hidden m-3 p-3">
      <div className="flex">
        <span>
          <EyeIcon className="h-5 w-5 transform text-black mt-4 mr-1" />
        </span>
        <span className=" py-4 text-gray-700 text-base font-bold text-left">
          Role - {data?.roleName}
        </span>
      </div>

      <div
        name="Table"
        className="bg-white ml-3 mt-4 mb-6 overflow-hidden"
      // style={{ height: "470px" }}
      >
        <CustomTable
          rows={currentTableData}
          headers={tableHeaders}
          sortByHandler={setSortBy}
        />
      </div>
      {console.log(currentTableData, "DATA")}
      {/* {
        //show the table navigation buttons only if the table has more than 0 records.
        currentTableData.length > 0 ? (
          <div className="text-sm w-max mx-auto text-center flex mt-1">
            <button
              onClick={prevPage}
              className="border-2 text-gray-600 rounded-xl border-black p-1 w-6 h-6 mx-3"
            >
              <ChevronLeftIcon />
            </button>
            <div className="mx-2 mt-1 w-28">
              {getPageButtons(tablePageNumbersShown)}
            </div>
            <button
              onClick={nextPage}
              className="border-2  text-gray-600 rounded-xl border-black p-1 w-6 h-6 mx-3"
            >
              <ChevronRightIcon />
            </button>
          </div>
        ) : null
      } */}
    </div>
  );
};
export default RolesDisplay;
