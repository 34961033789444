import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import YieldMasterHome from "./YieldMasterHome";
import YieldMasterEdit from "./YieldMasterEdit";
import YieldMasterDisplay from "./YieldMasterDisplay";
import YieldMasterAdd from "./YieldMasterAdd";
import MasterDataDelete from "../common/MasterDataDelete";

const YieldMasterRoutes = () => {
    return (
        <BrowserRouter basename="/home/masterData/YieldMaster/">
            <Switch>
                <Route path="/" component={YieldMasterHome} exact />
                <Route path="/add" component={YieldMasterAdd} />
                <Route path="/edit/:id" component={YieldMasterEdit} />
                <Route path="/delete/:id" component={MasterDataDelete   } />
                <Route path="/display" component={YieldMasterDisplay} />
                <Route component={() => (<Redirect to="/" />)} />
            </Switch>
        </BrowserRouter>
    );
}

export default YieldMasterRoutes;