import React, { useState } from 'react';
import { useHistory } from 'react-router';
import { CustomButton } from '../../../components/atoms/CustomButton';
import axios from '../../../axios';
import { DocumentAddIcon } from '@heroicons/react/outline';
import CustomInputBox from '../../../components/atoms/CustomInputBox';
import { successToast, errorToast } from '../../../util/toast';
import { validateUomAdd } from '../common/validations';

const UomAdd = () => {

    const history = useHistory();
    
    const [newUom, setNewUom] = useState([{ uom: "" }]);
    const valueSetter = (index, newValue) => {
        let uom = [...newUom];
        uom[index].uom = newValue;
        setNewUom(uom);
    }

    const addUom = () => {
        if(validateUomAdd(newUom))
        axios.post("uom/", newUom[0])
            .then(response => {
                if (response?.data?.ResponseMessage === "SUCCESS") {
                    successToast("New Uom added!");
                    history.goBack();
                }
            })
            .catch(error => {
                errorToast(error?.response?.data?.Comments);
                console.log(error);
            })
    }

    return (
        <div className="p-3 m-3">
            <div className="flex mb-7">
                <DocumentAddIcon className="h-5 w-5text-black mt-3 mr-1" />
                <div className="text-base pt-3 font-bold text-gray-800">Add UoM</div>
            </div>
            <div>
                {
                    newUom.map((value, index) => {
                        return (
                            <div className="mb-4 flex flex-wrap w-1/2">
                                <div className="flex w-full">
                                    <div className="w-full">
                                        <CustomInputBox title="New Unit of Measurement" value={newUom[index].uom} valueSetter={(value) => { valueSetter(index, value) }} />
                                    </div>
                                    {/* {

                                       newUom.length-1 !== index  ? 
                                       (<span onClick={() => { setNewUom(newUom.filter((v, i) => { return i !== index; })) }} className="h-5 w-5 ml-2 mt-8 text-gray-500 hover:text-red-500 hover:p-1"><TrashIcon /></span>)
                                       :(<span className="h-4 w-4 ml-2 mt-8 text-white rounded-md bg-green-500" onClick={() => { setNewUom([...newUom, { uom: "" }]) }} ><PlusIcon /></span>)
                                        // :<CustomButton className=" mt-8 ml-3 text-sm p-1 bg-green-500" btnName={<span className="h-3 w-3"><PlusIcon /></span>} onClick={() => { setNewUom([...newUom, { name: "" }]) }} />
                                    } */}
                                    </div>
                                </div>
                        )
                    })
                }
            </div>
            <div className="flex w-1/2 justify-between">
                <CustomButton btnName="Cancel" bgColor="bg-gray-400" onClick={() => { history.goBack() }} />
                <CustomButton btnName="Submit"  onClick={addUom} />
            </div>
        </div>);
}
export default UomAdd;
