import React from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { CustomButton } from "../atoms/CustomButton";
import axios from "../../axios";
import { useLocation, useHistory } from "react-router-dom";

import { errorToast, successToast } from "../../util/toast";
// import { useParams } from 'react-router-dom';

const initialValues = { otp: "" };

const validationSchema = Yup.object({
  otp: Yup.string().required("OTP is required"),
});

const OtpForm = ({match}) => {
  // const { email } = useParams();
  const location = useLocation();
  const history =  useHistory();
  console.log(location.state.email);

  // console.log(location.pathname);

  // useEffect(() => {
  //     const currentPath = location.pathname;
  //     const searchParams = new URLSearchParams(location.search).get('email');
  //     console.log(searchParams);
  // }, [location])

  function otp(values) {
    // const searchParams = new URLSearchParams(location.search).get("email");
    // if (!searchParams) {
    //   errorToast("kindly request an otp");
    // }
    // console.log(searchParams);
    axios
      .post("verifyOTP", {
        email:location.state.email,
        otp: values.otp,
      })
      .then(function (response) {
        console.log(response.data.Comments);
        successToast(response.data.Comments ,3000);
        history.push('/home');
      })
      .catch(function (error) {
        console.log(error?.response?.data?.Comments);
        errorToast(error?.response?.data?.Comments);
      });
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={(values) => {
        otp(values);
      }}
    >
      <div className="">
        <div className="flex flex-col sm:max-w-md  mx-auto bg-white  px-6 pt-5 pb-5 rounded-3xl border-t-8 border-green-400">
          <span className=" font-robo  text-large font-bold text-center pt-6 pb-6 text-green-500">
            Enter your otp
          </span>
          <Form className="flex flex-col space-y-4">
            <div className="flex flex-col">
              <Field
                className="border border-gray-400 rounded-md pl-4 py-2 font-light focus:outline-none hover:border-green-400"
                name="otp"
                type="text"
                placeholder="Otp"
              />
              <div className=" font-robo text-red-700 text-xs">
                <ErrorMessage name="otp" />
              </div>
            </div>
            <div className="text-center pb-6 pt-4">
              <CustomButton
                type="submit"
                btnName="submit"
                className="bg-green-500 hover:bg-green-600 p-2"
              />
            </div>
          </Form>
        </div>
      </div>
    </Formik>
  );
};

export default OtpForm;
