import React, { useEffect, useState } from "react";
import { Redirect, Route, Switch } from "react-router";
import Login from "../pages/Login";
import ForgotPassword from "../pages/ForgotPassword";
import Otp from "../pages/Otp";
import ResetPassword from "../pages/ResetPassword";
import Homepage from "../pages/HomePage";
import Error from "../pages/Error";
import { BrowserRouter } from "react-router-dom";
import CropLifeCycleRoutes from "../pages/Transaction Data/CropLifeCycle/routes";
import ChangePassword from '../pages/ChangePassword'

const loggedIn = () => {
  console.log(localStorage.getItem("cfUserToken"));
  return localStorage.getItem("cfUserToken") !== null;
};

function AuthRoutes() {
  const [isAuthenticated, setAuthenticated] = useState(
    localStorage.getItem("cfUserToken") !== null
  );
  useEffect(() => {
    setAuthenticated(loggedIn());
  }, [localStorage]);

  return (
    <BrowserRouter>
      <Switch>
        <PrivateLoginRoute path="/" exact component={Login} />
        {/* <Route path="/home/transactionData/cropLifeCycle" exact component={CropLifeCycleRoutes} /> */}
        <Route path="/forgotpassword" exact component={ForgotPassword} />
        <Route path="/otp" component={Otp} exact />
        <Route
          path="/reset-password/:email/:token"
          exact
          component={ResetPassword}
        />

        <Route
          path="/change-password"
          exact
          component={ChangePassword}
        />
        {/* <Route path="/resetpassword" exact component={ResetPassword} /> */}
        {/* <PrivateRoute path="/home/transactionData/allVisits" component={Error} /> */}
        <PrivateRoute path="/home" component={Homepage} />
        <Route component={Error} />
      </Switch>
    </BrowserRouter>
  );
}

const PrivateRoute = ({ component: Component, ...rest }) => {
  console.log(loggedIn());
  return <Route
    {...rest}
    render={(props) =>

      loggedIn() ? (
        <Component {...props} />
      ) : (
        <Redirect to={{ pathname: "/" }} />
      )
    }
  />
};

const PrivateLoginRoute = ({ component: Component, ...rest }) => {
  console.log(loggedIn());
  return <Route
    {...rest}
    render={(props) =>

      loggedIn() ? (
        <Redirect to={{ pathname: "/home" }} />
      ) : (
        <Component {...props} />
      )
    }
  />
};

export default AuthRoutes;
