import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import ProcessTypesHome from "./ProcessTypesHome";
import ProcessTypesDisplay from "./ProcessTypesDisplay";
import ProcessTypesAdd from "./ProcessTypesAdd";

const ProcessTypesRoutes = () => {
    return (
        <BrowserRouter basename="/home/masterData/processTypesMaster/">
            <Switch>
                <Route path="/" component={ProcessTypesHome} exact />
                <Route path="/add" component={ProcessTypesAdd} />
                <Route path="/display" component={ProcessTypesDisplay} />
                <Route component={() => (<Redirect to="/" />)} />
            </Switch>
        </BrowserRouter>
    );
}

export default ProcessTypesRoutes;