import React, { useEffect, useState } from "react";
import Select from "react-select";

const CustomDropDownSearchSelect = ({
  items,
  placeholder,
  label,
  labelDisable,
  multi,
  disabled,
  handler,
  selected,
}) => {
  const [currentOptions, setOptions] = useState([]);
  useEffect(() => {
    let temp = [];
    if (items.length === 0) {
      temp.push({ value: null, label: `Any ${label}`, isDisabled: true });
      //handler(null); //please dont enable this line.
      setOptions(temp);
    } else {
      temp.push({ value: null, label: `Any ${label}` });
      items.forEach((element) => {
        temp.push({ value: element, label: element });
      });
      setOptions(temp);
    }
  }, [items, label]);

  return (
    <div className="mb-2" style={{minWidth:"150px" }}>
      <span
        className={`text-sm font-base text-gray-700 ${
          labelDisable ? "hidden" : "visible"
        } `}
      >
        {label}
      </span>
      <Select
        isMulti={multi}
        isDisabled={disabled || items.length === 0 ? true : false}
        value={
          multi
            ? currentOptions.filter((option) => {
                return selected.indexOf(option.label) !== -1;
              })
            : currentOptions.filter((option) => {
                return option.label === selected;
              })
        }
        className="text-sm w-full"
        placeholder={items.length !== 0 ? placeholder : `No ${label}`}
        onChange={(val) => {
          if (!multi) handler(val.value);
          else
            handler(
              val.map((element) => {
                return element.value;
              })
            );
        }}
        options={currentOptions}
      />
    </div>
  );
};
export default CustomDropDownSearchSelect;
