import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { CustomButton } from "../../../components/atoms/CustomButton";
import axios from "../../../axios";
import { DocumentAddIcon, PlusIcon, TrashIcon } from "@heroicons/react/outline";
import CustomInputBox from "../../../components/atoms/CustomInputBox";
import { successToast, errorToast } from "../../../util/toast";
import CustomDropDownSearchSelect from "../../../components/atoms/CustomDropDownSearchSelect";
import { validateFarmerAdd } from "../../Master Data/common/validations";
import CustomDateSelector from "../../../components/atoms/CustomDateSelector";

const getItemName = (obj, prop1, prop2) => {
  return obj[prop2];
};
//returns the first object in the list whose property matches the target
const searchObject = (items, target, property) => {
  return items.filter((element) => {
    return element[property] === target;
  })[0];
};

const FarmerMasterAdd = () => {
  const history = useHistory();
  const makeDataObject = () => {
    return {
      year: new Date().getFullYear(),
      popId: null,
      pakkaAarthiId: null,
      kacchaAarthiId: null,
      address: "",
      ownLand: null,
      leasedLand: "0",
      leasedLandowner: "",
      totalLand: 0,
      cycle: "",
      seedSource: null,
      seedTreatment: 0,
      seedTreatmentChemical: "",
      name: "",
      fatherName: "",
      levelingArea: null,
      date: new Date().getFullYear(),
      remarks: "",
      village: null,
      mandiId: null,
      varietiesList: [{ landUsed: 0, varietyId: null }],
      Jform: [{ JformAcre: null, JformName: "" }],
    };
  };

  const makeJformObject = () => {
    return {
      JformAcre: null,
      JformName: "",
    };
  };
  const [newFarmer, setNewFarmer] = useState([makeDataObject()]);
  const [newJform, setNewJform] = useState([makeJformObject()]);

  const [varietyList, setVarietyList] = useState([]);
  const [mandiList, setMandiList] = useState(null);
  const [villageList, setVillageList] = useState(null);
  const [farmerList, setFarmerList] = useState(null);
  const [aarthiList, setAarthiList] = useState(null);
  const [popList, setPopList] = useState(null);
  const [seedTreatment, setSeedTreatment] = useState(true);
  const [leasedLand, setLeasedLand] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);

  useEffect(() => {
    axios
      .get(`aarthiMaster/load`)
      .then((response) => {
        setAarthiList(response.data.Result.AarthiMaster);
        return axios.get(`mandiMaster/load`);
      })
      .then((response) => {
        setMandiList(response.data.Result.MandiMaster);
        return axios.get(`pop`);
      })
      .then((response) => {
        setPopList(response.data.Result.Pop);
        return axios.get("villageMasters/load");
      })
      .then((response) => {
        // console.log(response.data.Result)
        setVillageList(response.data.Result.villageMaster);
        return axios.get("farmerMaster/load");
      })
      .then((response) => {
        setFarmerList(response.data.Result.FarmerMaster);
        return axios.get("varietyMaster/load");
      })
      .then((response) => {
        setVarietyList(response.data.Result.VarietyMaster);
      })
      .catch((error) => {
        console.log(error);
        errorToast(error?.response?.data?.Comments);
      });
  }, []);

  const valueSetter = (index, property, newValue) => {
    if (newValue !== null) {
      let Farmer = [...newFarmer];
      Farmer[index][property] = newValue;
      setNewFarmer(Farmer);
    }
  };
  const varietiesValueSetter = (farmerIndex, varietyIndex, property, value) => {
    if (value !== null) {
      let Farmer = [...newFarmer];
      Farmer[farmerIndex].varietiesList[varietyIndex][property] = value;
      setNewFarmer(Farmer);
    }
  };

  const jFormValueSetter = (farmerIndex, jFormIndex, property, value) => {
    if (value !== null) {
      let Farmer = [...newFarmer];
      Farmer[farmerIndex].Jform[jFormIndex][property] = value;
      setNewFarmer(Farmer);
    }
  };

  const addFarmer = () => {
    const VarietiesLists = [];
    const jFormLists = newFarmer[0].Jform;
    newFarmer.forEach((element) => {
      console.log(
        element.village,
        searchObject(villageList, element.village, "id")?.name
      );
      VarietiesLists.push(element.varietiesList);
    });

    const tempFarmer = newFarmer.map((element) => {
      const v = { ...element };
      console.log(v);
      // v.village = searchObject(villageList ,  element.village ,'id').name;
      // delete v.varietiesList;
      v.totalLand = Number(v.ownLand) + Number(v.leasedLand);
      return v;
    });
    if (validateFarmerAdd(tempFarmer)) {
      setIsSubmit(true);
      successToast("Your request is being processed");
      axios
        .post("farmerMaster/", tempFarmer) //removing the varietiesList property from all the new farmer data. Since it is not required.
        .then((response) => {
          if (response?.data?.ResponseMessage === "SUCCESS") {
            let VarietiesList = [];
            response.data.Result.FarmerMaster.forEach((farmer, farmerIndex) => {
              VarietiesLists[farmerIndex].forEach((element) => {
                element.farmerId = farmer.id;
              });
              VarietiesList.push(...VarietiesLists[farmerIndex]);
            });
            return axios.post(`farmerVariety`, VarietiesList).then((res) => {
              if (res?.data?.ResponseMessage === "SUCCESS") {
                const jFormBody = jFormLists.map((element) => ({
                  farmerId: response.data.Result.FarmerMaster[0].id,
                  name: element.JformName,
                  acre: element.JformAcre,
                }));
                successToast("New Farmer added!");
                history.goBack();
                return axios.post(`farmerJform`, jFormBody);
              } else {
                console.log(res.data.ResponseMessage);
              }
            });
          } else {
            errorToast("An error occured");
            console.log(response.data.ResponseMessage);
          }
        })
        .then((response) => {
          if (response?.data?.ResponseMessage === "SUCCESS") {
            successToast("New Farmer added!");
            history.goBack();
          }
        })
        .catch((error) => {
          errorToast(error?.response?.data?.Comments);
          console.log(error);
          // history.goBack();
        });
    } else {
      setIsSubmit(false);
    }
  };
  const addVariety = (index) => {
    let Farmer = [...newFarmer];
    Farmer[index].varietiesList = [
      ...Farmer[index].varietiesList,
      { varietyId: null, landUsed: 0 },
    ];
    setNewFarmer(Farmer);
  };
  const removeVariety = (index, varietyIndex) => {
    let Farmer = [...newFarmer];
    Farmer[index].varietiesList = Farmer[index].varietiesList.filter(
      (element, i) => {
        return i !== varietyIndex;
      }
    );
    setNewFarmer(Farmer);
  };

  const addJform = (index) => {
    let Farmer = [...newFarmer];
    Farmer[index].Jform = [
      ...Farmer[index].Jform,
      { JformAcre: null, JformName: "" },
    ];
    setNewFarmer(Farmer);
  };

  const removeJform = (index) => {
    let Farmer = [...newFarmer];
    Farmer[index].Jform = Farmer[index].Jform.filter((element, i) => {
      return i !== index;
    });
    setNewFarmer(Farmer);
  };

  return (
    <div className="p-3 m-3">
      <div className="flex mb-7">
        <DocumentAddIcon className="h-5 w-5text-black mt-3 mr-1" />
        <div className="text-base pt-3 font-bold text-gray-800">Add Farmer</div>
      </div>
      <div>
        <ol style={{ listStyleType: "number" }}>
          {newFarmer.map((value, index) => {
            return (
              <li>
                <div className="mb-4 flex">
                  <div className="flex w-3/4">
                    <div className="flex-wrap flex gap-y-1 m-3 gap-x-2">
                      <CustomInputBox
                        title="Name"
                        value={value.name}
                        className="w-1/2"
                        valueSetter={(value) => {
                          valueSetter(index, "name", value);
                        }}
                      />
                      <div className="w-full">
                        <CustomDropDownSearchSelect
                          label={"PoP"}
                          items={
                            popList !== null
                              ? popList.map((value) => {
                                  // return value.pop;
                                  return getItemName(
                                    value,
                                    "identifier",
                                    "pop"
                                  );
                                })
                              : []
                          }
                          selected={
                            value.popId !== null &&
                            popList !== null &&
                            popList.length > 0
                              ? getItemName(
                                  popList.filter((element) => {
                                    return element.id === value.popId;
                                  })[0],
                                  "identifier",
                                  "pop"
                                )
                              : null
                          }
                          handler={(value) => {
                            if (value !== null)
                              valueSetter(
                                index,
                                "popId",
                                popList.filter((element) => {
                                  return (
                                    getItemName(
                                      element,
                                      "identifier",
                                      "pop"
                                    ) === value
                                  );
                                })[0].id
                              );
                            else {
                              valueSetter(index, "popId", null);
                            }
                          }}
                        />
                      </div>
                      <CustomInputBox
                        title="Father's Name"
                        value={value.fatherName}
                        className="w-1/2"
                        valueSetter={(value) => {
                          valueSetter(index, "fatherName", value);
                        }}
                      />
                      <div className="flex gap-x-3 w-full">
                        <div className="flex w-full">
                          <div className="w-1/2 mr-2">
                            <CustomDropDownSearchSelect
                              label={"Mandi"}
                              items={
                                mandiList !== null
                                  ? mandiList.map((value) => {
                                      return getItemName(
                                        value,
                                        "identifier",
                                        "name"
                                      );
                                    })
                                  : []
                              }
                              selected={
                                value.mandiId !== null &&
                                mandiList !== null &&
                                mandiList.length > 0
                                  ? getItemName(
                                      searchObject(
                                        mandiList,
                                        value.mandiId,
                                        "id"
                                      ),
                                      "identifier",
                                      "name"
                                    )
                                  : null
                              }
                              handler={(value) => {
                                if (value !== null)
                                  valueSetter(
                                    index,
                                    "mandiId",
                                    mandiList.filter((element) => {
                                      return (
                                        getItemName(
                                          element,
                                          "identifier",
                                          "name"
                                        ) == value
                                      );
                                    })[0].id
                                  );
                                else {
                                  valueSetter(index, "mandiId", null);
                                }
                              }}
                            />
                          </div>
                          <div className="w-1/2">
                            <CustomDropDownSearchSelect
                              label={"Village"}
                              items={
                                villageList !== null
                                  ? villageList
                                      .filter(
                                        (val) =>
                                          value.mandiId === val.MandiMaster.id
                                      )
                                      .map((value) => {
                                        return getItemName(
                                          value,
                                          "identifier",
                                          "name"
                                        );
                                      })
                                  : []
                              }
                              selected={
                                value.village !== null &&
                                villageList !== null &&
                                villageList.length > 0
                                  ? getItemName(
                                      searchObject(
                                        villageList,
                                        value.village,
                                        "id"
                                      ),
                                      "identifier",
                                      "name"
                                    )
                                  : null
                              }
                              handler={(value) => {
                                if (value !== null)
                                  valueSetter(
                                    index,
                                    "village",
                                    villageList.filter((element) => {
                                      return (
                                        getItemName(
                                          element,
                                          "identifier",
                                          "name"
                                        ) == value
                                      );
                                    })[0].id
                                  );
                                else {
                                  valueSetter(index, "village", null);
                                }
                              }}
                            />
                          </div>
                        </div>
                      </div>
                      <CustomInputBox
                        title="Address"
                        value={value.address}
                        className="w-1/2"
                        valueSetter={(value) => {
                          valueSetter(index, "address", value);
                        }}
                      />
                      <div className="w-full">
                        <CustomDropDownSearchSelect
                          label={"Cycle"}
                          items={["Paddy-Wheat", "Paddy-Pea", "Paddy-Potato"]}
                          selected={value.cycle}
                          handler={(value) => {
                            valueSetter(index, "cycle", value);
                          }}
                        />
                      </div>
                      <div className="w-full flex gap-x-2">
                        <CustomInputBox
                          title="Own Land"
                          value={value.ownLand}
                          className="w-1/2"
                          valueSetter={(value) => {
                            valueSetter(index, "ownLand", value);
                          }}
                        />
                        <CustomInputBox
                          title="Leased Land"
                          value={value.leasedLand}
                          className="w-1/2"
                          valueSetter={(value) => {
                            setLeasedLand(false);
                            valueSetter(index, "leasedLand", value);
                          }}
                        />
                        <CustomInputBox
                          title="Total Land"
                          disabled={true}
                          value={
                            Number(value.ownLand) + Number(value.leasedLand)
                          }
                          className="w-1/2"
                          valueSetter={(value) => {
                            valueSetter(
                              index,
                              "totalLand",
                              Number(value.ownLand) + Number(value.leasedLand)
                            );
                          }}
                        />
                      </div>
                      <CustomInputBox
                        disabled={leasedLand}
                        title="Leased Landowner Name"
                        value={value.leasedLandowner}
                        className="w-1/2"
                        valueSetter={(value) => {
                          valueSetter(index, "leasedLandowner", value);
                        }}
                      />
                      <div className=" gap-x-2 w-full flex">
                        <div className="w-full">
                          <CustomDropDownSearchSelect
                            items={["Market", "Own"]}
                            handler={(value) => {
                              valueSetter(index, "seedSource", value);
                            }}
                            label={"Seed Source"}
                            selected={value["seedSource"]}
                          />
                        </div>

                        <div className="w-full">
                          <CustomDropDownSearchSelect
                            items={["Yes", "No"]}
                            handler={(value) => {
                              if (value === "Yes") {
                                setSeedTreatment(false);
                              } else {
                                setSeedTreatment(true);
                              }
                              valueSetter(
                                index,
                                "seedTreatment",
                                value === "Yes" ? 1 : 0
                              );
                            }}
                            label={"Seed Treatment"}
                            selected={
                              value["seedTreatment"] === 1 ? "Yes" : "No"
                            }
                          />
                        </div>
                        <div className="w-full">
                          <CustomInputBox
                            disabled={seedTreatment}
                            title="Seed Treatment Chemical Name"
                            value={value.seedTreatmentChemical}
                            className="w-1/2"
                            valueSetter={(value) => {
                              valueSetter(
                                index,
                                "seedTreatmentChemical",
                                value
                              );
                            }}
                          />
                        </div>
                      </div>
                      <div className="flex gap-x-3 w-full">
                        <div className="w-full">
                          <CustomDropDownSearchSelect
                            label={"Kaccha Aarthi"}
                            items={
                              aarthiList !== null
                                ? aarthiList
                                    .filter(
                                      (val) =>
                                        value.mandiId === val.MandiMaster.id
                                    )
                                    .map((value) => {
                                      return getItemName(
                                        value,
                                        "identifier",
                                        "name"
                                      );
                                    })
                                : []
                            }
                            selected={
                              value.kacchaAarthiId !== null &&
                              aarthiList !== null &&
                              aarthiList.length > 0
                                ? getItemName(
                                    searchObject(
                                      aarthiList,
                                      value.kacchaAarthiId,
                                      "id"
                                    ),
                                    "identifier",
                                    "name"
                                  )
                                : null
                            }
                            handler={(value) => {
                              if (value !== null)
                                valueSetter(
                                  index,
                                  "kacchaAarthiId",
                                  aarthiList.filter((element) => {
                                    return (
                                      getItemName(
                                        element,
                                        "identifier",
                                        "name"
                                      ) == value
                                    );
                                  })[0].id
                                );
                              else {
                                valueSetter(index, "kacchaAarthiId", null);
                              }
                            }}
                          />
                        </div>
                        <div className="w-full">
                          <CustomDropDownSearchSelect
                            label={"Pakka Aarthi"}
                            items={
                              aarthiList !== null
                                ? aarthiList
                                    .filter(
                                      (val) =>
                                        value.mandiId === val.MandiMaster.id
                                    )
                                    .map((value) => {
                                      return getItemName(
                                        value,
                                        "identifier",
                                        "name"
                                      );
                                    })
                                : []
                            }
                            selected={
                              value.pakkaAarthiId !== null &&
                              aarthiList !== null &&
                              aarthiList.length > 0
                                ? getItemName(
                                    searchObject(
                                      aarthiList,
                                      value.pakkaAarthiId,
                                      "id"
                                    ),
                                    "identifier",
                                    "name"
                                  )
                                : null
                            }
                            handler={(value) => {
                              if (value !== null)
                                valueSetter(
                                  index,
                                  "pakkaAarthiId",
                                  aarthiList.filter((element) => {
                                    return (
                                      getItemName(
                                        element,
                                        "identifier",
                                        "name"
                                      ) == value
                                    );
                                  })[0].id
                                );
                              else {
                                valueSetter(index, "pakkaAarthiId", null);
                              }
                            }}
                          />
                        </div>
                      </div>
                      <div className="flex gap-x-3 w-full">
                        <div className="w-full">
                          <CustomInputBox
                            title="Laser Leveling Area"
                            value={value.levelingArea}
                            className="w-1/2"
                            valueSetter={(value) => {
                              valueSetter(index, "levelingArea", value);
                            }}
                          />
                        </div>
                        <div className="w-full">
                          <CustomDateSelector
                            label={"Date"}
                            date={value.date === null ? null : value.date}
                            onChange={(val) => {
                              valueSetter(index, "date", val);
                              console.log(val, "Value Date");
                            }}
                          />
                        </div>
                        <div className="w-full">
                          <CustomInputBox
                            title="Remarks"
                            value={value.remarks}
                            className="w-1/2"
                            valueSetter={(value) => {
                              valueSetter(index, "remarks", value);
                            }}
                          />
                        </div>
                      </div>

                      {value.Jform.map((element, varIndex) => {
                        return (
                          <div className="flex gap-x-3 w-full" key={varIndex}>
                            <div className="w-full">
                              <CustomInputBox
                                title="Jform Acre"
                                value={element.JformAcre}
                                className="w-1/2"
                                valueSetter={(valuee) => {
                                  jFormValueSetter(
                                    index,
                                    varIndex,
                                    "JformAcre",
                                    valuee
                                  );
                                }}
                              />
                            </div>
                            <div className="w-full">
                              <CustomInputBox
                                title="Jform Name"
                                value={element.JformName}
                                className="w-1/2"
                                valueSetter={(valuee) => {
                                  jFormValueSetter(
                                    index,
                                    varIndex,
                                    "JformName",
                                    valuee
                                  );
                                }}
                              />
                            </div>
                            {varIndex !== value.Jform.length - 1 ? (
                              <span
                                onClick={() => {
                                  removeJform(index);
                                }}
                                className="h-5 w-10 mt-8 mr-3 text-gray-500 hover:text-red-500 hover:p-1"
                              >
                                <TrashIcon />
                              </span>
                            ) : (
                              <span
                                className="h-5 w-10 mt-8 text-white rounded-md bg-green-500"
                                onClick={() => {
                                  addJform(index);
                                }}
                              >
                                <PlusIcon />
                              </span>
                            )}
                          </div>
                        );
                      })}

                      {/* This is the add varieties section */}

                      <div className="w-full flex">
                        <div className="flex flex-wrap w-full">
                          {value.varietiesList.map((element, varIndex) => {
                            return (
                              <div className="flex gap-x-3 w-full">
                                <div className="w-full">
                                  <CustomDropDownSearchSelect
                                    label={"Variety"}
                                    items={
                                      varietyList !== null
                                        ? varietyList.map((value) => {
                                            return getItemName(
                                              value,
                                              "identifier",
                                              "name"
                                            );
                                          })
                                        : []
                                    }
                                    selected={
                                      element.varietyId !== null &&
                                      varietyList !== null &&
                                      varietyList.length > 0
                                        ? getItemName(
                                            searchObject(
                                              varietyList,
                                              element.varietyId,
                                              "id"
                                            ),
                                            "identifier",
                                            "name"
                                          )
                                        : null
                                    }
                                    handler={(value) => {
                                      if (value !== null)
                                        varietiesValueSetter(
                                          index,
                                          varIndex,
                                          "varietyId",
                                          varietyList.filter((element) => {
                                            return (
                                              getItemName(
                                                element,
                                                "identifier",
                                                "name"
                                              ) == value
                                            );
                                          })[0].id
                                        );
                                      else {
                                        varietiesValueSetter(
                                          index,
                                          varIndex,
                                          "varietyId",
                                          null
                                        );
                                      }
                                    }}
                                  />
                                </div>

                                <div className="w-full">
                                  <CustomInputBox
                                    title="Land Used"
                                    value={element.landUsed}
                                    className="w-1/2"
                                    valueSetter={(value) => {
                                      varietiesValueSetter(
                                        index,
                                        varIndex,
                                        "landUsed",
                                        value
                                      );
                                    }}
                                  />
                                </div>

                                {varIndex === value.varietiesList.length - 1 ? (
                                  <div className="ml-5 mt-7">
                                    <CustomButton
                                      btnName="Add variety"
                                      onClick={() => {
                                        addVariety(index);
                                      }}
                                    />
                                  </div>
                                ) : (
                                  <CustomButton
                                    btnName="🗑️"
                                    bgColor="bg-white"
                                    onClick={() => {
                                      removeVariety(index, varIndex);
                                    }}
                                  />
                                )}
                              </div>
                            );
                          })}

                          {newFarmer.length !== 1 ? (
                            <span
                              onClick={() => {
                                setNewFarmer(
                                  newFarmer.filter((v, i) => {
                                    return i !== index;
                                  })
                                );
                              }}
                              className="h-6 w-6 mt-8 mr-3 text-gray-500 hover:text-red-500 hover:p-1"
                            >
                              <TrashIcon />
                            </span>
                          ) : null}
                          <span
                            className="h-5 w-5 mt-8 text-white rounded-md bg-green-500"
                            onClick={() => {
                              setNewFarmer([...newFarmer, makeDataObject()]);
                            }}
                          >
                            <PlusIcon />
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
            );
          })}
        </ol>
      </div>
      <div className="flex w-1/5 mt-10 ml-3 justify-between">
        <CustomButton
          btnName="Cancel"
          bgColor="bg-gray-400"
          onClick={() => {
            history.goBack();
          }}
        />
        {!isSubmit ? (
          <CustomButton btnName="Submit" onClick={addFarmer} />
        ) : (
          <CustomButton btnName="Submit" onClick={() => {}} />
        )}
      </div>
    </div>
  );
};
export default FarmerMasterAdd;
