import React, { useState } from "react";
import { useHistory, useLocation } from "react-router";
import { CustomButton } from "../../../components/atoms/CustomButton";
import axios from "../../../axios";
import { DocumentAddIcon } from "@heroicons/react/outline";
import CustomInputBox from "../../../components/atoms/CustomInputBox";
import { successToast, errorToast } from "../../../util/toast";
import Switch from "react-switch";
import { validateValidationAdd } from "../../Master Data/common/validations";

const InitialValidationAdd = ({ match }) => {
  const history = useHistory();
  const location = useLocation();
  const [isSubmit, setIsSubmit] = useState(false);
  const [farmerVariety, setFarmerVariety] = useState(
    JSON.parse(location.state).data
  );
  console.log(farmerVariety);
  const makeDataObject = () => {
    return {
      farmerId: farmerVariety.FarmerMaster.id,
      varietyId: farmerVariety.VarietyMaster.id,
      landUsed: farmerVariety.landUsed.value,
      actualLandUsed: 0,
      isAcreageVerified: 0,
      isVarietyVerified: 0,
      verificationComment: "",
    };
  };
  const [newInitialValidation, setNewInitialValidations] = useState([
    makeDataObject(),
  ]);

  const valueSetter = (index, property, newValue) => {
    let initialValidation = [...newInitialValidation];
    initialValidation[index][property] = newValue;
    setNewInitialValidations(initialValidation);
  };

  const addInitialValidations = () => {
    if (validateValidationAdd(newInitialValidation)) {
      setIsSubmit(true);
      successToast("Your request is being processed");
      console.log(newInitialValidation);
      axios
        .post("initialValidation/", newInitialValidation)
        .then((response) => {
          if (response?.data?.ResponseMessage === "SUCCESS") {
            successToast("New initial validation added!");
            history.push("/home/transactionData/initialValidation");
          }
        })
        .catch((error) => {
          errorToast(error?.response?.data?.Comments);
          console.log(error);
        });
    } else {
      setIsSubmit(false);
    }
  };

  return (
    <div className="p-3 m-3">
      <div className="flex mb-7">
        <DocumentAddIcon className="h-5 w-5text-black mt-3 mr-1" />
        <div className="text-base pt-3 font-bold text-gray-800">
          Add Initial Validation
        </div>
      </div>
      <div>
        {newInitialValidation !== null
          ? newInitialValidation.map((value, index) => {
              return (
                <div className="mb-10 flex-wrap w-1/2">
                  <div className="flex w-full">
                    <div className="w-full  gap-x-3">
                      <div className="grid grid-cols-2 gap-x-3">
                        <CustomInputBox
                          title={"Farmer"}
                          borderDisable={true}
                          disabled={true}
                          value={`${farmerVariety.FarmerMaster.name}`}
                        />

                        <CustomInputBox
                          title={"Variety"}
                          borderDisable={true}
                          disabled={true}
                          value={`${farmerVariety.VarietyMaster.name}`}
                        />
                        <CustomInputBox
                          title="Land Used"
                          disabled={true}
                          borderDisable={true}
                          value={value.landUsed}
                          valueSetter={(value) => {
                            valueSetter(index, "landUsed", value);
                          }}
                        />

                        <div className="">
                          <div className="text-sm font-light">
                            Is Acreage Verified?
                          </div>
                          <Switch
                            className="mt-2"
                            checked={
                              value.isAcreageVerified == 1 ? true : false
                            }
                            onChange={(value) => {
                              valueSetter(
                                index,
                                "isAcreageVerified",
                                value === true ? 1 : 0
                              );
                            }}
                          />
                        </div>
                        <div className="">
                          <div className="text-sm font-light">
                            Is Variety Verified? &nbsp;
                            {/* <br></br> */}
                          </div>
                          {/* <br></br> */}
                          <Switch
                            className="mt-2"
                            checked={
                              value.isVarietyVerified === 1 ? true : false
                            }
                            onChange={(value) => {
                              valueSetter(
                                index,
                                "isVarietyVerified",
                                value === true ? 1 : 0
                              );
                            }}
                          />
                        </div>

                        <CustomInputBox
                          title="Actual Land Used"
                          value={value.actualLandUsed}
                          valueSetter={(value) => {
                            valueSetter(
                              index,
                              "actualLandUsed",
                              value === "" ? 0 : value
                            );
                          }}
                        />

                        <div className="">
                          <CustomInputBox
                            title={"Comment"}
                            value={value.verificationComment}
                            valueSetter={(value) => {
                              valueSetter(index, "verificationComment", value);
                            }}
                          />
                        </div>
                      </div>
                    </div>

                    {/* {
                      newInitialValidation.length - 1 !== index ? (
                        <span
                          onClick={() => {
                            setNewInitialValidations(
                              newInitialValidation.filter((v, i) => {
                                return i !== index;
                              })
                            );
                          }}
                          className="h-5 w-5 ml-2 mt-8 text-gray-500 hover:text-red-500 hover:p-1"
                        >
                          <TrashIcon />
                        </span>
                      ) : (
                        <span
                          className="h-4 w-4 ml-2 mt-8 text-white rounded-md bg-green-500"
                          onClick={() => {
                            setNewInitialValidations([
                              ...newInitialValidation,
                              makeDataObject(),
                            ]);
                          }}
                        >
                          <PlusIcon />
                        </span>
                      )
                      // :<CustomButton className=" mt-8 ml-3 text-sm p-1 bg-green-500" btnName={<span className="h-3 w-3"><PlusIcon /></span>} onClick={() => { setNewVarieties([...newVarieties, { name: "" }]) }} />
                    } */}
                  </div>
                </div>
              );
            })
          : null}
      </div>
      <div className="flex w-1/2 justify-between">
        <CustomButton
          btnName="Cancel"
          bgColor="bg-gray-400"
          onClick={() => {
            history.goBack();
          }}
        />
        {!isSubmit ? (
          <CustomButton
            btnName="Submit"
            onClick={() => {
              setNewInitialValidations(
                newInitialValidation.map((element) => {
                  if (element.isVarietyVerified === null) {
                    element.isVarietyVerified = 0;
                  }
                  return element;
                })
              );
              addInitialValidations();
            }}
          />
        ) : (
          <CustomButton btnName="Submit" onClick={() => {}} />
        )}
      </div>
    </div>
  );
};
export default InitialValidationAdd;
