import { React, useEffect, useState } from "react";
import { useLocation, Redirect } from "react-router-dom";
import { EyeIcon } from "@heroicons/react/outline";
import axios from "../../../axios";
import { errorToast } from "../../../util/toast";
import CustomInputBox from "../../../components/atoms/CustomInputBox";

const SeasonalPlanningDisplay = () => {
  const location = useLocation();
  const [initialData, setInitialData] = useState(
    JSON.parse(location.state).data
  );
  const [data, setData] = useState(null);

  const getItemName = (obj, prop1, prop2) => {
    return obj[prop2];
  };

  const [farmYield, setFarmYield] = useState(null);
  useEffect(() => {
    if (initialData.id !== undefined)
      axios
        .get(`seasonalPlannings/${initialData.id}`)
        .then((response) => {
          console.log("Result is", response.data.Result.SeasonalPlanning);
          setData(response.data.Result.SeasonalPlanning);
        })
        .catch((error) => {
          console.log(error);
          errorToast(error?.message);
        });
  }, [initialData]);

  useEffect(() => {
    axios
      .get(
        `yieldMaster/${data?.MandiMaster?.ZoneMaster?.id}/${data?.VarietyMaster.id}`
      )
      .then((res) => {
        setFarmYield(res.data.Result.YieldMaster);
        console.log(res.data.Result.YieldMaster, "Yield Master");
      })
      .catch((error) => {
        console.log(error);
        errorToast(error?.message);
      });
  }, [data]);

  if (!initialData) return <Redirect to="/" />;
  return data !== null ? (
    <div className="overflow-hidden m-3 p-3 ">
      <div className="flex">
        <span>
          <EyeIcon className="h-5 w-5 transform text-black mt-4 mr-1" />
        </span>
        <span className=" py-4 text-gray-700 text-base font-bold text-left">
          Display Seasonal Planning
        </span>
      </div>
      <div className="flex flex-wrap">
        <div className=" grid grid-cols-2  gap-x-5 gap-x-3 mb-4">
          {/* <CustomDisplayBox title="ID" value={data.id} className=" pl-2"/> */}

          <CustomInputBox
            disabled={true}
            borderDisable={true}
            title={"Mandi Name"}
            value={getItemName(data.MandiMaster, "identifier", "name")}
          />

          <CustomInputBox
            disabled={true}
            borderDisable={true}
            title={"Incharge"}
            value={getItemName(data.Incharge, "identifier", "name")}
          />

          <CustomInputBox
            disabled={true}
            borderDisable={true}
            title={"Field Motivator Name"}
            value={getItemName(data.FieldMotivator, "identifier", "name")}
          />

          <CustomInputBox
            disabled={true}
            borderDisable={true}
            title={"Target Acre"}
            value={data.targetAcre}
          />

          <CustomInputBox
            disabled={true}
            borderDisable={true}
            title={"Variety"}
            value={getItemName(data.VarietyMaster, "identifier", "name")}
          />

          <CustomInputBox
            disabled={true}
            borderDisable={true}
            title={"Registered Acre"}
            value={data.registeredAcre}
          />

          <CustomInputBox
            disabled={true}
            borderDisable={true}
            title={"No. of Farmers"}
            value={data.numberOfFarmers}
          />

          <CustomInputBox
            disabled={true}
            borderDisable={true}
            title={"Target Yield"}
            value={farmYield?.farmYield * data.registeredAcre}
          />
          <CustomInputBox
            disabled={true}
            borderDisable={true}
            title={"Expected Farm Yield"}
            value={farmYield?.farmYield * data.targetAcre}
          />
          <CustomInputBox
            disabled={true}
            borderDisable={true}
            title={"Result Compliance Percentage"}
            value={
              (farmYield?.farmYield * data.targetAcre * farmYield?.compliance) /
              100
            }
          />
          <CustomInputBox
            disabled={true}
            borderDisable={true}
            title={"Purchase (Procurement%)"}
            value={
              (((farmYield?.farmYield *
                data.targetAcre *
                farmYield?.compliance) /
                100) *
                farmYield?.procurement) /
              100
            }
          />
          <CustomInputBox
            disabled={true}
            borderDisable={true}
            title={"Head Rice"}
            value={
              (((((farmYield?.farmYield *
                data.targetAcre *
                farmYield?.compliance) /
                100) *
                farmYield?.procurement) /
                100) *
                farmYield?.headRiceYield) /
              100
            }
          />
        </div>
      </div>
      <div className="flex w-max gap-x-10"></div>
    </div>
  ) : null;
};
export default SeasonalPlanningDisplay;
