import React, { useState } from "react";
import { useHistory, useLocation } from "react-router";
import { CustomButton } from "../../../components/atoms/CustomButton";
import axios from "../../../axios";
import { DocumentAddIcon } from "@heroicons/react/outline";
import CustomInputBox from "../../../components/atoms/CustomInputBox";
import { successToast, errorToast } from "../../../util/toast";
import CustomDateSelector from "../../../components/atoms/CustomDateSelector";
import Switch from "react-switch";

const InitialValidationAdd = ({ match }) => {
  const history = useHistory();
  const location = useLocation();
  const [isSubmit, setIsSubmit] = useState(false);
  const [farmerVariety, setFarmerVariety] = useState(
    JSON.parse(location.state).data
  );
  console.log("FARMERVARIETY", farmerVariety);
  const makeDataObject = () => {
    return {
      id: farmerVariety.id,
      farmerVarietyId: farmerVariety.id,
      samplingRequestDate: new Date(),
      samplingDate: null,
      acre: null,
      expectedHarvestingDate: null,
    };
  };
  const [newInitialValidation, setNewInitialValidations] = useState([
    makeDataObject(),
  ]);

  const getItemName = (obj, prop1, prop2) => {
    return obj[prop2];
  };

  const getDateString = (date) => {
    let year = date.getFullYear();
    let month = date.getMonth() + 1;
    month = month < 10 ? "0" + month : month;
    let day = date.getDate();
    day = day < 10 ? "0" + day : day;

    return day + "/" + month + "/" + year;
  };

  const valueSetter = (index, property, newValue) => {
    let initialValidation = [...newInitialValidation];

    console.log(index, property, newValue);
    initialValidation[index][property] = newValue;

    setNewInitialValidations(initialValidation);
  };

  const checkAll = () => {
    let flag = true;
    for (let i = 0; i < newInitialValidation.length; i++) {
      const temp = newInitialValidation[i];
      let harvesting = new Date(temp.expectedHarvestingDate);
      let sampling = new Date(temp.samplingDate);
      if (temp.isAcreageVerified === 0) {
        if (
          temp.actualLandUsed.trim() === "" ||
          Number(temp.actualLandUsed === NaN)
        ) {
          flag = false;
          errorToast("Please enter actual land used!");
        }
      } else if (harvesting.getTime() < sampling.getTime()) {
        flag = false;
        errorToast(
          "Expected harvesting date cannot be less than the sampling date!"
        );
      }
    }
    return flag;
  };

  const addInitialValidations = () => {
    if (checkAll()) {
      setIsSubmit(true);
      successToast("Your request is being processed");
      axios
        .post("cropCuttingSampling/", newInitialValidation)
        .then((response) => {
          if (response?.data?.ResponseMessage === "SUCCESS") {
            successToast("New Sample Request Made!");
            history.push("/home/transactionData/sampling");
          }
        })
        .catch((error) => {
          errorToast(error?.response?.data?.Result?.error);
          console.log(error);
        });
    } else {
      setIsSubmit(false);
    }
  };

  return (
    <div className="p-3 m-3">
      <div className="flex mb-7">
        <DocumentAddIcon className="h-5 w-5text-black mt-3 mr-1" />
        <div className="text-base pt-3 font-bold text-gray-800">
          Add Sampling
        </div>
      </div>
      <div>
        {newInitialValidation !== null
          ? newInitialValidation.map((value, index) => {
              console.log("VAlue", value);
              return (
                <div className="mb-10 flex-wrap w-2/3">
                  <div className="w-full grid grid-cols-2  gap-x-3">
                    <CustomInputBox
                      title={"Farmer"}
                      disabled={true}
                      borderDisable={true}
                      value={getItemName(
                        farmerVariety.FarmerMaster,
                        "identifier",
                        "name"
                      )}
                    />

                    <CustomInputBox
                      title={"Variety"}
                      disabled={true}
                      borderDisable={true}
                      value={getItemName(
                        farmerVariety.VarietyMaster,
                        "identifier",
                        "name"
                      )}
                    />

                    {/* <CustomInputBox
                title="Sampling Number"
                disabled={false}
                value={value?.samplingNumber}
                className="w-1/2"
                valueSetter={(value) => {
                  valueSetter(index ,"samplingNumber", value);
                }}
              /> */}

                    <CustomDateSelector
                      label={"Sampling Request Date"}
                      disabled={true}
                      date={
                        value.samplingRequestDate === null
                          ? ""
                          : new Date(value.samplingRequestDate)
                      }
                      onChange={(value) => {
                        valueSetter(
                          index,
                          "samplingRequestDate",
                          new Date(value)
                        );
                      }}
                    />

                    <CustomDateSelector
                      label={"Sampling Date"}
                      date={
                        value.samplingDate === null
                          ? ""
                          : new Date(value.samplingDate)
                      }
                      onChange={(value) => {
                        valueSetter(index, "samplingDate", new Date(value));
                      }}
                    />

                    <CustomInputBox
                      title="Area in acre "
                      value={value?.acre}
                      disabled={false}
                      className="w-1/2"
                      valueSetter={(value) => {
                        valueSetter(index, "acre", value);
                      }}
                    />
                    <CustomDateSelector
                      label="Expected Harvesting date"
                      date={
                        value.expectedHarvestingDate === null
                          ? ""
                          : new Date(value.expectedHarvestingDate)
                      }
                      className="w-1/2"
                      onChange={(value) => {
                        valueSetter(
                          index,
                          "expectedHarvestingDate",
                          new Date(value)
                        );
                      }}
                    />
                  </div>
                </div>
              );
            })
          : null}
      </div>
      <div className="flex w-1/2 justify-between">
        <CustomButton
          btnName="Cancel"
          bgColor="bg-gray-400"
          onClick={() => {
            history.goBack();
          }}
        />
        {!isSubmit ? (
          <CustomButton
            btnName="Submit"
            onClick={() => {
              setNewInitialValidations(
                newInitialValidation.map((element) => {
                  if (element.isVarietyVerified === null) {
                    element.isVarietyVerified = 0;
                  }
                  return element;
                })
              );
              addInitialValidations();
            }}
          />
        ) : (
          <CustomButton btnName="Submit" onClick={() => {}} />
        )}
      </div>
    </div>
  );
};
export default InitialValidationAdd;
