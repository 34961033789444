import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import MasterDataDelete from "../common/MasterDataDelete";
import ChemicalMasterHome from "./ChemicalMasterHome";
import ChemicalMasterEdit from "./ChemicalMasterEdit";
import ChemicalMasterDisplay from "./ChemicalMasterDisplay";
import ChemicalMasterAdd from "./ChemicalMasterAdd";

const ChemicalMasterRoutes = () => {
    return (
        <BrowserRouter basename="/home/masterData/ChemicalMaster/">
            <Switch>
                <Route path="/" component={ChemicalMasterHome} exact />
                <Route path="/add" component={ChemicalMasterAdd} />
                <Route path="/edit/:id" component={ChemicalMasterEdit} />
                <Route path="/delete/:id" component={MasterDataDelete} />
                <Route path="/display" component={ChemicalMasterDisplay} />
                <Route component={() => (<Redirect to="/" />)} />
            </Switch>
        </BrowserRouter>
    );
}

export default ChemicalMasterRoutes;