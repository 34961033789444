import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import UomHome from "./UomHome";
import UomDisplay from "./UomDisplay";
import UomAdd from "./UomAdd";

const UomRoutes = () => {
    return (
        <BrowserRouter basename="/home/masterData/uomMaster/">
            <Switch>
                <Route path="/" component={UomHome} exact />
                <Route path="/add" component={UomAdd} />
                <Route path="/display" component={UomDisplay} />
                <Route component={() => (<Redirect to="/" />)} />
            </Switch>
        </BrowserRouter>
    );
}

export default UomRoutes;