import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import TransactionDataDelete from "../common/TransactionDataDelete";
import Home from "./home";
import Add from "./add"
import Edit from "./edit";
import Display from "./display";
import AddSelect from './addSelect'

const CropLifeCycleRoutes = () => {

    console.log("Crops")
    return (
        <BrowserRouter basename="/home/transactionData/cropLifeCycle/">
            <Switch>
                <Route path="/" component={Home} exact />
                <Route path="/add" component={Add} exact />
                <Route path="/addSelect" component={AddSelect} exact/>
                <Route path="/delete/:id" component={TransactionDataDelete} />
                <Route path="/edit/:id" component={Edit} />
                <Route path="/display" component={Display} /> 
                <Route component={() => (<Redirect to="/" />)} />
            </Switch>
        </BrowserRouter>
    );
}

export default CropLifeCycleRoutes;