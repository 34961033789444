import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import MasterDataDelete from "../common/MasterDataDelete";
import FertilizerMasterHome from "./FertilizerMasterHome";
import FertilizerMasterEdit from "./FertilizerMasterEdit";
import FertilizerMasterDisplay from "./FertilizerMasterDisplay";
import FertilizerMasterAdd from "./FertilizerMasterAdd";

const FertilizerMasterRoutes = () => {
    return (
        <BrowserRouter basename="/home/masterData/FertilizerMaster/">
            <Switch>
                <Route path="/" component={FertilizerMasterHome} exact />
                <Route path="/add" component={FertilizerMasterAdd} />
                <Route path="/edit/:id" component={FertilizerMasterEdit} />
                <Route path="/delete/:id" component={MasterDataDelete} />
                <Route path="/display" component={FertilizerMasterDisplay} />
                <Route component={() => (<Redirect to="/" />)} />
            </Switch>
        </BrowserRouter>
    );
}

export default FertilizerMasterRoutes;