import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { CustomButton } from "../../../components/atoms/CustomButton";
import TransactionDataDelete from "../common/TransactionDataDelete";
import axios from "../../../axios";
import { DocumentAddIcon, CalendarIcon } from "@heroicons/react/outline";
import {
  EyeIcon,
  PencilIcon,
  TrashIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
} from "@heroicons/react/solid";
import { toast } from "react-toastify";
import CustomTable from "../../../components/atoms/CustomTable";
import CustomDropDownSearchSelect from "../../../components/atoms/CustomDropDownSearchSelect";
import { errorToast, successToast } from "../../../util/toast";

const getItemName = (obj, prop1, prop2) => {
  return obj[prop2];
};
//returns the first object in the list whose property matches the target
const searchObject = (items, target, property) => {
  return items.filter((element) => {
    return element[property] === target;
  })[0];
};

const SeasonalPlanningHome = () => {
  const role = JSON.parse(localStorage.getItem("cfUserAuth"));
  const result = role.find((obj) => {
    return obj.moduleName === "seasonalPlannings";
  });
  console.log("ROLE", role, result);
  const history = useHistory();
  const tablePageNumbersShown = 3;
  //table related.
  const [currentTableData, setTableData] = useState([]);
  const [zoneId, setZoneId] = useState(null);
  const rowsPerPage = 10;
  const [totalRecords, setTotalRecords] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [sortBy, setSortBy] = useState({
    headerName: "id",
    order: false,
  });

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showUploadFile, setShowUploadFile] = useState(false);
  const [deleteObject, setDeleteObject] = useState(null);

  const tableHeaders = [
    { displayName: "id", property: "id", sortable: true, show: false },
    {
      displayName: "Variety",
      property: "VarietyMaster.name",
      sortable: true,
      show: true,
    },
    {
      displayName: "Mandi Name",
      property: "MandiMaster.name",
      sortable: true,
      show: true,
    },

    {
      displayName: "Incharge",
      property: "Incharge.name",
      sortable: true,
      show: true,
    },

    {
      displayName: "Field Motivator",
      property: "FieldMotivator.name",
      sortable: false,
      show: true,
    },
    {
      displayName: "Target Acre",
      property: "targetAcre",
      sortable: true,
      show: true,
    },
    {
      displayName: "actions",
      property: "actions",
      sortable: false,
      show: true,
    },
  ];

  const [inchargeId, setInchargeId] = useState(null);
  const [mandiId, setMandiId] = useState(null);
  const [mandiIdentifier, setMandiIdentifier] = useState(null);
  const [fieldMotivatorId, setFieldMotivatorId] = useState(null);
  const [varietyId, setVarietyId] = useState(null);
  const [mandiList, setMandiList] = useState(null);
  const [zoneList, setZoneList] = useState(null);

  const [fieldMotivatorList, setFieldMotivatorList] = useState(null);
  const [inchargeList, setInchargeList] = useState(null);
  const [varietyList, setVarietyList] = useState(null);

  useEffect(() => {
    axios
      .get(`zoneMasters/specificload/${localStorage.getItem("cfUserId")}`)
      .then((response) => {
        console.log("Ummeed", response.data.Result.ZoneMaster);
        setZoneList(response.data.Result.ZoneMaster);
        return axios.get(
          `mandiMaster/load/${localStorage.getItem("cfUserId")}`
        );
      })
      .then((response) => {
        console.log("MNADDI", response.data.Result.MandiMaster);
        setMandiList(response.data.Result.MandiMaster);
        return axios.get(`varietyMaster/load`);
      })
      .then((response) => {
        // console.log("IJI" , response.data.Result.VarietyMaster)
        setVarietyList(response.data.Result.VarietyMaster);
        return axios.get(`users/index`, {
          params: { role: "Field Motivator", limit: 1000000, isActive: 1 },
        });
      })
      .then((response) => {
        console.log("UsersReceive", response.data.Result.users);
        setFieldMotivatorList(response.data.Result.users);
        return axios.get(`users/index`, {
          params: { role: "CF Head", limit: 1000000, isActive: 1 },
        });
      })
      .then((response) => {
        console.log("UsersReceive", response.data.Result.users);
        setInchargeList(response.data.Result.users);
        console.log("Incharge", response.data.Result.users);
      })
      .catch((err) => {
        
        // errorToast(err.data.Comments);
        console.log("YUP" , err);
      });
  }, []);

  const addActions = (objects) => {
    //adds the actions buttons to the table rows.
    const newObjects = [];

    objects.map((obj) => {
      obj.name = { value: "this seasonal planning" }; // this is for delete
      obj.mandiName = {
        value: getItemName(obj.MandiMaster, "identifier", "name"),
        show: true,
        order: 2,
      };
      obj.incharge = {
        value: getItemName(obj.Incharge, "identifier", "name"),
        show: true,
        order: 3,
      };
      obj.targetAcre = { value: obj.targetAcre, show: true, order: 5 };
      obj.varietyName = {
        value: getItemName(obj.VarietyMaster, "identifier", "name"),
        show: true,
        order: 1,
      };
      obj.fieldMotivator = {
        value: getItemName(obj.FieldMotivator, "identifier", "name"),
        show: true,
        order: 4,
      };

      obj.actions = {
        order: 6,
        value: (
          <div className="flex gap-x-3 -ml-4 p-1">
            {result.moduleUpdate == 1 && (
              <Link
                to={{
                  pathname: `/edit/${obj.id}`,
                  state: JSON.stringify({ data: obj, id: obj.id }),
                }}
              >
                <span>
                  <PencilIcon className="h-5 w-5 text-gray-700 hover:text-black" />
                </span>
              </Link>
            )}
            <Link
              to={{
                pathname: `/display`,
                state: JSON.stringify({ data: obj }),
              }}
            >
              <span>
                <EyeIcon className="h-5 w-5 text-gray-700 hover:text-black" />
              </span>
            </Link>
            {result.moduleDelete == 1 && (
              <span>
                <TrashIcon
                  className="h-5 w-5 text-gray-700 hover:text-black"
                  onClick={() => {
                    setShowDeleteModal(true);
                    setDeleteObject(obj);
                  }}
                />
              </span>
            )}
          </div>
        ),
        show: true,
      };
      newObjects.push(obj);
    });

    return newObjects;
  };

  const deleteSeasonalPlanning = () => {
    axios
      .delete(`seasonalPlannings/${deleteObject.id}`)
      .then((response) => {
        if (response?.data?.ResponseMessage === "SUCCESS") {
          window.location.reload();
          successToast("Delete Successful!");
          // remove this and do it better, we need to remove the row of the delete object thats it
          setShowDeleteModal(false);
        }
      })
      .catch((error) => {
        errorToast(error?.response?.data?.Comments);
        // console.log(error);
      });
  };

  useEffect(() => {
    //updating the table data based on the search terms.

    let params = {
      page: pageNumber,
      limit: 10,
      orderBy: sortBy.headerName,
      order: sortBy.order ? "ASC" : "DESC",
    };
    if (inchargeId !== null) {
      params.inchargeId = inchargeId;
    }
    if (zoneId !== null) {
      params.zoneId = zoneId;
    }
    if (mandiId !== null) {
      params.mandiId = mandiId;
    }
    if (varietyId !== null) {
      params.varietyId = varietyId;
    }
    if (fieldMotivatorId !== null) {
      params.fieldMotivatorId = fieldMotivatorId;
    }
    if (fieldMotivatorId !== null) {
      params.fieldMotivatorId = fieldMotivatorId;
    }
    // params.year = new Date().getFullYear();
    if (1) {
      console.log("Params", params);
      axios
        .get(`seasonalPlannings`, { params })
        .then((response) => {
          console.log("TOTAL", response.data.Result);
          setTotalRecords(response.data.Result.total);
          const returnedRows = response.data.Result.SeasonalPlanning;

          console.log(response.data.Result.SeasonalPlanning);
          setTotalPages(Math.ceil(response.data.Result.total / rowsPerPage));
          let actualRows = addActions(returnedRows);
          console.log("Table Data: ", response.data);
          setTableData(actualRows);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [
    inchargeId,
    mandiIdentifier,
    fieldMotivatorId,
    varietyId,
    mandiList,
    mandiId,
    inchargeList,
    zoneList,
    zoneId,
    varietyList,
    fieldMotivatorList,
    pageNumber,
    sortBy,
    showUploadFile,
  ]);

  useEffect(() => {
    setMandiId(null);
  }, [zoneId]);

  useEffect(() => {
    setFieldMotivatorId(null);
    setInchargeId(null);
  }, [mandiId]);

  useEffect(() => {
    setPageNumber(1);
  }, [inchargeId, mandiId, mandiIdentifier, fieldMotivatorId, varietyId]);

  const nextPage = () => {
    if (pageNumber !== totalPages) setPageNumber(pageNumber + 1);
  };
  const prevPage = () => {
    if (pageNumber !== 1) setPageNumber(pageNumber - 1);
  };

  const getPageButtons = (noOfButtons) => {
    let buttonList = [];
    if (true) {
      let printed = 0;
      for (
        let i = Math.floor((pageNumber - 1) / noOfButtons) * noOfButtons + 1;
        printed < noOfButtons && i <= totalPages;
        i++
      ) {
        buttonList.push(
          <button
            className={`px-3 ${
              i === pageNumber
                ? " bg-green-500 text-white text-sm rounded-xl"
                : "text-gray-600 text-sm"
            }`}
            onClick={() => {
              setPageNumber(i);
            }}
          >
            {i}
          </button>
        );
        printed++;
      }
    }
    return buttonList;
  };

  return (
    <div className="m-3 h-screen">
      {/* {console.log(mandiList)} */}
      <TransactionDataDelete
        show={showDeleteModal}
        object={deleteObject}
        onClose={(e) => {
          setShowDeleteModal(false);
        }}
        onYes={deleteSeasonalPlanning}
      />
      {/* <TransactionDataAddCSV
        sampleFileDownloadPath={"sampleSeasonal.csv"}
        fileUploadPath={"fertilizerMaster/file"}
        show={showUploadFile}
        onClose={(e) => {
          setShowUploadFile(false);
        }}
      /> */}

      <div className="flex justify-between overflow-wrap align-middle">
        <div name="IconAndPageTitle" className="bg-white flex ml-3">
          <span>
            <CalendarIcon className="my-3 mx-1 h-6 w-6 mr-1" />
          </span>
          <span className="text-lg mt-3 text-center align-center">
            Seasonal Planning
          </span>
          <span className="mt-5 text-xs ml-2">({totalRecords} records)</span>
        </div>
        <div name="Add buttons" className="flex flex-wrap gap-x-10">
          {/* <CustomButton
            onClick={() => {
              history.push("/renew");
            }}
            btnName="Renew"
            icon={<RefreshIcon className="h-5 w-5 mr-1" />}
          /> */}
          {result.moduleCreate == 1 && (
            <CustomButton
              onClick={() => {
                history.push("/add");
              }}
              btnName="Add"
              icon={<DocumentAddIcon className="h-5 w-5 mr-1" />}
            />
          )}
        </div>
      </div>
      <div name="FilterSection" className="gap-x-2 ml-3 bg-white w-11/12 mb-4">
        <div className="mt-6 flex gap-x-3 w-full">
          <div className="-mt-6 w-full">
            <CustomDropDownSearchSelect
              label={"Zone"}
              labelDisable={true}
              placeholder={"Select Zone"}
              items={
                zoneList !== null
                  ? zoneList?.map((element) => {
                      return getItemName(element, "identifier", "name");
                    })
                  : []
              }
              selected={
                zoneId !== null && zoneList !== null
                  ? getItemName(
                      searchObject(zoneList, zoneId, "id"),
                      "identifier",
                      "name"
                    )
                  : null
              }
              handler={(value) => {
                if (value !== null) {
                  setZoneId(
                    zoneList?.filter((element) => {
                      return (
                        getItemName(element, "identifier", "name") === value
                      );
                    })[0].id
                  );
                } else {
                  setZoneId(null);
                }
              }}
            />
          </div>

          <div className="-mt-6 w-full">
            <CustomDropDownSearchSelect
              items={
                mandiList !== null
                  ? mandiList
                      ?.filter((element) => {
                        return element.zoneId === zoneId;
                      })
                      ?.map((element) => {
                        return getItemName(element, "identifier", "name");
                      })
                  : []
              }
              label={"Mandi"}
              labelDisable={true}
              placeholder={"Mandi"}
              selected={
                mandiId !== null
                  ? getItemName(
                      searchObject(mandiList, mandiId, "id"),
                      "identifier",
                      "name"
                    )
                  : null
              }
              handler={(value) => {
                if (value !== null && mandiList !== null) {
                  setMandiId(() => {
                    const s = mandiList?.filter((element) => {
                      // console.log("ELEMENT" , getItemName(element, "identifier", "name") , element)
                      return (
                        getItemName(element, "identifier", "name") === value
                      );
                    });

                    console.log("ELEMENT", s);

                    return s[0].id;
                  });
                } else {
                  setMandiIdentifier(null);
                }
              }}
            />
          </div>
          <div className="-mt-6 w-full">
            <CustomDropDownSearchSelect
              items={
                inchargeList !== null
                  ? inchargeList
                      ?.filter((element) => {
                        return element?.UserMandis.some(el => el.mandiId === mandiId)
                      })
                      ?.map((element) => {
                        return getItemName(element, "identifier", "name");
                      })
                  : []
              }
              label="Incharge"
              labelDisable={true}
              placeholder={"Incharge"}
              selected={
                inchargeId !== null
                  ? getItemName(
                      searchObject(inchargeList, inchargeId, "id"),
                      "identifier",
                      "name"
                    )
                  : null
              }
              handler={(value) => {
                if (value !== null && inchargeList !== null) {
                  setInchargeId(
                    inchargeList?.filter((element) => {
                      return (
                        getItemName(element, "identifier", "name") === value
                      );
                    })[0].id
                  );
                } else {
                  setInchargeId(null);
                }
              }}
            />
          </div>
          <div className="-mt-6 w-full">
            <CustomDropDownSearchSelect
              items={
                fieldMotivatorList !== null 
                  ? fieldMotivatorList
                      ?.filter((element) => {
                        return element?.UserMandis.some(el => el.mandiId === mandiId)
                      })
                      ?.map((element) => {
                        return getItemName(element, "identifier", "name");
                      })
                  : []
              }
              label={"Field Motivator"}
              labelDisable={true}
              placeholder={"Field Motivator"}
              selected={
                fieldMotivatorId !== null
                  ? getItemName(
                      searchObject(fieldMotivatorList, fieldMotivatorId, "id"),
                      "identifier",
                      "name"
                    )
                  : null
              }
              handler={(value) => {
                if (value !== null && fieldMotivatorList !== null) {
                  setFieldMotivatorId(
                    fieldMotivatorList.filter((element) => {
                      return (
                        getItemName(element, "identifier", "name") === value
                      );
                    })[0].id
                  );
                } else {
                  setFieldMotivatorId(null);
                }
              }}
            />
          </div>
          <div className="-mt-6 w-full">
            {console.log("Variety", varietyList)}
            <CustomDropDownSearchSelect
              items={
                varietyList !== null
                  ? varietyList?.map((element) => {
                      return getItemName(element, "identifier", "name");
                    })
                  : []
              }
              label={"Variety"}
              labelDisable={true}
              placeholder={"Variety"}
              selected={
                varietyId !== null
                  ? getItemName(
                      searchObject(varietyList, varietyId, "id"),
                      "identifier",
                      "name"
                    )
                  : null
              }
              handler={(value) => {
                if (value !== null && varietyList !== null) {
                  setVarietyId(
                    varietyList?.filter((element) => {
                      return (
                        getItemName(element, "identifier", "name") === value
                      );
                    })[0].id
                  );
                } else {
                  setVarietyId(null);
                }
              }}
            />
          </div>
          {/* <CustomButton btnName="Filter" className="p-2 mt-12 bg-green-500 hover:bg-green-700 text-sm " onClick={applyFilter} /> */}
        </div>
      </div>
      <div
        name="Table"
        className="bg-white ml-3 mt-4 overflow-hidden"
        style={{ height: "470px" }}
      >
        <CustomTable
          rows={currentTableData}
          headers={tableHeaders}
          sortByHandler={setSortBy}
        />
      </div>
      {
        //show the table navigation buttons only if the table has more than 0 records.
        currentTableData.length > 0 ? (
          <div className="text-sm w-max mx-auto text-center flex mt-1">
            <button
              onClick={prevPage}
              className="border-2 text-gray-600 rounded-xl border-black p-1 w-6 h-6 mx-3"
            >
              <ChevronLeftIcon />
            </button>
            <div className="mx-2 mt-1 w-28">
              {getPageButtons(tablePageNumbersShown)}
            </div>
            <button
              onClick={nextPage}
              className="border-2  text-gray-600 rounded-xl border-black p-1 w-6 h-6 mx-3"
            >
              <ChevronRightIcon />
            </button>
          </div>
        ) : null
      }
    </div>
  );
};

export default SeasonalPlanningHome;
