import React from 'react';
import { useHistory } from 'react-router-dom';
import { UserCircleIcon }from '@heroicons/react/solid';
import CustomDropDownMenu from '../atoms/CustomDropDownMenu';

const Header = (props) => {
    const history = useHistory();

    const logout = () =>{
        if(localStorage.getItem("cfUserToken"))
        {
            localStorage.removeItem("cfUserToken");
            history.push("/");
        }
    }
    const changePassword = () =>{
        history.push(`/change-password`);
    }

        return (
            <div className="bg-hero-img6 flex justify-end items-center h-10 pt-1 mb-1">
                <div className="pr-4 flex items-center space-x-4">
                    <CustomDropDownMenu titleContent={<UserCircleIcon className="h-9 w-9 hover text-green-700" />} 
                        options={[
                            {
                                onClick:changePassword,
                                content:<p>Change Password</p>
                            },
                            {
                                onClick:logout,
                                content:<p>Logout</p>
                            }
                        ]}
                    />
                    
                    
                    {/* <CustomButton btnName="logout" className="bg-green-500 hover:bg-green-600 text-sm" onClick={logout}/> */}
                </div>
            </div>
        );
}

export default Header;