import React, { useEffect, useState } from "react";
import { useLocation, Redirect, useHistory } from "react-router-dom";
import { CustomButton } from "../../../components/atoms/CustomButton";
import CustomInputBox from "../../../components/atoms/CustomInputBox";
import axios from "../../../axios";
import { toast } from "react-toastify";
import { PencilIcon } from "@heroicons/react/outline";
import CustomDropDownSearchSelect from "../../../components/atoms/CustomDropDownSearchSelect";
import { successToast, errorToast } from "../../../util/toast";
import { validateFarmerEdit } from "../../Master Data/common/validations";
import { DocumentAddIcon, PlusIcon, TrashIcon } from "@heroicons/react/outline";
import CustomDateSelector from "../../../components/atoms/CustomDateSelector";

/*
    "year": 2021,
    "popId": 1,
    "pakkaAarthiId": 3,
    "kacchaAarthiId": 3,
    "address": "address 2",
    "cycle": "Khareef",
    "seedSource": "own",
    "seedTreatment": 0,
    "name": "Farmer 1",
    "village": "v1",
    "mandiId": 6
*/

const getItemName = (obj, prop1, prop2) => {
  // console.log("OBJECT" , obj)
  if (obj == undefined) return "s";
  return obj[prop2];
};
//returns the first object in the list whose property matches the target
const searchObject = (items, target, property) => {
  console.log(items, target, property);
  return items.filter((element) => {
    return element[property] === target;
  })[0];
};

const FarmerMasterEdit = () => {
  const updatePropList = [
    "year",
    "popId",
    "pakkaAarthiId",
    "kacchaAarthiId",
    "address",
    "cycle",
    "seedSource",
    "seedTreatment",
    "farmer",
    "fatherName",
    "villageId",
    "mandiId",
  ];

  const location = useLocation();
  const history = useHistory();
  const id = JSON.parse(location.state).data.id;
  const [data, setData] = useState(JSON.parse(location.state).data);

  const [villageList, setVillageList] = useState(null);
  const [specificVariety, setSpecificVariety] = useState([]);
  const [varietyList, setVarietyList] = useState([]);
  const [variteisList, setVariteisList] = useState([]);
  console.log("DAARATA", data, JSON.parse(location.state));

  const [aarthiList, setAarthiList] = useState(null);
  const [mandiList, setMandiList] = useState(null);
  const [popList, setPopList] = useState(null);
  const [error, setError] = useState(null);
  const [Jform, setJform] = useState(null);

  const valueSetter = (propertyName, newValue) => {
    const newData = { ...data };
    newData[propertyName] = newValue;
    setData(newData);
  };

  const addVariety = (index) => {
    let Farmer = [...variteisList];
    Farmer = [...Farmer, { landUsed: 0, varietyId: null }];
    setVariteisList(Farmer);
  };
  const removeVariety = (index, varietyIndex) => {
    let Farmer = [...variteisList];
    Farmer = Farmer.filter((element, i) => {
      return i !== varietyIndex;
    });
    setVariteisList(Farmer);
  };

  const addJform = (index) => {
    let Farmer = [...Jform];
    Farmer = [...Farmer, { name: "", acre: null }];
    setJform(Farmer);
  };

  const removeJform = (index) => {
    let Farmer = [...Jform];
    Farmer = Farmer.filter((element, i) => {
      return i !== index;
    });
    setJform(Farmer);
  };

  const varietiesValueSetter = (farmerIndex, varietyIndex, property, value) => {
    if (value !== null) {
      let Farmer = [...variteisList];
      Farmer[varietyIndex][property] = value;
      // setNewFarmer(Farmer);

      console.log("Farmer", Farmer);
      setVariteisList(Farmer);
    }
  };

  const JformValueSetter = (farmerIndex, Index, property, value) => {
    if (value !== null) {
      let Farmer = [...Jform];
      Farmer[Index][property] = value;

      console.log("Farmer", Farmer);
      setJform(Farmer);
    }
  };

  useEffect(() => {
    const newObj = {};
    Object.keys(data).forEach((key) => {
      if (data[key]?.hasOwnProperty("show")) {
        newObj[key] = data[key].value;
      } else {
        newObj[key] = data[key];
      }
    });
    if (Object.keys(newObj).length > 0) setData(newObj);

    axios
      .get(`aarthiMaster/load`)
      .then((response) => {
        setAarthiList(response.data.Result.AarthiMaster);
        return axios.get(`mandiMaster/load`);
      })
      .then((response) => {
        setMandiList(response.data.Result.MandiMaster);
        return axios.get(`pop`);
      })
      .then((response) => {
        setPopList(response.data.Result.Pop);
        return axios.get("villageMasters/load");
      })
      .then((response) => {
        setVillageList(response.data.Result.villageMaster);
        return axios.get(`specificVariety/${data.id}`);
      })
      .then((response) => {
        setSpecificVariety(response.data.Result.FarmerVariety);
        setVariteisList(response.data.Result.FarmerVariety);
        console.log("Hmmm", response.data.Result.FarmerVariety);
        return axios.get("varietyMaster/load");
      })
      .then((response) => {
        setVarietyList(response.data.Result.VarietyMaster);
        return axios.get(`farmerJform/${data.id}`);
      })
      .then((response) => {
        setJform(response.data.Result.Jform);
      })
      .catch((error) => {
        console.log(error);
        errorToast(error?.response?.data?.Comments);
      });
  }, []);

  if (!location.state) {
    return <Redirect to="/" />;
  }

  const createSendingData = async (obj) => {
    return new Promise((resolve, reject) => {
      let newData = {};
      Object.keys(obj).forEach((property, index) => {
        if (updatePropList.includes(property)) {
          newData[property] = obj[property];
        }
        if (index === Object.keys(obj).length - 1) resolve(newData);
      });
    });
  };

  const editFarmer = () => {
    createSendingData(data)
      .then((newData) => {
        newData.ownLand = data.ownLand;
        newData.leasedLand = data.leasedLand;
        newData.leasedLandowner = data.leasedLandowner;
        newData.totalLand = parseInt(data.ownLand) + parseInt(data.leasedLand);
        newData.varitiesList = variteisList;
        newData.village = data.villageId;
        newData.name = data.farmer;
        newData.fatherName = data.fatherName;
        newData.levelingArea = data.levelingArea;
        newData.date = new Date(data.date);
        newData.remarks = data.remarks;
        newData.Jform = Jform;

        if (validateFarmerEdit(newData)) {
          let updatedData = newData;

          // updatedData.name = data.farmer

          delete updatedData["villageId"];
          delete updatedData["farmer"];
          delete updatedData["Jform"];

          console.log("OK", updatedData);
          return axios.put(`farmerMaster/${id}`, updatedData);
        } else throw { message: "please rectify the data" };
      })
      .then((response) => {
        const id = response.data.Result.FarmerMaster.id;
        if (response?.data?.ResponseMessage === "SUCCESS") {
          return axios
            .put(`farmerVarietyList/${id}`, variteisList)
            .then((res) => {
              if (res?.data?.ResponseMessage === "SUCCESS") {
                return axios.put(`farmerJform/${id}`, Jform);
              } else {
                console.log(res.data.ResponseMessage);
              }
            });
        } else {
          errorToast("An error occured");
          console.log(response.data.ResponseMessage);
        }
      })
      .then((response) => {
        if (response?.data?.ResponseMessage === "SUCCESS") {
          successToast("Farmer Updated!");
          history.goBack();
        }
      })
      .catch((error) => {
        errorToast(error?.response?.data?.Comments);
        console.log(error);
      });
  };

  return data !== null ? (
    <div className=" m-3 p-3">
      <div className="flex">
        <span>
          <PencilIcon className="h-5 w-5 transform text-black mt-4 mr-1" />
        </span>
        <span className=" py-4 text-gray-700 text-base font-bold text-left">
          Edit Farmer Master
        </span>
      </div>
      <div className="mb-4 flex">
        <div className="flex w-3/4">
          <div className="flex-wrap flex gap-y-1 m-3 gap-x-2">
            <CustomInputBox
              title="Name"
              value={data.farmer}
              className="w-1/2"
              valueSetter={(value) => {
                valueSetter("farmer", value);
              }}
            />
            <div className="w-full">
              <CustomDropDownSearchSelect
                label={"PoP"}
                items={
                  popList !== null
                    ? popList.map((value) => {
                        // return data.pop;
                        return getItemName(value, "identifier", "pop");
                      })
                    : []
                }
                selected={
                  data.popId !== null && popList !== null && popList.length > 0
                    ? getItemName(
                        popList.filter((element) => {
                          return element.id === data.popId;
                        })[0],
                        "identifier",
                        "pop"
                      )
                    : null
                }
                handler={(value) => {
                  if (value !== null)
                    valueSetter(
                      "popId",
                      popList.filter((element) => {
                        return (
                          getItemName(element, "identifier", "pop") === value
                        );
                      })[0].id
                    );
                  else {
                    valueSetter("popId", null);
                  }
                }}
              />
            </div>
            <CustomInputBox
              title="Father's Name"
              value={data.fatherName}
              className="w-1/2"
              valueSetter={(value) => {
                valueSetter("fatherName", value);
              }}
            />
            <div className="flex gap-x-3 w-full">
              <div className="flex w-full">
                <div className="w-1/2 mr-2">
                  <CustomDropDownSearchSelect
                    label={"Mandi"}
                    items={
                      mandiList !== null
                        ? mandiList.map((value) => {
                            return getItemName(value, "identifier", "name");
                          })
                        : []
                    }
                    selected={
                      data.mandiId !== null &&
                      mandiList !== null &&
                      mandiList.length > 0
                        ? getItemName(
                            searchObject(mandiList, data.mandiId, "id"),
                            "identifier",
                            "name"
                          )
                        : null
                    }
                    handler={(value) => {
                      if (value !== null)
                        valueSetter(
                          "mandiId",
                          mandiList.filter((element) => {
                            return (
                              getItemName(element, "identifier", "name") ==
                              value
                            );
                          })[0].id
                        );
                      else {
                        valueSetter("mandiId", null);
                      }
                    }}
                  />
                </div>
                {console.log("IDDDDDDDDDD", data.village)}
                <div className="w-1/2">
                  <CustomDropDownSearchSelect
                    label={"Village"}
                    items={
                      villageList !== null
                        ? villageList
                            .filter(
                              (val) => data.mandiId === val.MandiMaster.id
                            )
                            .map((value) => {
                              return getItemName(value, "identifier", "name");
                            })
                        : []
                    }
                    selected={
                      data.villageId !== null &&
                      villageList !== null &&
                      villageList.length > 0
                        ? getItemName(
                            searchObject(villageList, data.villageId, "id"),
                            "identifier",
                            "name"
                          )
                        : null
                    }
                    handler={(value) => {
                      if (value !== null)
                        valueSetter(
                          "villageId",
                          villageList.filter((element) => {
                            return (
                              getItemName(element, "identifier", "name") ==
                              value
                            );
                          })[0].id
                        );
                      else {
                        valueSetter("villageId", null);
                      }
                    }}
                  />
                </div>
              </div>
            </div>
            <CustomInputBox
              title="Address"
              value={data.address}
              className="w-1/2"
              valueSetter={(value) => {
                valueSetter("address", value);
              }}
            />
            <div className="w-full">
              <CustomDropDownSearchSelect
                label={"Cycle"}
                items={["Paddy-Wheat", "Paddy-Pea", "Paddy-Potato"]}
                selected={data.cycle}
                handler={(value) => {
                  valueSetter("cycle", value);
                }}
              />
            </div>
            <div className="w-full flex gap-x-2">
              <CustomInputBox
                title="Own Land"
                value={data.ownLand}
                className="w-1/2"
                valueSetter={(value) => {
                  valueSetter("ownLand", value);
                }}
              />
              <CustomInputBox
                title="Leased Land"
                value={data.leasedLand}
                className="w-1/2"
                valueSetter={(value) => {
                  valueSetter("leasedLand", value);
                }}
              />
              <CustomInputBox
                title="Total Land"
                disabled={true}
                value={Number(data.ownLand) + Number(data.leasedLand)}
                className="w-1/2"
                valueSetter={(value) => {
                  valueSetter(
                    "totalLand",
                    Number(data.ownLand) + Number(data.leasedLand)
                  );
                }}
              />
            </div>
            <CustomInputBox
              title="Leased Landowner Name"
              value={data.leasedLandowner}
              className="w-1/2"
              valueSetter={(value) => {
                valueSetter("leasedLandowner", value);
              }}
            />
            {/* <div className=" gap-x-2 w-full flex">
                        
                          <div className="w-full">
                          <CustomDropDownSearchSelect
                            items={["Market", "Own"]}
                            handler={(value) => {
                              valueSetter( "seedSource", value);
                            }}
                            label={"Seed Source"}
                            selected={value["seedSource"]}
                          />  
                          </div>
                          
                            <div className="w-full">
                              
                          <CustomDropDownSearchSelect
                            items={["Yes", "No"]}
                            handler={(value) => {
                              valueSetter(
                                
                                "seedTreatment",
                                value === "Yes" ? 1 : 0
                              );
                            }}
                            label={"Seed Treatment"}
                            selected={
                              value["seedTreatment"] === 1 ? "Yes" : "No"
                            }
                            
                          />
                          
                          </div>

                            
                          
                          <div className="w-full">
                          <CustomDropDownSearchSelect
                            label={"PoP"}
                            items={
                              popList !== null
                                ? popList.map((value) => {
                                    // return data.pop;
                                    return getItemName(value , "identifier" , "pop");
                                  })
                                : []
                            }
                            selected={
                              data.popId !== null &&
                              popList !== null &&
                              popList.length > 0
                                ? getItemName(popList.filter((element) => {
                                    return element.id === data.popId;
                                  })[0] , "identifier" ,"pop")
                                : null
                            }
                            handler={(value) => {
                              if (value !== null)
                                valueSetter(
                                  
                                  "popId",
                                  popList.filter((element) => {
                                    return getItemName(element,"identifier",  "pop") === value;
                                  })[0].id
                                );
                              else {
                                valueSetter( "popId", null);
                              }
                            }}
                          />
                          </div>
                          
                      </div> */}
            <div className="flex gap-x-3 w-full">
              <div className="w-full">
                <CustomDropDownSearchSelect
                  label={"Kaccha Aarthi"}
                  items={
                    aarthiList !== null
                      ? aarthiList
                          .filter((val) => data.mandiId === val.MandiMaster.id)
                          .map((value) => {
                            return getItemName(value, "identifier", "name");
                          })
                      : []
                  }
                  selected={
                    data.kacchaAarthiId !== null &&
                    aarthiList !== null &&
                    aarthiList.length > 0
                      ? getItemName(
                          searchObject(aarthiList, data.kacchaAarthiId, "id"),
                          "identifier",
                          "name"
                        )
                      : null
                  }
                  handler={(value) => {
                    if (value !== null)
                      valueSetter(
                        "kacchaAarthiId",
                        aarthiList.filter((element) => {
                          return (
                            getItemName(element, "identifier", "name") == value
                          );
                        })[0].id
                      );
                    else {
                      valueSetter("kacchaAarthiId", null);
                    }
                  }}
                />
              </div>
              <div className="w-full">
                <CustomDropDownSearchSelect
                  label={"Pakka Aarthi"}
                  items={
                    aarthiList !== null
                      ? aarthiList
                          .filter((val) => data.mandiId === val.MandiMaster.id)
                          .map((value) => {
                            return getItemName(value, "identifier", "name");
                          })
                      : []
                  }
                  selected={
                    data.pakkaAarthiId !== null &&
                    aarthiList !== null &&
                    aarthiList.length > 0
                      ? getItemName(
                          searchObject(aarthiList, data.pakkaAarthiId, "id"),
                          "identifier",
                          "name"
                        )
                      : null
                  }
                  handler={(value) => {
                    if (value !== null)
                      valueSetter(
                        "pakkaAarthiId",
                        aarthiList.filter((element) => {
                          return (
                            getItemName(element, "identifier", "name") == value
                          );
                        })[0].id
                      );
                    else {
                      valueSetter("pakkaAarthiId", null);
                    }
                  }}
                />
              </div>
            </div>
            <div className="flex gap-x-3 w-full">
              <div className="w-full">
                <CustomInputBox
                  title="Laser Leveling Area"
                  value={data.levelingArea}
                  className="w-1/2"
                  valueSetter={(value) => {
                    valueSetter("levelingArea", value);
                  }}
                />
              </div>
              <div className="w-full">
                <CustomDateSelector
                  label={"Date"}
                  date={data.date === null ? null : new Date(data.date)}
                  onChange={(val) => {
                    valueSetter("date", val);
                  }}
                />
              </div>
              <div className="w-full">
                <CustomInputBox
                  title="Remarks"
                  value={data.remarks}
                  className="w-1/2"
                  valueSetter={(value) => {
                    valueSetter("remarks", value);
                  }}
                />
              </div>
            </div>

            <div className="w-full flex">
              <div className="flex flex-wrap w-full">
                {Jform?.map((element, varIndex) => {
                  return (
                    <div className="flex gap-x-3 w-full">
                      <div className="w-full">
                        <CustomInputBox
                          title="Jform Acre"
                          value={element.acre}
                          className="w-1/2"
                          valueSetter={(value) => {
                            JformValueSetter(0, varIndex, "acre", value);
                          }}
                        />
                      </div>

                      <div className="w-full">
                        <CustomInputBox
                          title="Jform Name"
                          value={element.name}
                          className="w-1/2"
                          valueSetter={(value) => {
                            JformValueSetter(0, varIndex, "name", value);
                          }}
                        />
                      </div>
                      {varIndex === 0 ? (
                        <span
                          className="h-5 w-10 mt-8 text-white rounded-md bg-green-500"
                          onClick={() => {
                            addJform(varIndex);
                          }}
                        >
                          <PlusIcon />
                        </span>
                      ) : (
                        <CustomButton
                          btnName="🗑️"
                          bgColor="bg-white"
                          onClick={() => {
                            removeJform(varIndex);
                          }}
                        />
                      )}
                    </div>
                  );
                })}
              </div>
            </div>

            {/* This is the add varieties section */}

            <div className="w-full flex">
              <div className="flex flex-wrap w-full">
                {variteisList?.map((element, varIndex) => {
                  {
                    console.log(
                      "Element",
                      element,
                      searchObject(varietyList, element.varietyId, "id")
                    );
                  }
                  return (
                    <div className="flex gap-x-3 w-full">
                      <div className="w-full">
                        <CustomDropDownSearchSelect
                          label={"Variety"}
                          items={
                            varietyList !== null
                              ? varietyList.map((value) => {
                                  return getItemName(
                                    value,
                                    "identifier",
                                    "name"
                                  );
                                })
                              : []
                          }
                          selected={
                            element.varietyId !== null &&
                            varietyList !== null &&
                            varietyList.length > 0
                              ? getItemName(
                                  searchObject(
                                    varietyList,
                                    element.varietyId,
                                    "id"
                                  ),
                                  "identifier",
                                  "name"
                                )
                              : null
                          }
                          handler={(value) => {
                            if (value !== null)
                              varietiesValueSetter(
                                0,
                                varIndex,
                                "varietyId",
                                varietyList.filter((element) => {
                                  return (
                                    getItemName(
                                      element,
                                      "identifier",
                                      "name"
                                    ) == value
                                  );
                                })[0].id
                              );
                            else {
                              varietiesValueSetter(
                                0,
                                varIndex,
                                "varietyId",
                                null
                              );
                            }
                          }}
                        />
                      </div>

                      <div className="w-full">
                        <CustomInputBox
                          title="Land Used"
                          value={element.landUsed}
                          className="w-1/2"
                          valueSetter={(value) => {
                            varietiesValueSetter(
                              0,
                              varIndex,
                              "landUsed",
                              value
                            );
                          }}
                        />
                      </div>

                      {varIndex === 0 ? (
                        <div className="ml-5 mt-7">
                          <CustomButton
                            btnName="Add variety"
                            onClick={() => {
                              addVariety(0);
                            }}
                          />
                        </div>
                      ) : (
                        <CustomButton
                          btnName="🗑️"
                          bgColor="bg-white"
                          onClick={() => {
                            removeVariety(0, varIndex);
                          }}
                        />
                      )}
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex mt-8 w-1/5 justify-between">
        <CustomButton
          btnName="Cancel"
          className="p-2 bg-gray-500 hover:bg-gray-700 text-lg"
          onClick={() => {
            history.goBack();
          }}
        />
        <CustomButton
          btnName="Update"
          className="p-2 bg-green-500 hover:bg-green-700 text-lg"
          onClick={editFarmer}
        />
      </div>

      <div className="text-xl text-red-500">{error}</div>
      <div className="flex w-max gap-x-10"></div>
    </div>
  ) : null;
};
export default FarmerMasterEdit;
