import React, { useState } from "react";
import { useLocation, Redirect, useHistory } from "react-router-dom";
import { CustomButton } from "../../../components/atoms/CustomButton";
import CustomInputBox from "../../../components/atoms/CustomInputBox";
import axios from "../../../axios";
import { toast } from "react-toastify";
import { PencilIcon } from "@heroicons/react/outline";
import { validateMandiEdit } from "../common/validations";
import { successToast, errorToast } from "../../../util/toast";

const MandiMasterEdit = () => {
  const location = useLocation();
  const history = useHistory();
  const { data } = JSON.parse(location.state);
  const [name, setName] = useState(data?.name.value);
  const [zone, setZone] = useState(data?.zone);
  const [district, setDistrict] = useState(data?.district);
  const [state, setstate] = useState(data?.state);
  const [address, setAddress] = useState(data?.address);
  const [identifier, setIdentifier] = useState(data?.identifier);
  const [error, setError] = useState(null);

  if (!location.state) {
    return <Redirect to="/" />;
  }

  const editMandi = () => {
    if (validateMandiEdit({ name: name })) {
      axios
        .put(`mandiMaster/${data.id}`, { name: name })
        .then((response) => {
          if (response?.data?.ResponseMessage === "SUCCESS") {
            toast.success("Update successful!", { position: "top-center" });
            history.goBack();
          }
        })
        .catch((error) => {
          errorToast(error?.response?.data?.Comments);
          console.log(error);
        });
    }
  };

  return (
    <div className="overflow-hidden m-3 p-3">
      <div className="flex">
        <span>
          <PencilIcon className="h-5 w-5 transform text-black mt-4 mr-1" />
        </span>
        <span className=" py-4 text-gray-700 text-base font-bold text-left">
          Edit mandi master
        </span>
      </div>
      <div className="flex flex-wrap justify-left">
        <div className="w-1/2">
          <div className="grid grid-cols-2 gap-x-3 mb-4">
            <CustomInputBox
              title="State"
              borderDisable={true}
              disabled={true}
              value={data.state.value}
            />
            <CustomInputBox
              title="District"
              borderDisable={true}
              disabled={true}
              value={data.district.value}
            />
            <CustomInputBox
              title="Address"
              borderDisable={true}
              disabled={true}
              value={data.address}
            />
            <CustomInputBox
              title="Zone"
              borderDisable={true}
              disabled={true}
              value={data.zone.value}
            />
            <CustomInputBox
              title="Name"
              value={name}
              className="w-1/2"
              valueSetter={setName}
            />
          </div>
          {/* <p className="mt-4 py-4 text-gray-700 text-left text-base">You can edit the name of the zone.</p> */}
          <div className="flex mt-8 justify-between">
            <CustomButton
              btnName="Cancel"
              className="p-2 bg-gray-500 hover:bg-gray-700 text-lg"
              onClick={() => {
                history.goBack();
              }}
            />
            <CustomButton
              btnName="Update"
              className="p-2 bg-green-500 hover:bg-green-700 text-lg"
              onClick={editMandi}
            />
          </div>
        </div>
      </div>
      <div className="text-xl text-red-500">{error}</div>
      <div className="flex w-max gap-x-10"></div>
    </div>
  );
};
export default MandiMasterEdit;
