import { React, useEffect, useState } from "react";
import { useLocation, Redirect } from "react-router-dom";
import { EyeIcon } from "@heroicons/react/outline";
import axios from "../../../axios";
import { errorToast } from "../../../util/toast";
import CustomInputBox from "../../../components/atoms/CustomInputBox";

const CropLifeCycleDisplay = () => {
  const location = useLocation();
  const [initialData, setInitialData] = useState(
    JSON.parse(location.state).data
  );

  const [fertilizerList, setFertilizerList] = useState(null);
  const [data, setData] = useState(null);
  const [cropHealthList, setCropHealthList] = useState(null);
  const [cropStageList, setCropStageList] = useState(null);

  const [dateArray, setDateArray] = useState([]);
  const [stageArray, setStageArray] = useState([]);
  const [chemicalList, setChemicalList] = useState(null);

  console.log(dateArray, "Date Array");
  console.log(stageArray, "Stage Array");

  const getItemName = (obj, prop1, prop2) => {
    return obj[prop2];
  };

  const searchObject = (items, target, property) => {
    return items.filter((element) => {
      return element[property] === target;
    })[0];
  };

  useEffect(() => {
    axios
      .get(`cropStage`)
      .then((response) => {
        setCropStageList(response.data.Result.CropStage);
        return axios.get(`fertilizerMaster/load`);
      })
      .then((response) => {
        setFertilizerList(response.data.Result.FertilizerMaster);
        return axios.get(`chemicalMasters/load`);
      })
      .then((response) => {
        setChemicalList(response.data.Result.ChemicalMaster);
      });

    if (initialData.id !== undefined)
      axios
        .get(`allVisit/${initialData.id}`)
        .then((response) => {
          console.log("Result is", response.data.Result.AllVisit);
          let temp = response.data.Result.AllVisit;
          if (temp.recommended) {
            temp.recommended = JSON.parse(temp.recommended);
          }
          if (temp.used) {
            temp.used = JSON.parse(temp.used);
          }
          setData(temp);
          const uniqueDate = [
            ...new Map(
              JSON.parse(response.data.Result.AllVisit?.irrigationDates)?.map(
                (item, key) => [item["index"], item]
              )
            ).values(),
          ];
          setDateArray(uniqueDate);

          const uniqueStage = [
            ...new Map(
              JSON.parse(response.data.Result.AllVisit?.irrigationStages)?.map(
                (item, key) => [item["index"], item]
              )
            ).values(),
          ];
          setStageArray(uniqueStage);
        })
        .catch((error) => {
          console.log(error);
          errorToast(error?.message);
        });
  }, [initialData]);

  const getDateString = (date) => {
    let year = date.getFullYear();
    let month = date.getMonth() + 1;
    month = month < 10 ? "0" + month : month;
    let day = date.getDate();
    day = day < 10 ? "0" + day : day;
    return year + "/" + month + "/" + day;
  };

  if (!initialData) return <Redirect to="/" />;
  return data !== null ? (
    <div className="overflow-hidden m-3 p-3 ">
      <div className="flex">
        <span>
          <EyeIcon className="h-5 w-5 transform text-black mt-4 mr-1" />
        </span>
        <span className=" py-4 text-gray-700 text-base font-bold text-left">
          Display All Visit
        </span>
      </div>
      <div className="flex flex-wrap">
        <div className="">
          <div className=" grid grid-cols-4  gap-x-5 gap-x-3 mb-4">
            {/* <CustomDisplayBox title="ID" value={data.id} className=" pl-2"/> */}
            {/* <CustomInputBox
              title={"Farmer Code"}
              disabled={true}
              borderDisable={true}
              value={data.FarmerVariety.FarmerMaster.id}
            /> */}
            <CustomInputBox
              title={"Farmer Name"}
              disabled={true}
              borderDisable={true}
              value={data.FarmerVariety.FarmerMaster.name}
            />
            <CustomInputBox
              title={"Visit Date"}
              disabled={true}
              borderDisable={true}
              value={
                data.visitDate !== null
                  ? getDateString(new Date(data.visitDate))
                  : null
              }
            />
            <CustomInputBox
              title={"Contact Person"}
              disabled={true}
              borderDisable={true}
              value={data.contactPerson}
            />
            <CustomInputBox
              title={"Contact PersonMobile"}
              disabled={true}
              borderDisable={true}
              value={data.contactPersonMobile}
            />

            <CustomInputBox
              title={"Crop Stage"}
              disabled={true}
              borderDisable={true}
              value={
                cropStageList !== null && data.cropStageId !== null
                  ? getItemName(
                      searchObject(cropStageList, data.cropStageId, "id"),
                      "identifier",
                      "name"
                    )
                  : null
              }
            />

            <CustomInputBox
              title={"Other Recommendation"}
              disabled={true}
              borderDisable={true}
              value={data.otherRecommendation}
            />
            <CustomInputBox
              title={"Variety Name"}
              disabled={true}
              borderDisable={true}
              value={data.FarmerVariety.VarietyMaster.name}
            />
            <CustomInputBox
              title={"Land Used"}
              disabled={true}
              borderDisable={true}
              value={data.FarmerVariety.landUsed}
            />

            <CustomInputBox
              title={"Unresolved Farmer's Query"}
              disabled={true}
              borderDisable={true}
              value={data.unresolvedFarmerQuery}
            />
            <CustomInputBox
              title={"Actual Land Used"}
              disabled={true}
              borderDisable={true}
              value={data.FarmerVariety.actualLandUsed}
            />
            <CustomInputBox
              title={"Is Acreage Verified?"}
              disabled={true}
              borderDisable={true}
              value={data.FarmerVariety.isAcreageVerified === 1 ? "Yes" : "No"}
            />
            <CustomInputBox
              title={"General Remark"}
              disabled={true}
              borderDisable={true}
              value={data.generalRemarks}
            />
            <CustomInputBox
              title={"Action Required"}
              disabled={true}
              borderDisable={true}
              value={data.actionRequired === 1 ? "Yes" : "No"}
            />
            <CustomInputBox
              title={"Is Variety Verified"}
              disabled={true}
              borderDisable={true}
              value={data.FarmerVariety.isVarietyVerified === 1 ? "Yes" : "No"}
            />
            <div>
              <div className="text-sm text-gray-500">Used Fertilizer</div>
              {fertilizerList != null && data?.usedFertilizerIdArray != null
                ? JSON.parse(data?.usedFertilizerIdArray)?.map((id, index) => {
                    return (
                      <div className="text-sm flex">
                        <span>
                          {index + 1}){" "}
                          {getItemName(
                            searchObject(fertilizerList, id, "id"),
                            "identifier",
                            "name"
                          )}
                        </span>
                      </div>
                    );
                  })
                : null}
            </div>
            <CustomInputBox
              title={"Fertilizer Used Date"}
              disabled={true}
              borderDisable={true}
              value={
                data.usedFertilizerDate !== null
                  ? getDateString(new Date(data.usedFertilizerDate))
                  : null
              }
            />

            <CustomInputBox
              title={"Crop Health"}
              disabled={true}
              borderDisable={true}
              value={data.CropHealth.name}
            />

            <div>
              <div className="text-sm text-gray-500">Insects/Diseases</div>
              {data?.InsectDiseaseLists?.map((obj, index) => {
                return (
                  <div className="text-sm flex">
                    <span>
                      {index + 1}) {obj.InsectsDiseasesMaster.insectDiseaseName}
                    </span>
                  </div>
                );
              })}
            </div>

            <div>
              <div className="text-sm text-gray-500">
                Recommended Fertilizer
              </div>
              {fertilizerList != null && data?.fertilizerIdArray != null
                ? JSON.parse(data?.fertilizerIdArray).map((id, index) => {
                    return (
                      <div className="text-sm flex">
                        <span>
                          {index + 1}){" "}
                          {getItemName(
                            searchObject(fertilizerList, id, "id"),
                            "identifier",
                            "name"
                          )}
                        </span>
                      </div>
                    );
                  })
                : null}
            </div>
            <CustomInputBox
              title={"Used Other Ingredients"}
              disabled={true}
              borderDisable={true}
              value={data?.usedOtherIngredient}
            />
            <CustomInputBox
              title={"Other Ingredient Used Date"}
              disabled={true}
              borderDisable={true}
              value={
                data?.otherIngredientUsedDate !== null
                  ? getDateString(new Date(data?.otherIngredientUsedDate))
                  : null
              }
            />
            <CustomInputBox
              title={"Number of irrigations from last visit"}
              disabled={true}
              borderDisable={true}
              value={data.waterUsage}
            />
            <div className="w-20"></div>
            <div className="w-20"></div>
            <div className="w-20"></div>
            {stageArray.map((item, index) => {
              return (
                <>
                  <div className="flex flex-row">
                    <CustomInputBox
                      title={"Date"}
                      disabled={true}
                      borderDisable={true}
                      value={getDateString(new Date(dateArray[index]?.date))}
                    />
                    <CustomInputBox
                      title={"Crop Stage"}
                      disabled={true}
                      borderDisable={true}
                      value={item.value}
                    />
                  </div>
                  <div className="w-20"></div>
                  <div className="w-20"></div>
                  <div className="w-20"></div>
                </>
              );
            })}
            <br />
          </div>
          <div>
            <div className="text-sm text-gray-500 mb-4">
              Recommended Chemicals
            </div>
            {data?.recommended?.map((obj, index) => {
              return (
                <div className="text-sm flex">
                  <span className="mr-2">{index + 1})</span>
                  <CustomInputBox
                    title={"Recommended Ingredient"}
                    disabled={true}
                    borderDisable={true}
                    value={obj?.ingredient}
                  />
                  <CustomInputBox
                    title={"Recommended Brand"}
                    disabled={true}
                    borderDisable={true}
                    value={obj?.usesType}
                  />
                  <CustomInputBox
                    title={"Recommended UsesType"}
                    disabled={true}
                    borderDisable={true}
                    value={obj?.brand}
                  />
                </div>
              );
            })}
          </div>
          <br />
          <br />
          <div>
            <div className="text-sm text-gray-500 mb-4">Used Chemicals</div>
            {data?.used?.map((obj, index) => {
              return (
                <div className="text-sm flex">
                  <span className="mr-2">{index + 1}) </span>

                  <CustomInputBox
                    title={"Used Chemical Ingredient"}
                    disabled={true}
                    borderDisable={true}
                    value={obj?.ingredient}
                  />
                  <CustomInputBox
                    title={"Used Chemical Brand"}
                    disabled={true}
                    borderDisable={true}
                    value={obj?.usesType}
                  />
                  <CustomInputBox
                    title={"Used Chemical UsesType"}
                    disabled={true}
                    borderDisable={true}
                    value={obj?.brand}
                  />
                  <CustomInputBox
                    title={"Used Chemical Date"}
                    disabled={true}
                    borderDisable={true}
                    value={getDateString(new Date(obj.date))}
                  />
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <div className="flex w-max gap-x-10"></div>
    </div>
  ) : null;
};
export default CropLifeCycleDisplay;
