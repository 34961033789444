import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import UsersHome from "./UsersHome";
import UsersEdit from "./UsersEdit";
import UsersDisplay from "./UsersDisplay";
import UsersAdd from "./UsersAdd";
import MasterDataDelete from "../common/MasterDataDelete";

const UsersRoutes = () => {
  return (
    <BrowserRouter basename="/home/masterData/users/">
      <Switch>
        <Route path="/" component={UsersHome} exact />
        <Route path="/add" component={UsersAdd} />
        <Route path="/edit/:id" component={UsersEdit} />
        <Route path="/delete/:id" component={MasterDataDelete} />
        <Route path="/display" component={UsersDisplay} />
        <Route component={() => <Redirect to="/" />} />
      </Switch>
    </BrowserRouter>
  );
};

export default UsersRoutes;
