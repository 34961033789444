import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import { UserCircleIcon }from '@heroicons/react/solid'
import { CustomButton } from '../atoms/CustomButton';

const Navbar = (props) => {
    const history = useHistory();

    const logout = () =>{
        if(localStorage.getItem("cfUserToken"))
        {
            localStorage.removeItem("cfUserToken");
            history.push("/");
        }
    }

    if (props.value) {
        return (
            <div className="flex  justify-between items-center h-16 w-full">
                <div className="font-robo text-xl text-black font-semibold tracking-widest ml-4 p-2 rounded-xl">
                    <Link to="/">Control Farming</Link>
                </div>
                <div className="pr-4 flex justify-between items-center">
                    <UserCircleIcon className="h-12 w-12 text-green-700" />
                    <CustomButton btnName="logout" className="bg-green-500 hover:bg-green-600 text-sm" onClick={logout}/>
                </div>
            </div>
        );
    } else {
        return (
            <div className="flex justify-between items-center h-16 w-full">
                <div className="font-robo text-xl font-semibold tracking-widest pl-4">
                    <Link to="/">Control Farming</Link>
                </div>
    
            </div>
        );
    }
}

export default Navbar;