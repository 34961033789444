import React, { useState } from "react";
import { useHistory } from "react-router";
import { CustomButton } from "../../../components/atoms/CustomButton";
import axios from "../../../axios";
import { DocumentAddIcon, PlusIcon, TrashIcon } from "@heroicons/react/outline";
import CustomInputBox from "../../../components/atoms/CustomInputBox";
import { successToast, errorToast } from "../../../util/toast";
import { validateZoneAdd } from "../common/validations";

const ZoneMasterAdd = () => {
  const history = useHistory();
  const [newZones, setNewZones] = useState([{ name: "" }]);
  const [isSubmit, setIsSubmit] = useState(false);

  const valueSetter = (index, prop, newValue) => {
    let zones = [...newZones];
    zones[index][prop] = newValue;
    zones[index][prop] = newValue;
    setNewZones(zones);
  };

  const addZones = () => {
    if (validateZoneAdd(newZones)) {
      setIsSubmit(true);
      successToast("Your request is being processed");
      axios
        .post("zoneMasters/", newZones)
        .then((response) => {
          if (response?.data?.ResponseMessage === "SUCCESS") {
            successToast("New zones added!");
            history.goBack();
          }
        })
        .catch((error) => {
          errorToast(error?.response?.data?.Comments);
          console.log(error);
        });
    } else {
      setIsSubmit(false);
    }
  };

  return (
    <div className="p-3 m-3">
      <div className="flex mb-7">
        <DocumentAddIcon className="h-5 w-5text-black mt-3 mr-1" />
        <div className="text-base pt-3 font-bold text-gray-800">
          Add zone master
        </div>
      </div>
      <div>
        {newZones.map((value, index) => {
          return (
            <div className="mb-4 flex flex-wrap w-1/2">
              <div className="flex w-full gap-x-2">
                <div className="w-full">
                  <CustomInputBox
                    title="Zone name"
                    value={newZones[index].name}
                    valueSetter={(value) => {
                      valueSetter(index, "name", value);
                    }}
                  />
                </div>
                {
                  newZones.length - 1 !== index ? (
                    <span
                      onClick={() => {
                        setNewZones(
                          newZones.filter((v, i) => {
                            return i !== index;
                          })
                        );
                      }}
                      className="h-5 w-8 ml-2 mt-8 text-gray-500 hover:text-red-500 hover:p-1"
                    >
                      <TrashIcon />
                    </span>
                  ) : (
                    <span
                      className="h-5 w-5 ml-2 mt-8 text-white rounded-md bg-green-500"
                      onClick={() => {
                        setNewZones([...newZones, { name: "" }]);
                      }}
                    >
                      <PlusIcon />
                    </span>
                  )
                  // :<CustomButton className=" mt-8 ml-3 text-sm p-1 bg-green-500" btnName={<span className="h-3 w-3"><PlusIcon /></span>} onClick={() => { setNewZones([...newZones, { name: "" }]) }} />
                }
              </div>
            </div>
          );
        })}
      </div>
      <div className="flex w-1/2 justify-between">
        <CustomButton
          btnName="Cancel"
          bgColor="bg-gray-400"
          onClick={() => {
            history.goBack();
          }}
        />
        {!isSubmit ? (
          <CustomButton btnName="Submit" onClick={addZones} />
        ) : (
          <CustomButton btnName="Submit" onClick={() => {}} />
        )}
      </div>
    </div>
  );
};
export default ZoneMasterAdd;
