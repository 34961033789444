import { React, useEffect, useState } from "react";
import { useLocation, Redirect } from "react-router-dom";
import { EyeIcon } from "@heroicons/react/outline";
import axios from "../../../axios";
import { errorToast } from "../../../util/toast";
import CustomInputBox from "../../../components/atoms/CustomInputBox";

const InitialValidationDisplay = () => {
  const location = useLocation();
  const [initialData, setInitialData] = useState(
    JSON.parse(location.state).data
  );
  const [data, setData] = useState(null);

  useEffect(() => {
    if (initialData.id !== undefined)
      axios
        .get(`initialValidation/${initialData.id}`)
        .then((response) => {
          console.log("Result is", response.data.Result.InitialValidation[0]);
          setData(response.data.Result.InitialValidation[0]);
        })
        .catch((error) => {
          console.log(error);
          errorToast(error?.message);
        });
  }, [initialData]);

  if (!initialData) return <Redirect to="/" />;
  return data !== null ? (
    <div className="overflow-hidden m-3 p-3 ">
      <div className="flex">
        <span>
          <EyeIcon className="h-5 w-5 transform text-black mt-4 mr-1" />
        </span>
        <span className=" py-4 text-gray-700 text-base font-bold text-left">
          Display Initial Validation
        </span>
      </div>
      <div className="flex flex-wrap">
        <div className=" grid grid-cols-2  gap-x-5 gap-x-3 mb-4">
          {/* <CustomDisplayBox title="ID" value={data.id} className=" pl-2"/> */}

          <CustomInputBox
            title={"Farmer"}
            value={`${data.FarmerMaster.name}`}
            disabled={true}
            borderDisable={true}
          />

          <CustomInputBox
            title={"Land Used"}
            value={data.landUsed}
            disabled={true}
            borderDisable={true}
          />

          <CustomInputBox
            title={"Variety Name"}
            value={`${data.VarietyMaster.name}`}
            disabled={true}
            borderDisable={true}
          />

          <CustomInputBox
            title={"Actual Land Used"}
            value={
              data.actualLandUsed === null ? "[unknown]" : data.actualLandUsed
            }
            disabled={true}
            borderDisable={true}
          />

          <CustomInputBox
            title={"Is Variety Verified?"}
            value={data.isVarietyVerified ? "Yes" : "No"}
            disabled={true}
            borderDisable={true}
          />

          <CustomInputBox
            title={"Is Acreage Verified?"}
            value={data.isAcreageVerified ? "Yes" : "No"}
            disabled={true}
            borderDisable={true}
          />
        </div>
      </div>
      <div className="flex w-max gap-x-10"></div>
    </div>
  ) : null;
};
export default InitialValidationDisplay;
