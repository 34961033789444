import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router";
import { CustomButton } from "../../../components/atoms/CustomButton";
import axios from "../../../axios";
import { DocumentAddIcon } from "@heroicons/react/outline";
import CustomInputBox from "../../../components/atoms/CustomInputBox";
import { successToast, errorToast } from "../../../util/toast";
import CustomDropDownSearchSelect from "../../../components/atoms/CustomDropDownSearchSelect";
import { validateEstimationAdd } from "../../Master Data/common/validations";

const ProductionEstimationAdd = ({ match }) => {
  const history = useHistory();
  const location = useLocation();
  const [farmerVariety, setFarmerVariety] = useState(
    JSON.parse(location.state).data
  );

  console.log("FArmer", farmerVariety, new Date(farmerVariety.sowingDate));
  const [harvestingMethodList, setHarvestingMethodList] = useState(null);
  const [harvestingMethodId, setHarvestingMethodId] = useState(null);
  const [isSubmit, setIsSubmit] = useState(false);
  // const [actualYield  , setActualYield]= useState(0);
  // const [expectedYield  , setExpectedYield]= useState(null);
  useEffect(() => {
    axios
      .get(`harvestingMethod`)
      .then((response) => {
        setHarvestingMethodList(response.data.Result.HarvestingMethod);
        console.log(response.data.Result.HarvestingMethod);
      })
      .catch((error) => {
        console.log(error);
        errorToast(error?.response?.data?.Comments);
      });
  }, []);

  useEffect(() => {
    console.log("JERE", farmerVariety);
    const zoneId = farmerVariety.FarmerMaster.MandiMaster.zoneId;
    const varietyId = farmerVariety.VarietyMaster.id;

    axios
      .get("expectedYield/", {
        params: { zoneId: zoneId, varietyId: varietyId },
      })
      .then((response) => {
        console.log(response.data.Result.YieldMaster);
        if (!response.data.Result.YieldMaster.farmYield) {
          errorToast(
            "Expected Yield not filled in Yield Master for the given data!"
          );
        }
        valueSetter(
          0,
          "expectedYield",
          response.data.Result.YieldMaster.farmYield
        );
      })
      .catch((e) => {
        console.log(e);
        errorToast(
          "Expected Yield not filled in Yield Master for the given data!"
        );
      });
  }, []);

  const getItemName = (obj, prop1, prop2) => {
    return obj[prop2];
  };
  //returns the first object in the list whose property matches the target
  const searchObject = (items, target, property) => {
    return items.filter((element) => {
      return element[property] === target;
    })[0];
  };

  const getDateString = (date) => {
    let year = date.getFullYear();
    let month = date.getMonth() + 1;
    month = month < 10 ? "0" + month : month;
    let day = date.getDate();
    day = day < 10 ? "0" + day : day;

    return day + "/" + month + "/" + year;
  };

  const addDaysToDate = (date, days) => {
    let newDate = date;
    newDate.setDate(newDate.getDate() + days);
    return newDate;
  };

  const makeDataObject = () => {
    return {
      farmerId: farmerVariety.FarmerMaster.id,
      varietyId: farmerVariety.VarietyMaster.id,
      expectedYield: null,
      expectedProduction: null,
      actualYield: null,
      actualProduction: null,
      harvestingMethodId: null,
    };
  };
  const [newProductionEstimation, setNewProductionEstimations] = useState([
    makeDataObject(),
  ]);

  const valueSetter = (index, property, newValue) => {
    let productionEstimation = [...newProductionEstimation];
    productionEstimation[index][property] = newValue;
    setNewProductionEstimations(productionEstimation);
  };

  const checkAll = () => {
    let flag = true;
    for (let i = 0; i < newProductionEstimation.length; i++) {
      const temp = newProductionEstimation[i];
      if (temp.isAcreageVerified === 0) {
        if (
          temp.actualLandUsed.trim() === "" ||
          Number(temp.actualLandUsed === NaN)
        ) {
          flag = false;
          errorToast("Please enter actual land used!");
        }
      }
    }
    return flag;
  };

  const addProductionEstimations = () => {
    console.log(newProductionEstimation);
    newProductionEstimation[0]["actualProduction"] =
      newProductionEstimation[0]["actualYield"] *
      farmerVariety.actualLandUsed.value;
    newProductionEstimation[0]["expectedProduction"] =
      newProductionEstimation[0]["expectedYield"] *
      farmerVariety.actualLandUsed.value;
    if (validateEstimationAdd(newProductionEstimation)) {
      setIsSubmit(true);
      successToast("Your request is being processed");
      console.log(newProductionEstimation);
      axios
        .post("productionEstimation/", newProductionEstimation)
        .then((response) => {
          if (response?.data?.ResponseMessage === "SUCCESS") {
            successToast("New production estimation added!");
            history.push("home/transactionData/estimation");
          }
        })
        .catch((error) => {
          errorToast(error?.response?.data?.Comments);
          console.log(error);
        });
    } else {
      setIsSubmit(false);
    }
  };

  return (
    <div className="p-3 m-3">
      <div className="flex mb-7">
        <DocumentAddIcon className="h-5 w-5text-black mt-3 mr-1" />
        <div className="text-base pt-3 font-bold text-gray-800">
          Add Production Estimation
        </div>
      </div>
      <div>
        {newProductionEstimation !== null
          ? newProductionEstimation.map((value, index) => {
              return (
                <div className="mb-10 grid grid-cols-2 w-1/2 gap-x-4">
                  <CustomInputBox
                    title={"Farmer"}
                    value={getItemName(
                      farmerVariety.FarmerMaster,
                      "identifier",
                      "name"
                    )}
                    disabled={true}
                    borderDisable={true}
                  />
                  <CustomInputBox
                    title={"Variety"}
                    value={getItemName(
                      farmerVariety.VarietyMaster,
                      "identifier",
                      "name"
                    )}
                    disabled={true}
                    borderDisable={true}
                  />
                  {console.log(farmerVariety.sowingDate)}
                  <CustomInputBox
                    title={"Sowing Date"}
                    value={
                      farmerVariety.sowingDate === null
                        ? "[unknown]"
                        : getDateString(new Date(farmerVariety.sowingDate))
                    }
                    disabled={true}
                    borderDisable={true}
                  />
                  <CustomInputBox
                    title={"Seed To Seed Date"}
                    value={
                      farmerVariety.sowingDate !== null
                        ? getDateString(
                            addDaysToDate(
                              new Date(farmerVariety.sowingDate),
                              farmerVariety.VarietyMaster.seedToSeed
                            )
                          )
                        : "[unknown]"
                    }
                    disabled={true}
                    borderDisable={true}
                  />

                  <CustomInputBox
                    title={"Actual Land Used"}
                    disabled={true}
                    borderDisable={true}
                    value={`${farmerVariety.actualLandUsed.value}`}
                  />
                  <CustomDropDownSearchSelect
                    items={
                      harvestingMethodList != null
                        ? harvestingMethodList.map((item) => {
                            // return item.name;
                            return getItemName(item, "identifier", "name");
                          })
                        : []
                    }
                    selected={
                      value.harvestingMethodId !== null &&
                      harvestingMethodList !== null
                        ? getItemName(
                            harvestingMethodList.filter((item) => {
                              return item.id === value.harvestingMethodId;
                            })[0],
                            "identifier",
                            "name"
                          )
                        : null
                    }
                    handler={(value) => {
                      if (value !== null) {
                        setHarvestingMethodId(
                          harvestingMethodList.filter(
                            (item) =>
                              getItemName(item, "identifier", "name") === value
                          )[0].id
                        );

                        valueSetter(
                          index,
                          "harvestingMethodId",
                          harvestingMethodList.filter(
                            (item) =>
                              getItemName(item, "identifier", "name") === value
                          )[0].id
                        );
                      } else {
                        setHarvestingMethodId(null);
                        valueSetter(index, "harvestingMethodId", null);
                      }
                    }}
                    label={"Harvesting Method"}
                  />

                  <CustomInputBox
                    title={"Expected Yield"}
                    value={value.expectedYield}
                    valueSetter={(value) => {
                      valueSetter(index, "expectedYield", value);
                    }}
                    disabled={true}
                  />
                  <CustomInputBox
                    title={"Expected Production"}
                    value={
                      value.expectedYield * farmerVariety.actualLandUsed.value
                    }
                    disabled={true}
                    valueSetter={(value) => {
                      valueSetter(
                        index,
                        "expectedProduction",
                        value.expectedYield * farmerVariety.actualLandUsed.value
                      );
                    }}
                  />

                  <CustomInputBox
                    title={"Estimated Yield"}
                    value={value.actualYield}
                    valueSetter={(value) => {
                      // setActualYield(value)
                      // console.log('value' , value , parseInt(value))
                      if (value != null && value != "")
                        valueSetter(index, "actualYield", parseInt(value));
                      else valueSetter(index, "actualYield", null);
                    }}
                  />
                  <CustomInputBox
                    title={"Actual Production"}
                    value={
                      value.actualYield * farmerVariety.actualLandUsed.value
                    }
                    valueSetter={(value) => {
                      valueSetter(
                        index,
                        "actualProduction",
                        value.actualYield * farmerVariety.actualLandUsed.value
                      );
                    }}
                    disabled={true}
                  />

                  {console.log(harvestingMethodList)}
                </div>
              );
            })
          : null}
      </div>
      <div className="flex w-1/2 justify-between">
        <CustomButton
          btnName="Cancel"
          bgColor="bg-gray-400"
          onClick={() => {
            history.goBack();
          }}
        />
        {!isSubmit ? (
          <CustomButton btnName="Submit" onClick={addProductionEstimations} />
        ) : (
          <CustomButton btnName="Submit" onClick={() => {}} />
        )}
      </div>
    </div>
  );
};
export default ProductionEstimationAdd;
