import React from 'react'

export const CustomButton = ({type, onClick, btnName, bgColor, icon, width , id}) => {
    return (
                <button style={width!=undefined? {width:width}:{}} type={type} className={`flex text-sm w-20 justify-center p-1 my-auto align-middle rounded-md text-white ${bgColor?bgColor:"bg-green-500"}`} onClick={onClick} id={id?id:null}>
                {
                    icon ? <span>{icon}</span> : null
                }
                {btnName?btnName:null}
                </button>
    )
}

//mx-auto 
 //w-24