import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { CustomButton } from "../../../components/atoms/CustomButton";
import axios from "../../../axios";
import { DocumentAddIcon, PlusIcon, TrashIcon } from "@heroicons/react/outline";
import CustomInputBox from "../../../components/atoms/CustomInputBox";
import { successToast, errorToast } from "../../../util/toast";
import CustomDropDownSearchSelect from "../../../components/atoms/CustomDropDownSearchSelect";
import { validateAarthiAdd } from "../common/validations";

const getItemName = (obj, prop1) => {
  return obj[prop1];
};
//returns the first object in the list whose property matches the target
const searchObject = (items, target, property) => {
  return items.filter((element) => {
    return element[property] === target;
  })[0];
};

const AarthiMasterAdd = () => {
  const history = useHistory();
  const [newAarthi, setNewAarthi] = useState([
    {
      name: "",
      identifier: null,
      mandiId: null,
      contactPerson: "",
      shopNumber: null,
      mobile: null,
      email: null,
    },
  ]);
  const [mandiList, setMandiList] = useState([]);
  const [isSubmit, setIsSubmit] = useState(false);

  useEffect(() => {
    axios
      .get(`mandiMaster/load`)
      .then((response) => {
        setMandiList(response.data.Result.MandiMaster);
      })
      .catch((error) => {
        console.log(error.message);
        errorToast(error?.response?.data?.Comments);
      });
  }, []);

  const valueSetter = (index, property, newValue) => {
    if (newValue !== null) {
      let Aarthi = [...newAarthi];
      Aarthi[index][property] = newValue;
      setNewAarthi(Aarthi);
    }
  };

  const addAarthi = () => {
    console.log(newAarthi);
    if (validateAarthiAdd(newAarthi)) {
      setIsSubmit(true);
      successToast("Your request is being processed");
      axios
        .post("aarthiMaster/", newAarthi)
        .then((response) => {
          if (response?.data?.ResponseMessage === "SUCCESS") {
            successToast("New Aarthi added!");
            history.goBack();
          }
        })
        .catch((error) => {
          errorToast(error?.response?.data?.Comments);
          console.log(error);
        });
    } else {
      setIsSubmit(false);
    }
  };

  return (
    <div className="p-3 m-3">
      <div className="flex mb-7">
        <DocumentAddIcon className="h-5 w-5text-black mt-3 mr-1" />
        <div className="text-base pt-3 font-bold text-gray-800">Add Aarthi</div>
      </div>
      <div className="w-full">
        <ol style={{ listStyleType: "number" }}>
          {newAarthi.map((value, index) => {
            return (
              <li>
                <div className="mb-4 flex">
                  <div className="flex w-1/2">
                    <div className="flex-wrap flex gap-y-4 m-3 gap-x-2 w-full">
                      <div className="w-full">
                        <CustomInputBox
                          title="Aarthi Code"
                          value={newAarthi[index].identifier}
                          valueSetter={(value) => {
                            valueSetter(index, "identifier", value);
                          }}
                        />
                        <CustomInputBox
                          title="Aarthi  Name"
                          value={newAarthi[index].name}
                          valueSetter={(value) => {
                            valueSetter(index, "name", value);
                          }}
                        />
                        <CustomInputBox
                          title="Contact Person"
                          value={newAarthi[index].contactPerson}
                          valueSetter={(value) => {
                            valueSetter(index, "contactPerson", value);
                          }}
                        />
                        <CustomInputBox
                          title="Shop Number"
                          value={newAarthi[index].shopNumber}
                          valueSetter={(value) => {
                            valueSetter(index, "shopNumber", value);
                          }}
                        />
                        <CustomInputBox
                          title="Mobile"
                          value={newAarthi[index].mobile}
                          valueSetter={(value) => {
                            valueSetter(index, "mobile", value);
                          }}
                        />
                        <CustomInputBox
                          title="Email"
                          value={newAarthi[index].email}
                          valueSetter={(value) => {
                            valueSetter(index, "email", value);
                          }}
                        />
                      </div>
                      <div className="flex gap-x-4 w-full">
                        {/* Mandi Drop Down */}
                        <div className="w-full">
                          <CustomDropDownSearchSelect
                            label={"Mandi"}
                            items={mandiList.map((value) => {
                              return getItemName(value, "name");
                            })}
                            selected={
                              value.mandiId !== null && mandiList.length > 0
                                ? getItemName(
                                    searchObject(
                                      mandiList,
                                      value.mandiId,
                                      "id"
                                    ),
                                    "name"
                                  )
                                : null
                            }
                            handler={(value) => {
                              if (value !== null)
                                valueSetter(
                                  index,
                                  "mandiId",
                                  mandiList.filter((element) => {
                                    return (
                                      getItemName(element, "name") == value
                                    );
                                  })[0].id
                                );
                              else {
                                valueSetter(index, "mandiId", null);
                              }
                            }}
                          />
                        </div>
                        {/* PoP dropdown */}
                        <div className="h-5 w-5">
                          {
                            newAarthi.length - 1 !== index ? (
                              <div
                                onClick={() => {
                                  setNewAarthi(
                                    newAarthi.filter((v, i) => {
                                      return i !== index;
                                    })
                                  );
                                }}
                                className="h-5 w-5 ml-2 mt-8 text-gray-500 hover:text-red-500 hover:p-1"
                              >
                                <TrashIcon />
                              </div>
                            ) : (
                              <div
                                className="h-4 w-4 ml-2 mt-8 text-white rounded-md bg-green-500"
                                onClick={() => {
                                  setNewAarthi([
                                    ...newAarthi,
                                    {
                                      name: "",
                                      identifier: null,
                                      mandiId: null,
                                      contactPerson: "",
                                      shopNumber: null,
                                      mobile: null,
                                      email: null,
                                    },
                                  ]);
                                }}
                              >
                                <PlusIcon />
                              </div>
                            )
                            // :<CustomButton className=" mt-8 ml-3 text-sm p-1 bg-green-500" btnName={<span className="h-3 w-3"><PlusIcon /></span>} onClick={() => { setNewAarthi([...newAarthi, { name: "" }]) }} />
                          }
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
            );
          })}
        </ol>
      </div>
      <div className="flex ml-2 w-1/5 justify-between mr-2">
        <CustomButton
          btnName="Cancel"
          bgColor="bg-gray-400"
          onClick={() => {
            history.goBack();
          }}
        />
        {!isSubmit ? (
          <CustomButton btnName="Submit" onClick={addAarthi} />
        ) : (
          <CustomButton btnName="Submit" onClick={() => {}} />
        )}
      </div>
    </div>
  );
};
export default AarthiMasterAdd;
