import React, { useEffect, useState } from "react";
import { useLocation, Redirect, useHistory, Switch } from "react-router-dom";
import { CustomButton } from "../../../components/atoms/CustomButton";
import CustomInputBox from "../../../components/atoms/CustomInputBox";
import axios from "../../../axios";
import { toast } from "react-toastify";
import { PencilIcon } from "@heroicons/react/outline";
import CustomToggleSwitch from "../../../components/atoms/CustomToggleSwitch";
import { errorToast } from "../../../util/toast";
import { validateValidationEdit } from "../../Master Data/common/validations";

const InitialValidationEdit = () => {
  const updatePropList = [
    "isAcreageVerified",
    "isVarietyVerified",
    "actualLandUsed",
    "verificationComment",
  ];

  const location = useLocation();
  const history = useHistory();
  const id = JSON.parse(location.state).data.id;
  const [data, setData] = useState(null);

  const [error, setError] = useState(null);

  const valueSetter = (propertyName, newValue) => {
    const newData = { ...data };
    newData[propertyName] = newValue;

    console.log("newData", newData);
    setData(newData);
  };

  useEffect(() => {
    // const newObj = {};
    // Object.keys(data).forEach((key) => {
    //   if (data[key]?.hasOwnProperty("show")) {
    //     newObj[key] = data[key].value;
    //   } else {
    //     newObj[key] = data[key];
    //   }
    // });
    // if (Object.keys(newObj).length > 0) setData(newObj);
    axios
      .get(`initialValidation/${id}`)
      .then((response) => {
        const temp = response.data.Result.InitialValidation[0];
        console.log(temp);
        temp.isAcreageVerified =
          temp.isAcreageVerified === null ? 0 : temp.isAcreageVerified;
        temp.isVarietyVerified =
          temp.isVarietyVerified === null ? 0 : temp.isVarietyVerified;
        setData(temp);
      })
      .catch((error) => {
        errorToast(error?.response?.data?.Comments);
        console.log(error);
      });
  }, []);

  if (!location.state) {
    return <Redirect to="/" />;
  }

  const createSendingData = async (obj) => {
    return new Promise((resolve, reject) => {
      let newData = {};
      Object.keys(obj).forEach((property, index) => {
        if (updatePropList.includes(property)) {
          newData[property] = obj[property];
        }
        if (index === Object.keys(obj).length - 1) resolve(newData);
      });
    });
  };

  const editInitialValidation = () => {
    // console.log("data" , data)
    createSendingData(data)
      .then((newData) => {
        // console.log(newData)
        // if (newData.isAcreageVerified == 1)
        //   newData.actualLandUsed = data.landUsed;
        // console.log(newData, " is the new data");
        if (validateValidationEdit(newData))
          return axios.put(`initialValidation/${data.id}`, newData);
        else throw { message: "please rectify the data" };
      })
      .then((response) => {
        if (response?.data?.ResponseMessage === "SUCCESS") {
          toast.success("Update successful!", { position: "top-center" });
          history.goBack();
        }
      })
      .catch((error) => {
        errorToast(error?.response?.data?.Comments);
        console.log(error);
      });
  };

  return (
    <div className=" m-3 p-3">
      <div className="flex">
        <span>
          <PencilIcon className="h-5 w-5 transform text-black mt-4 mr-1" />
        </span>
        <span className=" py-4 text-gray-700 text-base font-bold text-left">
          Edit Initial Validation
        </span>
      </div>
      <div className="flex flex-wrap justify-left">
        {data && (
          <div className="w-2/3">
            <div className="grid grid-cols-2 gap-x-3 gap-y-4 mb-4">
              <CustomInputBox
                title="Farmer"
                disabled={true}
                borderDisable={true}
                value={`${data.FarmerMaster.name}`}
              />

              <CustomInputBox
                title="Variety"
                borderDisable={true}
                value={`${data.VarietyMaster.name}`}
                disabled={true}
              />
              <CustomInputBox
                title="Land Used"
                borderDisable={true}
                value={data?.landUsed}
                disabled={true}
              />
              {/* <br></br> */}
              <CustomToggleSwitch
                checked={data?.isAcreageVerified == 1 ? true : false}
                label2={"Is Acreage verified"}
                onChange={(value) => {
                  console.log("value is", value);
                  if (value === true) {
                    valueSetter("actualLandUsed", data.landUsed);
                  } else {
                    valueSetter("actualLandUsed", 0);
                  }
                  valueSetter("isAcreageVerified", value.value == true ? 1 : 0);
                }}
              />
              <CustomToggleSwitch
                checked={data?.isVarietyVerified == 1 ? true : false}
                label2={"Is Variety verified"}
                onChange={(value) => {
                  valueSetter("isVarietyVerified", value.value == true ? 1 : 0);
                }}
              />

              <CustomInputBox
                title="Actual Land Used"
                value={data?.actualLandUsed}
                valueSetter={(value) => {
                  valueSetter("actualLandUsed", value);
                }}
              />
              <CustomInputBox
                title={"Comment"}
                value={data?.verificationComment}
                valueSetter={(value) => {
                  valueSetter("verificationComment", value);
                }}
              />
            </div>
            <div className="flex mt-8 justify-between">
              <CustomButton
                btnName="Cancel"
                className="p-2 bg-gray-500 hover:bg-gray-700 text-lg"
                onClick={() => {
                  history.goBack();
                }}
              />
              <CustomButton
                btnName="Update"
                className="p-2 bg-green-500 hover:bg-green-700 text-lg"
                onClick={editInitialValidation}
              />
            </div>
          </div>
        )}
      </div>
      <div className="text-xl text-red-500">{error}</div>
      <div className="flex w-max gap-x-10"></div>
    </div>
  );
};
export default InitialValidationEdit;
