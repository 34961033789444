import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { CustomButton } from "../../../components/atoms/CustomButton";
import MasterDataDelete from "../common/MasterDataDelete";
import MasterDataAddCSV from "../common/MasterDataAddCSV";

import axios from "../../../axios";
import {
  DocumentAddIcon,
  CloudUploadIcon,
  ShoppingCartIcon,
} from "@heroicons/react/outline";
import {
  EyeIcon,
  PencilIcon,
  TrashIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
} from "@heroicons/react/solid";
import { toast } from "react-toastify";
import CustomTable from "../../../components/atoms/CustomTable";
import CustomInputBox from "../../../components/atoms/CustomInputBox";
import CustomDropDownSearchSelect from "../../../components/atoms/CustomDropDownSearchSelect";
import { errorToast, successToast } from "../../../util/toast";

const getItemName = (obj, prop1) => {
  return obj[prop1];
};

const searchObject = (items, target, property) => {
  return items.filter((element) => {
    return element[property] === target;
  })[0];
};

const MandiMasterHome = () => {
  const role = JSON.parse(localStorage.getItem("cfUserAuth"));
  const result = role.find((obj) => {
    return obj.moduleName === "mandiMasters";
  });

  const history = useHistory();
  const tablePageNumbersShown = 3;
  //table related.
  const [currentTableData, setTableData] = useState([]);
  const rowsPerPage = 10;
  const [totalRecords, setTotalRecords] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [sortBy, setSortBy] = useState({
    headerName: "name",
    order: false,
  });

  //dropdown data
  const [stateList, setStateList] = useState(null);
  const [zoneList, setZoneList] = useState(null);

  //modal related.
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showUploadFile, setShowUploadFile] = useState(false);
  const [deleteObject, setDeleteObject] = useState(null);

  //table headers for the custom table.
  const tableHeaders = [
    { property: "id", sortable: true, show: false, displayName: "id" },
    { property: "name", sortable: true, show: true, displayName: "name" },
    {
      property: "ZoneMaster.name",
      sortable: true,
      show: true,
      displayName: "zone",
    },
    {
      property: "District.district",
      sortable: true,
      show: true,
      displayName: "district",
    },
    {
      property: "address",
      sortable: false,
      show: false,
      displayName: "district",
    },
    {
      property: "State.state",
      sortable: true,
      show: true,
      displayName: "state",
    },
    {
      property: "actions",
      sortable: false,
      show: true,
      displayName: "actions",
    },
  ];

  //page related.
  const [searchState, setSearchState] = useState(null);
  const [zoneId, setZoneId] = useState(null);
  const [searchName, setSearchName] = useState("");

  const deleteMandi = () => {
    axios
      .delete(`mandiMaster/${deleteObject.id}`)
      .then((response) => {
        if (response?.data?.ResponseMessage === "SUCCESS") {
          window.location.reload();
          successToast("Delete Successful");
          // remove this and do it better, we need to remove the row of the delete object thats it
          setShowDeleteModal(false);
        } else {
          errorToast(response);
        }
      })
      .catch((error) => {
        errorToast(error?.response?.data?.Comments);
        console.log(error);
      });
  };

  const addActions = (objects) => {
    //adds the actions buttons to the table rows.
    const newObjects = [];
    objects.map((obj) => {
      /*
                ADD show property to showable properties of the objects
            */
      obj.name = { value: obj.name, show: true };
      obj.zone = { value: obj.ZoneMaster.name, show: true };
      obj.district = { value: obj.District.district, show: true };
      obj.state = { value: obj.State.state, show: true };
      obj.actions = {
        value: (
          <div className="flex gap-x-3 p-1">
            {result.moduleUpdate === 1 && (
              <Link
                to={{
                  pathname: `/edit/${obj.id}`,
                  state: JSON.stringify({ data: obj }),
                }}
              >
                <span>
                  <PencilIcon className="h-5 w-5 text-gray-700 hover:text-black" />
                </span>
              </Link>
            )}

            <Link
              to={{
                pathname: `/display`,
                state: JSON.stringify({ data: obj }),
              }}
            >
              <span>
                <EyeIcon className="h-5 w-5 text-gray-700 hover:text-black" />
              </span>
            </Link>
            {result.moduleDelete == 1 && (
              <span>
                <TrashIcon
                  className="h-5 w-5 text-gray-700 hover:text-black"
                  onClick={() => {
                    setShowDeleteModal(true);
                    setDeleteObject(obj);
                  }}
                />
              </span>
            )}
          </div>
        ),
        show: true,
      };
      newObjects.push(obj);
    });
    return newObjects;
  };

  //The "constructor" useEffect
  useEffect(() => {
    //loading the table data here for the first time.
    //load the first page of the table.
    let params = {
      page: pageNumber,
      limit: 10,
      orderBy: sortBy.headerName,
      order: sortBy.order ? "ASC" : "DESC",
    };
    axios
      .get(`states`)
      .then((response) => {
        console.log("states", response.data.Result.States);
        setStateList(response.data.Result.States);
        return axios.get(`zoneMasters/load`);
      })
      .then((response) => {
        setZoneList(response.data.Result.ZoneMaster);
        return axios.get(`mandiMaster`, { params });
      })
      .then((response) => {
        setTotalRecords(response.data.Result.total);
        setTotalPages(Math.ceil(response.data.Result.total / rowsPerPage));
        const returnedRows = response.data.Result.MandiMaster;
        let actualRows = addActions(returnedRows);
        console.log(actualRows);
        setTableData(actualRows);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    //updating the table data based on the search terms.
    let params = {
      page: pageNumber,
      limit: 10,
      orderBy: sortBy.headerName,
      order: sortBy.order ? "ASC" : "DESC",
    };
    if (zoneId !== null) {
      params.zoneId = zoneId;
    }
    if (searchState !== null) {
      params.stateId = searchState;
    }
    if (searchName !== null && searchName.trim() !== "") {
      params.name = searchName;
    }
    axios
      .get(`mandiMaster`, { params })
      .then((response) => {
        setTotalRecords(response.data.Result.total);
        //setTotalRecords(response.data.Result.ZoneMaster.length);
        const returnedRows = response.data.Result.MandiMaster;
        setTotalPages(Math.ceil(response.data.Result.total / rowsPerPage));
        let actualRows = addActions(returnedRows);
        console.log(actualRows, "1231231231");
        setTableData(actualRows);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [searchName, searchState, zoneId, pageNumber, sortBy, showUploadFile]);

  useEffect(() => {
    setPageNumber(1);
  }, [searchName, searchState, zoneId]);

  const applyFilter = () => {};
  const nextPage = () => {
    if (pageNumber !== totalPages) setPageNumber(pageNumber + 1);
  };
  const prevPage = () => {
    if (pageNumber !== 1) setPageNumber(pageNumber - 1);
  };
  const getPageButtons = (noOfButtons) => {
    let buttonList = [];
    if (true) {
      let printed = 0;
      for (
        let i = Math.floor((pageNumber - 1) / noOfButtons) * noOfButtons + 1;
        printed < noOfButtons && i <= totalPages;
        i++
      ) {
        buttonList.push(
          <button
            className={`px-3 ${
              i === pageNumber
                ? " bg-green-500 text-white text-sm rounded-xl"
                : "text-gray-600 text-sm"
            }`}
            onClick={() => {
              setPageNumber(i);
            }}
          >
            {i}
          </button>
        );
        printed++;
      }
    }
    return buttonList;
  };

  return (
    <div className="m-3 h-screen">
      <MasterDataDelete
        show={showDeleteModal}
        object={deleteObject}
        onClose={(e) => {
          setShowDeleteModal(false);
        }}
        onYes={deleteMandi}
      />
      <MasterDataAddCSV
        show={showUploadFile}
        onClose={(e) => {
          setShowUploadFile(false);
        }}
        fileUploadPath={"mandiMaster/file"}
        sampleFileDownloadPath={"/sampleMandi.csv"}
        successMessage="Mandi added successfully"
      />
      <div className="flex justify-between overflow-wrap align-middle">
        <div name="IconAndPageTitle" className="bg-white flex ml-3">
          <span>
            <ShoppingCartIcon className="my-3 mx-1 h-6 w-6 mr-1" />
          </span>
          <span className="text-lg mt-3 text-center align-center">
            Mandi master data
          </span>
          <span className="mt-5 text-xs ml-2">({totalRecords} records)</span>
        </div>
        {result.moduleCreate == 1 && (
          <div name="Add buttons" className="flex flex-wrap gap-x-10">
            <CustomButton
              onClick={() => {
                history.push("/add");
              }}
              btnName="Add"
              icon={<DocumentAddIcon className="h-5 w-5 mr-1" />}
            />
            <CustomButton
              onClick={() => {
                setShowUploadFile(true);
              }}
              btnName="CSV"
              icon={<CloudUploadIcon className="h-5 w-5 mr-1" />}
            />
          </div>
        )}
      </div>
      <div name="FilterSection" className="flex gap-x-3 ml-3 w-11/12">
        <div className="w-full">
          <CustomDropDownSearchSelect
            label="State"
            labelDisable={true}
            placeholder={`Select State`}
            items={
              stateList !== null
                ? stateList.map((element) => {
                    return getItemName(element, "state");
                  })
                : []
            }
            handler={(value) => {
              value !== null
                ? setSearchState(
                    stateList.filter((element) => {
                      return getItemName(element, "state") === value;
                    })[0].id
                  )
                : setSearchState(null);
            }}
            selected={
              stateList !== null && searchState !== null
                ? getItemName(
                    searchObject(stateList, searchState, "id"),
                    "id",
                    "state"
                  )
                : null
            }
          />
        </div>
        <div className="w-full">
          <CustomDropDownSearchSelect
            label={"Zone"}
            labelDisable={true}
            placeholder={"Select Zone"}
            items={
              zoneList !== null
                ? zoneList.map((element) => {
                    return getItemName(element, "name");
                  })
                : []
            }
            selected={
              zoneId !== null && zoneList !== null
                ? getItemName(
                    searchObject(zoneList, zoneId, "id"),
                    "identifier",
                    "name"
                  )
                : null
            }
            handler={(value) => {
              if (value !== null) {
                setZoneId(
                  zoneList.filter((element) => {
                    return getItemName(element, "name") === value;
                  })[0].id
                );
              } else {
                setZoneId(null);
              }
            }}
          />
        </div>

        <div className="w-full ">
          <CustomInputBox
            title="Search by name"
            labelClass="mt-2 hidden"
            value={searchName}
            valueSetter={setSearchName}
          />
        </div>
        <div className="mt-7">
          {/* <CustomButton
            btnName="Filter"
            className="p-2 bg-green-500 hover:bg-green-700 text-sm "
            onClick={applyFilter}
          /> */}
        </div>
      </div>
      <div
        name="Table"
        className="bg-white ml-3 mt-4 overflow-hidden"
        style={{ height: "420px" }}
      >
        <CustomTable
          rows={currentTableData}
          headers={tableHeaders}
          sortByHandler={setSortBy}
        />
      </div>
      {
        //show the table navigation buttons only if the table has more than 0 records.
        currentTableData.length > 0 ? (
          <div className="text-sm w-max mx-auto text-center flex mt-1">
            <button
              onClick={prevPage}
              className="border-2 text-gray-600 rounded-xl border-black p-1 w-6 h-6 mx-3"
            >
              <ChevronLeftIcon />
            </button>
            <div className="mx-2 mt-1 w-28">
              {getPageButtons(tablePageNumbersShown)}
            </div>
            <button
              onClick={nextPage}
              className="border-2  text-gray-600 rounded-xl border-black p-1 w-6 h-6 mx-3"
            >
              <ChevronRightIcon />
            </button>
          </div>
        ) : null
      }
    </div>
  );
};
export default MandiMasterHome;
