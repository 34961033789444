import { React, useEffect, useState } from "react";
import { useLocation, Redirect } from "react-router-dom";
import { EyeIcon } from "@heroicons/react/outline";
import axios from "../../../axios";
import { errorToast } from "../../../util/toast";
import CustomInputBox from "../../../components/atoms/CustomInputBox";
import CustomToggleSwitch from "../../../components/atoms/CustomToggleSwitch";

const CropLifeCycleDisplay = () => {
  const location = useLocation();
  const [initialData, setInitialData] = useState(
    JSON.parse(location.state).data
  );
  const [data, setData] = useState(null);

  const getItemName = (obj, prop1, prop2) => {
    return obj[prop2];
  };

  useEffect(() => {
    if (initialData.id !== undefined)
      axios
        .get(`cropCuttingSamplingConfirmation/${initialData.id}`)
        .then((response) => {
          console.log("Result is", response.data.Result.CropCuttingSampling);
          setData(response.data.Result.CropCuttingSampling);
        })
        .catch((error) => {
          console.log(error);
          // errorToast(error?.message);
        });
  }, [initialData]);

  const getDateString = (date) => {
    let year = date.getFullYear();
    let month = date.getMonth() + 1;
    month = month < 10 ? "0" + month : month;
    let day = date.getDate();
    day = day < 10 ? "0" + day : day;
    return day + "/" + month + "/" + year;
  };

  if (!initialData) return <Redirect to="/" />;

  return data !== null ? (
    <div className=" m-3 p-3">
      <div className="flex">
        <span className=" py-4 text-gray-700 text-base font-bold text-left">
          Display Sampling
        </span>
      </div>
      <div className="flex flex-wrap justify-left">
        <div className="w-2/3">
          <div className=" gap-x-3 space-y-2 mb-4">
            <div className="mb-4 flex gap-x-3">
              <CustomInputBox
                title={"Farmer"}
                disabled={true}
                borderDisable={true}
                value={getItemName(
                  data.FarmerVariety.FarmerMaster,
                  "identifier",
                  "name"
                )}
              />
              <CustomInputBox
                title={"Village"}
                disabled={true}
                borderDisable={true}
                value={getItemName(
                  data.FarmerVariety.FarmerMaster.VillageMaster,
                  "identifier",
                  "name"
                )}
              />
            </div>

            <div className="flex gap-x-3">
              <CustomInputBox
                title={"Variety"}
                disabled={true}
                borderDisable={true}
                value={getItemName(
                  data.FarmerVariety.VarietyMaster,
                  "identifier",
                  "name"
                )}
              />
              <CustomInputBox
                borderDisable={true}
                title="Sampling Number"
                disabled={true}
                value={data?.samplingNumber}
                className="w-1/2"
              />
            </div>

            <div className="flex gap-x-3">
              <span className="w-1/2">
                <CustomInputBox
                  disabled={true}
                  borderDisable={true}
                  title={"Sampling Date"}
                  value={
                    data.samplingDate === null ? (
                      <>N.A.</>
                    ) : (
                      getDateString(new Date(data.samplingDate))
                    )
                  }
                />
              </span>
              <span className="w-1/2">
                <CustomInputBox
                  borderDisable={true}
                  disabled={true}
                  title={"Sampling Request Date"}
                  value={
                    data?.samplingRequestDate === undefined
                      ? new Date()
                      : getDateString(new Date(data.samplingRequestDate))
                  }
                />
              </span>
            </div>

            <div className="flex gap-x-3">
              <CustomInputBox
                title="Area in acre "
                borderDisable={true}
                value={data?.acre}
                disabled={true}
                className="w-1/2"
              />
              <CustomInputBox
                disabled={true}
                borderDisable={true}
                title="Expected Harvesting date"
                value={getDateString(new Date(data?.expectedHarvestingDate))}
                className="w-1/2"
              />
            </div>

            <div className="flex gap-x-3">
              <div className="w-1/2">
                <CustomToggleSwitch
                  label2={"Is Confirmed"}
                  obj={"obj"}
                  checked={data?.isConfirmed}
                  disabled={true}
                  onChange={(val) => {}}
                />
              </div>
              <div className="w-1/2">
                <CustomInputBox
                  title="Sampling Combination Code"
                  disabled={true}
                  borderDisable={true}
                  value={data?.samplingCombinationCode}
                  className="w-1/2"
                />
              </div>
            </div>

            <div className="flex gap-x-3">
              <div className="w-1/2">
                <CustomToggleSwitch
                  label2={"Is Requested Resampling"}
                  obj={"obj"}
                  checked={data?.isRequestedResampling}
                  disabled={true}
                  onChange={(val) => {}}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <div className="text-xl text-red-500">{error}</div> */}
      <div className="flex w-max gap-x-10"></div>
    </div>
  ) : null;
};
export default CropLifeCycleDisplay;
