import React, { useEffect, useState } from "react";
import { useLocation, Redirect, useHistory } from "react-router-dom";
import { CustomButton } from "../../../components/atoms/CustomButton";
import CustomDisplayBox from "../../../components/atoms/CustomDisplayBox";
import CustomInputBox from "../../../components/atoms/CustomInputBox";
import axios from "../../../axios";
import { toast } from "react-toastify";
import { PencilIcon } from "@heroicons/react/outline";
import { errorToast, successToast } from "../../../util/toast";
import CustomDropDownSearchSelect from "../../../components/atoms/CustomDropDownSearchSelect";
import { validateVarietyEdit } from "../common/validations";

const getItemName = (obj, prop1) => {
  return obj[prop1];
};
//returns the first object in the list whose property matches the target
const searchObject = (items, target, property) => {
  return items.filter((element) => {
    return element[property] === target;
  })[0];
};

const VarietyMasterEdit = () => {
  const location = useLocation();
  const history = useHistory();
  const { data } = JSON.parse(location.state);
  const [name, setName] = useState(data?.name.value);
  const [uom, setUom] = useState(data?.uom.value);
  const [uomId, setUomId] = useState(data.uomId);
  const [seedToSeed, setSeedToSeed] = useState(data.seedToSeed.value);
  const [error, setError] = useState(null);
  const [uomObjectsList, setUomObjectsList] = useState([]);

  useEffect(() => {
    axios
      .get(`uom`)
      .then((response) => {
        setUomObjectsList(response.data.Result.Uom);
      })
      .catch((error) => {
        errorToast(error?.response?.data?.Comments);
        console.log(error);
      });
  }, []);

  if (!location.state) {
    return <Redirect to="/" />;
  }

  const editVariety = () => {
    if (
      validateVarietyEdit({ name: name, uomId: uomId, seedToSeed: seedToSeed })
    ) {
      axios
        .put(`varietyMaster/${data.id}`, {
          name: name,
          uomId: uomId,
          seedToSeed: seedToSeed,
        })
        .then((response) => {
          if (response?.data?.ResponseMessage === "SUCCESS") {
            successToast("Update Successful!");
            history.goBack();
          }
        })
        .catch((error) => {
          errorToast(error?.response?.data?.Comments);
          console.log(error);
        });
    }
  };

  return (
    <div className=" m-3 p-3">
      <div className="flex">
        <span>
          <PencilIcon className="h-5 w-5 transform text-black mt-4 mr-1" />
        </span>
        <span className=" py-4 text-gray-700 text-base font-bold text-left">
          Edit variety master
        </span>
      </div>
      <div className="flex flex-wrap justify-left">
        <div className="w-1/2">
          <div className="flex flex-wrap gap-x-3 mb-4">
            {/* <CustomDisplayBox title="ID" value={data.id} /> */}
            <CustomInputBox
              title="Name"
              value={name}
              className="w-1/2"
              valueSetter={setName}
            />
            <CustomInputBox
              title="Seed to seed time"
              value={seedToSeed}
              className="w-1/2"
              valueSetter={setSeedToSeed}
            />
            <CustomDropDownSearchSelect
              label={"UoM"}
              items={uomObjectsList.map((value) => {
                return getItemName(value, "uom");
              })}
              selected={
                uomId !== null && uomObjectsList.length > 0
                  ? getItemName(
                      searchObject(uomObjectsList, uomId, "id"),
                      "uom"
                    )
                  : null
              }
              handler={(value) => {
                if (value !== null && uomObjectsList.length > 0)
                  setUomId(
                    uomObjectsList.filter((element) => {
                      return getItemName(element, "uom") == value;
                    })[0].id
                  );
                else {
                  setUomId(null);
                }
              }}
            />
          </div>
          {/* <p className="mt-4 py-4 text-gray-700 text-left text-base">You can edit the name of the zone.</p> */}
          <div className="flex mt-8 justify-between">
            <CustomButton
              btnName="Cancel"
              className="p-2 bg-gray-500 hover:bg-gray-700 text-lg"
              onClick={() => {
                history.goBack();
              }}
            />
            <CustomButton
              btnName="Update"
              className="p-2 bg-green-500 hover:bg-green-700 text-lg"
              onClick={editVariety}
            />
          </div>
        </div>
      </div>
      <div className="text-xl text-red-500">{error}</div>
      <div className="flex w-max gap-x-10"></div>
    </div>
  );
};
export default VarietyMasterEdit;
