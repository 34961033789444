import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { CustomButton } from "../../../components/atoms/CustomButton";
import axios from "../../../axios";
import { DocumentAddIcon, PlusIcon, TrashIcon } from "@heroicons/react/outline";
import CustomInputBox from "../../../components/atoms/CustomInputBox";
import { successToast, errorToast } from "../../../util/toast";
import CustomDropDownSearchSelect from "../../../components/atoms/CustomDropDownSearchSelect";
import {
  checkIdentifier,
  checkNotNull,
  checkNumberInRange,
  checkString,
  validateVillageAdd,
} from "../common/validations";

const VillageMasterAdd = () => {
  const history = useHistory();
  const [districtList, setDistrictList] = useState(null);
  const [stateList, setStateList] = useState(null);
  const [zoneList, setZoneList] = useState(null);
  const [mandiList, setMandiList] = useState(null);
  const [isSubmit, setIsSubmit] = useState(false);

  const [newMandis, setNewMandis] = useState([
    {
      name: null,
      zoneId: null,
      mandiId: null,
    },
  ]);

  const getItemName = (obj, prop1) => {
    return obj[prop1];
  };

  useEffect(() => {
    axios
      .get(`zoneMasters/load`)
      .then((response) => {
        setZoneList(response.data.Result.ZoneMaster);

        return axios.get("mandiMaster/load");
      })
      .then((response) => {
        setMandiList(response.data.Result.MandiMaster);

        return axios.get(`states`);
      })
      .then((response) => {
        setStateList(response.data.Result.States);
        return axios.get(`districts`);
      })
      .then((response) => {
        setDistrictList(response.data.Result.Districts);
      })
      .catch((error) => {
        console.log(error);
        errorToast(error?.response?.data?.Comments);
      });
  }, []);

  const valueSetter = (index, newValue, property) => {
    let mandis = [...newMandis];
    mandis[index][property] = newValue;
    setNewMandis(mandis);
  };

  const addMandi = () => {
    console.log(newMandis);

    if (validateVillageAdd(newMandis)) {
      setIsSubmit(true);
      successToast("Your request is being processed");
      axios
        .post("villageMasters/", newMandis)
        .then((response) => {
          if (response?.data?.ResponseMessage === "SUCCESS") {
            successToast("New Village added!");
            history.goBack();
          }
        })
        .catch((error) => {
          errorToast(error?.response?.data?.Comments);
          console.log(error);
        });
    } else {
      setIsSubmit(false);
    }
  };

  return (
    <div className="p-3 m-3">
      <div className="flex mb-7">
        <DocumentAddIcon className="h-5 w-5text-black mt-3 mr-1" />
        <div className="text-base pt-3 font-bold text-gray-800">
          Add mandi master
        </div>
      </div>
      <div>
        {newMandis.map((value, index) => {
          return (
            <div className="mb-4 flex flex-wrap w-2/3">
              <div className="flex w-full">
                <div className="w-full">
                  <div>
                    <CustomDropDownSearchSelect
                      items={
                        zoneList !== null
                          ? zoneList.map((element) => {
                              return getItemName(element, "name");
                            })
                          : []
                      }
                      placeholder="Select Zone"
                      label="Zone"
                      selected={
                        zoneList !== null && value.zoneId !== null
                          ? getItemName(
                              zoneList.filter((element) => {
                                return element.id === value.zoneId;
                              })[0],
                              "name"
                            )
                          : null
                      }
                      handler={(value) => {
                        if (zoneList !== null && value !== null) {
                          valueSetter(
                            index,
                            zoneList.filter((element) => {
                              return getItemName(element, "name") === value;
                            })[0].id,
                            "zoneId"
                          );
                        } else {
                          valueSetter(index, null, "zoneId");
                        }
                      }}
                    />
                  </div>

                  <div>
                    <CustomDropDownSearchSelect
                      items={
                        mandiList !== null
                          ? mandiList
                              .filter(
                                (element) =>
                                  element.ZoneMaster.id === value.zoneId
                              )
                              .map((element) => {
                                return getItemName(element, "name");
                              })
                          : []
                      }
                      placeholder="Select Mandi"
                      label="Mandi"
                      selected={
                        mandiList !== null && value.mandiId !== null
                          ? getItemName(
                              mandiList?.filter((element) => {
                                return element.id === value.mandiId;
                              })[0],
                              "name"
                            )
                          : null
                      }
                      handler={(value) => {
                        if (mandiList !== null && value !== null) {
                          valueSetter(
                            index,
                            mandiList.filter((element) => {
                              return getItemName(element, "name") === value;
                            })[0].id,
                            "mandiId"
                          );
                        } else {
                          valueSetter(index, null, "mandiId");
                        }
                      }}
                    />
                  </div>

                  <CustomInputBox
                    title="Village"
                    value={value.name}
                    valueSetter={(value) => {
                      valueSetter(index, value, "name");
                    }}
                  />
                </div>
                {newMandis.length - 1 !== index ? (
                  <span
                    onClick={() => {
                      setNewMandis(
                        newMandis.filter((v, i) => {
                          return i !== index;
                        })
                      );
                    }}
                    className="h-5 w-5 ml-2 mt-8 text-gray-500 hover:text-red-500 hover:p-1"
                  >
                    <TrashIcon />
                  </span>
                ) : (
                  <span
                    className="h-4 w-4 ml-2 mt-8 text-white rounded-md bg-green-500"
                    onClick={() => {
                      setNewMandis([
                        ...newMandis,
                        {
                          name: null,
                          identifier: null,
                          zoneId: null,
                          mandiId: null,
                        },
                      ]);
                    }}
                  >
                    <PlusIcon />
                  </span>
                )}
              </div>
            </div>
          );
        })}
      </div>
      <div className="flex w-1/4 justify-between">
        <CustomButton
          btnName="Cancel"
          bgColor="bg-gray-400"
          onClick={() => {
            history.goBack();
          }}
        />
        {!isSubmit ? (
          <CustomButton btnName="Submit" onClick={addMandi} />
        ) : (
          <CustomButton btnName="Submit" onClick={() => {}} />
        )}
      </div>
    </div>
  );
};
export default VillageMasterAdd;
