import React, { useState, useRef, useEffect } from "react";
import { CustomButton } from "../../../components/atoms/CustomButton";
import axios from "../../../axios";
import { errorToast } from "../../../util/toast";
import { successToast } from "../../../util/toast";
import { XIcon, CloudUploadIcon } from "@heroicons/react/outline";

const MasterDataAddCSV = ({
  show,
  onClose,
  successMessage,
  sampleFileDownloadPath,
  fileUploadPath,
}) => {
  const [file, setFile] = useState(null);
  const [message, setMessage] = useState(null);

  const submit = () => {
    if (file === null) {
      setMessage({ message: "no file selected!", color: "red" });
    } else {
      console.log("UPLOADED", file);
      let data = new FormData();
      data.append("file", file);
      console.log("SHOW", data);
      axios
        .post(`${fileUploadPath}`, data)
        .then((response) => {
          console.log("RESP", response, successMessage);
          if (response?.data?.ResponseMessage === "SUCCESS") {
            successToast(successMessage, 2000);
            document.getElementById("closeButtonxyz").click();
          } else errorToast("Something went wrong");
        })
        .catch((error) => {
          errorToast(
            `Value of row ${
              error?.response?.data?.Result?.error?.details[0]?.path[0] + 1
            } and ${
              error?.response?.data?.Result?.error?.details[0]?.path[1]
            } column does not exist in database`
          );

          console.log(error?.response?.data, "YHI HAI NA");
        });
    }
  };

  useEffect(() => {
    if (file !== null) {
      if (
        file.type === "text/csv" ||
        file.type === "application/vnd.ms-excel"
      ) {
        setMessage(null);
      } else {
        setMessage({ message: "Please upload a .csv file only", color: "red" });
      }
    }
  }, [file]);

  if (show)
    return (
      <div>
        <div className="fixed top-0 left-0 right-0 bottom-0 flex items-center justify-center h-full w-full ">
          {" "}
        </div>
        <div className="fixed top-1/2 left-1/2 -mt-40 -ml-60 w-popup h-50 border-2 text-lg py-3 rounded-xl shadow-2xl bg-white opacity-100">
          <div className="flex justify-end pr-3">
            <span
              onClick={(e) => {
                onClose(e);
              }}
            >
              <XIcon className="h-5 w-5 text-gray-500" />
            </span>
          </div>
          <p className="p-3 text-center font-bold">Upload CSV</p>
          <label className="w-64 mx-auto flex flex-col items-center px-4 bg-white rounded-md tracking-wide  cursor-pointer text-black ease-linear transition-all duration-150">
            <p
              className={`mt-2 text-base leading-normal ${
                file !== null ? "text-green-600" : "text-black"
              }`}
            >
              {file ? `Uploaded ${file.name}` : "Select a file or drop it here"}
            </p>
            <span className="mt-4 h-24 w-24 text-gray-700">
              <CloudUploadIcon />
            </span>
            <input
              type="file"
              className="w-60 pt-10 hidden"
              onChange={(e) => {
                console.log("OKK", e, e.target.files[0]);
                setFile(null);
                if (
                  e?.target?.files[0]?.type === "text/csv" ||
                  e?.target?.files[0]?.type === "application/vnd.ms-excel"
                ) {
                  console.log("Herererer CSV");
                  setFile(e.target.files[0]);
                }
              }}
            />
          </label>
          <p className="text-center text-sm mt-10">
            {" "}
            <a
              href={`${sampleFileDownloadPath}`}
              className=" text-blue-500"
              download
            >
              Download sample csv here
            </a>{" "}
          </p>
          {message ? (
            <div
              className={`text-center text-sm mt-2 text-${message.color}-600`}
            >
              {message.message}
            </div>
          ) : null}
          <div className="flex justify-around mt-4">
            <CustomButton
              btnName="Close"
              id="closeButtonxyz"
              bgColor="bg-gray-400"
              onClick={(e) => {
                onClose(e);
              }}
            />
            <CustomButton
              btnName="Submit"
              onClick={(e) => {
                submit();
              }}
            />
          </div>
        </div>
      </div>
    );
  else return null;
};

export default MasterDataAddCSV;
