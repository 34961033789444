import React from "react";
import { useLocation, Redirect } from "react-router-dom";
import { EyeIcon } from "@heroicons/react/outline";
import CustomInputBox from "../../../components/atoms/CustomInputBox";

const YieldMasterDisplay = () => {
  const location = useLocation();
  if (!JSON.parse(location.state)) return <Redirect to="/" />;
  const { data } = JSON.parse(location.state);
  return (
    <div className="overflow-hidden m-3 p-3">
      <div className="flex">
        <span>
          <EyeIcon className="h-5 w-5 transform text-black mt-4 mr-1" />
        </span>
        <span className=" py-4 text-gray-700 text-base font-bold text-left">
          Display Yield master
        </span>
      </div>
      <div className="grid grid-cols-2 w-1/2 flex-wrap justify-left">
        {/* <CustomDisplayBox title="ID" value={data.id} className=" pl-2"/> */}
        <CustomInputBox
          title="Procurement"
          value={data.procurement}
          disabled={true}
          borderDisable={true}
        />
        <CustomInputBox
          title="Compliance"
          value={data.compliance.value}
          disabled={true}
          borderDisable={true}
        />
        <CustomInputBox
          title="Farm Yield"
          value={data.farmYield.value}
          disabled={true}
          borderDisable={true}
        />
        <CustomInputBox
          title="Head Rice Yield"
          value={data.headRiceYield}
          disabled={true}
          borderDisable={true}
        />
        {console.log(data)}
        <CustomInputBox
          title="Unit of Measurement"
          value={`${data.Uom.uom}`}
          disabled={true}
          borderDisable={true}
        />
        <CustomInputBox
          title="Process Type"
          value={`${data.ProcessType.processType}`}
          disabled={true}
          borderDisable={true}
        />
        <CustomInputBox
          title="Variety"
          value={`${data.VarietyMaster.name}`}
          disabled={true}
          borderDisable={true}
        />
        <CustomInputBox
          title="Zone"
          value={`${data.ZoneMaster.name}`}
          disabled={true}
          borderDisable={true}
        />
        <CustomInputBox
          title="Maturity Days"
          value={`${data.maturityDays}`}
          disabled={true}
          borderDisable={true}
        />
        <CustomInputBox
          title="Transplanting Days"
          value={`${data.transplantingDays}`}
          disabled={true}
          borderDisable={true}
        />
      </div>
      <div className="flex w-max gap-x-10"></div>
    </div>
  );
};
export default YieldMasterDisplay;
