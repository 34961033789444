import {React, useState} from 'react';
import Switch from 'react-switch';

const CustomToggleSwitch = ({label , label2 , obj, checked, onChange ,disabled, keys}) => {

  
  
  const [check ,setcheck] = useState(checked)

    return (
    <div className="flex flex-col">
    <div className="text-sm font-light mb-2">{label2}</div>
      <Switch
      disabled={disabled}
        className="-mt-1"
        checked={check}
        onChange={(value) => {
            onChange({value:value , obj:obj , label:label});
            setcheck(value)
        }}
      />
  </div>)
}

export default CustomToggleSwitch;