import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { CustomButton } from "../../../components/atoms/CustomButton";
import axios from "../../../axios";
import {
  DocumentAddIcon,
  PlusIcon,
  TrashIcon,
  XCircleIcon,
} from "@heroicons/react/outline";
import CustomInputBox from "../../../components/atoms/CustomInputBox";
import { successToast, errorToast } from "../../../util/toast";
import CustomDropDownSearchSelect from "../../../components/atoms/CustomDropDownSearchSelect";
import { validateYieldAdd } from "../common/validations";

const getItemName = (obj, prop1) => {
  return obj[prop1];
};
//returns the first object in the list whose property matches the target
const searchObject = (items, target, property) => {
  return items.filter((element) => {
    return element[property] === target;
  })[0];
};

const YieldMasterAdd = () => {
  const history = useHistory();
  const [newYield, setNewYield] = useState([
    {
      procurement: null,
      compliance: null,
      headRiceYield: null,
      farmYield: null,
      uomId: null,
      processTypeId: null,
      varietyId: null,
      identifier: null,
      zoneId: null,
      transplantingDays: null,
      maturityDays: null,
    },
  ]);
  const [uomObjectsList, setUomObjectsList] = useState([]);
  const [processTypesList, setProcessTypesList] = useState([]);
  const [varietiesList, setVarietiesList] = useState([]);
  const [zonesList, setZonesList] = useState([]);
  const [isSubmit, setIsSubmit] = useState(false);

  useEffect(() => {
    axios
      .get(`zoneMasters/load`)
      .then((response) => {
        setZonesList(response.data.Result.ZoneMaster);
        return axios.get(`processType`);
      })
      .then((response) => {
        setProcessTypesList(response.data.Result.ProcessTypes);
        return axios.get(`uom`);
      })
      .then((response) => {
        console.log(response.data.Result.Uom);
        setUomObjectsList(response.data.Result.Uom);
        return axios.get("varietyMaster/load");
      })
      .then((response) => {
        setVarietiesList(response.data.Result.VarietyMaster);
      })
      .catch((error) => {
        console.log(error?.message);
        errorToast(error?.response?.data?.Comments);
      });
  }, []);

  const valueSetter = (index, property, newValue) => {
    if (newValue !== null) {
      let Yield = [...newYield];
      Yield[index][property] = newValue;
      setNewYield(Yield);
    }
  };

  const addYield = () => {
    console.log(newYield);
    if (validateYieldAdd(newYield)) {
      setIsSubmit(true);
      successToast("Your request is being processed");
      axios
        .post("yieldMaster/", newYield)
        .then((response) => {
          if (response?.data?.ResponseMessage === "SUCCESS") {
            successToast("New Yield added!");
            history.goBack();
          }
        })
        .catch((error) => {
          errorToast(error?.response?.data?.Comments);
          console.log(JSON.stringify(error));
        });
    } else {
      setIsSubmit(false);
    }
  };

  return (
    <div className="p-3 m-3 w-11/12">
      <div className="flex mb-7">
        <DocumentAddIcon className="h-5 w-5text-black mt-3 mr-1" />
        <div className="text-base pt-3 font-bold text-gray-800">Add Yield</div>
      </div>
      <div>
        <ol style={{ listStyleType: "number" }}>
          {newYield.map((value, index) => {
            return (
              <li>
                <div className="mb-4 flex">
                  <div className="flex w-11/12">
                    <div className="flex-wrap flex gap-y-4 m-3 gap-x-2 ">
                      <div className="flex  gap-2 w-full">
                        <div className="w-full">
                          <CustomDropDownSearchSelect
                            label={"Zone"}
                            items={zonesList.map((value) => {
                              return getItemName(value, "name");
                            })}
                            selected={
                              value.zoneId !== null && zonesList.length > 0
                                ? getItemName(
                                    searchObject(zonesList, value.zoneId, "id"),
                                    "name"
                                  )
                                : null
                            }
                            handler={(value) => {
                              if (value !== null)
                                valueSetter(
                                  index,
                                  "zoneId",
                                  zonesList.filter((element) => {
                                    return (
                                      getItemName(element, "name") == value
                                    );
                                  })[0].id
                                );
                              else {
                                valueSetter(index, "zoneId", null);
                              }
                            }}
                          />
                        </div>

                        <div className="w-full">
                          <CustomDropDownSearchSelect
                            items={
                              varietiesList !== null
                                ? varietiesList.map((element) => {
                                    return getItemName(element, "name");
                                  })
                                : []
                            }
                            label={"Variety"}
                            // labelDisable={true}
                            placeholder={"Variety"}
                            selected={
                              value.varietyId !== null
                                ? getItemName(
                                    searchObject(
                                      varietiesList,
                                      value.varietyId,
                                      "id"
                                    ),
                                    "name"
                                  )
                                : null
                            }
                            handler={(value) => {
                              if (value !== null && varietiesList !== null) {
                                valueSetter(
                                  index,
                                  "varietyId",
                                  varietiesList.filter((element) => {
                                    return (
                                      getItemName(element, "name") === value
                                    );
                                  })[0].id
                                );
                              } else {
                                valueSetter(index, "varietyId", null);
                              }
                            }}
                          />
                        </div>

                        <div className="w-full">
                          <CustomDropDownSearchSelect
                            label={"UoM"}
                            items={uomObjectsList.map((value) => {
                              return getItemName(value, "uom");
                            })}
                            selected={
                              value.uomId !== null && uomObjectsList.length > 0
                                ? getItemName(
                                    searchObject(
                                      uomObjectsList,
                                      value.uomId,
                                      "id"
                                    ),
                                    "uom"
                                  )
                                : null
                            }
                            handler={(value) => {
                              if (value !== null)
                                valueSetter(
                                  index,
                                  "uomId",
                                  uomObjectsList.filter((element) => {
                                    return getItemName(element, "uom") == value;
                                  })[0].id
                                );
                              else {
                                valueSetter(index, "uomId", null);
                              }
                            }}
                          />
                        </div>
                        <div className="w-full">
                          <CustomDropDownSearchSelect
                            label={"Process Types"}
                            items={processTypesList.map((value) => {
                              // console.log(value)
                              return getItemName(value, "processType");
                            })}
                            selected={
                              value.processTypeId !== null
                                ? getItemName(
                                    searchObject(
                                      processTypesList,
                                      value.processTypeId,
                                      "id"
                                    ),
                                    "processType"
                                  )
                                : null
                            }
                            handler={(value) => {
                              if (value !== null)
                                valueSetter(
                                  index,
                                  "processTypeId",
                                  processTypesList.filter((element) => {
                                    return (
                                      getItemName(element, "processType") ==
                                      value
                                    );
                                  })[0].id
                                );
                              else {
                                valueSetter(index, "processTypeId", null);
                              }
                            }}
                          />
                        </div>

                        {/* Usage Types Dropdown */}

                        <div className="h-5 w-5">
                          {
                            newYield.length - 1 !== index ? (
                              <div
                                onClick={() => {
                                  setNewYield(
                                    newYield.filter((v, i) => {
                                      return i !== index;
                                    })
                                  );
                                }}
                                className="h-5 w-5 ml-2 mt-8 text-gray-500 hover:text-red-500 hover:p-1"
                              >
                                <TrashIcon />
                              </div>
                            ) : (
                              <div
                                className="h-4 w-4 ml-2 mt-8 text-white rounded-md bg-green-500"
                                onClick={() => {
                                  setNewYield([
                                    ...newYield,
                                    {
                                      identifier: null,
                                      procurement: null,
                                      compliance: null,
                                      headRiceYield: null,
                                      farmYield: null,
                                      uomId: null,
                                      processTypeId: null,
                                      varietyId: null,
                                      zoneId: null,
                                      transplantingDays: null,
                                      maturityDays: null,
                                    },
                                  ]);
                                }}
                              >
                                <PlusIcon />
                              </div>
                            )
                            // :<CustomButton className=" mt-8 ml-3 text-sm p-1 bg-green-500" btnName={<span className="h-3 w-3"><PlusIcon /></span>} onClick={() => { setNewYield([...newYield, { name: "" }]) }} />
                          }
                        </div>
                      </div>

                      <div className="w-full flex gap-x-2">
                        <CustomInputBox
                          title="Farm Yield"
                          value={newYield[index].farmYield}
                          valueSetter={(value) => {
                            valueSetter(index, "farmYield", value);
                          }}
                        />

                        <CustomInputBox
                          title="Compliance"
                          value={newYield[index].compliance}
                          valueSetter={(value) => {
                            valueSetter(index, "compliance", value);
                          }}
                        />
                      </div>
                      <div className="w-full flex gap-x-2">
                        <CustomInputBox
                          title="Procurement"
                          value={newYield[index].procurement}
                          valueSetter={(value) => {
                            valueSetter(index, "procurement", value);
                          }}
                        />

                        <CustomInputBox
                          title="Head Rice Yield"
                          value={newYield[index].headRiceYield}
                          valueSetter={(value) => {
                            valueSetter(index, "headRiceYield", value);
                          }}
                        />
                      </div>
                      <div className="w-full flex gap-x-2">
                        <CustomInputBox
                          title="Maturity Days"
                          value={newYield[index].maturityDays}
                          valueSetter={(value) => {
                            valueSetter(index, "maturityDays", value);
                          }}
                        />

                        <CustomInputBox
                          title="Transplanting Days"
                          value={newYield[index].transplantingDays}
                          valueSetter={(value) => {
                            valueSetter(index, "transplantingDays", value);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </li>
            );
          })}
        </ol>
      </div>
      <div className="flex w-1/4 mt-10 ml-3 justify-between">
        <CustomButton
          btnName="Cancel"
          bgColor="bg-gray-400"
          onClick={() => {
            history.goBack();
          }}
        />
        {!isSubmit ? (
          <CustomButton btnName="Submit" onClick={addYield} />
        ) : (
          <CustomButton btnName="Submit" onClick={() => {}} />
        )}
      </div>
    </div>
  );
};
export default YieldMasterAdd;
