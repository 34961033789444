import React, { useEffect, useState } from "react";
import { useLocation, Redirect, useHistory } from "react-router-dom";
import { CustomButton } from "../../../components/atoms/CustomButton";
import CustomInputBox from "../../../components/atoms/CustomInputBox";
import axios from "../../../axios";
import { toast } from "react-toastify";
import { PencilIcon } from "@heroicons/react/outline";
import CustomDropDownSearchSelect from "../../../components/atoms/CustomDropDownSearchSelect";
import { errorToast } from "../../../util/toast";
import CustomDateSelector from "../../../components/atoms/CustomDateSelector";

const CropLifeCycleEdit = () => {
  const updatePropList = [
    "samplingDate",
    "samplingRequestDate",
    "acre",
    "samplingNumber",
  ];

  const location = useLocation();
  const history = useHistory();
  const id = JSON.parse(location.state).data.id;
  const [data, setData] = useState(null);

  const [farmerList, setFarmerList] = useState(null);
  const [varietyList, setVarietyList] = useState(null);
  const [error, setError] = useState(null);

  const getItemName = (obj, prop1, prop2) => {
    return obj[prop2];
  };

  const valueSetter = (propertyName, newValue) => {
    console.log("here");
    const newData = { ...data };
    newData[propertyName] = newValue;

    console.log(newData);
    setData(newData);
  };

  useEffect(() => {
    // const newObj = {};
    // Object.keys(data).forEach((key) => {
    //   if (data[key]?.hasOwnProperty("show")) {
    //     newObj[key] = data[key].value;
    //   } else {
    //     newObj[key] = data[key];
    //   }
    // });
    // if (Object.keys(newObj).length > 0) setData(newObj);
    axios
      .get(`cropCuttingSampling/${id}`)
      .then((response) => {
        const temp = response.data.Result.CropCuttingSampling;
        console.log("Sampling", response, temp);

        setData(temp);
        return axios.get(`farmerMaster/load`);
      })
      .then((response) => {
        setFarmerList(response.data.Result.FarmerMaster);
        return axios.get(`varietyMaster/load`);
      })
      .then((response) => {
        setVarietyList(response.data.Result.VarietyMaster);
      })
      .catch((error) => {
        console.log(error);
        errorToast(error?.response?.data?.Comments);
      });
  }, []);

  if (!location.state) {
    return <Redirect to="/" />;
  }

  const createSendingData = async (obj) => {
    return new Promise((resolve, reject) => {
      let newData = {};
      Object.keys(obj).forEach((property, index) => {
        // console.log( "index" ,property , index)
        if (updatePropList.includes(property)) {
          newData[property] = obj[property];
        }
        if (index === Object.keys(obj).length - 1) resolve(newData);
      });
    });
  };
  const getDateString = (date) => {
    let year = date.getFullYear();
    let month = date.getMonth() + 1;
    month = month < 10 ? "0" + month : month;
    let day = date.getDate();
    day = day < 10 ? "0" + day : day;

    return day + "/" + month + "/" + year;
  };
  const editCropLifeCycle = () => {
    if (data?.actualLandUsed === NaN) {
      setError("please specify the actualLandUsed");
    } else if (data?.landUsed === NaN) {
      setError("Please specify the land used");
    } else {
      createSendingData(data)
        .then((newData) => {
          console.log(newData, data, " is the new data hhmmmm");
          return axios.put(
            `cropCuttingSamplingConfirmation/${data.id}`,
            newData
          );
        })
        .then((response) => {
          if (response?.data?.ResponseMessage === "SUCCESS") {
            toast.success("Update successful!", { position: "top-center" });
            history.push("/home/transactionData/sampling");
          }
        })
        .catch((error) => {
          errorToast(error?.response?.data?.Comments);
          console.log(error);
        });
    }
  };

  return data !== null ? (
    <div className=" m-3 p-3">
      <div className="flex">
        <span>
          <PencilIcon className="h-5 w-5 transform text-black mt-4 mr-1" />
        </span>
        <span className=" py-4 text-gray-700 text-base font-bold text-left">
          Edit Sampling
        </span>
      </div>
      {console.log("DATA", data)}
      <div className="flex flex-wrap justify-left">
        <div className="w-2/3">
          <div className=" gap-x-3 space-y-2 mb-4">
            <div className="mb-4 flex gap-x-3">
              <CustomInputBox
                title={"Farmer"}
                disabled={true}
                borderDisable={true}
                value={getItemName(
                  data.FarmerVariety.FarmerMaster,
                  "identifier",
                  "name"
                )}
              />
              <CustomInputBox
                title={"Village"}
                disabled={true}
                borderDisable={true}
                value={getItemName(
                  data.FarmerVariety.FarmerMaster.VillageMaster,
                  "identifier",
                  "name"
                )}
              />
            </div>

            <div className="flex gap-x-3">
              <CustomInputBox
                title={"Variety"}
                disabled={true}
                borderDisable={true}
                value={getItemName(
                  data.FarmerVariety.VarietyMaster,
                  "identifier",
                  "name"
                )}
              />
              <CustomInputBox
                title="Sampling Number"
                disabled={true}
                value={data?.samplingNumber}
                className="w-1/2"
                valueSetter={(value) => {
                  valueSetter("samplingNumber", value);
                }}
              />
            </div>

            <div className="flex gap-x-3">
              <span className="w-1/2">
                <CustomDateSelector
                  label={"Sampling Date"}
                  date={
                    data.samplingCollectDate === null
                      ? new Date()
                      : new Date(data.samplingCollectDate)
                  }
                  onChange={(value) => {
                    valueSetter("samplingDate", new Date(value));
                  }}
                />
              </span>
              <span className="w-1/2">
                <CustomDateSelector
                  label={"Sampling Request Date"}
                  date={
                    data.samplingRequestDate === null
                      ? ""
                      : new Date(data.samplingRequestDate)
                  }
                  onChange={(value) => {
                    valueSetter("samplingRequestDate", new Date(value));
                  }}
                />
              </span>
            </div>

            <div className="flex gap-x-3">
              <div className="w-1/2">
                <CustomInputBox
                  title="Area in acre "
                  value={data?.acre}
                  disabled={false}
                  className="w-1/2"
                  valueSetter={(value) => {
                    valueSetter("acre", value);
                  }}
                />
              </div>

              <div className="w-1/2">
                <CustomDateSelector
                  label="Expected Harvesting date"
                  onChange={(value) => {
                    valueSetter("expectedHarvestingDate", new Date(value));
                  }}
                  date={
                    data.expectedHarvestingDate === null
                      ? ""
                      : new Date(data.expectedHarvestingDate)
                  }
                  className="w-1/2"
                />
              </div>
            </div>
          </div>
          <div className="flex mt-8 justify-between">
            <CustomButton
              btnName="Cancel"
              className="p-2 bg-gray-500 hover:bg-gray-700 text-lg"
              onClick={() => {
                history.goBack();
              }}
            />
            <CustomButton
              btnName="Update"
              className="p-2 bg-green-500 hover:bg-green-700 text-lg"
              onClick={editCropLifeCycle}
            />
          </div>
        </div>
      </div>
      <div className="text-xl text-red-500">{error}</div>
      <div className="flex w-max gap-x-10"></div>
    </div>
  ) : null;
};
export default CropLifeCycleEdit;
