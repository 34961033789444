import { XIcon } from '@heroicons/react/outline';
import React from 'react';
import { CustomButton } from '../../../components/atoms/CustomButton';


const MasterDataDelete = ({show, object, onClose, onYes , msg}) => {

    if(show)
    return (
        <div>
        <div className="fixed top-0 left-0 z-30 h-full w-full"> </div>
        <div className="fixed top-1/2 left-1/2 -mt-20 -ml-60 z-50 h-50 w-popup border-2 text-lg py-3 rounded-xl shadow-2xl bg-white" >
        <div className="flex justify-end pr-3"><span onClick={(e) => { onClose(e) }}><XIcon className="h-5 w-5 text-gray-500"/></span></div>
            <p className="p-3 text-center">Are you sure you want to {msg} {object.name.value} ?</p>
            <div className = "flex justify-around mt-4">
                <CustomButton btnName="No"  bgColor="bg-gray-300" onClick={(e)=>{onClose(e)}}/>
                <CustomButton btnName="Yes" onClick={ (e)=>{onYes(e)}}/>
            </div>
        </div>
        </div>
    );
    else return null
}

export default MasterDataDelete;