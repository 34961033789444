import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

import RolesHome from "./RolesHome";
import RolesDisplay from "./RolesDisplay"
import MasterDataDelete from "../common/MasterDataDelete";

const RolesRoute = () => {
  return (
    <BrowserRouter basename="/home/masterData/roles/">
      <Switch>
        <Route path="/" component={RolesHome} exact />
        {/* <Route path="/add" component={UsersAdd} />
        <Route path="/edit/:id" component={UsersEdit} />
  <Route path="/delete/:id" component={MasterDataDelete} /> */}
        <Route path="/display" component={RolesDisplay} />
        <Route component={() => <Redirect to="/" />} />
      </Switch>
    </BrowserRouter>
  );
};

export default RolesRoute;
