import { React, useEffect, useState } from "react";
import { useLocation, Redirect } from "react-router-dom";
import { EyeIcon } from "@heroicons/react/outline";
import axios from "../../../axios";
import { errorToast } from "../../../util/toast";
import CustomInputBox from "../../../components/atoms/CustomInputBox";
import CustomDateSelector from "../../../components/atoms/CustomDateSelector";

const getDateString = (date) => {
  let year = date.getFullYear();
  let month = date.getMonth() + 1;
  month = month < 10 ? "0" + month : month;
  let day = date.getDate();
  day = day < 10 ? "0" + day : day;
  return year + "/" + month + "/" + day;
};
const addDaysToDate = (date, days) => {
  console.log(date, days, "AAAA");
  let newDate = date;
  newDate.setDate(newDate.getDate() + days);

  newDate = getDateString(new Date(newDate));
  console.log(newDate);
  return newDate;
};
const CropLifeCycleDisplay = () => {
  const location = useLocation();
  const [initialData, setInitialData] = useState(
    JSON.parse(location.state).data
  );
  const [prevData, setPrevData] = useState(null);
  const [data, setData] = useState(null);
  console.log(initialData);

  useEffect(() => {
    if (initialData.id !== undefined)
      axios
        .get(`cropLifeCycle/${initialData.id}`)
        .then((response) => {
          // console.log("Result is", response.data.Result.CropLifeCycle[0]);
          response = response.data.Result.CropLifeCycle[0];
          response.sowingList = [
            { sowingDate: response.sowingDate, landUsed: response.landUsed },
          ];
          setPrevData(response);
        })
        .catch((error) => {
          console.log(error);
          errorToast(error?.message);
        });
  }, [initialData]);

  useEffect(() => {
    if (prevData != null) {
      axios.get(`cropLifeCycleSowingDates/${prevData.id}`).then((response) => {
        prevData.sowingList.splice(1, 0, ...response.data.Result.sowingList);
        setData(prevData);
      });
    }
  }, [prevData]);

  if (!initialData) return <Redirect to="/" />;
  return data !== null ? (
    <div className="overflow-hidden m-3 p-3 ">
      <div className="flex">
        <span>
          <EyeIcon className="h-5 w-5 transform text-black mt-4 mr-1" />
        </span>
        <span className=" py-4 text-gray-700 text-base font-bold text-left">
          Display Crop Life Cycle
        </span>
      </div>
      <div className="mb-4 flex gap-x-3 w-full flex-wrap">
        {/* <CustomDisplayBox title="ID" value={data.id} className=" pl-2"/> */}
        <div className="w-full flex">
          <CustomInputBox
            title={"Farmer"}
            disabled={true}
            borderDisable={true}
            value={`${data.FarmerMaster.name}`}
          />
          <CustomInputBox
            title={"Mandi"}
            disabled={true}
            borderDisable={true}
            value={`${data.FarmerMaster.MandiMaster.name}`}
          />
        </div>
        <div className="w-full flex">
          <CustomInputBox
            title={"Village"}
            disabled={true}
            borderDisable={true}
            value={`${data.FarmerMaster.VillageMaster.name}`}
          />
          <CustomInputBox
            title={"Variety"}
            disabled={true}
            borderDisable={true}
            value={`${data.VarietyMaster.name}`}
          />
        </div>
        <div className="w-full flex">
          {data.actualLandUsed && (
            <CustomInputBox
              title={"Actual Land Used"}
              disabled={true}
              borderDisable={true}
              value={data.actualLandUsed}
            />
          )}
          <CustomInputBox
            title={"Seed To Seed Date"}
            disabled={true}
            borderDisable={true}
            value={
              data.sowingDate === null
                ? "[unknown]"
                : addDaysToDate(
                    new Date(data.sowingDate),
                    data.VarietyMaster.seedToSeed
                  )
            }
          />
        </div>

        {data.sowingList.map((element, varIndex) => {
          return (
            <div className="flex gap-x-2 w-3/4">
              <div className="w-full">
                <CustomDateSelector
                  label={"Sowing Date"}
                  date={
                    element.sowingDate === null
                      ? null
                      : new Date(element.sowingDate)
                  }
                  disabled={true}
                />
              </div>

              <div className="w-full">
                <CustomInputBox
                  title="Land Used"
                  value={element.landUsed}
                  disabled={true}
                />
              </div>
            </div>
          );
        })}
      </div>
      <div className="flex w-max gap-x-10"></div>
    </div>
  ) : null;
};
export default CropLifeCycleDisplay;
