import React from 'react';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { Link, useHistory } from 'react-router-dom';
import axios from '../../axios';
import { errorToast, successToast } from '../../util/toast.js';
import { CustomButton } from '../atoms/CustomButton';


const initialValues = {
    email: '',
    password: '',
};

const validationSchema = Yup.object({
    email: Yup.string().email('Invalid email address').required('Required'),
    password: Yup.string().max(15, 'Must be less than 15 character').required('Required')
});


const LoginForm = () => {
    const history = useHistory();

    function login(values) {
        axios.post('signin', {
            email: values.email,
            password: values.password,
        })
            .then(function (response) {

                if (response.data.ResponseCode === 1) {
                    if (response.data.Result.User?.token && response.data.Result.User?.authority) {
                        console.log(response.data.Result.User)
                        localStorage.setItem("cfUserToken", response.data.Result.User.token)
                        localStorage.setItem("cfUserId", response.data.Result.User.id)
                        localStorage.setItem("cfUserAuth",JSON.stringify(response.data.Result.User?.authority))
                        localStorage.setItem("cfUserRole",JSON.stringify(response.data.Result.User?.info?.Role.roleName))
                        console.log("User logged in with token:", localStorage.getItem("cfUserAuth"))
                        history.push('/home');
                    } else {
                        history.push({pathname: "/otp", state:{email: values.email}});
                    }
                    successToast(response.data.Comments, 1000);

                } else {
                    errorToast(response.data.Comments, 3000);
                }
            })
            .catch(function (error) {
                console.log(error.response?.data.Comments);
                errorToast(error.response?.data.Comments, 3000);
            });

    }

    return (

        <Formik
            // initialValues={{ email: '', password: '', }}
            // validationSchema={Yup.object({
            //     // firstName: Yup.string()
            //     //     .max(15, 'Must be 15 characters or less')
            //     //     .required('Required'),

            //     email: Yup.string().email('Invalid email address').required('Required'),
            //     password: Yup.string()
            //         .max(10, 'Must be less than 15 character')
            //         .required('Required')
            // })}
            initialValues={initialValues}

            validationSchema={validationSchema}

            onSubmit={(values) => {
                login(values);
            }}
        >


            <div className="">
                <div className="flex flex-col sm:max-w-md  mx-auto bg-white  px-6 pt-5 pb-5 rounded-3xl border-t-8 border-green-400">
                    <span className=" font-robo  text-lg font-bold text-center pt-4 pb-6 text-green-500">Login</span>
                    <Form className="flex flex-col space-y-4">
                        <div className="flex flex-col">
                            {/* <label htmlFor="email" className=" text-xs font-thin">E-mail</label> */}
                            <Field
                                className="border border-gray-400 rounded-md pl-4 py-2 focus:outline-none 
                                hover:border-green-400 font-light"
                                name="email" type="text"
                                placeholder="E-mail"
                            />
                            <div className=" font-robo text-red-700 text-xs">
                                <ErrorMessage name="email" />
                            </div>

                        </div>

                        <div className="flex flex-col">
                            {/* <label htmlFor="password" className="text-sm"> Password </label> */}
                            <Field name="password" type="password" placeholder="Password"
                                className="border border-gray-400 rounded-md pl-4 py-2 font-light hover:border-green-400 focus:outline-none"
                            />
                            <div className=" font-robo text-red-700 text-xs">
                                <ErrorMessage name="password" />
                            </div>

                            {/* type="password" for not showing password  */}
                        </div>
                        <div className="font-thin  text-base  pb-4 text-green-500">
                            <Link to="/forgotpassword" className="float-right">Forgot Password?</Link>
                        </div>

                        {/* 
                        <button type="submit" className="bg-green-500 rounded-md mx-auto text-white
                        py-2 w-24">Submit</button> */}
                        <div className="mx-auto">
                            <CustomButton btnName='Submit' type="submit" className="bg-green-400 p-2 hover:bg-green-500" />
                        </div>

                    </Form>
                </div>

            </div>



        </Formik>
    )
}

export default LoginForm;
