import { Menu, Transition } from '@headlessui/react'
import { Fragment } from 'react'

export default function CustomDropDownMenu({ titleContent, options }) {
    return (
        <div className="mb-2 text-right top-16">
            <Menu as="div" className="relative inline-block text-left">
                <div>
                    <Menu.Button className="inline-flex justify-center w-full px-4 py-2 text-sm font-medium text-white focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75">
                        {titleContent}
                    </Menu.Button>
                </div>
                <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                >
                    <Menu.Items className="absolute right-0 w-56 mt-0 origin-top-right bg-white rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none z-50">
                        <div className="px-1 py-1 z-50">
                            {
                                options.map((value, index) => {
                                    return (
                                        <Menu.Item>
                                            {({ active }) => (
                                                <div className="cursor-pointer w-max mx-auto" key={index}>
                                                    <div
                                                        className={`${active ? 'text-green-500' : 'text-green-600'} rounded-md px-2 py-2 text-sm`}
                                                        onClick={value.onClick}
                                                    >
                                                        {value.content}
                                                    </div>
                                                </div>
                                            )}
                                        </Menu.Item>
                                    )
                                })
                            }
                        </div>
                    </Menu.Items>
                </Transition>
            </Menu>
        </div>
    )
}