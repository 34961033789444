import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { CustomButton } from "../../../components/atoms/CustomButton";
import axios from "../../../axios";
import { DocumentAddIcon, PlusIcon, TrashIcon } from "@heroicons/react/outline";
import CustomInputBox from "../../../components/atoms/CustomInputBox";
import { successToast, errorToast } from "../../../util/toast";
import CustomDropDownSearchSelect from "../../../components/atoms/CustomDropDownSearchSelect";
import { validateChemicalAdd } from "../common/validations";

const getItemName = (obj, prop1) => {
  return obj[prop1];
};
const getItemName2 = (obj, prop1, prop2) => {
  return obj[prop2];
};
//returns the first object in the list whose property matches the target
const searchObject = (items, target, property) => {
  return items.filter((element) => {
    return element[property] === target;
  })[0];
};

const ChemicalMasterAdd = () => {
  const history = useHistory();
  const [newChemical, setNewChemical] = useState([
    {
      activeIngredient: "",
      brandName: "",
      uomId: null,
      phi: null,
      dosePerAcre: null,
      usesTypeId: null,
      popId: null,
      insectDiseaseList: null,
      isRecommended: false,
    },
  ]);
  const [uomObjectsList, setUomObjectsList] = useState([]);
  const [popList, setPopList] = useState([]);
  const [usesTypesList, setUsesTypesList] = useState([]);
  const [insectDiseaseListId, setInsectDiseaseListId] = useState(null);
  const [isSubmit, setIsSubmit] = useState(false);

  useEffect(() => {
    axios
      .get(`pop`)
      .then((response) => {
        setPopList(response.data.Result.Pop);
        return axios.get(`usesType`);
      })
      .then((response) => {
        setUsesTypesList(response.data.Result.UsesTypes);
        return axios.get(`uom`);
      })
      .then((response) => {
        setUomObjectsList(response.data.Result.Uom);
        return axios.get(`insectsDiseasesMaster/load`);
      })
      .then((response) => {
        setInsectDiseaseListId(response.data.Result.insectDiseaseMaster);
      })
      .catch((error) => {
        console.log(error?.message);
        errorToast(error?.response?.data?.Comments);
      });
  }, []);

  const valueSetter = (index, property, newValue) => {
    if (newValue !== null) {
      let Chemical = [...newChemical];
      Chemical[index][property] = newValue;
      setNewChemical(Chemical);
    }
  };

  const addChemical = () => {
    console.log(newChemical);
    if (validateChemicalAdd(newChemical)) {
      setIsSubmit(true);
      successToast("Your request is being processed");
      axios
        .post("chemicalMasters/", newChemical)
        .then((response) => {
          if (response?.data?.ResponseMessage === "SUCCESS") {
            successToast("New Chemical added!");
            history.goBack();
          }
        })
        .catch((error) => {
          errorToast(error?.response?.data?.Comments);
          console.log(error);
        });
    } else {
      setIsSubmit(false);
    }
  };
  return (
    <div className="p-3 m-3">
      <div className="flex mb-7">
        <DocumentAddIcon className="h-5 w-5text-black mt-3 mr-1" />
        <div className="text-base pt-3 font-bold text-gray-800">
          Add Chemical
        </div>
      </div>
      <div>
        <ol style={{ listStyleType: "number" }}>
          {newChemical.map((value, index) => {
            return (
              <li>
                <div className="mb-4 flex">
                  <div className="flex w-2/3">
                    <div className="flex-wrap flex gap-y-4 m-3 gap-x-2">
                      <div className="w-full">
                        <CustomInputBox
                          title="Chemical Brand Name"
                          value={newChemical[index].brandName}
                          valueSetter={(value) => {
                            valueSetter(index, "brandName", value);
                          }}
                        />
                        <CustomInputBox
                          title="Active Ingredient"
                          value={newChemical[index].activeIngredient}
                          valueSetter={(value) => {
                            valueSetter(index, "activeIngredient", value);
                          }}
                        />
                        <CustomInputBox
                          title="Dose Per Acre"
                          value={newChemical[index].dosePerAcre}
                          valueSetter={(value) => {
                            valueSetter(index, "dosePerAcre", value);
                          }}
                        />
                        <CustomInputBox
                          title="PHI"
                          value={newChemical[index].phi}
                          valueSetter={(value) => {
                            valueSetter(index, "phi", value);
                          }}
                        />
                        <CustomDropDownSearchSelect

                          label={"Insect/Disease"}
                            items={
                              insectDiseaseListId !== null 
                                ? insectDiseaseListId.map((value) => {
                                  return getItemName(value, "insectDiseaseName");
                                })
                              :[]
                            }
                            selected={
                              value.insectDiseaseList !== null && insectDiseaseListId.length > 0
                                ? getItemName(
                                    searchObject(
                                      insectDiseaseListId,
                                      value.insectDiseaseList,
                                      "id"
                                    ),
                                    "insectDiseaseName"
                                  )
                                : null
                            }
                            handler={(value) => {
                              if (value !== null)
                                valueSetter(
                                  index,
                                  "insectDiseaseList",
                                  insectDiseaseListId.filter((element) => {
                                    return getItemName(element, "insectDiseaseName") == value;
                                  })[0].id
                                );
                              else {
                                valueSetter(index, "insectDiseaseList", null);
                              }
                            }}

                          // label={"Insect/Disease"}
                          // multi={true}
                          // items={
                          //   insectDiseaseListId !== null
                          //     ? insectDiseaseListId.map((element) => {
                          //         return getItemName2(
                          //           element,
                          //           "identifier",
                          //           "insectDiseaseName"
                          //         );
                          //       })
                          //     : []
                          // }
                          // selected={
                          //   insectDiseaseListId !== null &&
                          //   value.insectDiseaseList !== null
                          //     ? insectDiseaseListId
                          //         .filter((item) => {
                          //           return (
                          //             value?.insectDiseaseList?.indexOf(
                          //               item.id
                          //             ) !== -1
                          //           );
                          //         })
                          //         .map((item) =>
                          //           getItemName2(
                          //             item,
                          //             "identifier",
                          //             "insectDiseaseName"
                          //           )
                          //         )
                          //     : []
                          // }
                          // handler={(value) => {
                          //   if (value !== null) {
                          //     valueSetter(
                          //       index,
                          //       "insectDiseaseList",
                          //       JSON.stringify(
                          //         insectDiseaseListId
                          //           .filter((element) => {
                          //             console.log(
                          //               getItemName2(
                          //                 element,
                          //                 "identifier",
                          //                 "insectDiseaseName"
                          //               )
                          //             );
                          //             return (
                          //               value.indexOf(
                          //                 getItemName2(
                          //                   element,
                          //                   "identifier",
                          //                   "insectDiseaseName"
                          //                 )
                          //               ) !== -1
                          //             );
                          //           })
                          //           .map((item) => {
                          //             return item.id;
                          //           })
                          //       )
                          //     );
                          //   } else {
                          //     valueSetter(index, "insectDiseaseList", null);
                          //   }
                          // }}
                        />
                        <CustomDropDownSearchSelect
                          label={"IS Recommended?"}
                          items={["Yes", "No"]}
                          selected={value.isRecommended == 1 ? "Yes" : "No"}
                          handler={(value) => {
                            valueSetter(
                              index,
                              "isRecommended",
                              value == "Yes" ? true : false
                            );
                            console.log(value, "VAlUE");
                          }}
                        />
                      </div>
                      <div className="flex gap-x-4 w-full">
                        {/* UoM Drop Down */}
                        <div className="w-full">
                          <CustomDropDownSearchSelect
                            label={"UoM"}
                            items={uomObjectsList.map((value) => {
                              return getItemName(value, "uom");
                            })}
                            selected={
                              value.uomId !== null && uomObjectsList.length > 0
                                ? getItemName(
                                    searchObject(
                                      uomObjectsList,
                                      value.uomId,
                                      "id"
                                    ),
                                    "uom"
                                  )
                                : null
                            }
                            handler={(value) => {
                              if (value !== null)
                                valueSetter(
                                  index,
                                  "uomId",
                                  uomObjectsList.filter((element) => {
                                    return getItemName(element, "uom") == value;
                                  })[0].id
                                );
                              else {
                                valueSetter(index, "uomId", null);
                              }
                            }}
                          />
                        </div>
                        {/* PoP dropdown */}
                        <div className="w-full">
                          <CustomDropDownSearchSelect
                            label={"Package of Practice"}
                            items={popList.map((value) => {
                              return getItemName(value, "pop");
                            })}
                            selected={
                              value.popId !== null && popList.length > 0
                                ? getItemName(
                                    popList.filter((element) => {
                                      return element.id === value.popId;
                                    })[0],
                                    "pop"
                                  )
                                : null
                            }
                            handler={(value) => {
                              if (value !== null && popList.length > 0)
                                valueSetter(
                                  index,
                                  "popId",
                                  popList.filter((element) => {
                                    return (
                                      getItemName(element, "pop") === value
                                    );
                                  })[0].id
                                );
                              else {
                                valueSetter(index, "popId", null);
                              }
                            }}
                          />
                        </div>

                        {/* Usage Types Dropdown */}
                        <div className="w-full">
                          <CustomDropDownSearchSelect
                            label={"Usage Type"}
                            items={usesTypesList.map((value) => {
                              return getItemName(value, "usesType");
                            })}
                            selected={
                              value.usesTypeId !== null &&
                              usesTypesList.length > 0
                                ? getItemName(
                                    usesTypesList.filter((element) => {
                                      return element.id === value.usesTypeId;
                                    })[0],
                                    "usesType"
                                  )
                                : null
                            }
                            handler={(value) => {
                              if (value !== null && usesTypesList.length > 0)
                                valueSetter(
                                  index,
                                  "usesTypeId",
                                  usesTypesList.filter((element) => {
                                    return (
                                      getItemName(element, "usesType") === value
                                    );
                                  })[0].id
                                );
                              else {
                                valueSetter(index, "usesTypeId", null);
                              }
                            }}
                          />
                        </div>
                        <div className="h-5 w-5">
                          {
                            newChemical.length - 1 !== index ? (
                              <div
                                onClick={() => {
                                  setNewChemical(
                                    newChemical.filter((v, i) => {
                                      return i !== index;
                                    })
                                  );
                                }}
                                className="h-5 w-5 ml-2 mt-8 text-gray-500 hover:text-red-500 hover:p-1"
                              >
                                <TrashIcon />
                              </div>
                            ) : (
                              <div
                                className="h-4 w-4 ml-2 mt-8 text-white rounded-md bg-green-500"
                                onClick={() => {
                                  setNewChemical([
                                    ...newChemical,
                                    {
                                      activeIngredient: "",
                                      brandName: "",
                                      uomId: null,
                                      phi: null,
                                      dosePerAcre: null,
                                      usesTypeId: null,
                                      popId: null,
                                    },
                                  ]);
                                }}
                              >
                                <PlusIcon />
                              </div>
                            )
                            // :<CustomButton className=" mt-8 ml-3 text-sm p-1 bg-green-500" btnName={<span className="h-3 w-3"><PlusIcon /></span>} onClick={() => { setNewChemical([...newChemical, { name: "" }]) }} />
                          }
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
            );
          })}
        </ol>
      </div>
      <div className="flex w-1/2 justify-between">
        <CustomButton
          btnName="Cancel"
          bgColor="bg-gray-400"
          onClick={() => {
            history.goBack();
          }}
        />
        {!isSubmit ? (
          <CustomButton btnName="Submit" onClick={addChemical} />
        ) : (
          <CustomButton btnName="Submit" onClick={() => {}} />
        )}
      </div>
    </div>
  );
};
export default ChemicalMasterAdd;
