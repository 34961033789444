import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { CustomButton } from "../../../components/atoms/CustomButton";
import MasterDataAddCSV from "../common/MasterDataAddCSV";
import MasterDataDelete from "../common/MasterDataDelete";
import axios from "../../../axios";
import {
  DocumentAddIcon,
  CloudUploadIcon,
  SunIcon,
} from "@heroicons/react/outline";
import {
  EyeIcon,
  PencilIcon,
  TrashIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
} from "@heroicons/react/solid";
import { toast } from "react-toastify";
import CustomTable from "../../../components/atoms/CustomTable";
import CustomInputBox from "../../../components/atoms/CustomInputBox";
import CustomDropDownSearchSelect from "../../../components/atoms/CustomDropDownSearchSelect";
import { errorToast } from "../../../util/toast";

const getItemName = (obj, prop1) => {
  return obj[prop1];
};
//returns the first object in the list whose property matches the target
const searchObject = (items, target, property) => {
  return items.filter((element) => {
    return element[property] === target;
  })[0];
};

const FertilizerMasterHome = () => {
  const role = JSON.parse(localStorage.getItem("cfUserAuth"));
  const result = role.find((obj) => {
    return obj.moduleName === "fertilizerMasters";
  });

  const history = useHistory();
  const tablePageNumbersShown = 3;
  //table related.
  const [currentTableData, setTableData] = useState([]);
  const rowsPerPage = 10;
  const [totalRecords, setTotalRecords] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [sortBy, setSortBy] = useState({
    headerName: "id",
    order: false,
  });

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showUploadFile, setShowUploadFile] = useState(false);
  const [deleteObject, setDeleteObject] = useState(null);

  const tableHeaders = [
    { displayName: "id", property: "id", sortable: true, show: false },
    { displayName: "name", property: "name", sortable: true, show: true },
    {
      displayName: "Brand Name",
      property: "brandName",
      sortable: true,
      show: true,
    },
    { displayName: "UoM", property: "uom", sortable: false, show: true },
    {
      displayName: "actions",
      property: "actions",
      sortable: false,
      show: true,
    },
  ];
  const [searchUomId, setSearchUomId] = useState(null);
  const [searchName, setSearchName] = useState(null);
  const [uomId, setUomId] = useState(null);

  const [uomObjectsList, setUomObjectsList] = useState([]);

  const addActions = (objects) => {
    //adds the actions buttons to the table rows.
    const newObjects = [];
    objects.map((obj) => {
      obj.name = { value: obj.name, show: true };
      obj.brandName = { value: obj.brandName, show: true };
      obj.uom = { value: obj.Uom.uom, show: true };
      obj.actions = {
        value: (
          <div className="flex gap-x-3 p-1">
            {result.moduleUpdate == 1 && (
              <Link
                to={{
                  pathname: `/edit/${obj.id}`,
                  state: JSON.stringify({ data: obj }),
                }}
              >
                <span>
                  <PencilIcon className="h-5 w-5 text-gray-700 hover:text-black" />
                </span>
              </Link>
            )}

            <Link
              to={{
                pathname: `/display`,
                state: JSON.stringify({ data: obj }),
              }}
            >
              <span>
                <EyeIcon className="h-5 w-5 text-gray-700 hover:text-black" />
              </span>
            </Link>
            {result.moduleDelete == 1 && (
              <span>
                <TrashIcon
                  className="h-5 w-5 text-gray-700 hover:text-black"
                  onClick={() => {
                    setShowDeleteModal(true);
                    setDeleteObject(obj);
                  }}
                />
              </span>
            )}
          </div>
        ),
        show: true,
      };
      newObjects.push(obj);
    });
    return newObjects;
  };

  const deleteFertilizer = () => {
    console.log(deleteObject);
    axios
      .delete(`fertilizerMaster/${deleteObject.id}`)
      .then((response) => {
        if (response?.data?.ResponseMessage === "SUCCESS") {
          toast.success("Delete successful!", { position: "top-center" });
          // remove this and do it better, we need to remove the row of the delete object thats it
          window.location.reload(false);
          setShowDeleteModal(false);
        }
      })
      .catch((error) => {
        errorToast(error?.response?.data?.Comments);
        console.log(error);
      });
  };

  useEffect(() => {
    //loading the table data here for the first time.
    //load the first page of the table.
    axios
      .get(`uom`)
      .then((response) => {
        setUomObjectsList(response.data.Result.Uom);
      })
      .catch((error) => {
        console.log(error?.message);
        errorToast(error?.response?.data?.Comments);
      });
  }, []);

  useEffect(() => {
    //updating the table data based on the search terms.
    let params = {
      page: pageNumber,
      limit: 10,
      orderBy: sortBy.headerName,
      order: sortBy.order ? "ASC" : "DESC",
    };
    if (searchName !== null && searchName.trim() !== "") {
      params.name = searchName.trim();
    }
    if (uomId !== null) {
      params.uomId = uomId;
    }
    console.log(params);
    axios
      .get(`fertilizerMaster`, { params })
      .then((response) => {
        setTotalRecords(response.data.Result.total);
        const returnedRows = response.data.Result.FertilizerMaster;
        setTotalPages(Math.ceil(response.data.Result.total / rowsPerPage));
        let actualRows = addActions(returnedRows);
        setTableData(actualRows);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [searchName, searchUomId, pageNumber, sortBy, uomId, showUploadFile]);

  useEffect(() => {
    setPageNumber(1);
  }, [searchName, searchUomId]);

  const nextPage = () => {
    if (pageNumber !== totalPages) setPageNumber(pageNumber + 1);
  };
  const prevPage = () => {
    if (pageNumber !== 1) setPageNumber(pageNumber - 1);
  };

  const getPageButtons = (noOfButtons) => {
    let buttonList = [];
    if (true) {
      let printed = 0;
      for (
        let i = Math.floor((pageNumber - 1) / noOfButtons) * noOfButtons + 1;
        printed < noOfButtons && i <= totalPages;
        i++
      ) {
        buttonList.push(
          <button
            className={`px-3 ${
              i === pageNumber
                ? " bg-green-500 text-white text-sm rounded-xl"
                : "text-gray-600 text-sm"
            }`}
            onClick={() => {
              setPageNumber(i);
            }}
          >
            {i}
          </button>
        );
        printed++;
      }
    }
    return buttonList;
  };

  const applyFilter = () => {};

  return (
    <div className="m-3 h-screen">
      <MasterDataDelete
        show={showDeleteModal}
        object={deleteObject}
        onClose={(e) => {
          setShowDeleteModal(false);
        }}
        onYes={deleteFertilizer}
      />
      <MasterDataAddCSV
        sampleFileDownloadPath={"/sampleFertilizer.csv"}
        fileUploadPath={"fertilizerMaster/file"}
        show={showUploadFile}
        onClose={(e) => {
          setShowUploadFile(false);
        }}
        successMessage="Fertilizer added successfully"
      />

      <div className="flex justify-between overflow-wrap align-middle">
        <div name="IconAndPageTitle" className="bg-white flex ml-3">
          <span>
            <SunIcon className="my-3 mx-1 h-6 w-6 mr-1" />
          </span>
          <span className="text-lg mt-3 text-center align-center">
            Fertilizer Master data
          </span>
          <span className="mt-5 text-xs ml-2">({totalRecords} records)</span>
        </div>
        {result.moduleCreate === 1 && (
          <div name="Add buttons" className="flex flex-wrap gap-x-10">
            <CustomButton
              onClick={() => {
                history.push("/add");
              }}
              btnName="Add"
              icon={<DocumentAddIcon className="h-5 w-5 mr-1" />}
            />
            <CustomButton
              onClick={() => {
                setShowUploadFile(true);
              }}
              btnName="CSV"
              icon={<CloudUploadIcon className="h-5 w-5 mr-1" />}
            />
          </div>
        )}
      </div>
      <div
        name="FilterSection"
        className="flex  gap-x-2 ml-3 bg-white w-1/2 mb-4"
      >
        <div className="w-full">
          <CustomDropDownSearchSelect
            label={"UoM"}
            items={uomObjectsList.map((value) => {
              return getItemName(value, "uom");
            })}
            selected={
              uomId !== null && uomObjectsList.length > 0
                ? getItemName(searchObject(uomObjectsList, uomId, "id"), "uom")
                : null
            }
            handler={(value) => {
              if (value !== null && uomObjectsList.length > 0)
                setUomId(
                  uomObjectsList.filter((element) => {
                    return getItemName(element, "uom") == value;
                  })[0].id
                );
              else {
                setUomId(null);
              }
            }}
          />
        </div>
        <div className="mt-6 flex gap-x-2 w-full">
          <CustomInputBox
            title="Search by name"
            labelClass="mt-2 hidden"
            valueSetter={setSearchName}
            value={searchName}
          />
          {/* <CustomButton btnName="Filter" className="p-2 mt-12 bg-green-500 hover:bg-green-700 text-sm " onClick={applyFilter} /> */}
        </div>
      </div>
      <div
        name="Table"
        className="bg-white ml-3 mt-4 overflow-hidden"
        style={{ height: "420px" }}
      >
        <CustomTable
          rows={currentTableData}
          headers={tableHeaders}
          sortByHandler={setSortBy}
        />
      </div>
      {
        //show the table navigation buttons only if the table has more than 0 records.
        currentTableData.length > 0 ? (
          <div className="text-sm w-max mx-auto text-center flex mt-1">
            <button
              onClick={prevPage}
              className="border-2 text-gray-600 rounded-xl border-black p-1 w-6 h-6 mx-3"
            >
              <ChevronLeftIcon />
            </button>
            <div className="mx-2 mt-1 w-28">
              {getPageButtons(tablePageNumbersShown)}
            </div>
            <button
              onClick={nextPage}
              className="border-2  text-gray-600 rounded-xl border-black p-1 w-6 h-6 mx-3"
            >
              <ChevronRightIcon />
            </button>
          </div>
        ) : null
      }
    </div>
  );
};

export default FertilizerMasterHome;
