import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { CustomButton } from "../../../components/atoms/CustomButton";
import axios from "../../../axios";
import { DocumentAddIcon, PlusIcon, TrashIcon } from "@heroicons/react/outline";
import CustomInputBox from "../../../components/atoms/CustomInputBox";
import { successToast, errorToast } from "../../../util/toast";
import CustomDropDownSearchSelect from "../../../components/atoms/CustomDropDownSearchSelect";
// import { validateUsersAdd } from "../common/validations";

const UsersAdd = () => {
  const history = useHistory();
  const [newUsers, setnewUsers] = useState([
    { name: "", email: "", password: "", role: "",},
  ]);

  //   const RoleList = ([{name:"IT Admin" , id:1} ,{name:"Super Admin" , id:2}  ,{name:"Management Team" , id:3} ,{name:"Purchaser" , id:4}
  //  , {name:"Quality Team" , id:5} ,{name:"CF Head" , id:6}  , {name:"Field Motivator" , id:7} ,{name:"Field Assistant" , id:8}]);

  const [RoleList, setRoleList] = useState(null);
  const [MandiList, setMandiList] = useState(null);
  const [roleId, setRoleId] = useState(null);
  const [isSubmit, setIsSubmit] = useState(false);

  const valueSetter = (index, property, newValue) => {
    let users = [...newUsers];
    users[index][property] = newValue;
    setnewUsers(users);
  };

  useEffect(() => {
    axios
      .get("rolesall")
      .then((res) => {
        console.log("ROLEES", res.data.Result.permissions);
        let filterRole = res.data.Result.permissions;
        filterRole = filterRole.filter((elem) => {
          if (elem.roleName != "Super Admin") return elem;
        });
        setRoleList(filterRole);
      })
      .catch((e) => {
        console.log(e);
      });

    axios
      .get(`mandiMaster/load`)
      .then((res) => {
        console.log(res.data.Result.MandiMaster);
        setMandiList(res.data.Result.MandiMaster);
      })
      .catch((e) => console.log(e));
  }, []);
  const addUser = () => {
    if (true) {
      var Role;

      if (roleId === null) Role = "";
      else {
        Role = getItem(
          searchObject(RoleList, roleId, "identifier"),
          "identifier",
          "roleName"
        );
        newUsers[0].role = Role;
      }
      setIsSubmit(true);
      successToast("Your request is being processed");

      axios
        .post("signup", newUsers[0])
        .then((response) => {
          console.log(response.data);

          return response.data.Result.user;
        })
        .then((res) => {
          axios
            .get(`getuserbyidentifier/${res.identifier}`)
            .then((data2) => {
              console.log(data2.data.Result.user.id);
              return data2.data.Result.user.id;
            })
            .then((userId) => {
              const bullkarray = [];
              newUsers[0].userRoles != undefined &&
                newUsers[0]?.userRoles.map((elem) => {
                  const temp_obj = {};

                  temp_obj["mandiId"] = elem;
                  temp_obj["userId"] = userId;

                  bullkarray.push(temp_obj);
                });

              // console.log(bullkarray);
              return bullkarray;
            })
            .then((bulkarray) => {
              if (
                RoleList != null &&
                roleId != null &&
                (getItem(
                  searchObject(RoleList, roleId, "identifier"),
                  "identifier",
                  "roleName"
                ) == "Purchaser" ||
                  getItem(
                    searchObject(RoleList, roleId, "identifier"),
                    "identifier",
                    "roleName"
                  ) == "Field Motivator" ||
                  getItem(
                    searchObject(RoleList, roleId, "identifier"),
                    "identifier",
                    "roleName"
                  ) == "Field Assistant" ||
                  getItem(
                    searchObject(RoleList, roleId, "identifier"),
                    "identifier",
                    "roleName"
                  ) == "CF Head")
              ) {
              } else {
                bulkarray = [];
              }
              axios.post("userMandi", bulkarray).then((res_mandi) => {
                if (res_mandi?.data?.ResponseMessage === "SUCCESS") {
                  successToast("New user created!");
                  history.goBack();
                }
              });
            });
        })
        .catch((error) => {
          errorToast(error?.response?.data?.Comments);
          console.log(JSON.stringify(error));
        });
      // newUsers[0].userRole.map((val)=>
      // {
      //   axios.post('userMandi'  , {})
      // })
    }
  };

  const getItemName = (obj, prop1) => {
    return obj[prop1];
  };
  const getItem = (obj, prop1, prop2) => {
    return obj[prop2];
  };

  const searchObject = (items, target, property) => {
    return items.filter((element) => {
      return element[property] === target;
    })[0];
  };

  return (
    <div className="p-3 m-3">
      <div className="flex mb-7">
        <DocumentAddIcon className="h-5 w-5text-black mt-3 mr-1" />
        <div className="text-base pt-3 font-bold text-gray-800">Add User</div>
      </div>
      <div>
        {newUsers.map((value, index) => {
          return (
            <div className="mb-4 flex flex-wrap w-1/2">
              <div className="flex w-full">
                <div className="w-full mb-5">
                  <CustomInputBox
                    title="Name"
                    value={value.name}
                    valueSetter={(value) => {
                      valueSetter(index, "name", value);
                    }}
                  />
                  <CustomInputBox
                    title="User Email"
                    value={value.email}
                    valueSetter={(value) => {
                      valueSetter(index, "email", value);
                    }}
                  />
                  <CustomInputBox
                    title="User Password"
                    value={value.password}
                    valueSetter={(value) => {
                      valueSetter(index, "password", value);
                    }}
                  />
                  <CustomDropDownSearchSelect
                    label="User Role"
                    items={
                      RoleList !== null
                        ? RoleList.map((element) => {
                            return getItemName(element, "roleName");
                          })
                        : []
                    }
                    selected={
                      roleId !== null
                        ? getItemName(
                            searchObject(RoleList, roleId, "identifier"),
                            "roleName"
                          )
                        : null
                    }
                    handler={(value) => {
                      if (value !== null && RoleList !== null) {
                        setRoleId(
                          RoleList.filter((element) => {
                            return getItemName(element, "roleName") === value;
                          })[0].identifier
                        );
                      } else {
                        setRoleId(null);
                      }
                    }}
                  />

                  {RoleList != null &&
                    roleId != null &&
                    (getItem(
                      searchObject(RoleList, roleId, "identifier"),
                      "identifier",
                      "roleName"
                    ) === "Purchaser" ||
                      getItem(
                        searchObject(RoleList, roleId, "identifier"),
                        "identifier",
                        "roleName"
                      ) === "Field Motivator" ||
                      getItem(
                        searchObject(RoleList, roleId, "identifier"),
                        "identifier",
                        "roleName"
                      ) === "Field Assistant" ||
                      getItem(
                        searchObject(RoleList, roleId, "identifier"),
                        "identifier",
                        "roleName"
                      ) === "CF Head") && (
                      <div>
                        <CustomDropDownSearchSelect
                          label="User mandis"
                          items={
                            MandiList !== null
                              ? MandiList.map((element) => {
                                  return getItemName(element, "name");
                                })
                              : []
                          }
                          multi={true}
                          handler={(newValues) => {
                            valueSetter(
                              index,
                              "userRoles",
                              MandiList.filter((item) => {
                                return (
                                  newValues.indexOf(
                                    getItemName(item, "name")
                                  ) !== -1
                                );
                              }).map((item) => {
                                return item.id;
                              })
                            );
                          }}
                          selected={
                            value.userRoles !== undefined && MandiList !== null
                              ? MandiList.filter((item) => {
                                  return (
                                    value.userRoles.indexOf(item.id) !== -1
                                  );
                                }).map((item) => getItemName(item, "name"))
                              : []
                          }
                        />
                      </div>
                    )}
                </div>
              </div>
            </div>
          );
        })}
      </div>
      <div className="flex w-1/2 justify-between">
        <CustomButton
          btnName="Cancel"
          bgColor="bg-gray-400"
          onClick={() => {
            history.goBack();
          }}
        />
        {!isSubmit ? (
          <CustomButton btnName="Submit" onClick={addUser} />
        ) : (
          <CustomButton btnName="Submit" onClick={() => {}} />
        )}
      </div>
    </div>
  );
};
export default UsersAdd;
