import React, {useEffect, useState} from 'react';
import { Data } from './SidebarData';
import { Menu } from './SubMenu';
import { MenuIcon } from '@heroicons/react/outline';

import axios from "../../../axios";



// import { DatabaseIcon } from '@heroicons/react/outline';
//react icons giving erorr .. remmove package


const Sidebar = () => {

    const [isShowing, setShowing] = useState(true);
    const toggleMenu = ()=>{
        setShowing(!isShowing);
    }

    


    return (
        <div className="bg-white">
            
        {/* {console.log(allowed)} */}
        <p className={`${isShowing?"w-65 pl-2":"w-0 pl-0"} overflow-hidden transition-all duration-200 bg-hero-img6 font-robo text-base text-left text-black font-semibold tracking-widest py-2`}>
            Control Farming
        </p>
        <div className="flex">
            <div className={`${isShowing?"w-65":"w-0 h-screen"} bg-white font-robo overflow-auto text-sm transition-all duration-200`}>
                    {
                        Data.map((items, index) => {

                            console.log(items)
                            
                            return <Menu items={items} key={index} /> })
                    }
            </div>
            <span>
                {
                    <button className={`absolute ${isShowing?"-ml-10":"ml-0"} top-0 mt-0 transition-all duration-200 bg-white hover:bg-gray-300 text-gray-500 block w-7 h-8`} onClick={toggleMenu}>
                        <span ><MenuIcon/></span>
                    </button>
                }
            </span>
        </div>
        </div>)
}

export default Sidebar
