import React from "react";
import { useLocation, Redirect } from "react-router-dom";
import { EyeIcon } from "@heroicons/react/outline";
import axios from "../../../axios";
import CustomInputBox from "../../../components/atoms/CustomInputBox";

const ChemicalMasterDisplay = () => {
  // if (!JSON.parse(location.state)) return <Redirect to="/" />;
  const location = useLocation();
  const { data } = JSON.parse(location.state);
  const [insectDiseaseListId, setInsectDiseaseListId] = React.useState(null);
  React.useEffect(() => {
    axios.get(`insectsDiseasesMaster/load`).then((res) => {
      let name = [];
      res?.data?.Result?.insectDiseaseMaster?.map((api) => {
        let isPresent = false;
        if (api?.id == data?.insectDiseaseList?.value) {
          isPresent = true;
        }
        if (isPresent) {
          name.push(api?.insectDiseaseName);
        }
      });
      setInsectDiseaseListId(name);
    });
  }, []);
  return (
    <div className="overflow-hidden m-3 p-3">
      <div className="flex">
        <span>
          <EyeIcon className="h-5 w-5 transform text-black mt-4 mr-1" />
        </span>
        <span className=" py-4 text-gray-700 text-base font-bold text-left">
          Display chemical master
        </span>
      </div>
      <div className="grid grid-cols-2 w-1/2 justify-left">
        {/* <CustomDisplayBox title="ID" value={data.id} className=" pl-2"/> */}
        <CustomInputBox
          title="Brand Name"
          value={data.brandName.value}
          disabled={true}
          borderDisable={true}
        />
        <CustomInputBox
          title="Dose"
          value={data.dosePerAcre.value}
          disabled={true}
          borderDisable={true}
        />
        <CustomInputBox
          title="Active Ingredient"
          value={data.activeIngredient.value}
          disabled={true}
          borderDisable={true}
        />
        <CustomInputBox
          title="Unit of Measurement"
          value={data.uom.value}
          disabled={true}
          borderDisable={true}
        />
        <CustomInputBox
          title="PHI(Days)"
          value={data.phi.value}
          disabled={true}
          borderDisable={true}
        />
        <CustomInputBox
          title="Package of Practice"
          value={data.Pop.pop}
          disabled={true}
          borderDisable={true}
        />
        <CustomInputBox
          title="Usage Types"
          value={data.UsesType.usesType}
          disabled={true}
          borderDisable={true}
        />
        <div>
          <div className="text-sm text-gray-500">Insects/Diseases</div>
          {insectDiseaseListId?.map((obj, index) => {
            return (
              <div className="text-sm flex">
                <span>
                  {index + 1}) {obj}
                </span>
              </div>
            );
          })}
        </div>
        <CustomInputBox
          title="Is Recommended"
          value={data.isRecommended.value == 1 ? "Yes" : "No"}
          disabled={true}
          borderDisable={true}
        />
      </div>
      <div className="flex w-max gap-x-10"></div>
    </div>
  );
};
export default ChemicalMasterDisplay;
