import { errorToast } from "../../../util/toast";
import validator from "validator";

const checkString = (value) => {
  return value && value.trim() !== ``;
};

const checkNumber = (value) => {
  return value && value !== "" && !isNaN(Number(value));
};

const checkNumberInRange = (number, left, right) => {
  return (
    checkNumber(number) && Number(number) >= left && Number(number) <= right
  );
};

const checkMobile = (value) => {
  return value && validator.isMobilePhone(value, `en-IN`);
};

const checkEmail = (value) => {
  return value && validator.isEmail(value);
};

const validateMandiAdd = (elements) => {
  let flag = true;
  elements.forEach((element, index) => {
    const { name, identifier, address, stateId, districtId, zoneId } = element;
    if (!checkString(name)) {
      errorToast(`At Add Entry [${index + 1}]: Name must be a proper string`);
      flag = false;
      return false;
    } else if (!checkString(address)) {
      errorToast(
        `At Add Entry [${index + 1}]: address must be a proper string`
      );
      flag = false;
      return false;
    } else if (zoneId === null) {
      errorToast(`At Add Entry [${index + 1}]: zone must not be empty`);
      flag = false;
      return false;
    } else if (stateId === null) {
      errorToast(`At Add Entry [${index + 1}]: state must not be empty`);
      flag = false;
      return false;
    } else if (districtId === null) {
      errorToast(`At Add Entry [${index + 1}]: district must not be empty`);
      flag = false;
      return false;
    }
  });
  return flag;
};

const validateVillageAdd = (elements) => {
  let flag = true;
  elements.forEach((element, index) => {
    const { name, identifier, address, zoneId, mandiId } = element;
    if (!checkString(name)) {
      errorToast(`At Add Entry [${index + 1}]: Name must be a proper string`);
      flag = false;
      return false;
    } else if (zoneId === null) {
      errorToast(`At Add Entry [${index + 1}]: zone must not be empty`);
      flag = false;
      return false;
    } else if (mandiId === null) {
      errorToast(`At Add Entry [${index + 1}]: Mandi must not be empty`);
      flag = false;
      return false;
    }
  });
  return flag;
};

const validateMandiEdit = (element) => {
  let flag = true;
  const { name } = element;
  if (!checkString(name)) {
    errorToast(`Name must be a proper string`);
    flag = false;
    return false;
  }
  return flag;
};

const validateAarthiAdd = (elements) => {
  let flag = true;
  elements.forEach((element, index) => {
    const {
      identifier,
      name,
      mandiId,
      contactPerson,
      shopNumber,
      mobile,
      email,
    } = element;
    if (!checkNumber(identifier)) {
      errorToast(`At Add Entry [${index + 1}]: Aarthi code must be a number`);
      flag = false;
      return false;
    } else if (!checkString(name)) {
      errorToast(`At Add Entry [${index + 1}]: Name must not be empty`);
      flag = false;
      return false;
    } else if (mandiId === null) {
      errorToast(`At Add Entry [${index + 1}]: mandi must not be empty!`);
      flag = false;
      return false;
    } else if (!checkString(contactPerson)) {
      errorToast(
        `At Add Entry [${index + 1}]: contact person must be a string`
      );
      flag = false;
      return false;
    } else if (!checkNumber(shopNumber)) {
      errorToast(`At Add Entry [${index + 1}]: shopNumber must be a number`);
      flag = false;
      return false;
    } else if (!checkMobile(mobile)) {
      errorToast(`At Add Entry [${index + 1}]: mobile number must be a valid`);
      flag = false;
      return false;
    } else if (!checkEmail(email)) {
      errorToast(
        `At Add Entry [${index + 1}]: email must be a valid email address`
      );
      flag = false;
      return false;
    }
  });
  return flag;
};

const validateAarthiEdit = (element) => {
  let flag = true;
  const { name, mandiId, contactPerson, shopNumber, mobile, email } = element;
  if (!checkString(name)) {
    errorToast(`Name must not be empty`);
    flag = false;
    return false;
  } else if (mandiId === null) {
    errorToast(`mandi must not be empty!`);
    flag = false;
    return false;
  } else if (!checkString(contactPerson)) {
    errorToast(`contact person must be a string`);
    flag = false;
    return false;
  } else if (!checkNumber(shopNumber)) {
    errorToast(`shopNumber must be a number`);
    flag = false;
    return false;
  } else if (!checkMobile(mobile)) {
    errorToast(`mobile number must be a string`);
    flag = false;
    return false;
  } else if (!checkEmail(email)) {
    errorToast(`email must be a valid email address`);
    flag = false;
    return false;
  }
  return flag;
};
const validateYieldAdd = (elements) => {
  let flag = true;
  elements.forEach((element, index) => {
    const {
      compliance,
      procurement,
      headRiceYield,
      farmYield,
      zoneId,
      uomId,
      processTypeId,
      varietyId,
    } = element;
    if (!checkNumberInRange(compliance, 1, 100)) {
      errorToast(
        `At Add Entry [${index + 1}]: compliance must be between 1 and 100!`
      );
      flag = false;
      return flag;
    } else if (!checkNumberInRange(procurement, 1, 100)) {
      errorToast(
        `At Add Entry [${index + 1}]: procurement must be between 1 and 100`
      );
      flag = false;
      return flag;
    } else if (!checkNumberInRange(headRiceYield, 1, 100)) {
      errorToast(
        `At Add Entry [${index + 1}]: headRiceYield must be between 1 and 100`
      );
      flag = false;
      return flag;
    } else if (!checkNumber(farmYield)) {
      errorToast(`At Add Entry [${index + 1}]: farmYield must be a number!`);
      flag = false;
      return flag;
    } else if (zoneId === null) {
      errorToast(`At Add Entry [${index + 1}]: zone must be not be empty!`);
      flag = false;
      return flag;
    } else if (uomId === null) {
      errorToast(`At Add Entry [${index + 1}]: uom must be not be empty!`);
      flag = false;
      return flag;
    } else if (processTypeId === null) {
      errorToast(
        `At Add Entry [${index + 1}]: process Type must be not be empty!`
      );
      flag = false;
      return flag;
    } else if (varietyId === null) {
      errorToast(`At Add Entry [${index + 1}]: variety must be not be empty!`);
      flag = false;
      return flag;
    }
  });
  return flag;
};

const validateYieldEdit = (element) => {
  let flag = true;
  const {
    compliance,
    procurement,
    headRiceYield,
    farmYield,
    zoneId,
    uomId,
    processTypeId,
    varietyId,
  } = element;
  if (!checkNumberInRange(compliance, 1, 100)) {
    errorToast(`compliance must be between 1 and 100!`);
    flag = false;
    return flag;
  } else if (!checkNumberInRange(procurement, 1, 100)) {
    errorToast(`procurement must be between 1 and 100`);
    flag = false;
    return flag;
  } else if (!checkNumberInRange(headRiceYield, 1, 100)) {
    errorToast(`headRiceYield must be between 1 and 100`);
    flag = false;
    return flag;
  } else if (!checkNumber(farmYield)) {
    errorToast(`farmYield must be a number!`);
    flag = false;
    return flag;
  } else if (zoneId === null) {
    errorToast(`zone must be not be empty!`);
    flag = false;
    return flag;
  } else if (uomId === null) {
    errorToast(`uom must be not be empty!`);
    flag = false;
    return flag;
  } else if (processTypeId === null) {
    errorToast(`process Type must be not be empty!`);
    flag = false;
    return flag;
  } else if (varietyId === null) {
    errorToast(`variety must be not be empty!`);
    flag = false;
    return flag;
  }
  return flag;
};
const validateZoneAdd = (elements) => {
  let flag = true;
  elements.forEach((element, index) => {
    const { name } = element;
    if (!checkString(name)) {
      errorToast(`At Add Entry [${index + 1}]: Name must be a valid string`);
      flag = false;
      return false;
    }
  });
  return flag;
};

const validateZoneEdit = (element) => {
  let flag = true;
  const { name } = element;
  if (!checkString(name)) {
    errorToast(`Name must be a valid string`);
    flag = false;
    return false;
  }
  return flag;
};
const validateInsectDiseaseAdd = (elements) => {
  let flag = true;
  elements.forEach((element, index) => {
    const { insectDiseaseName, type, severity } = element;
    if (!checkString(insectDiseaseName)) {
      flag = false;
      errorToast(
        `At Add Entry [${
          index + 1
        }]: insect/disease name must be a valid string`
      );
      return false;
    } else if ([`Disease`, `Insect`].indexOf(type) === -1) {
      flag = false;
      errorToast(
        `At Add Entry [${
          index + 1
        }]: insect/disease type must be 'Insect' or 'Disease'`
      );
      return false;
    } else if ([`High`, `Medium`, `Low`].indexOf(severity) === -1) {
      flag = false;
      errorToast(
        `At Add Entry [${
          index + 1
        }]: severity type must be 'High', 'Medium' or 'Low'`
      );
      return false;
    }
  });
  return flag;
};

const validateInsectDiseaseEdit = (element) => {
  let flag = true;
  const { insectDiseaseName, type, severity } = element;
  if (!checkString(insectDiseaseName)) {
    flag = false;
    errorToast(`insect/disease name must be a valid string`);
    return false;
  } else if ([`Disease`, `Insect`].indexOf(type) === -1) {
    flag = false;
    errorToast(`insect/disease type must be 'Insect' or 'Disease'`);
    return false;
  } else if ([`High`, `Medium`, `Low`].indexOf(severity) === -1) {
    flag = false;
    errorToast(`severity type must be 'High', 'Medium' or 'Low'`);
    return false;
  }

  return flag;
};
const validateFertilizerAdd = (elements) => {
  let flag = true;
  elements.forEach((element, index) => {
    const { identifier, name, brandName, uomId } = element;

    if (!checkString(name)) {
      errorToast(`At Add Entry [${index + 1}]: Name must be a valid string`);
      flag = false;
      return false;
    } else if (!checkString(brandName)) {
      errorToast(
        `At Add Entry [${index + 1}]: brandName must be a valid string`
      );
      flag = false;
      return false;
    } else if (uomId === null) {
      errorToast(`At Add Entry [${index + 1}]: uom must not be empty`);
      flag = false;
      return false;
    }
  });

  console.log(flag);
  return flag;
};

const validateFertilizerEdit = (element) => {
  let flag = true;
  const { name, brandName, uomId } = element;
  if (!checkString(name)) {
    errorToast(`Name must be a valid string`);
    flag = false;
    return false;
  } else if (!checkString(brandName)) {
    errorToast(`brandName must be a valid string`);
    flag = false;
    return false;
  } else if (uomId === null) {
    errorToast(`uom must not be empty`);
    flag = false;
    return false;
  }

  return flag;
};
const validateChemicalAdd = (elements) => {
  let flag = true;
  elements.forEach((element, index) => {
    const {
      identifier,
      activeIngredient,
      brandName,
      uomId,
      phi,
      dosePerAcre,
      usesTypeId,
      popId,
      insectDiseaseList
    } = element;

    if (!checkString(brandName)) {
      errorToast(
        `At Add Entry [${index + 1}]: brandName must be a valid string`
      );
      flag = false;
      return false;
    } else if (!checkString(activeIngredient)) {
      errorToast(
        `At Add Entry [${index + 1}]: active ingredient must be a valid string`
      );
      flag = false;
      return false;
    } else if (!checkNumber(dosePerAcre)) {
      errorToast(
        `At Add Entry [${index + 1}]: dosePerAcre must be a valid number`
      );
      flag = false;
      return false;
    } else if (!checkNumber(phi)) {
      errorToast(`At Add Entry [${index + 1}]: PHI must be a valid number`);
      flag = false;
      return false;
    } else if (uomId === null) {
      errorToast(`At Add Entry [${index + 1}]: uom must not be empty`);
      flag = false;
      return false;
    } else if (popId === null) {
      errorToast(`At Add Entry [${index + 1}]: pop must not be empty`);
      flag = false;
      return false;
    } else if (usesTypeId === null) {
      errorToast(`At Add Entry [${index + 1}]: usesType must not be empty`);
      flag = false;
      return false;
    }else if (insectDiseaseList === null) {
      errorToast(`At Add Entry [${index + 1}]: insectDiseaseList must not be empty`);
      flag = false;
      return false;
    }
  });
  return flag;
};

const validateChemicalEdit = (element) => {
  let flag = true;
  const {
    activeIngredient,
    brandName,
    uomId,
    phi,
    dosePerAcre,
    usesTypeId,
    popId,
    insectDiseaseList
  } = element;
  if (!checkString(brandName)) {
    errorToast(`brandName must be a valid string`);
    flag = false;
    return false;
  } else if (!checkString(activeIngredient)) {
    errorToast(`active ingredient must be a valid string`);
    flag = false;
    return false;
  } else if (!checkNumber(dosePerAcre)) {
    errorToast(`dosePerAcre must be a valid number`);
    flag = false;
    return false;
  } else if (!checkNumber(phi)) {
    errorToast(`phi must be a valid number`);
    flag = false;
    return false;
  } else if (uomId === null) {
    errorToast(`uom must not be empty`);
    flag = false;
    return false;
  } else if (popId === null) {
    errorToast(`pop must not be empty`);
    flag = false;
    return false;
  } else if (usesTypeId === null) {
    errorToast(`usesType must not be empty`);
    flag = false;
    return false;
  }else if (insectDiseaseList === null) {
    errorToast(`insectDiseaseList must not be empty`);
    flag = false;
    return false;
  }
  return flag;
};

const validateVarietyAdd = (elements) => {
  let flag = true;

  console.log("Validation See");
  elements.forEach((element, index) => {
    const { identifier, name, uomId, seedToSeed } = element;
    // if (!checkNumberInRange(Number(identifier), 1000000000, 9999999999)) {
    //   errorToast(
    //     `At Add Entry [${index + 1}]: Identifier must be a 10 digit number`
    //   );
    //   flag = false;
    //   return false;
    // } else
    if (!checkString(name)) {
      errorToast(`At Add Entry [${index + 1}]: name must be a a valid string`);
      flag = false;
      return false;
    } else if (!checkNumber(seedToSeed)) {
      errorToast(
        `At Add Entry [${index + 1}]: seedToSeed must be a valid number`
      );
      flag = false;
      return false;
    } else if (uomId === null) {
      errorToast(`At Add Entry [${index + 1}]: uom must not be empty`);
      flag = false;
      return false;
    }
  });
  return flag;
};

const validateVarietyEdit = (element) => {
  let flag = true;
  const { name, uomId, seedToSeed } = element;
  if (!checkString(name)) {
    errorToast(`name must be a a valid string`);
    flag = false;
    return false;
  } else if (!checkNumber(seedToSeed)) {
    errorToast(`seedToSeed must be a valid number`);
    flag = false;
    return false;
  } else if (uomId === null) {
    errorToast(`uom must not be empty`);
    flag = false;
    return false;
  }

  return flag;
};
const validateFarmerAdd = (elements) => {
  let flag = true;
  elements.forEach((element, index) => {
    const {
      identifier,
      year,
      popId,
      pakkaAarthiId,
      kacchaAarthiId,
      address,
      ownLand,
      leasedLand,
      totalLand,
      cycle,
      seedSource,
      seedTreatment,
      name,
      fatherName,
      village,
      mandiId,
      varietiesList,
    } = element;

    let sumOfLandused = 0;

    let mapp = {};
    varietiesList?.map((val) => {
      // console.log("ook", parseInt(val.landUsed  , 10))
      sumOfLandused += parseInt(val.landUsed, 10);

      if (mapp[val.varietyId] != undefined || mapp[val.varietyId] != null) {
        errorToast(`Variteis should be unique`);
        flag = false;
        return false;
      }
      mapp[val.varietyId] = 1;
    });

    if (sumOfLandused > totalLand) {
      console.log(sumOfLandused, totalLand);
      errorToast(
        `At Add Entry [${
          index + 1
        }]:Sum of land used must be less than Total Land`
      );
      flag = false;
      return false;
    }

    if ([`Market`, `Own`].indexOf(seedSource) === -1) {
      flag = false;
      errorToast(
        `At Add Entry [${index + 1}]: seed source must by 'Market' or 'Own'`
      );
      return false;
    } else if (seedTreatment !== 0 && seedTreatment !== 1) {
      flag = false;
      errorToast(
        `At Add Entry [${index + 1}]: seed treatment must by Yes or No`
      );
      return false;
    } else if (
      !checkNumberInRange(year, 1970, Number(new Date().getFullYear()))
    ) {
      errorToast(
        `At Add Entry [${
          index + 1
        }]: Year must be valid (between 1970 to ${Number(
          new Date().getFullYear()
        )}`
      );
      flag = false;
      return false;
    } else if (!checkString(name)) {
      errorToast(`At Add Entry [${index + 1}]: name must be a valid string`);
      flag = false;
      return false;
    } else if (!checkString(fatherName)) {
      errorToast(
        `At Add Entry [${index + 1}]: father's name must be a valid string`
      );
      flag = false;
      return false;
    } else if (village == null || village == undefined) {
      errorToast(`At Add Entry [${index + 1}]: village must be filled`);
      flag = false;
      return false;
    } else if (!checkString(cycle)) {
      errorToast(
        `At Add Entry [${index + 1}]: cycle name must be a valid string`
      );
      flag = false;
      return false;
    } else if (pakkaAarthiId === null) {
      errorToast(`At Add Entry [${index + 1}]: pakka aarthi must not be empty`);
      flag = false;
      return false;
    } else if (kacchaAarthiId === null) {
      errorToast(
        `At Add Entry [${index + 1}]: kaccha aarthi must not be empty`
      );
      flag = false;
      return false;
    } else if (popId === null) {
      errorToast(`At Add Entry [${index + 1}]: pop must not be empty`);
      flag = false;
      return false;
    } else if (mandiId === null) {
      errorToast(`At Add Entry [${index + 1}]: mandi must not be empty`);
      flag = false;
      return false;
    } else if (!checkNumber(ownLand)) {
      errorToast(`At Add Entry [${index + 1}]: own land must be a number`);
      flag = false;
      return false;
    } else if (!checkNumber(leasedLand)) {
      errorToast(`At Add Entry [${index + 1}]: leased land must be a number`);
      flag = false;
      return false;
    } else if (!checkNumber(totalLand)) {
      errorToast(`At Add Entry [${index + 1}]: total land must be a number`);
      flag = false;
      return false;
    }
  });
  return flag;
};

const validateFarmerEdit = (element) => {
  let flag = true;
  const {
    year,
    popId,
    pakkaAarthiId,
    kacchaAarthiId,
    address,
    ownLand,
    leasedLand,
    totalLand,
    cycle,
    seedSource,
    seedTreatment,
    name,
    fatherName,
    villageId,
    mandiId,
    varitiesList,
  } = element;

  let sumOfLandused = 0;
  let mapp = {};
  varitiesList?.map((val) => {
    sumOfLandused += parseInt(val.landUsed, 10);

    if (parseInt(val.landUsed, 10) == 0 || val.varietyId == null) {
      errorToast(`Land Used and Variety Cannot be empty`);
      flag = false;
      return false;
    }

    if (mapp[val.varietyId] != undefined || mapp[val.varietyId] != null) {
      errorToast(`Variteis should be unique`);
      flag = false;
      return false;
    }
    mapp[val.varietyId] = 1;
  });

  if (sumOfLandused > totalLand) {
    errorToast(`Sum of land used must be less than Total Land`);
    flag = false;
    return false;
  } else if ([`Market`, `Own`].indexOf(seedSource) === -1) {
    flag = false;
    errorToast(`seed source must by 'Market' or 'Own'`);
    return false;
  } else if (seedTreatment !== 0 && seedTreatment !== 1) {
    flag = false;
    errorToast(`seed treatment must by Yes or No`);
    return false;
  } else if (
    !checkNumberInRange(year, 1970, Number(new Date().getFullYear()))
  ) {
    errorToast(
      `Year must be valid (between 1970 to ${Number(new Date().getFullYear())}`
    );
    flag = false;
    return false;
  } else if (!checkString(name)) {
    errorToast(`name must be a valid string`);
    flag = false;
    return false;
  } else if (!checkString(fatherName)) {
    errorToast(`father's name must be a valid string`);
    flag = false;
    return false;
    // } else if (villageId == undefined || villageId == null) {
    //   errorToast(`village must be filled`);
    //   flag = false;
    //   return false;
  } else if (!checkString(cycle)) {
    errorToast(`cycle name must be a valid string`);
    flag = false;
    return false;
  } else if (pakkaAarthiId === null) {
    errorToast(`pakka aarthi must not be empty`);
    flag = false;
    return false;
  } else if (kacchaAarthiId === null) {
    errorToast(`kaccha aarthi must not be empty`);
    flag = false;
    return false;
  } else if (popId === null) {
    errorToast(`pop must not be empty`);
    flag = false;
    return false;
  } else if (mandiId === null) {
    errorToast(`mandi must not be empty`);
    flag = false;
    return false;
  } else if (!checkNumber(ownLand)) {
    errorToast(`own land must be a number`);
    flag = false;
    return false;
  } else if (!checkNumber(leasedLand)) {
    errorToast(`leased land must be a number`);
    flag = false;
    return false;
  } else if (!checkNumber(totalLand)) {
    errorToast(`total land must be a number`);
    flag = false;
    return false;
  }

  return flag;
};
const validateSeasonalPlanningAdd = (elements) => {
  let flag = true;
  elements.forEach((element, index) => {
    const {
      year,
      varietyId,
      inchargeId,
      mandiId,
      fieldMotivatorId,
      targetAcre,
    } = element;
    if (!checkNumber(targetAcre)) {
      errorToast(`At Add Entry [${index + 1}]: target acre must be a number`);
      flag = false;
      return false;
    } else if (
      !checkNumberInRange(year, 1970, Number(new Date().getFullYear()))
    ) {
      errorToast(
        `At Add Entry [${
          index + 1
        }]: Year must be valid (between 1970 to ${Number(
          new Date().getFullYear()
        )}`
      );
      flag = false;
      return false;
    } else if (varietyId === null) {
      errorToast(`At Add Entry [${index + 1}]: variety must not be empty`);
      flag = false;
      return false;
    } else if (mandiId === null) {
      errorToast(`At Add Entry [${index + 1}]: mandi must not be empty`);
      flag = false;
      return false;
    } else if (inchargeId === null) {
      errorToast(`At Add Entry [${index + 1}]: incharge must not be empty`);
      flag = false;
      return false;
    } else if (fieldMotivatorId === null) {
      errorToast(
        `At Add Entry [${index + 1}]: field motivator must not be empty`
      );
      flag = false;
      return false;
    }
  });
  return flag;
};

const validateSeasonalPlanningEdit = (element) => {
  let flag = true;
  const { year, varietyId, inchargeId, mandiId, fieldMotivatorId, targetAcre } =
    element;
  if (!checkNumber(targetAcre)) {
    errorToast(`target acre must be a number`);
    flag = false;
    return false;
  } else if (varietyId === null) {
    errorToast(`variety must not be empty`);
    flag = false;
    return false;
  } else if (mandiId === null) {
    errorToast(`mandi must not be empty`);
    flag = false;
    return false;
  } else if (inchargeId === null) {
    errorToast(`incharge must not be empty`);
    flag = false;
    return false;
  } else if (fieldMotivatorId === null) {
    errorToast(`field motivator must not be empty`);
    flag = false;
    return false;
  }
  return flag;
};
const validateAllVisitsAdd = (elements) => {
  let flag = true;
  elements.forEach((element, index) => {
    const {
      farmerVarietyId,
      visitDate,
      cropStageId,
      cropHealthId,
      insectDiseasesList,
      fertilizerId,
      recommendedChemical,
      otherRecommendation,
      usedFertilizerId,
      usedFertilizerDate,
      usedChemicalId,
      usedChemicalDate,
      waterUsage,
      unresolvedFarmerQuery,
      actionRequired,
      generalRemarks,
      contactPersonMobile,
      contactPerson,
      usedOtherIngredient,
      otherIngredientUsedDate,
    } = element;
    if (farmerVarietyId === null) {
      errorToast(`At Add Entry: farmer variety id cannot be null`);
      flag = false;
      return false;
    }
    // else if (usedFertilizerId === null) {
    //   errorToast(`At Add Entry: used fertilizer cannot be null`);
    //   flag = false;
    //   return false;
    // }
    // else if (usedChemicalId === null) {
    //   errorToast(`At Add Entry: used chemical cannot be null`);
    //   flag = false;
    //   return false;
    // }
    else if (visitDate === null) {
      errorToast(`At Add Entry: visit date must not be empty`);
      flag = false;
      return false;
    }
    // else if (usedFertilizerDate === null) {
    //   errorToast(`At Add Entry: used fertilizer date must not be empty`);
    //   flag = false;
    //   return false;
    // }
    // else if (usedChemicalDate === null) {
    //   errorToast(`At Add Entry: used chemical date must not be empty`);
    //   flag = false;
    //   return false;
    // }
    else if (contactPerson != null && !checkMobile(contactPersonMobile)) {
      errorToast(`At Add Entry: mobile number must be a valid`);
      flag = false;
      return false;
    }
    //  else if (
    //   recommendedChemical === null ||
    //   recommendedChemical.length == 0
    // ) {
    //   errorToast(`At Add Entry: recommended chemical must not be empty`);
    //   flag = false;
    //   return false;
    // }
    else if (contactPerson === null) {
      errorToast(`At Add Entry: contact person must not be empty`);
      flag = false;
      return false;
    } else if (cropStageId === null) {
      errorToast(`At Add Entry: crop stage id cannot be null`);
      flag = false;
      return false;
    } else if (cropHealthId === null) {
      errorToast(`At Add Entry: crop health id cannot be null`);
      flag = false;
      return false;
    }
    // else if (insectDiseasesList === null || insectDiseasesList.length == 0) {
    //   errorToast(`At Add Entry: insect/disease must be empty`);
    //   flag = false;
    //   return false;
    // }
    // else if (fertilizerId === null) {
    //   errorToast(`At Add Entry: fertilizer cannot be null`);
    //   flag = false;
    //   return false;
    // }
    // else if (!checkString(otherRecommendation)) {
    //   errorToast(
    //     `At Add Entry [${
    //       index + 1
    //     }]: other recommendation must be a valid string`
    //   );
    //   flag = false;
    //   return false;
    // }
    // else if (!checkString(unresolvedFarmerQuery)) {
    //   errorToast(
    //     `At Add Entry [${
    //       index + 1
    //     }]: unresolved farmer query must be a valid string`
    //   );
    //   flag = false;
    //   return false;
    // }
    else if (actionRequired !== 0 && actionRequired !== 1) {
      errorToast(`At Add Entry: action required must be a 0 or 1`);
      flag = false;
      return false;
    }
    // else if (!checkString(generalRemarks)) {
    //   errorToast(`At Add Entry: general remarks must be a valid string`);
    //   flag = false;
    //   return false;
    // }
    // else if (!checkNumber(waterUsage)) {
    //   errorToast(`At Add Entry: water usage must be a valid number`);
    //   flag = false;
    //   return false;
    // }
  });
  return flag;
};

const validateAllVisitsEdit = (element) => {
  let flag = true;
  const {
    farmerVarietyId,
    visitDate,
    cropStageId,
    cropHealthId,
    insectDiseaseId,
    fertilizerId,
    recommendedChemical,
    otherRecommendation,
    usedFertilizerId,
    usedFertilizerDate,
    usedChemicalId,
    usedChemicalDate,
    waterUsage,
    unresolvedFarmerQuery,
    actionRequired,
    generalRemarks,
  } = element;
  if (farmerVarietyId === null) {
    errorToast(`farmer variety id cannot be null`);
    flag = false;
    return false;
  }
  //  else if (usedFertilizerId === null) {
  //   errorToast(`used fertilizer cannot be null`);
  //   flag = false;
  //   return false;
  // }
  // else if (usedChemicalId === null) {
  //   errorToast(`used chemical cannot be null`);
  //   flag = false;
  //   return false;
  // }
  else if (visitDate === null) {
    errorToast(`visit date must not be empty`);
    flag = false;
    return false;
  }
  // else if (usedFertilizerDate === null) {
  //   errorToast(`used fertilizer date must not be empty`);
  //   flag = false;
  //   return false;
  // }
  // else if (usedChemicalDate === null) {
  //   errorToast(`used chemical date must not be empty`);
  //   flag = false;
  //   return false;
  // }
  else if (cropStageId === null) {
    errorToast(`crop stage id cannot be null`);
    flag = false;
    return false;
  } else if (cropHealthId === null) {
    errorToast(`crop health id cannot be null`);
    flag = false;
    return false;
  } else if (insectDiseaseId === null) {
    errorToast(`insect/disease cannot be null`);
    flag = false;
    return false;
  }
  // else if (fertilizerId === null) {
  //   errorToast(`fertilizer cannot be null`);
  //   flag = false;
  //   return false;
  // }
  // else if (!checkString(otherRecommendation)) {
  //   errorToast(`other recommendation must be a valid string`);
  //   flag = false;
  //   return false;
  // }
  // else if (!checkString(unresolvedFarmerQuery)) {
  //   errorToast(`unresolved farmer query must be a valid string`);
  //   flag = false;
  //   return false;
  // }
  else if (actionRequired !== 0 && actionRequired !== 1) {
    errorToast(`action required must be a 0 or 1`);
    flag = false;
    return false;
  }
  // else if (!checkString(generalRemarks)) {
  //   errorToast(`general remarks must be a valid string`);
  //   flag = false;
  //   return false;
  // }
  // else if (!checkNumber(waterUsage)) {
  //   errorToast(`water usage must be a valid number`);
  //   flag = false;
  //   return false;
  // }

  return flag;
};
const validateValidationAdd = (elements) => {
  let flag = true;
  elements.forEach((element, index) => {
    const {
      actualLandUsed,
      isAcreageVerified,
      isVarietyVerified,
      verificationComment,
    } = element;

    if (isAcreageVerified != 1) {
      errorToast(`Entry [${index + 1}]: Acreage must be verified`);

      flag = false;
      return false;
    } else if (isVarietyVerified != 1) {
      errorToast(`Entry [${index + 2}]: Variety must be verified`);

      flag = false;
      return false;
    } else if (!checkNumber(actualLandUsed) || actualLandUsed == 0) {
      errorToast(
        `At Add Entry [${
          index + 3
        }]: actual land used must be specified and Valid`
      );
      flag = false;
      return false;
    } else if (!checkString(verificationComment)) {
      errorToast(
        `At Add Entry [${index + 4}]: verification comment cannot be empty!`
      );
      flag = false;
      return false;
    }
  });
  return flag;
};

const validateValidationEdit = (element) => {
  let flag = true;
  const {
    actualLandUsed,
    isAcreageVerified,
    isVarietyVerified,
    verificationComment,
  } = element;

  if (!checkNumber(actualLandUsed) || actualLandUsed == 0) {
    errorToast(`actual land used must be Specified and Valid`);
    flag = false;
    return false;
  }
  return flag;
};
const validateCropLifeCycleAdd = (elements) => {
  let flag = true;
  let sum = 0;
  elements[0].sowingList.forEach((element, index) => {
    const { sowingDate, landUsed } = element;
    let sowingDateParsed = new Date(sowingDate).getTime();
    let presentDate = new Date().getTime();
    sum += parseInt(landUsed);
    if (sowingDate === null) {
      errorToast(`At Add Entry [${index + 1}]: sowing date must not be empty`);
      flag = false;
      return false;
    }
    // else if (!checkNumber(landUsed) || landUsed == 0) {
    //   errorToast(
    //     `At Add Entry [${index + 1}]: actual land used must be a valid number`
    //   );
    //   flag = false;
    //   return false;
    // }
    else if (sowingDateParsed > presentDate) {
      errorToast(
        `At Add Entry [${
          index + 1
        }]: sowing date cannot be higher than the present date`
      );
      flag = false;
      return false;
    }
  });

  if (parseInt(sum) > parseInt(elements[0].actualLandUsed)) {
    errorToast(
      `Sum of Land Used must be less than or equal to actual land used`
    );
    flag = false;
    return false;
  }
  return flag;
};

const validateCropLifeCycleEdit = (elements) => {
  let flag = true;
  let sum = 0;
  elements.sowingList.forEach((element, index) => {
    const { sowingDate, landUsed } = element;
    sum += parseInt(landUsed);
    if (sowingDate === null) {
      errorToast(`sowing date must not be empty`);
      flag = false;
      return false;
    } else if (!checkNumber(landUsed)) {
      errorToast(`actual land used must be a valid number`);
      flag = false;
      return false;
    }
    if (parseInt(sum) > parseInt(elements.actualLandUsed)) {
      errorToast(
        `Sum of Land Used must be less than or equal to actual land used`
      );
      flag = false;
      return false;
    }
  });
  return flag;
};
const validateEstimationAdd = (elements) => {
  let flag = true;

  elements.forEach((element, index) => {
    const {
      expectedYield,
      expectedProduction,
      actualProduction,
      actualYield,
      harvestingMethodId,
    } = element;
    if (!checkNumber(expectedProduction)) {
      errorToast(
        `At Add Entry [${
          index + 1
        }]: Expected production must be a valid number`
      );
      flag = false;
      return false;
    } else if (!checkNumber(expectedYield)) {
      errorToast(
        `At Add Entry [${index + 1}]: Expected Yield must be a valid number`
      );
      flag = false;
      return false;
    } else if (!checkNumber(actualYield) || actualYield == 0) {
      errorToast(
        `At Add Entry [${index + 1}]: Actual Yield must be a valid number`
      );
      flag = false;
      return false;
    } else if (!checkNumber(actualProduction) || actualProduction == 0) {
      errorToast(
        `At Add Entry [${index + 1}]: Actual Production must be a valid number`
      );
      flag = false;
      return false;
    } else if (harvestingMethodId === null) {
      errorToast(
        `At Add Entry [${index + 1}]: Harvesting method cannot be empty!`
      );
      flag = false;
      return false;
    }
  });
  return flag;
};

const validateEstimationEdit = (element) => {
  let flag = true;
  const { actualYield, actualProduction, harvestingMethodId } = element;
  if (!checkNumber(actualYield) || actualYield <= 0) {
    errorToast(`Expected Yield must be a valid number`);
    flag = false;
    return false;
  } else if (!checkNumber(actualProduction) || actualProduction <= 0) {
    errorToast(`Expected Yield must be a valid number`);
    flag = false;
    return false;
  } else if (harvestingMethodId === null) {
    errorToast(`Harvesting method cannot be empty!`);
    flag = false;
    return false;
  }
  return flag;
};
const validatePurchaseAdd = (elements) => {
  let flag = true;
  elements.forEach((element, index) => {
    const {
      slipNumber,
      slipDate,
      slipQuantity,
      isPurchased,
      kacchaAarthiId,
      pakkaAarthiId,
    } = element;
    if (!checkNumber(slipNumber)) {
      errorToast(
        `At Add Entry [${index + 1}]: slipNumber must be a valid number`
      );
      flag = false;
      return false;
    }
    // else if (!checkNumber(poNumber)) {
    //   errorToast(
    //     `At Add Entry [${index + 1}]: poNumber must be a valid number`
    //   );
    //   flag = false;
    //   return false;
    // }
    // else if (!checkNumber(billNumber)) {
    //   errorToast(
    //     `At Add Entry [${index + 1}]: billNumber must be a valid number`
    //   );
    //   flag = false;
    //   return false;
    // }
    // else if (!checkNumber(iFormNumber)) {
    //   errorToast(
    //     `At Add Entry [${index + 1}]: iFormNumber must be a valid number`
    //   );
    //   flag = false;
    //   return false;
    // }
    // else if (!checkNumber(iFormQuantity)) {
    //   errorToast(
    //     `At Add Entry [${index + 1}]: iFormQuantity must be a valid number`
    //   );
    //   flag = false;
    //   return false;
    // }
    else if (!checkNumber(slipNumber)) {
      errorToast(
        `At Add Entry [${index + 1}]: slipNumber must be a valid number`
      );
      flag = false;
      return false;
    } else if (!checkNumber(slipQuantity)) {
      errorToast(
        `At Add Entry [${index + 1}]: slipQuantity must be a valid number`
      );
      flag = false;
      return false;
    } else if (
      isPurchased == null ||
      (isPurchased !== 0 && isPurchased !== 1)
    ) {
      errorToast(`At Add Entry [${index + 1}]: isPurchased must be 1 or 0`);
      flag = false;
      return false;
    } else if (slipDate === null) {
      errorToast(`At Add Entry [${index + 1}]: slipDate must not be empty`);
      flag = false;
      return false;
    }
    // else if (billDate === null) {
    //   errorToast(`At Add Entry [${index + 1}]: billDate must not be empty`);
    //   flag = false;
    //   return false;
    // }
    // else if (iFormDate === null) {
    //   errorToast(`At Add Entry [${index + 1}]: iFormDate must not be empty`);
    //   flag = false;
    //   return false;
    // }

    // else if (poDate === null) {
    //   errorToast(`At Add Entry [${index + 1}]: poDate must not be empty`);
    //   flag = false;
    //   return false;
    // }
    else if (kacchaAarthiId === null) {
      errorToast(
        `At Add Entry [${index + 1}]: kaccha aarthi must not be empty`
      );
      flag = false;
      return false;
    } else if (pakkaAarthiId === null) {
      errorToast(`At Add Entry [${index + 1}]: pakka aarthi must not be empty`);
      flag = false;
      return false;
    }
  });
  return flag;
};

const validatePurchaseEdit = (element) => {
  let flag = true;
  const {
    slipNumber,
    slipDate,
    slipQuantity,
    isPurchased,
    jFormName,
    jFormNumber,
    jFormQuantity,
    jFormDate,
    kacchaAarthiId,
    pakkaAarthiId,
  } = element;
  if (!checkNumber(slipNumber)) {
    errorToast(`slipNumber must be a valid number`);
    flag = false;
    return false;
  } else if (!checkString(jFormName)) {
    errorToast(`jFormName must be a valid string`);
    flag = false;
    return false;
  } else if (!checkNumber(jFormNumber)) {
    errorToast(`jFormNumber must be a valid number`);
    flag = false;
    return false;
  } else if (!checkNumber(jFormQuantity)) {
    errorToast(`jFormQuantity must be a valid number`);
    flag = false;
    return false;
  }
  // else if (!checkNumber(poNumber)) {
  //   errorToast(`poNumber must be a valid number`);
  //   flag = false;
  //   return false;
  // }
  // else if (!checkNumber(billNumber)) {
  //   errorToast(`billNumber must be a valid number`);
  //   flag = false;
  //   return false;
  // }
  // else if (!checkNumber(iFormNumber)) {
  //   errorToast(`iFormNumber must be a valid number`);
  //   flag = false;
  //   return false;
  // } else if (!checkNumber(iFormQuantity)) {
  //   errorToast(`iFormQuantity must be a valid number`);
  //   flag = false;
  //   return false;
  // }
  else if (!checkNumber(slipNumber)) {
    errorToast(`slipNumber must be a valid number`);
    flag = false;
    return false;
  } else if (!checkNumber(slipQuantity)) {
    errorToast(`slipQuantity must be a valid number`);
    flag = false;
    return false;
  } else if (isPurchased !== 0 && isPurchased !== 1) {
    errorToast(`isPurchased must be 1 or 0`);
    flag = false;
    return false;
  } else if (slipDate === null) {
    errorToast(`slipDate must not be empty`);
    flag = false;
    return false;
  } else if (jFormDate === null) {
    errorToast(`jFormDate must not be empty`);
    flag = false;
    return false;
  }
  // else if (billDate === null) {
  //   errorToast(`billDate must not be empty`);
  //   flag = false;
  //   return false;
  // } else if (iFormDate === null) {
  //   errorToast(`iFormDate must not be empty`);
  //   flag = false;
  //   return false;
  // }
  // else if (poDate === null) {
  //   errorToast(`poDate must not be empty`);
  //   flag = false;
  //   return false;
  // }
  else if (kacchaAarthiId === null) {
    errorToast(`kaccha aarthi must not be empty`);
    flag = false;
    return false;
  } else if (pakkaAarthiId === null) {
    errorToast(`pakka aarthi must not be empty`);
    flag = false;
    return false;
  }

  return flag;
};

const validateUomAdd = (elements) => {
  let flag = true;
  elements.forEach((element, index) => {
    const { uom } = element;
    if (!checkString(uom)) {
      errorToast(
        `At Add Entry [${index + 1}]: uom name must be a valid string`
      );
      flag = false;
      return false;
    }
  });
  return flag;
};

const validateProcessTypeAdd = (elements) => {
  let flag = true;
  elements.forEach((element, index) => {
    const { processType } = element;
    if (!checkString(processType)) {
      errorToast(
        `At Add Entry [${index + 1}]: process type name must be a valid string`
      );
      flag = false;
      return false;
    }
  });
  return flag;
};

export {
  checkNumberInRange,
  checkString,
  checkNumber,
  validateUomAdd,
  validateProcessTypeAdd,
  validateAarthiAdd,
  validateAarthiEdit,
  validateMandiAdd,
  validateMandiEdit,
  validateYieldAdd,
  validateYieldEdit,
  validateZoneAdd,
  validateZoneEdit,
  validateInsectDiseaseAdd,
  validateInsectDiseaseEdit,
  validateFertilizerAdd,
  validateFertilizerEdit,
  validateChemicalAdd,
  validateChemicalEdit,
  validateVarietyAdd,
  validateVarietyEdit,
  validateFarmerAdd,
  validateFarmerEdit,
  validateSeasonalPlanningAdd,
  validateSeasonalPlanningEdit,
  validateCropLifeCycleAdd,
  validateCropLifeCycleEdit,
  validateAllVisitsAdd,
  validateAllVisitsEdit,
  validateEstimationAdd,
  validateEstimationEdit,
  validatePurchaseAdd,
  validatePurchaseEdit,
  validateValidationAdd,
  validateValidationEdit,
  validateVillageAdd,
};
