import React, { useEffect, useState } from "react";
import { useLocation, Redirect, useHistory } from "react-router-dom";
import { CustomButton } from "../../../components/atoms/CustomButton";
import CustomInputBox from "../../../components/atoms/CustomInputBox";
import axios from "../../../axios";
import { toast } from "react-toastify";
import { PencilIcon } from "@heroicons/react/outline";
import { errorToast } from "../../../util/toast";
import CustomDropDownSearchSelect from "../../../components/atoms/CustomDropDownSearchSelect";
import { validateYieldEdit } from "../common/validations";

const getItemName = (obj, prop1) => {
  return obj[prop1];
};
//returns the first object in the list whose property matches the target
const searchObject = (items, target, property) => {
  return items.filter((element) => {
    return element[property] === target;
  })[0];
};

const YieldMasterEdit = () => {
  const location = useLocation();
  const history = useHistory();
  const { data } = JSON.parse(location.state);
  console.log(data);
  const [compliance, setCompliance] = useState(data?.compliance.value);
  const [procurement, setProcurement] = useState(data?.procurement);
  const [farmYield, setFarmYield] = useState(data.farmYield.value);
  const [headRiceYield, setHeadRiceYield] = useState(data.headRiceYield);
  const [transplantingDays, setTransplantingDays] = useState(
    data.transplantingDays
  );
  const [maturityDays, setMaturityDays] = useState(data.maturityDays);

  const [processTypeId, setProcessTypeId] = useState(null);
  const [uomId, setUomId] = useState(null);
  const [zoneId, setZoneId] = useState(null);
  const [varietyId, setVarietyId] = useState(null);

  const [error, setError] = useState(null);

  const [uomObjectsList, setUomObjectsList] = useState([]);
  const [processTypesList, setProcesTypesList] = useState([]);
  const [zonesList, setZonesList] = useState([]);
  const [varietiesList, setVarietiesList] = useState([]);

  useEffect(() => {
    axios
      .get(`processType`)
      .then((response) => {
        setProcesTypesList(response.data.Result.ProcessTypes);
        setProcessTypeId(
          response.data.Result.ProcessTypes.filter((element) => {
            return element.identifier === data.ProcessType.identifier;
          })[0].id
        );
        return axios.get(`zoneMasters/load`);
      })
      .then((response) => {
        setZonesList(response.data.Result.ZoneMaster);
        console.log("Uom id:", uomId);
        setZoneId(
          response.data.Result.ZoneMaster.filter((element) => {
            return element.identifier === data.ZoneMaster.identifier;
          })[0].id
        );
        return axios.get(`uom`);
      })
      .then((response) => {
        setUomObjectsList(response.data.Result.Uom);
        setUomId(
          response.data.Result.Uom.filter((element) => {
            return element.identifier === data.Uom.identifier;
          })[0].id
        );
        return axios.get(`varietyMaster/load`);
      })
      .then((response) => {
        setVarietiesList(response.data.Result.VarietyMaster);
        setVarietyId(
          response.data.Result.VarietyMaster.filter((element) => {
            return element.identifier === data.VarietyMaster.identifier;
          })[0].id
        );
      })
      .catch((error) => {
        console.log(error?.message);
        errorToast(error?.response?.data?.Comments);
      });
  }, []);

  if (!location.state) {
    return <Redirect to="/" />;
  }

  const editChemical = () => {
    if (
      validateYieldEdit({
        procurement,
        zoneId,
        uomId,
        varietyId,
        processTypeId,
        compliance,
        headRiceYield,
        farmYield,
      })
    ) {
      axios
        .put(`yieldMaster/${data.id}`, {
          procurement,
          zoneId,
          uomId,
          varietyId,
          processTypeId,
          compliance,
          headRiceYield,
          farmYield,
          transplantingDays,
          maturityDays,
        })
        .then((response) => {
          if (response?.data?.ResponseMessage === "SUCCESS") {
            toast.success("Update successful!", { position: "top-center" });
            history.goBack();
          }
        })
        .catch((error) => {
          errorToast(error?.response?.data?.Comments);
          console.log(error);
        });
    }
  };

  return (
    <div className=" m-3 p-3">
      <div className="flex">
        <span>
          <PencilIcon className="h-5 w-5 transform text-black mt-4 mr-1" />
        </span>
        <span className=" py-4 text-gray-700 text-base font-bold text-left">
          Edit Yield master
        </span>
      </div>
      <div className="flex flex-wrap justify-left">
        <div className="w-1/2">
          <div className="flex flex-wrap gap-x-3 mb-4">
            {/* <CustomDisplayBox title="ID" value={data.id} /> */}
            <CustomInputBox
              title="Procurement"
              value={procurement}
              className="w-1/2"
              valueSetter={setProcurement}
            />
            <CustomInputBox
              title="Compliance"
              value={compliance}
              className="w-1/2"
              valueSetter={setCompliance}
            />
            <CustomInputBox
              title="Farm Yield"
              value={farmYield}
              className="w-1/2"
              valueSetter={setFarmYield}
            />
            <CustomInputBox
              title="Head Rice Yield"
              value={headRiceYield}
              className="w-1/2"
              valueSetter={setHeadRiceYield}
            />
            <CustomInputBox
              title="Transplanting Days"
              value={transplantingDays}
              className="w-1/2"
              valueSetter={setTransplantingDays}
            />
            <CustomInputBox
              title="Maturity Days"
              value={maturityDays}
              className="w-1/2"
              valueSetter={setMaturityDays}
            />
            <div className="flex gap-x-2 w-full">
              {/*UoM Drop down */}
              <div className="w-full">
                <CustomDropDownSearchSelect
                  label={"UoM"}
                  items={uomObjectsList.map((value) => {
                    return getItemName(value, "uom");
                  })}
                  selected={
                    uomId != null && uomObjectsList.length > 0
                      ? getItemName(
                          searchObject(uomObjectsList, uomId, "id"),

                          "uom"
                        )
                      : null
                  }
                  handler={(value) => {
                    if (value !== null && uomObjectsList.length > 0)
                      setUomId(
                        uomObjectsList.filter((element) => {
                          return getItemName(element, "uom") == value;
                        })[0].id
                      );
                    else {
                      setUomId(null);
                    }
                  }}
                />
              </div>

              {/* Process Types Dropdown */}
              <div className="w-full">
                <CustomDropDownSearchSelect
                  label={"Process Types"}
                  items={processTypesList.map((value) => {
                    return getItemName(value, "processType");
                  })}
                  selected={
                    processTypeId !== null && processTypesList.length > 0
                      ? getItemName(
                          searchObject(processTypesList, processTypeId, "id"),

                          "processType"
                        )
                      : null
                  }
                  handler={(value) => {
                    if (value !== null && processTypesList.length > 0)
                      setProcessTypeId(
                        processTypesList.filter((element) => {
                          return getItemName(element, "processType") == value;
                        })[0].id
                      );
                    else {
                      setProcessTypeId(null);
                    }
                  }}
                />
              </div>
            </div>
            <div className="w-full flex gap-x-2">
              <div className="w-full">
                <CustomDropDownSearchSelect
                  label={"Variety"}
                  items={varietiesList.map((value) => {
                    return getItemName(value, "name");
                  })}
                  selected={
                    varietyId !== null && varietiesList.length > 0
                      ? getItemName(
                          searchObject(varietiesList, varietyId, "id"),

                          "name"
                        )
                      : null
                  }
                  handler={(value) => {
                    if (value !== null && varietiesList.length > 0)
                      setVarietyId(
                        varietiesList.filter((element) => {
                          return getItemName(element, "name") == value;
                        })[0].id
                      );
                    else {
                      setVarietyId(null);
                    }
                  }}
                />
              </div>
              <div className="w-full">
                <CustomDropDownSearchSelect
                  label={"Zone"}
                  items={zonesList.map((value) => {
                    return getItemName(value, "name");
                  })}
                  selected={
                    zoneId !== null && zonesList.length > 0
                      ? getItemName(
                          searchObject(zonesList, zoneId, "id"),

                          "name"
                        )
                      : null
                  }
                  handler={(value) => {
                    if (value !== null && zonesList.length > 0)
                      setZoneId(
                        zonesList.filter((element) => {
                          return getItemName(element, "name") == value;
                        })[0].id
                      );
                    else {
                      setZoneId(null);
                    }
                  }}
                />
              </div>
            </div>
          </div>
          <div className="flex mt-8 justify-between">
            <CustomButton
              btnName="Cancel"
              className="p-2 bg-gray-500 hover:bg-gray-700 text-lg"
              onClick={() => {
                history.goBack();
              }}
            />
            <CustomButton
              btnName="Update"
              className="p-2 bg-green-500 hover:bg-green-700 text-lg"
              onClick={editChemical}
            />
          </div>
        </div>
      </div>
      <div className="text-xl text-red-500">{error}</div>
      <div className="flex w-max gap-x-10"></div>
    </div>
  );
};
export default YieldMasterEdit;
