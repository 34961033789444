import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import MasterDataDelete from "../../Master Data/common/MasterDataDelete";
import FarmerMasterHome from "./FarmerMasterHome";
import FarmerMasterRenew from "./FarmerMasterRenew";
import FarmerMasterAdd from "./FarmerMasterAdd"
import FarmerMasterEdit from "./FarmerMasterEdit";
import FarmerMasterDisplay from "./FarmerMasterDisplay";
import FarmerMasterRenewEdit from "./FarmerMasterRenewEdit";

const FarmerMasterRoutes = () => {
    return (
        <BrowserRouter basename="/home/transactionData/FarmerMaster/">
            <Switch>
                <Route path="/" component={FarmerMasterHome} exact />
                <Route path="/renew" component={FarmerMasterRenew} exact />
                <Route path="/freshRegistration" component={FarmerMasterAdd} exact />
                {/* <Route path="/add" component={FarmerMasterAdd} />
                <Route path="/delete/:id" component={MasterDataDelete} />
            */}
                <Route path="/edit/:id" component={FarmerMasterEdit} />
                <Route path="/renewEdit/:id" component={FarmerMasterRenewEdit} />
                <Route path="/display" component={FarmerMasterDisplay} /> 
                <Route component={() => (<Redirect to="/" />)} />
            </Switch>
        </BrowserRouter>
    );
}

export default FarmerMasterRoutes;