import { React, useEffect, useState } from "react";
import { useLocation, Redirect } from "react-router-dom";
import { EyeIcon } from "@heroicons/react/outline";
import axios from "../../../axios";
import { errorToast } from "../../../util/toast";
import CustomInputBox from "../../../components/atoms/CustomInputBox";

const getDateString = (date) => {
  let year = date.getFullYear();
  let month = date.getMonth() + 1;
  month = month < 10 ? "0" + month : month;
  let day = date.getDate();
  day = day < 10 ? "0" + day : day;
  return day + "/" + month + "/" + year;
};
const addDaysToDate = (date, days) => {
  console.log(date, days, "AAAA");
  let newDate = date;
  newDate.setDate(newDate.getDate() + days);

  newDate = getDateString(new Date(newDate));
  console.log(newDate);
  return newDate;
};

const ProductEstimationDisplay = () => {
  const location = useLocation();
  const [initialData, setInitialData] = useState(
    JSON.parse(location.state).data
  );
  console.log(initialData, "INITIAL");
  const [data, setData] = useState(null);
  const getItemName = (obj, prop1, prop2) => {
    return obj[prop2];
  };

  useEffect(() => {
    if (initialData.id !== undefined)
      axios
        .get(`productionEstimation/${initialData.id}`)
        .then((response) => {
          console.log(
            "Result is",
            response.data.Result.ProductionEstimation[0]
          );
          setData(response.data.Result.ProductionEstimation[0]);
        })
        .catch((error) => {
          console.log(error);
          errorToast(error?.message);
        });
  }, [initialData]);

  if (!initialData) return <Redirect to="/" />;
  return data !== null ? (
    <div className="overflow-hidden m-3 p-3 ">
      <div className="flex">
        <span>
          <EyeIcon className="h-5 w-5 transform text-black mt-4 mr-1" />
        </span>
        <span className=" py-4 text-gray-700 text-base font-bold text-left">
          Display Estimation
        </span>
      </div>
      <div className="">
        <div className=" grid grid-cols-2 w-2/3 gap-x-5 gap-x-3 mb-4">
          {/* <CustomDisplayBox title="ID" value={data.id} className=" pl-2"/> */}
          <CustomInputBox
            title={"Farmer"}
            disabled={true}
            borderDisable={true}
            value={getItemName(data.FarmerMaster, "identifier", "name")}
          />
          <CustomInputBox
            title={"Variety"}
            disabled={true}
            borderDisable={true}
            value={getItemName(data.VarietyMaster, "identifier", "name")}
          />
          {/* {console} */}
          <CustomInputBox
            title={"Village"}
            disabled={true}
            borderDisable={true}
            value={getItemName(
              data.FarmerMaster.VillageMaster,
              "identifier",
              "name"
            )}
          />

          <CustomInputBox
            title={"Seed Source"}
            disabled={true}
            borderDisable={true}
            value={
              data.FarmerMaster.seedSource === null
                ? "[unknown]"
                : data.FarmerMaster.seedSource
            }
          />

          <CustomInputBox
            title={"Sowing Date"}
            disabled={true}
            borderDisable={true}
            value={
              data.sowingDate === null
                ? "[unknown]"
                : getDateString(new Date(data.sowingDate))
            }
          />
          <CustomInputBox
            title={"Seed To Seed Date"}
            disabled={true}
            borderDisable={true}
            value={
              data.sowingDate === null
                ? "[unknown]"
                : addDaysToDate(
                    new Date(data.sowingDate),
                    data.VarietyMaster.seedToSeed
                  )
            }
          />
          <CustomInputBox
            title={"Actual Land Used"}
            disabled={true}
            borderDisable={true}
            value={
              data.actualLandUsed === null ? "unknown" : data.actualLandUsed
            }
          />
          <CustomInputBox
            title={"Harvesting Method"}
            disabled={true}
            borderDisable={true}
            value={
              data.HarvestingMethod?.name === null
                ? "[unknown]"
                : data.HarvestingMethod?.name
            }
          />
          <CustomInputBox
            title={"Expected Yield"}
            disabled={true}
            borderDisable={true}
            value={data.expectedYield === null ? "unknown" : data.expectedYield}
          />
          <CustomInputBox
            title={"Expected Production"}
            disabled={true}
            borderDisable={true}
            value={
              data.expectedProduction === null
                ? "[unknown]"
                : data.expectedProduction
            }
          />

          <CustomInputBox
            title={"Actual Yield"}
            disabled={true}
            borderDisable={true}
            value={data.actualYield === null ? "unknown" : data.actualYield}
          />
          <CustomInputBox
            title={"Actual Production"}
            disabled={true}
            borderDisable={true}
            value={
              data.actualProduction === null
                ? "[unknown]"
                : data.actualProduction
            }
          />
        </div>
      </div>
      <div className="flex w-max gap-x-10"></div>
    </div>
  ) : null;
};
export default ProductEstimationDisplay;
