import React from "react";
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { CustomButton } from "../atoms/CustomButton";
import axios from '../../axios';
import { useParams } from "react-router";
import {useHistory} from 'react-router-dom'
import { errorToast, successToast } from '../../util/toast.js';

const initialValues = { newPassword: '', confirmPassword: '', }

const validationSchema = Yup.object({
    newPassword: Yup.string()
        .required('Password is require'),

    confirmPassword: Yup.string()
        .required('Required')
        .oneOf([Yup.ref('newPassword'), null], 'Password must match')

})


const ResetPasswordForm = () => {

    let {email , token} = useParams();
    const history = useHistory();
    console.log(email);
    console.log(token);

    function reset(values) {
        
        // var email= {email} ;
        // var token = {token} ;
        axios.post('reset-password/'+email+'/'+token, {
          password : values.newPassword,
        //   confirmPassword:values.confirmPassword
          })
          .then(function (response) {
            console.log(response.data);

            successToast(response.data.Comments, 1000);
            history.push('/');
          })
          .catch(function (error) {
              
            errorToast(error.response?.data.Comments, 3000);
            console.log(error.response.data);
          });
    }

    return (
        <Formik
            initialValues={initialValues}

            validationSchema={validationSchema}
            onSubmit={(values) => {
                reset(values);
            }}
        >

            <div className="">
                <div className="flex flex-col sm:max-w-md  mx-auto bg-white 
                 px-6 pt-5 pb-5 rounded-3xl border-t-8 border-green-400">
                    <span className=" font-robo   text-large font-bold 
                    text-center pt-6 pb-6 text-green-500">Reset password</span>
                    <Form className="flex flex-col space-y-4">
                        <div className="flex flex-col">
                            <label htmlFor="newPassword" className="font-robo">New password</label>
                            <Field
                                className="border border-gray-400 rounded-md
                            pl-4 py-2 font-light
                            focus:outline-none hover:border-green-400"
                                name="newPassword" type="password"
                                placeholder="New password" />
                            <div className=" font-robo text-red-700 text-xs">
                                <ErrorMessage name="newPassword" />
                            </div>

                        </div>

                        <div className="flex flex-col">
                            <label htmlFor="confirmPassword" className="font-robo"> Confirm password </label>
                            <Field name="confirmPassword" type="text" placeholder="Confirm password"
                                className="border border-gray-400 rounded-md pl-4 py-2 font-light
                            hover:border-green-400 focus:outline-none"
                            />
                            <div className=" font-robo text-red-700 text-xs">
                                <ErrorMessage name="confirmPassword" />
                            </div>

                            {/* type="password" for not showing password  */}
                        </div>
                        <div className="text-center pb-6 pt-4">
                            <CustomButton type="submit" btnName="submit" className="p-2 bg-green-500 hover:bg-green-600"/>
                        </div>
                        {/* <div className="text-center pb-6 pt-4">
                        <button type="submit"
                            className="bg-green-500 
                            rounded-md  py-4 w-1/2"
                        > Submit
                        </button>
                        </div> */}

                    </Form>
                </div>

            </div>



        </Formik>
    );
}

export default ResetPasswordForm;