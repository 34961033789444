import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { CustomButton } from "../../../components/atoms/CustomButton";
import axios from "../../../axios";
import { DocumentAddIcon, PlusIcon, TrashIcon } from "@heroicons/react/outline";
import CustomInputBox from "../../../components/atoms/CustomInputBox";
import { successToast, errorToast } from "../../../util/toast";
import CustomDropDownSearchSelect from "../../../components/atoms/CustomDropDownSearchSelect";
import { validateVarietyAdd } from "../common/validations";

const getItemName = (obj, prop1) => {
  return obj[prop1];
};
//returns the first object in the list whose property matches the target
const searchObject = (items, target, property) => {
  return items.filter((element) => {
    return element[property] === target;
  })[0];
};

const VarietyMasterAdd = () => {
  const history = useHistory();
  const [uomObjectsList, setUomObjectsList] = useState([]);
  const [isSubmit, setIsSubmit] = useState(false);
  const [newVarieties, setNewVarieties] = useState([
    { name: "", uomId: null, seedToSeed: "" },
  ]);

  useEffect(() => {
    axios
      .get(`uom`)
      .then((response) => {
        setUomObjectsList(response.data.Result.Uom);
      })
      .catch((error) => {
        errorToast(error?.response?.data?.Comments);
        console.log(error);
      });
  }, []);

  const valueSetter = (index, property, newValue) => {
    let varieties = [...newVarieties];
    varieties[index][property] = newValue;
    setNewVarieties(varieties);
  };

  const addVarieties = () => {
    if (validateVarietyAdd(newVarieties)) {
      setIsSubmit(true);
      successToast("Your request is being processed");
      axios
        .post("varietyMaster/", newVarieties)
        .then((response) => {
          if (response?.data?.ResponseMessage === "SUCCESS") {
            successToast("New varieties added!");
            history.goBack();
          }
        })
        .catch((error) => {
          errorToast(error?.response?.data?.Comments);
          console.log(error);
        });
    } else {
      setIsSubmit(false);
    }
  };

  return uomObjectsList.length > 0 ? (
    <div className="p-3 m-3">
      <div className="flex mb-7">
        <DocumentAddIcon className="h-5 w-5text-black mt-3 mr-1" />
        <div className="text-base pt-3 font-bold text-gray-800">
          Add variety master
        </div>
      </div>
      <div>
        {newVarieties.map((value, index) => {
          return (
            <div className="mb-4 flex flex-wrap w-1/2">
              <div className="flex w-full">
                <div className="w-full mb-10">
                  <CustomInputBox
                    title="Variety name"
                    value={value.name}
                    valueSetter={(value) => {
                      valueSetter(index, "name", value);
                    }}
                  />
                  <CustomInputBox
                    title="Variety Seed To Seed"
                    value={value.seedToSeed}
                    valueSetter={(value) => {
                      valueSetter(index, "seedToSeed", value);
                    }}
                  />
                  <CustomDropDownSearchSelect
                    label={"UoM"}
                    items={uomObjectsList.map((value) => {
                      return getItemName(value, "uom");
                    })}
                    selected={
                      value.uomId !== null && uomObjectsList.length > 0
                        ? getItemName(
                            searchObject(uomObjectsList, value.uomId, "id"),
                            "uom"
                          )
                        : null
                    }
                    handler={(value) => {
                      if (value !== null)
                        valueSetter(
                          index,
                          "uomId",
                          uomObjectsList.filter((element) => {
                            return getItemName(element, "uom") == value;
                          })[0].id
                        );
                      else {
                        valueSetter(index, "uomId", null);
                      }
                    }}
                  />
                </div>
                {
                  newVarieties.length - 1 !== index ? (
                    <span
                      onClick={() => {
                        setNewVarieties(
                          newVarieties.filter((v, i) => {
                            return i !== index;
                          })
                        );
                      }}
                      className="h-5 w-5 ml-2 mt-8 text-gray-500 hover:text-red-500 hover:p-1"
                    >
                      <TrashIcon />
                    </span>
                  ) : (
                    <span
                      className="h-4 w-4 ml-2 mt-8 text-white rounded-md bg-green-500"
                      onClick={() => {
                        setNewVarieties([
                          ...newVarieties,
                          {
                            name: "",
                            uomId: null,
                            seedToSeed: null,
                          },
                        ]);
                      }}
                    >
                      <PlusIcon />
                    </span>
                  )
                  // :<CustomButton className=" mt-8 ml-3 text-sm p-1 bg-green-500" btnName={<span className="h-3 w-3"><PlusIcon /></span>} onClick={() => { setNewVarieties([...newVarieties, { name: "" }]) }} />
                }
              </div>
            </div>
          );
        })}
      </div>
      <div className="flex w-1/2 justify-between">
        <CustomButton
          btnName="Cancel"
          bgColor="bg-gray-400"
          onClick={() => {
            history.goBack();
          }}
        />
        {!isSubmit ? (
          <CustomButton btnName="Submit" onClick={addVarieties} />
        ) : (
          <CustomButton btnName="Submit" onClick={() => {}} />
        )}
      </div>
    </div>
  ) : null;
};
export default VarietyMasterAdd;
