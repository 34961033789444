import React, { useEffect, useState } from "react";
import { useLocation, Redirect, useHistory } from "react-router-dom";
import { CustomButton } from "../../../components/atoms/CustomButton";
import CustomInputBox from "../../../components/atoms/CustomInputBox";
import axios from "../../../axios";
import { toast } from "react-toastify";
import { PencilIcon } from "@heroicons/react/outline";
import CustomDropDownSearchSelect from "../../../components/atoms/CustomDropDownSearchSelect";
import { errorToast, successToast } from "../../../util/toast";
import { validateFarmerEdit } from "../../Master Data/common/validations";
import CustomDateSelector from "../../../components/atoms/CustomDateSelector";
import { PlusIcon } from "@heroicons/react/outline";

/*
    "year": 2021,
    "popId": 1,
    "pakkaAarthiId": 3,
    "kacchaAarthiId": 3,
    "address": "address 2",
    "cycle": "Khareef",
    "seedSource": "own",
    "seedTreatment": 0,
    "name": "Farmer 1",
    "village": "v1",
    "mandiId": 6
*/

const FarmerMasterEdit = () => {
  const updatePropList = [
    "year",
    "popId",
    "pakkaAarthiId",
    "kacchaAarthiId",
    "address",
    "cycle",
    "seedSource",
    "seedTreatment",
    "seedTreatmentChemical",
    "name",
    "fatherName",
    "village",
    "mandiId",
    "ownLand",
    "leasedLand",
    "totalLand",
    "levelingArea",
    "date",
    "remarks",
    "leasedLandowner",
  ];

  const location = useLocation();
  const history = useHistory();
  // const identifier = JSON.parse(location.state).data.identifier.value;
  const [data, setData] = useState(JSON.parse(location.state).data);

  const [aarthiList, setAarthiList] = useState(null);
  const [mandiList, setMandiList] = useState(null);
  const [popList, setPopList] = useState(null);
  const [error, setError] = useState(null);
  const [villageList, setVillageList] = useState(null);
  const [Jform, setJform] = useState(null);

  const valueSetter = (propertyName, newValue) => {
    const newData = { ...data };
    newData[propertyName] = newValue;
    setData(newData);
  };
  const getItemName = (obj, prop1, prop2) => {
    return obj[prop2];
  };
  const searchObject = (items, target, property) => {
    return items.filter((element) => {
      return element[property] === target;
    })[0];
  };

  useEffect(() => {
    axios
      .get("villageMasters/load")
      .then((response) => {
        setVillageList(response.data.Result.villageMaster);
      })
      .catch((err) => {
        console.log(err, "error");
      });
  }, []);
  useEffect(() => {
    setJform(data.jForm);
  }, [data.jForm]);
  useEffect(() => {
    const newObj = {};
    Object.keys(data).forEach((key) => {
      if (data[key]?.hasOwnProperty("show")) {
        newObj[key] = data[key].value;
      } else {
        newObj[key] = data[key];
      }
    });
    if (Object.keys(newObj).length > 0) setData(newObj);

    axios
      .get(`aarthiMaster/load`)
      .then((response) => {
        setAarthiList(response.data.Result.AarthiMaster);
        return axios.get(`mandiMaster/load`);
      })
      .then((response) => {
        setMandiList(response.data.Result.MandiMaster);
        return axios.get(`pop`);
      })
      .then((response) => {
        setPopList(response.data.Result.Pop);
      })
      .catch((error) => {
        console.log(error);

        errorToast(error?.response?.data?.Comments);
      });
  }, []);

  if (!location.state) {
    return <Redirect to="/" />;
  }

  const createSendingData = async (obj) => {
    return new Promise((resolve, reject) => {
      let newData = {};
      Object.keys(obj).forEach((property, index) => {
        if (updatePropList.includes(property)) {
          newData[property] = obj[property];
        }
        if (index === Object.keys(obj).length - 1) resolve(newData);
      });
    });
  };

  const editFarmer = () => {
    if (data?.name.trim().length === 0) {
      setError("please specify the name");
    } else {
      createSendingData(data)
        .then((newData) => {
          console.log(newData);
          if (validateFarmerEdit(newData)) {
            axios.post(`farmerMaster`, [newData]).then((res) => {
              const farmerId = data.id;
              const editJformBody = Jform.map((jf) => ({
                farmerId,
                name: jf.name,
                acre: jf.acre,
              }));
              return axios.put(`editJform`, { farmerId, editJformBody });
            });
          } else throw { message: "Please rectify the data" };
        })
        .then((response) => {
          if (response?.data?.ResponseMessage === "SUCCESS") {
            successToast("Farmer Renewed");
            history.goBack();
          }
        })
        .catch((error) => {
          errorToast(error?.response?.data?.Comments);
          // console.log(JSON.stringify(error));
        });
    }
  };
  const addJform = (index) => {
    let Farmer = [...Jform];
    Farmer = [...Farmer, { name: "", acre: null }];
    setJform(Farmer);
  };

  const removeJform = (index) => {
    let Farmer = [...Jform];
    Farmer = Farmer.filter((element, i) => {
      return i !== index;
    });
    setJform(Farmer);
  };

  const JformValueSetter = (farmerIndex, Index, property, value) => {
    if (value !== null) {
      let Farmer = [...Jform];
      Farmer[Index][property] = value;
      setJform(Farmer);
    }
  };
  return data !== null ? (
    <div className=" m-3 p-3">
      <div className="flex">
        <span>
          <PencilIcon className="h-5 w-5 transform text-black mt-4 mr-1" />
        </span>
        <span className=" py-4 text-gray-700 text-base font-bold text-left">
          Renew Farmer Master
        </span>
      </div>
      <div className="flex flex-wrap justify-left">
        <div className="w-2/3">
          <div className="flex flex-wrap gap-x-3 gap-y-4 mb-4">
            {/* <CustomInputBox
              title="Identifier"
              value={data?.identifier}
              valueSetter={(value) => {
                valueSetter("identifier", value);
              }}
            /> */}
            <CustomInputBox
              title="Year"
              value={data?.year}
              valueSetter={(value) => {
                valueSetter("year", value);
              }}
            />
            <CustomInputBox
              title="Name"
              value={data.name}
              className="w-1/2"
              valueSetter={(value) => {
                valueSetter("name", value);
              }}
            />
            <div className="w-full">
              <CustomDropDownSearchSelect
                label={"PoP"}
                items={
                  popList !== null
                    ? popList.map((value) => {
                        return value.pop;
                      })
                    : []
                }
                selected={
                  data.popId !== null && popList !== null && popList.length > 0
                    ? popList.filter((element) => {
                        return element.id === data.popId;
                      })[0].pop
                    : null
                }
                handler={(value) => {
                  if (value !== null)
                    valueSetter(
                      "popId",
                      popList.filter((element) => {
                        return element.pop === value;
                      })[0].id
                    );
                  else {
                    valueSetter("popId", null);
                  }
                }}
              />
            </div>
            <CustomInputBox
              title="Father's Name"
              value={data.fatherName}
              className="w-1/2"
              valueSetter={(value) => {
                valueSetter("fatherName", value);
              }}
            />
            <CustomInputBox
              title="Address"
              value={data.address}
              className="w-1/2"
              valueSetter={(value) => {
                valueSetter("address", value);
              }}
            />
            {/* <CustomInputBox
              title="Cycle"
              value={data.cycle}
              className="w-1/2"
              valueSetter={(value) => {
                valueSetter("cycle", value);
              }}
            /> */}
            <CustomDropDownSearchSelect
              items={["Paddy-Wheat", "Paddy-Pea", "Paddy-Potato"]}
              className="w-1/2"
              handler={(value) => {
                valueSetter("cycle", value);
              }}
              label={"Cycle"}
              selected={data["cycle"]}
            />
            <div className="mt-3 flex gap-x-3">
              <CustomDropDownSearchSelect
                items={["Market", "Own"]}
                handler={(value) => {
                  valueSetter("seedSource", value);
                }}
                label={"Seed Source"}
                selected={data["seedSource"]}
              />
              <CustomDropDownSearchSelect
                items={["Yes", "No"]}
                handler={(value) => {
                  valueSetter("seedTreatment", value === "Yes" ? 1 : 0);
                }}
                label={"Seed Treatment"}
                selected={data["seedTreatment"] === 1 ? "Yes" : "No"}
              />
              <div className="w-60">
                <CustomDropDownSearchSelect
                  label={"Village"}
                  items={
                    villageList !== null
                      ? villageList
                          .filter((val) => data.mandiId === val.MandiMaster.id)
                          .map((value) => {
                            return getItemName(value, "identifier", "name");
                          })
                      : []
                  }
                  selected={
                    data.village !== null &&
                    villageList !== null &&
                    villageList.length > 0
                      ? getItemName(
                          searchObject(villageList, data.village, "id"),
                          "identifier",
                          "name"
                        )
                      : null
                  }
                  handler={(value) => {
                    if (value !== null)
                      valueSetter(
                        // index,
                        "village",
                        villageList.filter((element) => {
                          return (
                            getItemName(element, "identifier", "name") == value
                          );
                        })[0].id
                      );
                    else {
                      valueSetter(
                        // index,
                        "village",
                        null
                      );
                    }
                  }}
                />
              </div>
              <CustomDropDownSearchSelect
                label={"Mandi"}
                items={
                  mandiList !== null
                    ? mandiList.map((value) => {
                        return value.name;
                      })
                    : []
                }
                selected={
                  data.mandiId !== null &&
                  mandiList !== null &&
                  mandiList.length > 0
                    ? mandiList.filter((element) => {
                        return element.id === data.mandiId;
                      })[0].name
                    : null
                }
                handler={(value) => {
                  if (value !== null)
                    valueSetter(
                      "mandiId",
                      mandiList.filter((element) => {
                        return element.name === value;
                      })[0].id
                    );
                  else {
                    valueSetter("mandiId", null);
                  }
                }}
              />
            </div>
            <div className="flex gap-x-3">
              <div className="w-full">
                <CustomDropDownSearchSelect
                  label={"Kaccha Aarthi"}
                  items={
                    aarthiList !== null
                      ? aarthiList
                          .filter((val) => data.mandiId === val.MandiMaster.id)
                          .map((value) => {
                            return getItemName(value, "identifier", "name");
                          })
                      : []
                  }
                  selected={
                    data.kacchaAarthiId !== null &&
                    aarthiList !== null &&
                    aarthiList.length > 0
                      ? aarthiList.filter((element) => {
                          return element.id === data.kacchaAarthiId;
                        })[0]?.name
                      : null
                  }
                  handler={(value) => {
                    if (value !== null)
                      valueSetter(
                        "kacchaAarthiId",
                        aarthiList.filter((element) => {
                          return element.name === value;
                        })[0].id
                      );
                    else {
                      valueSetter("kacchaAarthiId", null);
                    }
                  }}
                />
              </div>
              <div className="w-full">
                <CustomDropDownSearchSelect
                  label={"Pakka Aarthi"}
                  items={
                    aarthiList !== null
                      ? aarthiList
                          .filter((val) => data.mandiId === val.MandiMaster.id)
                          .map((value) => {
                            return getItemName(value, "identifier", "name");
                          })
                      : []
                  }
                  selected={
                    data.pakkaAarthiId !== null &&
                    aarthiList !== null &&
                    aarthiList.length > 0
                      ? aarthiList.filter((element) => {
                          return element.id === data.pakkaAarthiId;
                        })[0]?.name
                      : null
                  }
                  handler={(value) => {
                    if (value !== null)
                      valueSetter(
                        "pakkaAarthiId",
                        aarthiList.filter((element) => {
                          return element.name === value;
                        })[0].id
                      );
                    else {
                      valueSetter("pakkaAarthiId", null);
                    }
                  }}
                />
              </div>
              <div className="w-full">
                <CustomInputBox
                  title="Seed Treatment Chemical Name"
                  value={data.seedTreatmentChemical}
                  className="w-1/2"
                  valueSetter={(value) => {
                    valueSetter("seedTreatmentChemical", value);
                  }}
                />
              </div>
            </div>
            <div className="flex gap-x-3 w-full">
              <div className="w-full">
                <CustomInputBox
                  title="Laser Leveling Area"
                  value={data.levelingArea}
                  className="w-1/2"
                  valueSetter={(value) => {
                    valueSetter("levelingArea", value);
                  }}
                />
              </div>
              <div className="w-full">
                <CustomDateSelector
                  label={"Date"}
                  date={data.date === null ? null : new Date(data.date)}
                  onChange={(val) => {
                    valueSetter("date", val);
                  }}
                />
              </div>
              <div className="w-full">
                <CustomInputBox
                  title="Remarks"
                  value={data.remarks}
                  className="w-1/2"
                  valueSetter={(value) => {
                    valueSetter("remarks", value);
                  }}
                />
              </div>
            </div>
            <div className="w-full flex">
              <div className="flex flex-wrap w-full">
                {Jform?.map((element, varIndex) => {
                  return (
                    <div className="flex gap-x-3 w-full">
                      <div className="w-full">
                        <CustomInputBox
                          title="Jform Acre"
                          value={element.acre}
                          className="w-1/2"
                          valueSetter={(value) => {
                            JformValueSetter(0, varIndex, "acre", value);
                          }}
                        />
                      </div>

                      <div className="w-full">
                        <CustomInputBox
                          title="Jform Name"
                          value={element.name}
                          className="w-1/2"
                          valueSetter={(value) => {
                            JformValueSetter(0, varIndex, "name", value);
                          }}
                        />
                      </div>
                      {varIndex === 0 ? (
                        <span
                          className="h-5 w-10 mt-8 text-white rounded-md bg-green-500"
                          onClick={() => {
                            addJform(varIndex);
                          }}
                        >
                          <PlusIcon />
                        </span>
                      ) : (
                        <CustomButton
                          btnName="🗑️"
                          bgColor="bg-white"
                          onClick={() => {
                            removeJform(varIndex);
                          }}
                        />
                      )}
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
          <div className="flex mt-8 justify-between">
            <CustomButton
              btnName="Cancel"
              className="p-2 bg-gray-500 hover:bg-gray-700 text-lg"
              onClick={() => {
                history.goBack();
              }}
            />
            <CustomButton
              btnName="Renew"
              className="p-2 bg-green-500 hover:bg-green-700 text-lg"
              onClick={editFarmer}
            />
          </div>
        </div>
      </div>
      <div className="text-xl text-red-500">{error}</div>
      <div className="flex w-max gap-x-10"></div>
    </div>
  ) : null;
};
export default FarmerMasterEdit;
