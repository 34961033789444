import React, { useEffect, useState } from "react";
import { useLocation, Redirect, useHistory } from "react-router-dom";
import { CustomButton } from "../../../components/atoms/CustomButton";
import CustomInputBox from "../../../components/atoms/CustomInputBox";
import axios from "../../../axios";
import { toast } from "react-toastify";
import { PencilIcon } from "@heroicons/react/outline";
import CustomDropDownSearchSelect from "../../../components/atoms/CustomDropDownSearchSelect";
import { errorToast } from "../../../util/toast";
import { validateEstimationEdit } from "../../Master Data/common/validations";

const ProductionEstimationEdit = () => {
  const updatePropList = [
    "actualYield",
    "actualProduction",
    "harvestingMethodId",
  ];

  const getItemName = (obj, prop1, prop2) => {
    return obj[prop2];
  };

  const location = useLocation();
  const history = useHistory();
  const id = JSON.parse(location.state).data.id;
  const [data, setData] = useState(null);

  const [harvestingMethodList, setHarvestingMethodList] = useState(null);
  const [error, setError] = useState(null);

  const valueSetter = (index, propertyName, newValue) => {
    const newData = { ...data };
    newData[propertyName] = newValue;
    setData(newData);
  };

  useEffect(() => {
    // const newObj = {};
    // Object.keys(data).forEach((key) => {
    //   if (data[key]?.hasOwnProperty("show")) {
    //     newObj[key] = data[key].value;
    //   } else {
    //     newObj[key] = data[key];
    //   }
    // });
    // if (Object.keys(newObj).length > 0) setData(newObj);
    axios
      .get(`productionEstimation/${id}`)
      .then((response) => {
        const temp = response.data.Result.ProductionEstimation[0];
        console.log(temp);
        setData(temp);
        return axios.get(`harvestingMethod`);
      })
      .then((response) => {
        setHarvestingMethodList(response.data.Result.HarvestingMethod);
      })
      .catch((error) => {
        console.log(error);
        errorToast(error?.response?.data?.Comments);
      });
  }, []);

  if (!location.state) {
    return <Redirect to="/" />;
  }

  const createSendingData = async (obj) => {
    return new Promise((resolve, reject) => {
      let newData = {};
      Object.keys(obj).forEach((property, index) => {
        if (updatePropList.includes(property)) {
          newData[property] = obj[property];
        }
        if (index === Object.keys(obj).length - 1) resolve(newData);
      });
    });
  };
  const addDaysToDate = (date, days) => {
    let newDate = date;
    newDate.setDate(newDate.getDate() + days);
    return newDate;
  };

  const getDateString = (date) => {
    let year = date?.getFullYear();
    let month = date?.getMonth() + 1;
    month = month < 10 ? "0" + month : month;
    let day = date?.getDate();
    day = day < 10 ? "0" + day : day;
    return day + "/" + month + "/" + year;
  };
  const editProductionEstimation = () => {
    if (data?.harvestingMethodId === null) {
      //setError("please specify the actualLandUsed");
    } else if (data?.expectedYield === NaN) {
      //setError("Please specify the land used");
    } else {
      createSendingData(data)
        .then((newData) => {
          newData["actualProduction"] =
            newData["actualYield"] * data.actualLandUsed;
          console.log(newData, " is the new data");
          if (validateEstimationEdit(newData))
            return axios.put(`productionEstimation/${data.id}`, newData);
          else throw { message: "Please rectify the data" };
        })
        .then((response) => {
          if (response?.data?.ResponseMessage === "SUCCESS") {
            toast.success("Update successful!", { position: "top-center" });
            history.goBack();
          }
        })
        .catch((error) => {
          errorToast(error?.response?.data?.Comments);
          console.log(error);
        });
    }
  };

  return data !== null ? (
    <div className=" m-3 p-3">
      <div className="flex">
        <span>
          <PencilIcon className="h-5 w-5 transform text-black mt-4 mr-1" />
        </span>
        <span className=" py-4 text-gray-700 text-base font-bold text-left">
          Edit Production Estimation
        </span>
      </div>
      <div className="flex flex-wrap justify-left w-2/3">
        <div className="w-full ">
          <div className="grid grid-cols-2 gap-x-3 space-y-2 mb-4">
            {console.log(data, "Data")}
            <CustomInputBox
              title={"Farmer"}
              value={getItemName(data.FarmerMaster, "identifier", "name")}
              disabled={true}
              borderDisable={true}
            />
            <CustomInputBox
              title={"Variety"}
              value={getItemName(data.VarietyMaster, "identifier", "name")}
              disabled={true}
              borderDisable={true}
            />
            {console.log(data.sowingDate)}
            <CustomInputBox
              title={"Sowing Date"}
              value={
                data.sowingDate === null
                  ? "[unknown]"
                  : getDateString(new Date(data.sowingDate))
              }
              disabled={true}
              borderDisable={true}
            />
            <CustomInputBox
              title={"Seed To Seed Date"}
              value={
                data.sowingDate != null
                  ? getDateString(
                      addDaysToDate(
                        new Date(data.sowingDate),
                        data.VarietyMaster.seedToSeed
                      )
                    )
                  : "[unknown]"
              }
              disabled={true}
              borderDisable={true}
            />

            <CustomInputBox
              title={"Actual Land Used"}
              disabled={true}
              borderDisable={true}
              value={`${data.actualLandUsed}`}
            />
            <CustomDropDownSearchSelect
              items={
                harvestingMethodList != null
                  ? harvestingMethodList.map((item) => {
                      // return item.name;
                      return getItemName(item, "identifier", "name");
                    })
                  : []
              }
              selected={
                data.harvestingMethodId !== null &&
                harvestingMethodList !== null
                  ? getItemName(
                      harvestingMethodList.filter((item) => {
                        return item.id === data.harvestingMethodId;
                      })[0],
                      "identifier",
                      "name"
                    )
                  : null
              }
              handler={(value) => {
                if (value !== null) {
                  // setHarvestingMethodId( harvestingMethodList.filter(
                  //   (item) => getItemName(item, "identifier", "name") === value
                  // )[0].id)

                  valueSetter(
                    0,
                    "harvestingMethodId",
                    harvestingMethodList.filter(
                      (item) =>
                        getItemName(item, "identifier", "name") === value
                    )[0].id
                  );
                } else {
                  // setHarvestingMethodId(null)
                  valueSetter(0, "harvestingMethodId", null);
                }
              }}
              label={"Harvesting Method"}
            />

            <CustomInputBox
              title={"Expected Yield"}
              value={data.expectedYield}
              valueSetter={(value) => {
                valueSetter(0, "expectedYield", value);
              }}
              disabled={true}
            />
            <CustomInputBox
              title={"Expected Production"}
              value={data.expectedYield * data.actualLandUsed}
              disabled={true}
              valueSetter={(value) => {
                valueSetter(
                  0,
                  "expectedProduction",
                  value.expectedYield * data.actualLandUsed
                );
              }}
            />

            <CustomInputBox
              title={"Actual Yield"}
              value={data.actualYield}
              valueSetter={(value) => {
                // setActualYield(value)
                // console.log('value' , value , parseInt(value))
                if (value != null && value != "")
                  valueSetter(0, "actualYield", parseInt(value));
                else valueSetter(0, "actualYield", null);
              }}
            />
            <CustomInputBox
              title={"Actual Production"}
              value={data.actualYield * data.actualLandUsed}
              valueSetter={(value) => {
                valueSetter(
                  0,
                  "actualProduction",
                  value.actualYield * data.actualLandUsed
                );
              }}
              disabled={true}
            />
          </div>
          <div className="flex mt-8 justify-between">
            <CustomButton
              btnName="Cancel"
              className="p-2 bg-gray-500 hover:bg-gray-700 text-lg"
              onClick={() => {
                history.goBack();
              }}
            />
            <CustomButton
              btnName="Update"
              className="p-2 bg-green-500 hover:bg-green-700 text-lg"
              onClick={editProductionEstimation}
            />
          </div>
        </div>
      </div>
      <div className="text-xl text-red-500">{error}</div>
      <div className="flex w-max gap-x-10"></div>
    </div>
  ) : null;
};
export default ProductionEstimationEdit;
