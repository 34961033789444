import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import VarietyMasterHome from "./VarietyMasterHome";
import VarietyMasterEdit from "./VarietyMasterEdit";
import VarietyMasterDisplay from "./VarietyMasterDisplay";
import VarietyMasterAdd from "./VarietyMasterAdd";
import MasterDataDelete from "../common/MasterDataDelete";

const VarietyMasterRoutes = () => {
    return (
        <BrowserRouter basename="/home/masterData/VarietyMaster/">
            <Switch>
                <Route path="/" component={VarietyMasterHome} exact />
                <Route path="/add" component={VarietyMasterAdd} />
                <Route path="/edit/:id" component={VarietyMasterEdit} />
                <Route path="/delete/:id" component={MasterDataDelete} />
                <Route path="/display" component={VarietyMasterDisplay} />
                <Route component={() => (<Redirect to="/" />)} />
            </Switch>
        </BrowserRouter>
    );
}

export default VarietyMasterRoutes;