import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router";
import { CustomButton } from "../../../components/atoms/CustomButton";
import axios from "../../../axios";
import { DocumentAddIcon, PlusIcon, TrashIcon } from "@heroicons/react/outline";
import CustomInputBox from "../../../components/atoms/CustomInputBox";
import CustomDateSelector from "../../../components/atoms/CustomDateSelector";
import { successToast, errorToast } from "../../../util/toast";
import { validateCropLifeCycleAdd } from "../../Master Data/common/validations";
const CropLifeCycleAdd = () => {
  const history = useHistory();
  const location = useLocation();
  const [farmerVariety, setFarmerVariety] = useState(
    JSON.parse(location.state).data
  );
  const makeDataObject = () => {
    return {
      varietyId: farmerVariety.VarietyMaster.id,
      farmerId: farmerVariety.FarmerMaster.id,
      actualLandUsed: farmerVariety.actualLandUsed.value,
      sowingList: [{ landUsed: 0, sowingDate: null }],
    };
  };
  const getDateString = (date) => {
    let year = date.getFullYear();
    let month = date.getMonth() + 1;
    month = month < 10 ? "0" + month : month;
    let day = date.getDate();
    day = day < 10 ? "0" + day : day;
    return year + "/" + month + "/" + day;
  };
  const addDaysToDate = (date, days) => {
    let newDate = date;
    newDate.setDate(newDate.getDate() + days);
    return newDate;
  };
  const [varietyList, setVarietyList] = useState(null);
  const [farmerList, setFarmerList] = useState(null);
  const [newCropLifeCycle, setNewCropLifeCycles] = useState([makeDataObject()]);
  const [isSubmit, setIsSubmit] = useState(false);

  const addVariety = (index) => {
    let updatedCropLifeCycle = [...newCropLifeCycle];
    updatedCropLifeCycle[index].sowingList = [
      ...updatedCropLifeCycle[index].sowingList,
      { sowingDate: null, landUsed: 0 },
    ];
    setNewCropLifeCycles(updatedCropLifeCycle);
  };
  const removeVariety = (index, varietyIndex) => {
    let updatedCropLifeCycle = [...newCropLifeCycle];
    updatedCropLifeCycle[index].sowingList = updatedCropLifeCycle[
      index
    ].sowingList.filter((element, i) => {
      return i !== varietyIndex;
    });
    setNewCropLifeCycles(updatedCropLifeCycle);
  };

  useEffect(() => {
    axios
      .get(`varietyMaster/load`)
      .then((response) => {
        setVarietyList(response.data.Result.VarietyMaster);
        return axios.get(`farmerMaster/load`);
      })
      .then((response) => {
        setFarmerList(response.data.Result.FarmerMaster);
      })
      .catch((error) => {
        errorToast(error?.response?.data?.Comments);
        console.log(error);
      });
  }, []);

  const valueSetter = (index, property, newValue) => {
    let cropLifeCycles = [...newCropLifeCycle];
    cropLifeCycles[0].sowingList[index][property] = newValue;
    setNewCropLifeCycles(cropLifeCycles);
  };

  const addCropLifeCycles = () => {
    console.log(newCropLifeCycle);
    if (validateCropLifeCycleAdd(newCropLifeCycle)) {
      setIsSubmit(true);
      successToast("Your request is being processed");
      axios
        .post("cropLifeCycle/", newCropLifeCycle)
        .then((response) => {
          if (response?.data?.ResponseMessage === "SUCCESS") {
            successToast("New crop life cycles added!");
            history.push("/home/transactionData/cropLifeCycle");
          }
        })
        .catch((error) => {
          errorToast(error?.response?.data?.Comments);
          console.log(error);
        });
    } else {
      setIsSubmit(false);
    }
  };

  return (
    <div className="p-3 m-3">
      <div className="flex mb-7">
        <DocumentAddIcon className="h-5 w-5text-black mt-3 mr-1" />
        <div className="text-base pt-3 font-bold text-gray-800">
          Add Crop Life Cycle
        </div>
      </div>
      <div>
        {console.log("NEWCROP", newCropLifeCycle)}
        {newCropLifeCycle?.map((value, index) => {
          return (
            <div className="mb-10  w-1/2">
              <div className=" gap-x-3">
                <div className="space-y-5">
                  <div className="w-full flex">
                    <CustomInputBox
                      title={"Farmer"}
                      disabled={true}
                      borderDisable={true}
                      value={`${farmerVariety.FarmerMaster.name}`}
                    />

                    <CustomInputBox
                      title={"Variety"}
                      disabled={true}
                      borderDisable={true}
                      value={`${farmerVariety.VarietyMaster.name}`}
                    />
                  </div>

                  <div className="w-full flex">
                    {farmerVariety.actualLandUsed.valu && (
                      <CustomInputBox
                        title={"Actual Land Used"}
                        disabled={true}
                        borderDisable={true}
                        value={`${farmerVariety.actualLandUsed.value}`}
                      />
                    )}
                    <div className="w-full"></div>
                  </div>

                  {value.sowingList.map((element, varIndex) => {
                    console.log("ELEMNT", element);

                    return (
                      <div className="flex gap-x-2">
                        <div className="w-1/2">
                          <CustomDateSelector
                            label={"Sowing Date"}
                            date={
                              element.sowingDate === null
                                ? null
                                : element.sowingDate
                            }
                            onChange={(val) => {
                              valueSetter(varIndex, "sowingDate", val);
                            }}
                          />
                        </div>

                        <div className="w-1/2">
                          <div className="w-full gap-x-3">
                            <CustomInputBox
                              title="Land Used"
                              value={element.landUsed}
                              valueSetter={(value) => {
                                valueSetter(varIndex, "landUsed", value);
                              }}
                            />
                          </div>
                        </div>

                        {varIndex === 0 ? (
                          <div className="ml-5 mt-7">
                            <CustomButton
                              btnName="Add"
                              onClick={() => {
                                addVariety(index);
                              }}
                            />
                          </div>
                        ) : (
                          <CustomButton
                            btnName="🗑️"
                            bgColor="bg-white"
                            onClick={() => {
                              removeVariety(index, varIndex);
                            }}
                          />
                        )}
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          );
        })}
      </div>
      <div className="flex w-1/2 justify-between">
        <CustomButton
          btnName="Cancel"
          bgColor="bg-gray-400"
          onClick={() => {
            history.goBack();
          }}
        />
        {!isSubmit ? (
          <CustomButton btnName="Submit" onClick={addCropLifeCycles} />
        ) : (
          <CustomButton btnName="Submit" onClick={() => {}} />
        )}
      </div>
    </div>
  );
};
export default CropLifeCycleAdd;
