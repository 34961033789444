import React, { useEffect, useState } from "react";
import { useLocation, Redirect, useHistory } from "react-router-dom";
import { CustomButton } from "../../../components/atoms/CustomButton";
import CustomInputBox from "../../../components/atoms/CustomInputBox";
import axios from "../../../axios";
import { toast } from "react-toastify";
import { PencilIcon, PlusIcon, TrashIcon } from "@heroicons/react/outline";
import CustomDropDownSearchSelect from "../../../components/atoms/CustomDropDownSearchSelect";
import { errorToast } from "../../../util/toast";
import CustomDateSelector from "../../../components/atoms/CustomDateSelector";
import CustomToggleSwitch from "../../../components/atoms/CustomToggleSwitch";
import { validatePurchaseEdit } from "../../Master Data/common/validations";

const PurchaseDetailEdit = () => {
  const updatePropList = [
    "actualLandUsed",
    "sowingDate",
    "seedToSeedDate",
    "slipNumber",
    "slipQuantity",
    "slipDate",
    "isPurchased",
    "kacchaAarthiId",
    "pakkaAarthiId",
    "jFormDate",
    "jFormNumber",
    "jFormName",
    "jFormQuantity",
    "iForm",
    "bill",
    "po",
    "reason",
  ];

  const location = useLocation();
  const history = useHistory();
  const id = JSON.parse(location.state).data.id;
  const [data, setData] = useState([]);

  const [aarthiList, setAarthiList] = useState(null);
  const [error, setError] = useState(null);
  const [purchasedStatus, setPurchasedStatus] = useState(false);

  const [purchase, setPurchase] = useState(null);

  const valueSetter = (propertyName, newValue) => {
    const newData = { ...data[0] };
    newData[propertyName] = newValue;
    setData([newData]);
  };
  const searchObject = (items, target, property) => {
    return items.filter((element) => {
      return element[property] === target;
    })[0];
  };
  const getItemName = (obj, prop1, prop2) => {
    return obj[prop2];
  };
  useEffect(() => {
    // const newObj = {};
    // Object.keys(data).forEach((key) => {
    //   if (data[key]?.hasOwnProperty("show")) {
    //     newObj[key] = data[key].value;
    //   } else {
    //     newObj[key] = data[key];
    //   }
    // });
    // if (Object.keys(newObj).length > 0) setData(newObj);
    axios
      .get(`purchaseDetail/${id}`)
      .then((response) => {
        const temp = response.data.Result.PurchaseDetail;
        temp.slipDate = temp.slipDate !== null ? new Date(temp.slipDate) : null;
        if (temp.bill) {
          temp.bill = JSON.parse(temp.bill);
        }
        if (temp.iForm) {
          temp.iForm = JSON.parse(temp.iForm);
        }
        if (temp.po) {
          temp.po = JSON.parse(temp.po);
        }
        console.log(temp, "TEMPPP");
        setData([temp]);
        return axios.get(`aarthiMaster/load`);
      })
      .then((response) => {
        setAarthiList(response.data.Result.AarthiMaster);
      })
      .catch((error) => {
        console.log(error);
        errorToast(error?.response?.data?.Comments);
      });
  }, []);

  if (!location.state) {
    return <Redirect to="/" />;
  }

  const createSendingData = async (obj) => {
    return new Promise((resolve, reject) => {
      let newData = {};
      Object.keys(obj).forEach((property, index) => {
        if (updatePropList.includes(property)) {
          newData[property] = obj[property];
        }
        if (index === Object.keys(obj).length - 1) resolve(newData);
      });
    });
  };

  const getDateString = (date) => {
    let year = date.getFullYear();
    let month = date.getMonth() + 1;
    month = month < 10 ? "0" + month : month;
    let day = date.getDate();
    day = day < 10 ? "0" + day : day;
    return day + "/" + month + "/" + year;
  };

  const editPurchaseDetail = () => {
    let flag = true;
    let billSum = 0;
    let iFormSum = 0;
    let slipQty = parseInt(data[0].slipQuantity);
    data[0].bill.map((bill) => {
      billSum += parseInt(bill.billQuantity);
    });
    data[0].iForm.map((iFormItem) => {
      iFormSum += parseInt(iFormItem.iFormQuantity);
    });
    if (slipQty == billSum && slipQty == iFormSum) {
      console.log("fine");
    } else {
      flag = false;
      errorToast(
        "Sum of Bill Quantities and Sum of iForm Quantities should be equal to Slip Quantity"
      );
    }
    if (flag) {
      if (data[0]?.actualLandUsed === NaN) {
        setError("please specify the actualLandUsed");
      } else if (data[0]?.landUsed === NaN) {
        setError("Please specify the land used");
      } else {
        createSendingData(data[0])
          .then((newData) => {
            console.log(newData, " is the new data");
            if (purchasedStatus) {
              if (validatePurchaseEdit(newData))
                return axios.put(`purchaseDetail/${data[0].id}`, newData);
              else throw { message: "Please rectify the data" };
            } else {
              return axios.put(`purchaseDetail/${data[0].id}`, newData);
            }
          })
          .then((response) => {
            if (response?.data?.ResponseMessage === "SUCCESS") {
              toast.success("Update successful!", { position: "top-center" });
              history.push("/home/transactionData/purchase");
            }
          })
          .catch((error) => {
            errorToast(error?.response?.data?.Comments);
            console.log(error);
          });
      }
    }
  };

  const iFormValueSetter = (purchaseIndex, iFormIndex, property, value) => {
    if (value !== null) {
      let purchase = [...data];
      purchase[purchaseIndex].iForm[iFormIndex][property] = value;
      setData(purchase);
    }
  };

  const addiform = (index) => {
    let purchase = [...data];
    purchase[index].iForm = [
      ...purchase[index].iForm,
      { iFormNumber: null, iFormQuantity: null, iFormDate: null },
    ];
    setData(purchase);
  };

  const removeiform = (index) => {
    let purchase = [...data];
    purchase[index].iForm = purchase[index].iForm.filter((element, i) => {
      return i !== index;
    });
    setData(purchase);
  };

  const billValueSetter = (purchaseIndex, billIndex, property, value) => {
    if (value !== null) {
      let purchase = [...data];
      purchase[purchaseIndex].bill[billIndex][property] = value;
      setData(purchase);
    }
  };

  const addBill = (index) => {
    let purchase = [...data];
    purchase[index].bill = [
      ...purchase[index].bill,
      { billNumber: null, billQuantity: null, billDate: null },
    ];
    setData(purchase);
  };

  const removeBill = (index) => {
    let purchase = [...data];
    purchase[index].bill = purchase[index].bill.filter((element, i) => {
      return i !== index;
    });
    setData(purchase);
  };

  const poValueSetter = (purchaseIndex, poIndex, property, value) => {
    if (value !== null) {
      let purchase = [...data];
      purchase[purchaseIndex].po[poIndex][property] = value;
      setData(purchase);
    }
  };

  const addPo = (index) => {
    let purchase = [...data];
    purchase[index].po = [
      ...purchase[index].po,
      { poNumber: null, poQuantity: null },
    ];
    setData(purchase);
  };

  const removePo = (index) => {
    let purchase = [...data];
    purchase[index].po = purchase[index].po.filter((element, i) => {
      return i !== index;
    });
    setData(purchase);
  };

  return data.length !== 0 ? (
    <div className=" m-3 p-3">
      <div className="flex">
        <span>
          <PencilIcon className="h-5 w-5 transform text-black mt-4 mr-1" />
        </span>
        <span className=" py-4 text-gray-700 text-base font-bold text-left">
          Edit Purchase Detail
        </span>
      </div>
      <div className="grid grid-cols-2 w-2/3">
        {/* <div className="flex gap-x-3">
            <CustomDateSelector 
              label={"Sowing Date"}
              date = {data.sowingDate === null ? (new Date()) : (new Date(data.sowingDate))}
              onChange={(value)=>{valueSetter("sowingDate", getDateString(new Date(value)))}}
            />
            <CustomDateSelector 
              label={"Seed To Seed Date"}
              date = {data.seedToSeedDate === null ? (new Date()) : (new Date(data.seedToSeedDate))}
              onChange={(value)=>{valueSetter("seedToSeedDate", getDateString(new Date(value)))}}
            />
          </div> */}
        <CustomInputBox
          title={"Farmer Code"}
          disabled={true}
          borderDisable={true}
          value={getItemName(
            data[0].FarmerVariety.FarmerMaster,
            "identifier",
            "name"
          )}
        />
        <CustomInputBox
          title={"Variety"}
          disabled={true}
          borderDisable={true}
          value={getItemName(
            data[0].FarmerVariety.VarietyMaster,
            "identifier",
            "name"
          )}
        />
        <CustomInputBox
          title={"Purchaser Name"}
          disabled={true}
          borderDisable={true}
          value={data[0].purchaserName}
        />
        <div className="w-60">
          <CustomInputBox
            title={"Slip Number"}
            value={data[0].slipNumber}
            valueSetter={(value) => {
              valueSetter("slipNumber", value);
            }}
          />
        </div>
        <div className="w-60">
          <CustomInputBox
            title={"Slip Quantity"}
            value={data[0].slipQuantity}
            valueSetter={(value) => {
              valueSetter("slipQuantity", value);
            }}
          />
        </div>
        <div className="w-60">
          <CustomDateSelector
            label={"Slip Date"}
            date={data[0].slipDate === null ? null : new Date(data[0].slipDate)}
            onChange={(value) => {
              valueSetter("slipDate", new Date(value));
            }}
          />
        </div>
        {/* <div className="mt-1">
          <CustomToggleSwitch
            label2={"Purchased"}
            checked={data[0].isPurchased === 1}
            onChange={(value) => {
              valueSetter("isPurchased", value ? 1 : 0);
            }}
          />
        </div> */}
        <div className="w-60">
          <CustomDropDownSearchSelect
            label={"Purchased"}
            items={["Yes", "No"]}
            selected={data[0].isPurchased === 1 ? "Yes" : "No"}
            handler={(value) => {
              valueSetter("isPurchased", value == "Yes" ? 1 : 0);
            }}
          />
        </div>
        <div className="w-60">
          <CustomDropDownSearchSelect
            label={"Kaccha Aarthi Name"}
            items={
              aarthiList !== null
                ? aarthiList.map((element) => {
                    return getItemName(element, "identifier", "name");
                  })
                : []
            }
            selected={
              aarthiList !== null && data[0].kacchaAarthiId !== null
                ? getItemName(
                    searchObject(aarthiList, data[0].kacchaAarthiId, "id"),
                    "identifier",
                    "name"
                  )
                : null
            }
            handler={(value) => {
              if (value !== null) {
                valueSetter(
                  "kacchaAarthiId",
                  aarthiList.filter((element) => {
                    return getItemName(element, "identifier", "name") === value;
                  })[0].id
                );
              } else {
                valueSetter("kacchaAarthiId", null);
              }
            }}
          />
        </div>
        <div className="w-60">
          <CustomDropDownSearchSelect
            label={"Pakka Aarthi Name"}
            items={
              aarthiList !== null
                ? aarthiList.map((element) => {
                    return getItemName(element, "identifier", "name");
                  })
                : []
            }
            selected={
              aarthiList !== null && data[0].pakkaAarthiId !== null
                ? getItemName(
                    searchObject(aarthiList, data[0].pakkaAarthiId, "id"),
                    "identifier",
                    "name"
                  )
                : null
            }
            handler={(value) => {
              if (value !== null) {
                valueSetter(
                  "pakkaAarthiId",
                  aarthiList.filter((element) => {
                    return getItemName(element, "identifier", "name") === value;
                  })[0].id
                );
              } else {
                valueSetter("pakkaAarthiId", null);
              }
            }}
          />
        </div>
        <div className="w-60">
          <CustomDateSelector
            label={"J-Form Date"}
            date={
              data[0].jFormDate === null ? null : new Date(data[0].jFormDate)
            }
            onChange={(value) => {
              valueSetter("jFormDate", new Date(value));
            }}
          />
        </div>
        <div className="w-60">
          <CustomInputBox
            title={"J-Form Number"}
            value={data[0].jFormNumber}
            valueSetter={(value) => {
              valueSetter("jFormNumber", value);
            }}
          />
        </div>
        <div className="w-60">
          <CustomInputBox
            title={"Name on J-Form"}
            value={data[0].jFormName}
            valueSetter={(value) => {
              valueSetter("jFormName", value);
            }}
          />
        </div>
        <div className="w-60">
          <CustomInputBox
            title={"J-Form Quantity"}
            value={data[0].jFormQuantity}
            valueSetter={(value) => {
              valueSetter("jFormQuantity", value);
            }}
          />
        </div>
        <div className="w-60"></div>
      </div>
      {data[0].isPurchased || data[0].isPurchased === null ? (
        <>
          {data[0]?.iForm?.map((element, varIndex) => {
            return (
              <div className="flex gap-x-3 w-2/3" key={varIndex}>
                <div className="w-full">
                  <CustomInputBox
                    title={"I-Form Number"}
                    value={element.iFormNumber}
                    valueSetter={(value) => {
                      iFormValueSetter(0, varIndex, "iFormNumber", value);
                    }}
                  />
                </div>
                <div className="w-full">
                  <CustomDateSelector
                    label={"I-Form Date"}
                    date={
                      element.iFormDate === null
                        ? null
                        : new Date(element.iFormDate)
                    }
                    onChange={(value) => {
                      iFormValueSetter(0, varIndex, "iFormDate", value);
                    }}
                  />
                </div>
                <div className="w-full">
                  <CustomInputBox
                    title={"I-Form Quantity"}
                    value={element.iFormQuantity}
                    valueSetter={(value) => {
                      iFormValueSetter(0, varIndex, "iFormQuantity", value);
                    }}
                  />
                </div>
                {varIndex !== data[0].iForm.length - 1 ? (
                  <span
                    onClick={() => {
                      removeiform(0);
                    }}
                    className="h-5 w-16 mt-8 mr-3 text-gray-500 hover:text-red-500 hover:p-1"
                  >
                    <TrashIcon />
                  </span>
                ) : (
                  <span
                    className="h-5 w-16 mt-8 text-white rounded-md bg-green-500"
                    onClick={() => {
                      addiform(0);
                    }}
                  >
                    <PlusIcon />
                  </span>
                )}
              </div>
            );
          })}
          <br />
          {data[0]?.po?.map((element, varIndex) => {
            return (
              <div className="flex gap-x-3 w-2/3" key={varIndex}>
                <div className="w-full">
                  <CustomInputBox
                    title={"Po Number"}
                    value={element.poNumber}
                    valueSetter={(value) => {
                      poValueSetter(0, varIndex, "poNumber", value);
                    }}
                  />
                </div>
                <div className="w-full">
                  <CustomDateSelector
                    label={"Po Date"}
                    date={
                      element.poDate === null ? null : new Date(element.poDate)
                    }
                    onChange={(value) => {
                      poValueSetter(0, varIndex, "poDate", value);
                    }}
                  />
                </div>
                {varIndex !== data[0].po.length - 1 ? (
                  <span
                    onClick={() => {
                      removePo(0);
                    }}
                    className="h-5 w-10 mt-8 mr-3 text-gray-500 hover:text-red-500 hover:p-1"
                  >
                    <TrashIcon />
                  </span>
                ) : (
                  <span
                    className="h-5 w-10 mt-8 text-white rounded-md bg-green-500"
                    onClick={() => {
                      addPo(0);
                    }}
                  >
                    <PlusIcon />
                  </span>
                )}
              </div>
            );
          })}
          <br />
          {data[0]?.bill?.map((element, varIndex) => {
            return (
              <div className="flex gap-x-3 w-2/3" key={varIndex}>
                <div className="w-full">
                  <CustomInputBox
                    title={"Bill Number"}
                    value={element.billNumber}
                    valueSetter={(value) => {
                      billValueSetter(0, varIndex, "billNumber", value);
                    }}
                  />
                </div>
                <div className="w-full">
                  <CustomDateSelector
                    label={"Bill Date"}
                    date={
                      element.billDate === null
                        ? null
                        : new Date(element.billDate)
                    }
                    onChange={(value) => {
                      billValueSetter(0, varIndex, "billDate", value);
                    }}
                  />
                </div>
                <div className="w-full">
                  <CustomInputBox
                    title={"Bill Quantity"}
                    value={element.billQuantity}
                    valueSetter={(value) => {
                      billValueSetter(0, varIndex, "billQuantity", value);
                    }}
                  />
                </div>
                {varIndex !== data[0].bill.length - 1 ? (
                  <span
                    onClick={() => {
                      removeBill(0);
                    }}
                    className="h-5 w-16 mt-8 mr-3 text-gray-500 hover:text-red-500 hover:p-1"
                  >
                    <TrashIcon />
                  </span>
                ) : (
                  <span
                    className="h-5 w-16 mt-8 text-white rounded-md bg-green-500"
                    onClick={() => {
                      addBill(0);
                    }}
                  >
                    <PlusIcon />
                  </span>
                )}
              </div>
            );
          })}
        </>
      ) : (
        <>
          <div className="w-2/3">
            <CustomInputBox
              title={"Reason"}
              value={data[0].reason !== null ? data[0].reason : ""}
              valueSetter={(value) => {
                valueSetter("reason", value);
              }}
            />
          </div>
        </>
      )}
      <br />
      <br />
      <div className="w-1/3 flex flex-row justify-between">
        {/* Buttons section */}
        <CustomButton
          btnName="Cancel"
          className="p-2 bg-gray-500 hover:bg-gray-700 text-lg"
          onClick={() => {
            history.goBack();
          }}
        />
        <CustomButton
          btnName="Update"
          className="p-2 bg-green-500 hover:bg-green-700 text-lg"
          onClick={editPurchaseDetail}
        />
      </div>

      <div className="text-xl text-red-500">{error}</div>
      <div className="flex w-max gap-x-10"></div>
    </div>
  ) : null;
};
export default PurchaseDetailEdit;
