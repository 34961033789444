import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import VillageMasterHome from "./VillageMasterHome";
import VillageMasterEdit from "./VillageMasterEdit";
// import VarietyMasterDisplay from "./VarietyMasterDisplay";
import VillageMasterAdd from "./VillageMasterAdd";
// import MasterDataDelete from "../common/MasterDataDelete";

const VillageMasterRoutes = () => {
    return (
        <BrowserRouter basename="/home/masterData/villageMaster/">
            <Switch>
                <Route path="/" component={VillageMasterHome} exact />
                <Route path="/add" component={VillageMasterAdd} />
                <Route path="/edit/:id" component={VillageMasterEdit} />
                {/* <Route path="/delete/:id" component={MasterDataDelete} /> */}
                {/* <Route path="/display" component={VillageMasterDisplay} /> */}
                <Route component={() => (<Redirect to="/" />)} />
            </Switch>
        </BrowserRouter>
    );
}

export default VillageMasterRoutes;