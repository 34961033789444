import { BrowserRouter, Route, Switch } from "react-router-dom";
import SeasonalPlanningRoutes from "./SeasonalPlanning/routes";
import CropLifeCycleRoutes from "./CropLifeCycle/routes";
import InitialValidationRoutes from "./Validation/routes";
import AllVisitsRoutes from "./AllVisits/routes";
import EstimationRoutes from "./Estimation/routes";
import PurchaseRoutes from "./Purchase/routes";
import SamplingRoutes from "./Sampling/routes";
import SamplingconfirmRoutes from "./SamplingConfirmed/routes";
import FarmerMasterRoutes from "./FarmerMaster/FarmerMasterRoutes";
import SamplingResultRoutes from "./SamplingResult/routes";
import ResamplingRoutes from "./ResamplingApproval/routes";

const TransactionDataRoutes = () => {
  console.log("TRANSACTION");
  return (
    <BrowserRouter basename="/home/transactionData/">
      <Switch>
        <Route path="/" exact>
          <SeasonalPlanningRoutes />
        </Route>
        <Route path="/seasonalPlanning">
          <SeasonalPlanningRoutes />
        </Route>
        <Route path="/farmerMaster">
          <FarmerMasterRoutes />
        </Route>
        <Route path="/cropLifeCycle">
          <CropLifeCycleRoutes />
        </Route>
        <Route path="/initialValidation">
          <InitialValidationRoutes />
        </Route>
        <Route path="/allVisits">
          <AllVisitsRoutes />
        </Route>
        <Route path="/estimation">
          <EstimationRoutes />
        </Route>
        <Route path="/sampling">
          <SamplingRoutes />
        </Route>
        <Route path="/samplingconfirm">
          <SamplingconfirmRoutes />
        </Route>
        <Route path="/resamplingapproval">
          <ResamplingRoutes />
        </Route>
        <Route path="/samplingresult">
          <SamplingResultRoutes />
        </Route>
        <Route path="/purchase">
          <PurchaseRoutes />
        </Route>
      </Switch>
    </BrowserRouter>
  );
};

export default TransactionDataRoutes;
