import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import TransactionDataDelete from "../common/TransactionDataDelete";
import Home from "./home";

const ResamplingRoutes = () => {
  console.log("RESAMPLING");
  return (
    <BrowserRouter basename="/home/transactionData/resamplingapproval">
      <Switch>
        <Route path="/" component={Home} exact />
        <Route component={() => <Redirect to="/" />} />
      </Switch>
    </BrowserRouter>
  );
};

export default ResamplingRoutes;
