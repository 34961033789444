import React from 'react';
import Footer from '../components/molecules/Footer';
import Navbar from '../components/molecules/Navbar';
import ResetPasswordForm from '../components/molecules/ResetPasswordForm';


const ResetPassword = () => {
    return (
        <div className=" min-h-screen bg-hero-img4 bg-cover" >
            <div className=" flex flex-col space-y-28">
                <div>
                    <Navbar />
                </div>
                <div className="">
                    <ResetPasswordForm />
                </div>
                <div>
                    <Footer />
                </div>
            </div>
        </div>
    );
}

export default ResetPassword;